import React, { useState, useEffect, useRef } from 'react';
import './maincomponentD.css';
import { DayPicker } from 'react-day-picker';
import { IsValidEmail } from '../utils/GeneralUtils';
import { AxiosResponse } from 'axios';
import Modal from '@mui/material/Modal';
import emailjs from 'emailjs-com';
import { WorkyTime, ShiftStartEndDetails } from '../common/CommonInterfaces';
import {convertWorkyTimeToEpochSec, convertDatesToEpochSec} from '../common/TimeUtils'
import {populateShiftRequestContentV2} from '../common/ShiftServiceUtils'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';

import {
	RemoteExecutionServiceImpl,
	ServerResponse,
	getGlobalUserDetails,
	RemoteGETCallInputObject,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import 'react-day-picker/dist/style.css';
import {
	ShiftRequestDto,
	FirmDataDto,

	Workertype,
	CreateShiftRequestAPIObj,

	ShiftRequestUnitDtoV2,
	ShiftReqResponse,
} from '../jobberViews/objects/ShiftRequestConstants';
import { WorkyTimePicker, WorkyTimePickerFuture } from '../common/TimePicker';
import {
	convertTo12HourFormat,
	extractDate,
	UserDetails,
	JobberDetailsForOrg,
} from '../orgViews/OrgUtils';
import { JobberProfileBuilder } from '../orgViews/orgConstants'
// JobberProfileBuilder
import { TopNav, PushToNewSr, PushtoSrHistory } from '../orgViews/OrgCommonComponents';
import { Tooltip } from '@mui/material';
import { JobberProfile } from '../orgViews/jobberProfileBuilder';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import East from '@mui/icons-material/East';
import West from '@mui/icons-material/West';

const remoteCallObject = RemoteExecutionServiceImpl();
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const MainCompDesktop = () => {
	
	const isMobile = useIsMobile();
	const StepperManager = (page) => {
		if (page === 1) {
			return (
				<div className={isMobile? 'stepper-holder-mobile':'stepper-holder'}>
					<div className={isMobile? 'step-mobile':'step'}>
						<div style={{ background: '#2c68bd' }} id='step1' className='step-icon'>
							<div className='step-icon-inner1'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							<span style={{ fontWeight: 600 }}>Worker Selection</span>
						</div>
					</div>
					<div className='stepline'></div>

					<div className={isMobile? 'step-mobile':'step'}>
						<div id='step2' className='step-icon'>
							<div className='step-icon-inner2'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							Date & Time
						</div>
					</div>
					<div className='stepline'></div>

					<div className={isMobile? 'step-mobile':'step'}>
						<div id='step4' className='step-icon'>
							<div className='step-icon-inner3'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							Confirm
						</div>
					</div>
				</div>
			);
		} else if (page === 2) {
			return (
				<div className={isMobile? 'stepper-holder-mobile':'stepper-holder'}>
					<div className={isMobile? 'step-mobile':'step'}>
						<div style={{ background: '#2c68bd' }} id='step1' className='step-icon'>
							<div className='step-icon-inner1'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							Worker Selection
						</div>
					</div>
					<div id='stepline-active' className='stepline'></div>

					<div className={isMobile? 'step-mobile':'step'}>
						<div style={{ background: '#2c68bd' }} id='step2' className='step-icon'>
							<div className='step-icon-inner2'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							<span style={{ fontWeight: 600 }}>Date & Time</span>
						</div>
					</div>
					<div className='stepline'></div>



					<div className={isMobile? 'step-mobile':'step'}>
						<div id='step4' className='step-icon'>
							<div className='step-icon-inner3'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							Confirm
						</div>
					</div>
				</div>
			);
		} 
		
		else if (page === 3) {
			return (
				<div className={isMobile? 'stepper-holder-mobile':'stepper-holder'}>
					<div className={isMobile? 'step-mobile':'step'}>
						<div style={{ background: '#2c68bd' }} id='step1' className='step-icon'>
							<div className='step-icon-inner1'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							Worker Selection
						</div>
					</div>
					<div id='stepline-active' className='stepline'></div>

					<div className={isMobile? 'step-mobile':'step'}>
						<div style={{ background: '#2c68bd' }} id='step2' className='step-icon'>
							<div className='step-icon-inner2'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							Date & Time
						</div>
					</div>
					<div id='stepline-active' className='stepline'></div>

					<div className={isMobile? 'step-mobile':'step'}>
						<div style={{ background: '#2c68bd' }} id='step4' className='step-icon'>
							<div className='step-icon-inner3'></div>
						</div>
						<div className={isMobile? 'step-text-mobile':'step-text'}>
							<span style={{ fontWeight: 600 }}>Confirm</span>
						</div>
					</div>
				</div>
			);
		}
	};
	const makePrevJobbersApiCall = async () => {
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'org/previousJobbers',
			headers: {},
			queryParams: null,
		};

		const prevJobbers: AxiosResponse<ServerResponse<JobberDetailsForOrg[], Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<JobberDetailsForOrg[], Error>>(
				getCallInput
			);

		if (
			prevJobbers &&
			prevJobbers.data &&
			prevJobbers.data.data &&
			prevJobbers.data.data.length >= 1
		) {
			console.log('step 3');
			setHasPrevJobbers(false);

			setPrevJobbers(prevJobbers.data.data);
		}
	};
	const userInfo = getGlobalUserDetails();
	const orgData = JSON.parse(localStorage.getItem('orgData'));
	// console.log('ORG DATA :: ' + JSON.stringify(orgData));

	const [firmID, setFirmId] = useState('10201-390320-2121');
	const [facilityID, setFacilityID] = useState('facilityIdValue');
	const [firmLocation, setFirmLocation] = useState('locationValue');
	const [isReocurringShift, setIsReocurringShift] = useState(false);
	const simpleLocation = orgData.addressDto.city;
	const [workertype, setWorkertype] = useState(Workertype.WAREHOUSE_ASSOCIATE);
	const requestOrgName = orgData.orgName;
	const jobberHourlyRate = orgData.orgBillingData.agreedRatesPerAWorkerType.WAREHOUSE_ASSOCIATE;
	const [shiftEpochList, setShiftEpochList] = useState<ShiftStartEndDetails[]>([]);
	const [startDateEpoch, setStartDateEpoch] = useState<number[]>([]); //client will populate startDateOfSRUnitEpochSeconds
	const [endDateEpoch, setEndDateEpoch] = useState<number[]>([]); //client will populate endDateOfSRUnitEpochSeconds
	const [isTestData, setIsTestData] = useState(true);

	//assists setting preferred jobbers
	const [selectedJobbers, setSelectedJobbers] = useState([]);
	const [ selectedJobbersSubset, setSelectedJobbersSubset] = useState([]);
	const [hasPrevJobbers, setHasPrevJobbers] = useState(true);

	//assists populating shift request content
	const [numJobbers, setNumJobbers] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		console.log("v3StartTimeHourRef " + JSON.stringify(v3StartTimeHourRef))
		setStartTimeHour(v3StartTimeHourRef.current);
		setStartTimeMinute(v3StartTimeMinuteRef.current);
		setEndTimeHour(v3EndTimeHourRef.current);
		setEndTimeMinute(v3EndTimeMinuteRef.current);
	});

	useEffect(() => {
		const fetchData = async () => {
		  await makePrevJobbersApiCall();
		};
		fetchData();
	  }, []);

	const v3StartTimeHourRef = useRef(null);
	const v3StartTimeMinuteRef = useRef(null);
	const v3EndTimeHourRef = useRef(null);
	const v3EndTimeMinuteRef = useRef(null);

	const handleV3StartTimeHourChange = (newStartTime: number) => {
		v3StartTimeHourRef.current = newStartTime;
	};

	const handleV3StartTimeMinuteChange = (newStartTime: number) => {
		v3StartTimeMinuteRef.current = newStartTime;
	};

	const handleV3EndTimeHourChange = (newEndTime: number) => {
		v3EndTimeHourRef.current = newEndTime;
	};

	const handleV3EndTimeMinuteChange = (newEndTime: number) => {
		v3EndTimeMinuteRef.current = newEndTime;
	};

	//assists startDateEpoch + endDateEpoch
	const [selectedDays, setSelectedDays] = useState([]);
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [selectedWeeks, setSelectedWeeks] = useState<Date[]>([]);
	const [confirmedSelection, setConfirmedSelection] = useState(false);


	const [startTimeHour, setStartTimeHour] = useState(null);
	const [startTimeMinute, setStartTimeMinute] = useState(null);
	const [endTimeHour, setEndTimeHour] = useState(null);
	const [endTimeMinute, setEndTimeMinute] = useState(null);

	// succesful modal submission
	const [modalOpen, setModalOpen] = useState(false);
	const handleClose = () => setModalOpen(false);

	// error modal submission
	const [modalErrOpen, setModalErrOpen] = useState(false);
	const handleErrModalClose = () => setModalErrOpen(false);

	// unusual shift length modal
	const [modalShiftOpen, setModalShiftOpen] = useState(false);
	const handleShiftModalClose = () => setModalShiftOpen(false);
	const handleShiftback = () => {
		setCurrentPage(currentPage - 1);
		setModalShiftOpen(false);
	};

	const [prevJobbers, setPrevJobbers] = useState<JobberDetailsForOrg[] | null>(null);

	const sendErrorEmailv2 = async () => {
		// e.preventDefault();

		const serviceID = 'service_3mlm2ls';
		const templateID = 'template_xl6xpjw';
		const userID = 'zKdgUxA7H3dMaCf-m';

		const fullname =
			localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
		const orgemail = localStorage.getItem('userEmail');
		const emailReason = '!URGENT! ERROR SENDING SHIFT REQUEST';
		const emailMessage =
			fullname +
			' from  ' +
			localStorage.getItem('orgName') +
			' tried sending a shift request but failed. REQUIRES IMMEDIATE ATTENTION. \n\n' +
			'First Name : ' +
			localStorage.getItem('firstName') +
			'\n' +
			'Last Name : ' +
			localStorage.getItem('lastName') +
			'\n' +
			'Org Name : ' +
			localStorage.getItem('orgName') +
			'\n' +
			'Phone Number : ' +
			localStorage.getItem('userPhoneNumber') +
			'\n' +
			'Email : ' +
			orgemail +
			'\n';

		try {
			// await emailjs.sendForm(serviceID, templateID, "hello", userID);
			await emailjs.send(
				serviceID,
				templateID,
				{
					user_fullname: fullname,
					user_email: orgemail,
					user_reason: emailReason,
					user_message: emailMessage,
				},
				userID
			);

			console.log('Email Sent Successfully!');
		} catch (error) {
			console.log('An error occurred while sending the email.');
			console.error(error);
		}
	};

	

	//populate a list of preffered jobbers --> return type is of list
	const populateSelectedJobbers = (selectedJobbers) => {
		console.log('POPULATE SELECTED JOBBERS1111 : ' + selectedJobbers);
		if (!selectedJobbers || selectedJobbers.length === 0) return [];

		const jobbersSelectedEmail = selectedJobbers
			.split(',')
			.filter((email) => IsValidEmail(email))
			.map((email) => ({ value: email.trim() }));

		console.log('POPULATE SELECTED JOBBERS2222 : ' + JSON.stringify(jobbersSelectedEmail));

		return jobbersSelectedEmail;
	};

	const populateShiftRequestContent = (
		numJobbers,
		simpleLocation,
		workertype,
		requestOrgName,
		jobberHourlyRate,
		startDateEpoch:number[],
		endDateEpoch:number[],
		selectedJobbersSubset,
		isTestData
	) => {
		const apiShiftReqContent: ShiftRequestUnitDtoV2[] = [];

		const selectedJobbersActual =
			selectedJobbersSubset && selectedJobbersSubset.length > 0 ? selectedJobbersSubset : null;
		
		const selectedDaysSet = Array.from(new Set(selectedDays));
		console.log("startDateEpoch"+startDateEpoch)
		console.log("endDateEpoch"+endDateEpoch)
		const startDateEpochSet: number[] = Array.from(new Set(startDateEpoch));
		const endDateEpochSet: number[] = Array.from(new Set(endDateEpoch));
		console.log("startDateEpochSet"+startDateEpochSet)
		console.log("endDateEpochSet"+endDateEpochSet)
		


		for (let i = 0; i < numJobbers; i++) {
			for (let j = 0; j < startDateEpochSet.length; j++) {
				apiShiftReqContent.push({
					simpleLocation: simpleLocation, // api call - done
					workerType: workertype, // client side - done
					requestOrgName: requestOrgName, // api call - done
					jobberHourlyRate: jobberHourlyRate, // api call - done
					startDateOfSRUnitEpochSeconds: startDateEpochSet[j], // client side - done
					endDateOfSRUnitEpochSeconds: endDateEpochSet[j], // client side - done
					preferredJobbers: selectedJobbersActual, // api call + client side - done
					isTestData: isTestData, // api call - done
				});
			}
		}

		return apiShiftReqContent;
	};

	const apiFirmdata: FirmDataDto = {
		firmId: firmID,
		facilityId: facilityID,
		orgRequestingUseremail: { value: userInfo.userEmail.value },
		location: firmLocation,
	};

	// const apiBody: ShiftRequestDto = {
	// 	firmData: apiFirmdata,
	// 	shiftRequestContent: populateShiftRequestContent(
	// 		numJobbers,
	// 		simpleLocation,
	// 		workertype,
	// 		requestOrgName,
	// 		jobberHourlyRate,
	// 		startDateEpoch,
	// 		endDateEpoch,
	// 		selectedJobbersSubset,
	// 		isTestData
	// 	),
	// 	isTestData: isTestData,
	// };


	const apiBody: CreateShiftRequestAPIObj = {
		// firmData: apiFirmdata,
		isReOccurringShift: isReocurringShift,
		shiftRequestContent: populateShiftRequestContentV2({
			numJobbers: numJobbers,
			shiftStartEndDetails: shiftEpochList,
			simpleLocation: simpleLocation,
			workertype:			workertype,
			requestOrgName: requestOrgName,
			jobberHourlyRate: jobberHourlyRate,
			selectedJobbersSubset: selectedJobbersSubset,
			isTestData: isTestData
		}
		),
		isTestData: isTestData,
	};

	console.log('current page is : ' + currentPage);
	const handleNextClick = () => {
		setCurrentPage(currentPage + 1);
		window.scrollTo({ top: 1, behavior: "smooth" })
		
		if (currentPage === 2) {
			if (
				v3EndTimeHourRef.current - v3StartTimeHourRef.current >= 12 ||
				v3EndTimeHourRef.current - v3StartTimeHourRef.current <= 4
			) {
				console.log(
					'TESTTEST : ' + v3StartTimeHourRef.current + ' --- ' + v3EndTimeHourRef.current
				);
				console.log('test2 = ' + (v3EndTimeHourRef.current - v3StartTimeHourRef.current));
				setModalShiftOpen(true);
			}
			if(confirmedSelection && selectedWeeks.length > 0){
				console.log("1234512345"+selectedWeeks)
				console.log(" startTimeHour " + startTimeHour + " " + " + startTimeMinute" + startTimeMinute );
				setShiftEpochList(convertDatesToEpochSec({dates: selectedWeeks,
					workyStartTime: { hour: startTimeHour, minute: startTimeMinute },
					 workyEndTime: { hour: endTimeHour, minute: endTimeMinute }}))
				
					 
					 setStartDateEpoch(
					populateEpochDatesV2(selectedWeeks, { hour: startTimeHour, minute: startTimeMinute })
				);
				setEndDateEpoch(
					populateEpochDatesV2(selectedWeeks, { hour: endTimeHour, minute: endTimeMinute })
				);
			}else{
				
				setShiftEpochList(convertDatesToEpochSec({dates: selectedDays,
					 workyStartTime: { hour: startTimeHour, minute: startTimeMinute },
					  workyEndTime: { hour: endTimeHour, minute: endTimeMinute }}))
				setStartDateEpoch(
					populateEpochDatesV2(selectedDays, { hour: startTimeHour, minute: startTimeMinute })
				);
				setEndDateEpoch(
					populateEpochDatesV2(selectedDays, { hour: endTimeHour, minute: endTimeMinute })
				);
			}
		
			setSelectedJobbersSubset(populateSelectedJobbers(selectedJobbers.toString()));
		}
	};
	const handleBackClick = () => {
		window.scrollTo({ top: 1, behavior: "smooth" })
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	//given a list of dates and a singular time --> return a list of epoch seconds
	const populateEpochDatesV2 = (dates: Date[], time: WorkyTime): number[] => {
		console.log("POPULATE DATES "+dates)
		const epochTimes: number[] = dates.map((date) => {
			const dateObj = new Date(date);
			dateObj.setHours(time.hour, time.minute);

			const estEpochTime = new Date(
				dateObj.toLocaleString('en-US', { timeZone: 'America/New_York' })
			);
			console.log('epoch time ' + Math.floor(estEpochTime.getTime() / 1000));

			return Math.floor(estEpochTime.getTime() / 1000);
		});

		return epochTimes;
	};

	const renderCardContent = (page) => {
		switch (page) {
			case 1:
				return <SrPage1 />;
			case 2:
				return (
					<SrPage2
						onV3StartTimeHourChange={handleV3StartTimeHourChange}
						onV3StartTimeMinuteChange={handleV3StartTimeMinuteChange}
						onV3EndTimeHourChange={handleV3EndTimeHourChange}
						onV3EndTimeMinuteChange={handleV3EndTimeMinuteChange}
					/>
				);
			// case 3:
			// 	return <SrPage3 />;
			case 3:
				return <SrPage4 />;
			default:
				return <div>Error please contact us</div>;
		}
	};

	const SrPage1 = () => {
		const handlePlusClick = () => {
			setNumJobbers(numJobbers + 1);
		};
		const handleMinusClick = () => {
			if (numJobbers > 1) {
				setNumJobbers(numJobbers - 1);
			}
		};
		console.log(hasPrevJobbers);



		const PreviousJobberSelect = (previousJobberData: JobberDetailsForOrg[]) => {
			const handleSelectAll = () => {
				const allJobberEmails = previousJobberData.map(
					(jobber) => jobber.userDetails.userEmail.value
				);

				if (selectedJobbers.length === allJobberEmails.length) {
					setSelectedJobbers([]);
				} else {
					setSelectedJobbers(allJobberEmails);
				}
			};

			const handleJobberSelect = (email) => {
				if (selectedJobbers.includes(email)) {
					setSelectedJobbers(selectedJobbers.filter((e) => e !== email));
				} else {
					setSelectedJobbers([...selectedJobbers, email]);
				}
			};


			const AvailablePrevJobbers = () => {
			  
				return (
				  <>
					{previousJobberData.map((jobber, index) => {
					  const jobberProfile: JobberProfileBuilder = {
						jobberName: jobber.userDetails.firstName + " " + jobber.userDetails.lastName,
						jobberEmail: jobber.userDetails.userEmail.value,
						jobberPhone: jobber.userDetails.userPhoneNumber.value,
						jobberPic: jobber.userProfilePicUrl,
					  };
			  
					  return (
						<div style={{paddingTop:"5px"}}>
						<div className='each-prev-jobber-holder'>
							<div className='jobber-holder'>
			  				<JobberProfile
								key={index}
								hasCheck={true}
								jobberProfile={jobberProfile}
							/>
							</div>
							<div className='checkbox-holder' onClick={() => handleJobberSelect(jobberProfile.jobberEmail)}>
								<input
									type='checkbox'
									className='prev-jobber-checkbox'
									value={index}
									style={{ transform: 'scale(1.21)' }}
									checked={selectedJobbers.includes(jobberProfile.jobberEmail)}
									onChange={() => handleJobberSelect(jobberProfile.jobberEmail)}
								/>
							</div>
						</div>
						</div>
					  );
					})}
				  </>
				);
			  };
			  

			return (
				<>
					<button onClick={handleSelectAll} className='select-all-prev-jobbers'>
						{selectedJobbers.length === previousJobberData.length
							? 'Deselect All'
							: 'Select All'}
					</button>
					<AvailablePrevJobbers />
				</>
			);
		};




		return (
			<div className={isMobile? 'srpage1-mobile':'srpage1'}>



				<div className='srpage1-right'>
					<div className={isMobile? 'srpage1-left-subtitle-mobile' : 'srpage1-left-subtitle'}>Number Of Workers</div>
					<div className={isMobile? 'srpage1-numjobbers-mobile':'srpage1-numjobbers'}>
						<button className='srpage1-numjobbers-minus' onClick={handleMinusClick}>
							-
						</button>
						<input
							type='number'
							className='srpage1-numjobbers-value'
							value={numJobbers}
							onChange={(e) => setNumJobbers(parseInt(e.target.value) || 0)}
							min='1'
						/>
						<button className='srpage1-numjobbers-plus' onClick={handlePlusClick}>
							+
						</button>
					</div>
				</div>





				{prevJobbers ? (
					<div className={isMobile?'srpage3-left-mobile':'srpage3-left'}>
					<div className={isMobile? 'srpage1-left-subtitle-mobile' : 'srpage1-left-subtitle'}>Previous Workers</div>
						<div className='srpage3-jobber-holder'>{PreviousJobberSelect(prevJobbers)}</div>
					</div>
				) : null}






			</div>
		);
	};
	const [selectedRecurringDays, setSelectedRecurringDays] = useState<number[]>([]);

	const SrPage2 = ({
		onV3StartTimeHourChange,
		onV3StartTimeMinuteChange,
		onV3EndTimeHourChange,
		onV3EndTimeMinuteChange,
	}) => {
		console.log(hasPrevJobbers);

		const today = new Date();
		const isDateBeforeToday = (date) => {
			return date < today;
		};
		console.log('selectedDays' + selectedDays);
		console.log('selectedWeeks' + selectedWeeks);
		


		const handleYesSelect = () =>{
			setIsReocurringShift(true)
		}

		const handleNoSelect = () =>{
			setIsReocurringShift(false)
			setConfirmedSelection(false)
		}


		const Recurring = () => {
			return(
			<div className='srpage2-recurring'>
			<div className='srpage2-recurring-title'>Is this a Reocurring shift?</div>
			<div className='srpage2-recurring-switch'>
			
				{isReocurringShift || confirmedSelection? 
					<button onClick={handleYesSelect} className='calendar-toggle'>Yes</button> 
					: 
					<button id='toggleClosed' onClick={handleYesSelect} className='calendar-toggle'>Yes</button>
				}
				{isReocurringShift || confirmedSelection? 
					<button id='toggleClosed' onClick={handleNoSelect} className='calendar-toggle'>No</button>
					:
					<button onClick={handleNoSelect} className='calendar-toggle'>No</button>
				}

				<RecurringDow/>
			</div>
		</div>
		)
		}

		const RecurringDow = () => {
			// const [selectedRecurringDays, setSelectedRecurringDays] = useState<number[]>([]);
			const [tempSelectedDays, setTempSelectedDays] = useState([]);
			console.log("TEST 12345 : " + selectedRecurringDays)

			const toggleDaySelection = (day) => {
				const index = tempSelectedDays.indexOf(day);
				if (index === -1) {
					setTempSelectedDays([...tempSelectedDays, day]); // Add the day if it's not already selected
				} else {
					const newSelectedDays = [...tempSelectedDays];
					newSelectedDays.splice(index, 1); // Remove the day if it's already selected
					setTempSelectedDays(newSelectedDays);
				}
			};
		
			const confirmSelection = () => {
				setSelectedRecurringDays(tempSelectedDays);
				setConfirmedSelection(true)
				setIsReocurringShift(false); // Close the modal after confirmation
			};
		
			const isDaySelected = (day) => tempSelectedDays.includes(day);
		
			return (
				<Modal open={isReocurringShift} onClose={() => setIsReocurringShift(false)}>
					<div className='recurring-shift-modal'>
						<div className='recurring-shift-modal-title'>Which days of the week?</div>
						<div className='recurring-shift-modal-dow-holder'>
							{[0, 1, 2, 3, 4, 5, 6].map(day => (
								<button
									key={day}
									className={`recurring-dow ${isDaySelected(day) ? 'selected' : ''}`}
									onClick={() => toggleDaySelection(day)}>
									{['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][day]}
								</button>
							))}
						</div>
						<button className='recurring-confirm' onClick={confirmSelection}>Confirm</button>
					</div>
				</Modal>
			);
		};
		const handleWeekClick = (startOfWeek: Date) => {
			const days = [];
			for (let i = 0; i < 7; i++) {
			  const day = new Date(startOfWeek);
			  day.setDate(startOfWeek.getDate() + i);
			  if (selectedRecurringDays.includes(i)) {
				days.push(day);
			  }
			}
			return days;
		  };
		
		  const handleWeekNumberClickv2 = (weekNumber: number, startOfWeek: Date[]) => {
			if (startOfWeek.length > 0) {
			  const startOfClickedWeek = new Date(startOfWeek[0]);
			  const newWeek = handleWeekClick(startOfClickedWeek);
		
			  console.log("startOfWeek" + startOfWeek)
			  console.log("selectedWeeks " + selectedWeeks)
		
			  let valuesfound = 0;
		
			  for(let i=0; i<newWeek.length; i++){
				const potStartWeekDay = newWeek[i];
				// console.log(potStartWeekDay)
				if(selectedWeeks.some(date => date.getTime() === potStartWeekDay.getTime())) {
				  valuesfound++;
				  console.log(potStartWeekDay);
				}
			  }
			  console.log(valuesfound);
			  if (valuesfound === newWeek.length){
				const filteredDates:Date[] = [];
				for(let i=0; i<selectedWeeks.length; i++){
				  if(!newWeek.some(date => date.getTime() === selectedWeeks[i].getTime())) {
					filteredDates.push(selectedWeeks[i])
				  }
				}
				setSelectedWeeks(filteredDates);
		
			  }else{
				setSelectedWeeks(prevWeeks => {
				  const isSelected = prevWeeks.some(prevWeek =>
					prevWeek.getTime() === startOfClickedWeek.getTime()
				  );
				  if (isSelected) {
					return prevWeeks.filter(prevWeek =>
					  prevWeek.getTime() !== startOfClickedWeek.getTime()
					);
				  } else {
					return [...prevWeeks, ...newWeek];
				  }
				});
			  }
			}
		  };

		return (
			<div className={isMobile? 'srpage2-mobile':'srpage2'}>
				<div className={isMobile?'srpage2-left-mobile':'srpage2-left'}>
					{isMobile? <div className='srpage2-left-title-mobile'>Shift Date & Shift Time</div>:<div className='srpage2-left-title'>Shift Date(s)</div>}
					<div className='srpage2-left-calender'>
						{isReocurringShift || confirmedSelection ? 
							(
								<DayPicker 
									showWeekNumber
									showOutsideDays 
									fixedWeeks 
									min={1}
									selected={selectedWeeks}
									onWeekNumberClick={handleWeekNumberClickv2}
									modifiers={{
										disabled: { before: today },
									}}
									modifiersStyles={{
										selected: { backgroundColor: '#2c68bd' },
										disabled: { backgroundColor: '#ffffff', borderColor: '#fff' },
									}}
									disabled={isDateBeforeToday}
									styles={{
										day: {
											fontSize: '14px',
										},
									}}
								/>
							)
							:
							(
								<DayPicker
									mode='multiple'
									className='cal'
									min={1}
									selected={selectedDays}
									onSelect={setSelectedDays}
									modifiers={{
										disabled: { before: today },
									}}
									modifiersStyles={{
										selected: { backgroundColor: '#2c68bd' },
										disabled: { backgroundColor: '#ffffff', borderColor: '#fff' },
									}}
									disabled={isDateBeforeToday}
									styles={{
										day: {
											fontSize: '14px',
										},
									}}
								/>
							)
						}
						


					</div>
				</div>

				<div className={isMobile?'srpage2-right-mobile':'srpage2-right'}>
					{isMobile? null: <div className='srpage2-right-title'>Shift Time</div>}
					<div className='srpage2-right-inner'>
						<div className='srpage2-right-subtitle'>Start Time</div>
						<WorkyTimePicker
							onFinalTimeChangeHour={onV3StartTimeHourChange}
							onFinalTimeChangeMinute={onV3StartTimeMinuteChange}
						/>
						<div className='srpage2-right-subtitle'>End Time</div>
						<WorkyTimePickerFuture
							onFinalTimeChangeHour={onV3EndTimeHourChange}
							onFinalTimeChangeMinute={onV3EndTimeMinuteChange}
						/>
					</div>
					{/* INSERT HERE */}
					<Recurring/>
				</div>
			</div>
		);
	};

	const SrPage3 = () => {

		const PreviousJobberSelect = (previousJobberData: JobberDetailsForOrg[]) => {
			const handleSelectAll = () => {
				const allJobberEmails = previousJobberData.map(
					(jobber) => jobber.userDetails.userEmail.value
				);

				if (selectedJobbers.length === allJobberEmails.length) {
					setSelectedJobbers([]);
				} else {
					setSelectedJobbers(allJobberEmails);
				}
			};

			const handleJobberSelect = (email) => {
				if (selectedJobbers.includes(email)) {
					setSelectedJobbers(selectedJobbers.filter((e) => e !== email));
				} else {
					setSelectedJobbers([...selectedJobbers, email]);
				}
			};


			const AvailablePrevJobbers = () => {
			  
				return (
				  <>
					{previousJobberData.map((jobber, index) => {
					  const jobberProfile: JobberProfileBuilder = {
						jobberName: jobber.userDetails.firstName + " " + jobber.userDetails.lastName,
						jobberEmail: jobber.userDetails.userEmail.value,
						jobberPhone: jobber.userDetails.userPhoneNumber.value,
						jobberPic: jobber.userProfilePicUrl,
					  };
			  
					  return (
						<div style={{paddingTop:"5px"}}>
						<div className='each-prev-jobber-holder'>
							<div className='jobber-holder'>
			  				<JobberProfile
								key={index}
								hasCheck={true}
								jobberProfile={jobberProfile}
							/>
							</div>
							<div className='checkbox-holder' onClick={() => handleJobberSelect(jobberProfile.jobberEmail)}>
								<input
									type='checkbox'
									className='prev-jobber-checkbox'
									value={index}
									style={{ transform: 'scale(1.21)' }}
									checked={selectedJobbers.includes(jobberProfile.jobberEmail)}
									onChange={() => handleJobberSelect(jobberProfile.jobberEmail)}
								/>
							</div>
						</div>
						</div>
					  );
					})}
				  </>
				);
			  };
			  

			return (
				<>
					<button onClick={handleSelectAll} className='select-all-prev-jobbers'>
						{selectedJobbers.length === previousJobberData.length
							? 'Deselect All'
							: 'Select All'}
					</button>
					<AvailablePrevJobbers />
				</>
			);
		};

		return (
			<div className={isMobile? 'srpage3-mobile':'srpage3'}>

				{prevJobbers ? (
					<div className={isMobile?'srpage3-left-mobile':'srpage3-left'}>
						{isMobile ? (null):(<div className='srpage3-left-title'>Previous Workers</div>)}
						<div className='srpage3-jobber-holder'>{PreviousJobberSelect(prevJobbers)}</div>
					</div>
				) : null}

				{/* rightside - select previous jobers */}
				<div className={isMobile?'srpage3-right-mobile':'srpage3-right'}>
					<div className={isMobile?'srpage3-right-title-mobile':'srpage3-right-title'}>Worker Details</div>
					<div className='srpage3-right-inner'>
						<div className='srpage3-right-subtitle'>Worker Pay Rate</div>
						<div className='srpage3-right-setpay'>
							<div className='dollar'>${jobberHourlyRate}</div>
						</div>
						<div className='srpage3-right-subtitle'>Worker Type</div>
						<div className='srpage3-right-workertype'>
							<select
								className='input-workertype'
								value={workertype}
								onChange={(e) => setWorkertype(e.target.value as Workertype)}>
								<option value={Workertype.WAREHOUSE_ASSOCIATE}>Warehouse Associate</option>
								<option value={Workertype.FORKLIFT_OPERATOR} disabled>
									Forklift Operator
								</option>
								<option value={Workertype.GENERAL_LABOUR} disabled>
									General Labour
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const calcShiftDuration = ({ startTimeHour, startTimeMinute, endTimeHour, endTimeMinute }
		: { startTimeHour: any, startTimeMinute: any, endTimeHour: any, endTimeMinute: any }): number => {
			const hours1 = endTimeHour;
			const minutes1 = endTimeMinute;
			const hours2 = startTimeHour;
			const minutes2 = startTimeMinute;
			const totalMinutes1 = hours1 * 60 + minutes1;
			const totalMinutes2 = hours2 * 60 + minutes2;
			const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);
			let shiftDuration = differenceInMinutes / 60;

			if (shiftDuration > 10) {
				shiftDuration -= 1;
			} else if (shiftDuration >= 5) {
				shiftDuration -= 0.5;
			}
		return shiftDuration; // Return the calculated duration
	}

	const calcWorkyChargeNotIncludingTax = ({
		shiftDuration,
		numWorkers,
		numDays,
		workerHourlyPayRate,
		markUpPrecentage
	  }: {
		shiftDuration: number;
		numDays: number;
		numWorkers: number;
		workerHourlyPayRate: number;
		markUpPrecentage: number;
	  }): number => {
		const charge = shiftDuration * numWorkers * numDays * workerHourlyPayRate * (1 + markUpPrecentage);
		return Math.round(charge * 100) / 100; // Round to 2 decimal places
	};
	
	const SrPage4 = () => {
		const calculateShiftTotal = () => {
		

			let shiftDuration = calcShiftDuration({startTimeHour, startTimeMinute, endTimeHour, endTimeMinute});
			// number of workers
			const numWorkers = numJobbers;
			// number of days
			const numDays = selectedDays.length;
			// worker pay
			const workerPay = jobberHourlyRate;
			// tax
			const workerTax = 1.13;
			// worky charge
			const workyCharge = orgData.orgBillingData.workyMarkup + 1;

			const shiftChargeNotIncludingTax: number = calcWorkyChargeNotIncludingTax({shiftDuration, numWorkers: numJobbers,
				 numDays: (confirmedSelection) ? selectedWeeks.length : selectedDays.length,
				workerHourlyPayRate: jobberHourlyRate,
				 markUpPrecentage: orgData.orgBillingData.workyMarkup});

			const totalShiftCharge = shiftChargeNotIncludingTax +  calculateTax();
			return '$' + totalShiftCharge.toFixed(2);
			// maybe need to add a disclaimer -- timing charges etc...
		};

		const calculateTax = () : number => {
			// total number of hours
			
			const shiftDuration =  calcShiftDuration({startTimeHour, startTimeMinute, endTimeHour, endTimeMinute});
		
		
			
			const taxAmt: number = (calcWorkyChargeNotIncludingTax({shiftDuration, numWorkers: numJobbers,
				numDays: (confirmedSelection) ? selectedWeeks.length : selectedDays.length,
			    workerHourlyPayRate: jobberHourlyRate,
				markUpPrecentage: orgData.orgBillingData.workyMarkup}) * 0.13); 			
			
				return Math.round(taxAmt * 100) / 100; // Round to 2 decimal places
		};

		return (
			<div className={isMobile? 'srpage4-mobile':'srpage4'}>
				<div className={isMobile?'srpage4-title-mobile':'srpage4-title'}>Confirm & Submit</div>
				<div className='srpage4-inner'>
					<div className={isMobile? 'srpage4-reciept-mobile':'srpage4-reciept'}>
						<div className='srpage4-reciept-title'>
							Number of Workers: <span className='reciept-value'> {numJobbers}</span>
						</div>

						<div className='srpage4-reciept-title'>
							Shift Date(s): <span className='reciept-value'>
								{extractDate((confirmedSelection) ?  selectedWeeks : selectedDays)}
							</span>
						</div>

						<div className='srpage4-reciept-title'>
							Shift Time:
							<span className='reciept-value'>
								{convertTo12HourFormat(
									startTimeHour.toString() + ':' + startTimeMinute.toString()
								)}{' '}
								-{' '}
								{convertTo12HourFormat(
									endTimeHour.toString() + ':' + endTimeMinute.toString()
								)}
							</span>
							<Tooltip style={{fontSize:"18px"}} title="Workers will get one break per every 5 hours" placement='right'><HelpIcon/></Tooltip>
						</div>

						<div className='srpage4-reciept-title'>
							Worker Type: <span className='reciept-value'>Warehouse Associate</span>
						</div>

						<div className='srpage4-reciept-title'>
							Worker Pay: <span className='reciept-value'> ${jobberHourlyRate}</span>
			 </div>

						<div className='srpage4-reciept-title'>
							Worky Charge:
							<span className='reciept-value'>
								{orgData.orgBillingData.workyMarkup * 100}%
							</span>
						</div>

						<div className='srpage4-reciept-title'>
							Tax: <span className='reciept-value'> ${calculateTax()}</span>
						</div>

						<div className='srpage4-reciept-title'>
							Total: <span className='reciept-value'>{calculateShiftTotal()}</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const handleSRSubmit = async (e) => {
		e.preventDefault();
		console.log('apiBody' + JSON.stringify(apiBody));
		console.log("lsksksksks " + JSON.stringify(shiftEpochList))
		try {
			const response: AxiosResponse<ServerResponse<ShiftReqResponse, Error>> =
				await remoteCallObject.remotePOSTCallV2<
					ServerResponse<ShiftReqResponse, Error>,
					CreateShiftRequestAPIObj
				>({
					apiUrl: backendApiBasePathLocal + 'shiftrequest/create/',
					object: apiBody,
					headers: { 'Content-Type': 'application/json' },
				});
			console.log('POST request successful:', response);

			if (
				response &&
				(response.status === 200 || response.status === 201 || response.status === 202)
			) {
				console.log('200 or 201 or 202');
				setModalOpen(true);
			}
		} catch (e) {
			if (e.response && (e.response.status === 400 || e.response.status === 500)) {
				console.log('400 or 500');
				await sendErrorEmailv2();
				setModalErrOpen(true);
			}
			console.error('POST request failed:', e);
		}
	};

	return (
		<>
			<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />
			<Modal open={modalOpen} onClose={handleClose}>
				<div className='submit-success'>
					<div className='submit-success-title'>Success!</div>
					<div className='submit-success-icon'>
						<CheckCircleRoundedIcon
							style={{ fontSize: '150px', height: '150px', color: '#6DBB3A' }}
						/>
					</div>
					<div className='submit-success-subtitle'>
						You've successfully submitted your shift!
					</div>
					<div className='submit-success-subtitle'>Want to hire more? Click below</div>
					<button onClick={PushToNewSr} className='submit-success-button'>
						Hire More
					</button>
				</div>
			</Modal>

			<Modal open={modalErrOpen} onClose={handleErrModalClose}>
				<div className='submit-success'>
					<div className='submit-success-title'>Error</div>
					<div className='submit-success-subtitle'>There was an issue!</div>
					<div className='submit-success-subtitle'>Want to start again? Click below</div>
					<button onClick={PushToNewSr} className='submit-success-button'>
						Try Again
					</button>
				</div>
			</Modal>

			<Modal open={modalShiftOpen} onClose={handleShiftModalClose}>
				<div className='submit-success'>
					<div className='submit-success-title'>Shift Length</div>
					<div className='submit-success-subtitle'>Are you sure this is what you meant?</div>
					<div className='submit-success-subtitle'>
						Shift length : {v3EndTimeHourRef.current - v3StartTimeHourRef.current} Hr
					</div>
					<div className='shiftback-buttons'>
						<button
							onClick={handleShiftback}
							style={{ background: '#cccccc' }}
							className='submit-success-button'>
							Go Back
						</button>
						<button onClick={handleShiftModalClose} className='submit-success-button'>
							Looks Good
						</button>
					</div>
				</div>
			</Modal>

			<div className='org-app'>
				{TopNav({
					firstName: userInfo.firstName,
					lastname: userInfo.lastName,
					companyname: localStorage.getItem('orgName'),
					iconUrl: localStorage.getItem('orgIcon'),
					orgEmail: localStorage.getItem('userEmail'),
				})}
				<div className='srpage-title-holder'>
					<div className={isMobile ? 'srpage1-title-title-mobile':'srpage1-title-title'}>New Shift</div>
				</div>
				{isMobile ? <div className='srpage1-mobile-title'>New Shift</div>:null}

				{StepperManager(currentPage)}

				

				<div className={isMobile? 'org-newsr-mobile':'org-newsr'}>
					{renderCardContent(currentPage)}
					<div className={isMobile? 'card-content-d-progress-mobile':'card-content-d-progress'}>
						{currentPage > 1 && (
							isMobile ? (
							<button className='card-content-d-back-mobile' onClick={handleBackClick}>
								<West/>
							</button>
							):(
							<button className='card-content-d-back' onClick={handleBackClick}>
								Back
							</button>
							)
						)}
						{currentPage === 1 && (
							isMobile ? (
								<button
									className='card-content-d-next-mobile'
									onClick={handleNextClick}
									disabled={numJobbers <= 0}>
									<East/>
								</button>
							) : (
								<button
									className='card-content-d-next'
									onClick={handleNextClick}
									disabled={numJobbers <= 0}>
									Next
								</button>
							)
						)}
						{currentPage == 2 && (
							isMobile ? (
							<button
								className='card-content-d-next-mobile'
								onClick={handleNextClick}
								disabled={selectedDays.length <= 0 && selectedWeeks.length <= 0}>
								<East/>
							</button>
							):(
							<button
								className='card-content-d-next'
								onClick={handleNextClick}
								disabled={selectedDays.length <= 0 && selectedWeeks.length<=0}>
								Next
							</button>


							)
						)}
					
						{currentPage >= 3 && (
							isMobile ? (
								<button className='card-content-d-submit-mobile' onClick={handleSRSubmit}>
									Submit <CheckIcon style={{marginRight:'-10px'}}/>
								</button>
							):(
								<button className='card-content-d-submit' onClick={handleSRSubmit}>
									Submit
								</button>
							)
						)}
					</div>
				</div>

				<div onClick={()=>{window.location.assign('homepage')}} className='worky-footer'>worky</div>
			</div>
		</>
	);
};

export { MainCompDesktop };
