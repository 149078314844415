import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { SendErrorEmailv2 } from "../shared-engineering-services/SharedAPICalls";
import { convertDatesToEpochSec } from "../common/TimeUtils";
import{EmailAddress} from '../common/CommonInterfaces'
import { DayPicker } from "react-day-picker";
import East from "@mui/icons-material/East";
import West from "@mui/icons-material/West";
import { useIsMobile } from "../custom-hooks/useIsMobile";
import {
  RemoteExecutionServiceImpl,
  getGlobalUserDetails,
} from "../remoteExecution/RemoteExecutionServiceImpl";
import { DefaultComponents, handleSRSubmit, RetrievePreviousJobbersAPICall } from "./SrIngressHelperService";
import { TimePicker, WorkyTimeObj, FormatTime, DetermineHourForTweelve } from "./TimePicker";
import './newshift.css'
import { TopNav, PushToNewSr, PushtoSrHistory } from '../orgViews/OrgCommonComponents';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography, { typographyClasses } from '@mui/joy/Typography';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import {ShiftRecieptPage, JobberSelectionPage} from './NewShiftV4Components'
import { JobberDetailsForOrg } from "../orgViews/OrgUtils";

const orgData = JSON.parse(localStorage.getItem('orgData'));
console.log('ORGDATA : '+JSON.stringify(orgData));
const userInfo = getGlobalUserDetails();

const today = new Date();
const isDateBeforeToday = (date) => {
  return date < today;
};

function StepperV3Mobile(page) {
  if (page === 1){
    return(
      <Stepper
      size="lg"
      sx={{
        zIndex:"1",
        width: '100%',
        '--StepIndicator-size': '3rem',
        '--Step-connectorInset': '0px',
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'primary.300',
            color: 'primary.300',
          },
          '&::after': {
            bgcolor: 'primary.300',
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'currentColor',
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: 'neutral.outlinedDisabledColor',
        },
      }}
    >
      <Step
        orientation="vertical"
        active
        indicator={
          <StepIndicator variant="solid" color="primary">
            <HowToRegRoundedIcon />
          </StepIndicator>
        }
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'lg',
            fontSize: '0.75rem',
            letterSpacing: '0.5px',
          }}
        >
          Select Workers
        </Typography>
      </Step>

      <Step
        orientation="vertical"
        disabled
        indicator={
          <StepIndicator variant="outlined" color="neutral">
            <EditCalendarRoundedIcon />
          </StepIndicator>
        }
      />
      <Step
        orientation="vertical"
        disabled
        indicator={
          <StepIndicator variant="outlined" color="neutral">
            <MoreTimeIcon />
          </StepIndicator>
        }
      />
      <Step
        orientation="vertical"
        disabled
        indicator={
          <StepIndicator variant="outlined" color="neutral">
            <TaskAltRoundedIcon />
          </StepIndicator>
        }
      />
      
    </Stepper>
    );
  }
  if (page === 2){
    return (
      <Stepper
      size="lg"
      sx={{
        width: '100%',
        '--StepIndicator-size': '3rem',
        '--Step-connectorInset': '0px',
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'primary.300',
            color: 'primary.300',
          },
          '&::after': {
            bgcolor: 'primary.300',
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'currentColor',
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: 'neutral.outlinedDisabledColor',
        },
      }}
    >
      

      <Step
        orientation="vertical"
        completed
        indicator={
          <StepIndicator variant="outlined" color="primary">
            <HowToRegRoundedIcon />
          </StepIndicator>
        }
      />
      <Step
        orientation="vertical"
        active
        indicator={
          <StepIndicator variant="solid" color="primary">
            <EditCalendarRoundedIcon />
          </StepIndicator>
        }
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'lg',
            fontSize: '0.75rem',
            letterSpacing: '0.5px',
          }}
        >
          Select Dates
        </Typography>
      </Step>
      <Step
        orientation="vertical"
        disabled
        indicator={
          <StepIndicator variant="outlined" color="neutral">
            <MoreTimeIcon />
          </StepIndicator>
        }
      />
      <Step
        orientation="vertical"
        disabled
        indicator={
          <StepIndicator variant="outlined" color="neutral">
            <TaskAltRoundedIcon />
          </StepIndicator>
        }
      />
      
    </Stepper>
      );
  }
  if (page === 3){
    return(
      <Stepper
      size="lg"
      sx={{
        width: '100%',
        '--StepIndicator-size': '3rem',
        '--Step-connectorInset': '0px',
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'primary.300',
            color: 'primary.300',
          },
          '&::after': {
            bgcolor: 'primary.300',
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'currentColor',
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: 'neutral.outlinedDisabledColor',
        },
      }}
    >
      

      <Step
        orientation="vertical"
        completed
        indicator={
          <StepIndicator variant="outlined" color="primary">
            <HowToRegRoundedIcon />
          </StepIndicator>
        }
      />

       <Step
        orientation="vertical"
        completed
        indicator={
          <StepIndicator variant="outlined" color="primary">
            <EditCalendarRoundedIcon />
          </StepIndicator>
        }
      />

      <Step
        orientation="vertical"
        active
        indicator={
          <StepIndicator variant="solid" color="primary">
            <MoreTimeIcon />
          </StepIndicator>
        }
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'lg',
            fontSize: '0.75rem',
            letterSpacing: '0.5px',
          }}
        >
          Select Time
        </Typography>
      </Step>
     
      <Step
        orientation="vertical"
        disabled
        indicator={
          <StepIndicator variant="outlined" color="neutral">
            <TaskAltRoundedIcon />
          </StepIndicator>
        }
      />
      
    </Stepper>
    );
  }  
  if (page === 4){
    return(
      <Stepper
      size="lg"
      sx={{
        width: '100%',
        '--StepIndicator-size': '3rem',
        '--Step-connectorInset': '0px',
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'primary.300',
            color: 'primary.300',
          },
          '&::after': {
            bgcolor: 'primary.300',
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'currentColor',
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: 'neutral.outlinedDisabledColor',
        },
      }}
    >
      <Step
        completed
        orientation="vertical"
        indicator={
          <StepIndicator variant="outlined" color="primary">
            <HowToRegRoundedIcon />
          </StepIndicator>
        }
      />
      <Step
        orientation="vertical"
        completed
        indicator={
          <StepIndicator variant="outlined" color="primary">
            <EditCalendarRoundedIcon />
          </StepIndicator>
        }
      />
      <Step
        orientation="vertical"
        completed
        indicator={
          <StepIndicator variant="outlined" color="primary">
            <MoreTimeIcon />
          </StepIndicator>
        }
      />
      <Step
        orientation="vertical"
        active
        indicator={
          <StepIndicator variant="solid" color="primary">
            <TaskAltRoundedIcon />
          </StepIndicator>
        }
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'lg',
            fontSize: '0.75rem',
            letterSpacing: '0.5px',
          }}
        >
          Review & Submit
        </Typography>
      </Step>
    </Stepper>
    );
  }  
  
}
function StepperV3(page) {
  if (page === 1){
    return(
        <Stepper
            orientation="vertical"
            sx={{
                '--Stepper-verticalGap': '2.5rem',
                '--StepIndicator-size': '2.5rem',
                '--Step-gap': '1rem',
                '--Step-connectorInset': '0.5rem',
                '--Step-connectorRadius': '1rem',
                '--Step-connectorThickness': '4px',
                'textAlign':'left',
                '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                [`& .${stepClasses.completed}`]: {
                    '&::after': { bgcolor: '#2c68bd' }, // line colour
                },
                [`& .${stepClasses.active}`]: {
                    [`& .${stepIndicatorClasses.root}`]: {
                        border: '4px solid',
                        borderColor: '#fff',
                        boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                    },
                },
                [`& .${stepClasses.disabled} *`]: {
                    color: 'neutral.softDisabledColor',
                },
                [`& .${typographyClasses['title-sm']}`]: {
                    textTransform: 'uppercase',
                    letterSpacing: '1px',
                    fontSize: '10px',
                },
            }}
        >
            <Step
                completed
                indicator={
                    <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                        <PersonAddAlt1RoundedIcon />
                    </StepIndicator>
                }
            >
                <div>
                    <Typography level="title-sm">Step 1</Typography>
                    Select Workers
                </div>
            </Step>

            <Step disabled indicator={<StepIndicator><EditCalendarRoundedIcon/></StepIndicator>}>
                <div>
                    <Typography level="title-sm">Step 2</Typography>
                    Select Dates
                </div>
            </Step>
            <Step disabled indicator={<StepIndicator><MoreTimeIcon/></StepIndicator>}>
                <div>
                    <Typography level="title-sm">Step 3</Typography>
                    Select Time
                </div>
            </Step>

            <Step disabled indicator={<StepIndicator><TaskAltRoundedIcon/></StepIndicator>}>
                <div>
                    <Typography level="title-sm">Step 4</Typography>
                    Submit
                </div>
            </Step>
        </Stepper>

    );
  }
  if (page === 2){
    return (
        <Stepper
          orientation="vertical"
          sx={{
            // 'background':'red',
            '--Stepper-verticalGap': '2.5rem',
            '--StepIndicator-size': '2.5rem',
            '--Step-gap': '1rem',
            '--Step-connectorInset': '0.5rem',
            '--Step-connectorRadius': '1rem',
            '--Step-connectorThickness': '4px',
            'textAlign':'left',
            '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
            [`& .${stepClasses.completed}`]: {
              '&::after': { bgcolor: '#2c68bd' }, // line colour
            },
            [`& .${stepClasses.active}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                border: '4px solid',
                borderColor: '#fff',
                boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.primary[500]}`,
              },
            },
            [`& .${stepClasses.disabled} *`]: {
              color: 'neutral.softDisabledColor',
            },
            [`& .${typographyClasses['title-sm']}`]: {
              textTransform: 'uppercase',
              letterSpacing: '1px',
              fontSize: '10px',
            },
          }}
        >
          <Step
            completed
            indicator={
              <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <HowToRegRoundedIcon />
              </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 1</Typography>
              Select Workers
            </div>
          </Step>
    
    
    
          <Step
            completed
            indicator={
            <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <EditCalendarRoundedIcon />
            </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 2</Typography>
              Select Date
            </div>
          </Step>
          <Step disabled indicator={<StepIndicator><MoreTimeIcon/></StepIndicator>}>
                <div>
                    <Typography level="title-sm">Step 3</Typography>
                    Select Time
                </div>
            </Step>
          <Step disabled indicator={<StepIndicator><TaskAltRoundedIcon/></StepIndicator>}>
            <div>
              <Typography level="title-sm">Step 3</Typography>
              Submit
            </div>
          </Step>
        </Stepper>
      );
  }
  if (page === 3){
    return(
        <Stepper
          orientation="vertical"
          sx={{
            // 'background':'red',
            '--Stepper-verticalGap': '2.5rem',
            '--StepIndicator-size': '2.5rem',
            '--Step-gap': '1rem',
            '--Step-connectorInset': '0.5rem',
            '--Step-connectorRadius': '1rem',
            '--Step-connectorThickness': '4px',
            'textAlign':'left',
            '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
            [`& .${stepClasses.completed}`]: {
              '&::after': { bgcolor: '#2c68bd' }, // line colour
            },
            [`& .${stepClasses.active}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                border: '4px solid',
                borderColor: '#fff',
                boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.primary[500]}`,
              },
            },
            [`& .${stepClasses.disabled} *`]: {
              color: 'neutral.softDisabledColor',
            },
            [`& .${typographyClasses['title-sm']}`]: {
              textTransform: 'uppercase',
              letterSpacing: '1px',
              fontSize: '10px',
            },
          }}
        >
          <Step
            completed
            indicator={
              <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <HowToRegRoundedIcon />
              </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 1</Typography>
              Select Workers
            </div>
          </Step>
    
    
    
          <Step
            completed
            indicator={
            <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <EventAvailableRoundedIcon />
            </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 2</Typography>
              Select Dates
            </div>
          </Step>

          <Step
            completed
            indicator={
            <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <MoreTimeIcon />
            </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 3</Typography>
              Select Time
            </div>
          </Step>


          <Step disabled indicator={<StepIndicator><TaskAltRoundedIcon/></StepIndicator>}>
            <div>
              <Typography level="title-sm">Step 4</Typography>
              Submit
            </div>
          </Step>

        </Stepper>
    );
  }  
  if (page === 4){
    return(
        <Stepper
          orientation="vertical"
          sx={{
            // 'background':'red',
            '--Stepper-verticalGap': '2.5rem',
            '--StepIndicator-size': '2.5rem',
            '--Step-gap': '1rem',
            '--Step-connectorInset': '0.5rem',
            '--Step-connectorRadius': '1rem',
            '--Step-connectorThickness': '4px',
            'textAlign':'left',
            '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
            [`& .${stepClasses.completed}`]: {
              '&::after': { bgcolor: '#2c68bd' }, // line colour
            },
            [`& .${stepClasses.active}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                border: '4px solid',
                borderColor: '#fff',
                boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.primary[500]}`,
              },
            },
            [`& .${stepClasses.disabled} *`]: {
              color: 'neutral.softDisabledColor',
            },
            [`& .${typographyClasses['title-sm']}`]: {
              textTransform: 'uppercase',
              letterSpacing: '1px',
              fontSize: '10px',
            },
          }}
        >
          <Step
            completed
            indicator={
              <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <HowToRegRoundedIcon />
              </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 1</Typography>
              Select Workers
            </div>
          </Step>
    
    
    
          <Step
            completed
            indicator={
            <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <EventAvailableRoundedIcon />
            </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 2</Typography>
              Date & Time
            </div>
          </Step>
          <Step
            completed
            indicator={
            <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <MoreTimeIcon />
            </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 3</Typography>
              Select Time
            </div>
          </Step>
          <Step
            completed
            indicator={
            <StepIndicator variant="solid" style={{background:"#2c68bd"}}>
                <TaskAltRoundedIcon />
            </StepIndicator>
            }
          >
            <div>
              <Typography level="title-sm">Step 4</Typography>
              Submit
            </div>
          </Step>
        </Stepper>
    );
  }  
  
}
        


       

const NewShiftV4 = () =>{
    const isMobile = useIsMobile();
    const [selectedJobbers, setSelectedJobbers] = useState<EmailAddress[]>([]);
    const [currentPage, setCurrentPage] = useState(1);    
    const [selectedDays, setSelectedDays] = useState<Date[]>([]);
    const selectedDaysRefObj = useRef<Date[]>([]);
    const [finalNumJobbers, setFinalNumJobbers] = useState(1);	  
    const [isReOccurringShift, setIsReocurringShift] = useState(false);
    
    const selectedRecurringDaysRefObj = useRef<number[]>([]);
    const workyStartTimeRefObj = useRef<WorkyTimeObj>({hour:9, minute:0,isAM:true})
    const workyEndTimeRefObj = useRef<WorkyTimeObj>({hour:5, minute:0, isAM:false})
    const [currentMonth, setCurrentMonth] = useState(today);
        const [prevJobbers, setPrevJobbers] = useState<JobberDetailsForOrg[] | null>(null);    
        const handleMonthChange = (newMonth) => {
            setCurrentMonth(newMonth);
        };
  

        useEffect(() => {
          const fetchData = async () => {
            await RetrievePreviousJobbersAPICall({setPrevJobbers: setPrevJobbers});
          };
          try{
            
    
            fetchData();
          }catch(e){
            console.log("RetrievePreviousJobbersAPICall err: ", e)
          }
          
          
          }, []);
  
    
    
    
    
    const JobberSelectionPageButtons = ()=>{
        const handlePage1Next = () => {
                    setCurrentPage(2)
                };
        return(
            <button onClick={handlePage1Next} className="new-shift-next">Next</button>
        )
    }


    const[isFistModalOpenForReOccurringDayModal, setIsFistModalOpenForReOccurringDayModal] = useState(true);

    const RecurringDow = () => {
			const [confirmedSelection, setConfirmedSelection] = useState(false);
			const [tempSelectedDays, setTempSelectedDays] = useState([]);
      // const [isOpenInternalRecurringDaySelectionModal, setisOpenInternalRecurringDaySelectionModal] = useState(true);
			console.log("TEST 12345 : " + selectedRecurringDaysRefObj)
      console.log("isReOccurringShift " + isReOccurringShift + " isOpenInternalRecurringDaySelectionModal " + false
      + " selectedRecurringDaysRefObj " + JSON.stringify(selectedRecurringDaysRefObj.current))
			const toggleDaySelection = (day) => {
				const index = tempSelectedDays.indexOf(day);
				if (index === -1) {
					setTempSelectedDays([...tempSelectedDays, day]); // Add the day if it's not already selected
				} else {
					const newSelectedDays = [...tempSelectedDays];
					newSelectedDays.splice(index, 1); // Remove the day if it's already selected
					setTempSelectedDays(newSelectedDays);
				}
			};
		
			const confirmSelection = () => {
				// setSelectedRecurringDays(tempSelectedDays);
        selectedRecurringDaysRefObj.current = [...tempSelectedDays]
				setConfirmedSelection(true)
        setIsFistModalOpenForReOccurringDayModal(false)
				// setisOpenInternalRecurringDaySelectionModal(false); // Close the modal after confirmation
			};
		
			const isDaySelected = (day) => tempSelectedDays.includes(day);
		
			return (
				
        <Modal open={ (isFistModalOpenForReOccurringDayModal) } onClose={() => setIsFistModalOpenForReOccurringDayModal(false)}>
					<div className='recurring-shift-modal'>
						<div className='recurring-shift-modal-title'>Which days of the week?</div>
						<div className='recurring-shift-modal-dow-holder'>
							{[0, 1, 2, 3, 4, 5, 6].map(day => (
								<button
									key={day}
									className={`recurring-dow ${isDaySelected(day) ? 'selected' : ''}`}
									onClick={() => toggleDaySelection(day)}>
									{['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][day]}
								</button>
							))}
						</div>
						<button
                className='recurring-confirm'
                disabled={!tempSelectedDays || !tempSelectedDays || tempSelectedDays.length < 1}
                onClick={confirmSelection}>
                  Confirm
            </button>

					</div>
				</Modal>
			);
		};
    
    const ShiftDatePage = () => {

        const handleWeekNumberClickv2 = (weekNumber: number, startOfWeek: Date[]) => {
            
          console.log("selectedRecurringDaysRefObj "  + JSON.stringify(selectedRecurringDaysRefObj.current) +" startOfWeek " + startOfWeek);
        
            const handleWeekClick = (weekNumberInAYear: Date) => {
              const dayValuesWithInWeek: Date[] = [];
              for (let i = 0; i < 7; i++) {
                const day = new Date(weekNumberInAYear);
        
                day.setDate(weekNumberInAYear.getDate() + i);
        
                if(!selectedRecurringDaysRefObj.current){
                  dayValuesWithInWeek.push(day);
                }else if (selectedRecurringDaysRefObj.current.includes(i)) {
                  dayValuesWithInWeek.push(day);
                }
              }
              return dayValuesWithInWeek;
            };
        
            if (startOfWeek.length > 0) {
              const startOfClickedWeek = new Date(startOfWeek[0]);
              const daysInNewWeek = handleWeekClick(startOfClickedWeek);
        
              console.log("daysInNewWeek " + daysInNewWeek);
              //   console.log("selectedWeeks" + selectedWeeks)
        
              let valuesfound = 0;
        
              for (let i = 0; i < daysInNewWeek.length; i++) {
                const potStartWeekDay = daysInNewWeek[i];
                // console.log(potStartWeekDay)
        
                if (
                  selectedDays.some(
                    (date) => date.getTime() === potStartWeekDay.getTime()
                  )
                ) {
                  valuesfound++;
                  console.log(potStartWeekDay);
                }
              }
              console.log(valuesfound);
              // deselect
              if (valuesfound === daysInNewWeek.length) {
                const filteredDates: Date[] = [];
                for (let i = 0; i < selectedDays.length; i++) {
                  if (
                    !daysInNewWeek.some(
                      (date) => date.getTime() === selectedDays[i].getTime()
                    )
                  ) {
                    filteredDates.push(selectedDays[i]);
                  }
                }
                // setSelectedWeeks(filteredDates);
                setSelectedDays(filteredDates);
                selectedDaysRefObj.current = [...filteredDates];
              }
              // add elements
              else {
                console.log("here in sssss " + JSON.stringify(daysInNewWeek));
                const resultingSelectedDays: Date[] = [
                  ...selectedDays,
                  ...daysInNewWeek,
                ];
                setSelectedDays([...resultingSelectedDays]);
                selectedDaysRefObj.current = [...resultingSelectedDays];
              }
            }
          };

          const handleClickForRecourring = (newRecourringStatus: boolean) => {
            selectedDaysRefObj.current = [];
            setSelectedDays([]);
            setIsReocurringShift(newRecourringStatus);
            console.log("isReOccurringShift "  + isReOccurringShift)
          };
    
        return(
            <div className={isMobile? "new-shift-page-mobile":"new-shift-page"}>

                

                        <div className={isMobile?"new-shift-page-title-mobile":"new-shift-page-title"}>Select<span className="simple-blue"> Dates</span></div>
                        <div className={isMobile?"new-shift-selection-holder-mobile":"new-shift-selection-holder"}>
                            <div className={isMobile? "datepicker-holder-mobile":"datepicker-holder"}>
                                <div className={isMobile? "isrecurring-holder-mobile":"isrecurring-holder"}>
                                    <button className="recurring-button" onClick={()=>handleClickForRecourring(false)} disabled={!isReOccurringShift}>Individual Shift</button>
                                    <button className="recurring-button" onClick={()=> handleClickForRecourring(true)} disabled={isReOccurringShift}>Reocurring Shift</button>
                                </div>
                                <div className="new-shift-daypicker-holder">
                                {isReOccurringShift ? 
                                    (<DayPicker
                                        showWeekNumber
                                        showOutsideDays={false}
                                        fixedWeeks
                                        min={1}
                                        className="cal"
                                        selected={selectedDays}
                                        month={currentMonth}
                                        onMonthChange={handleMonthChange}
                                        onWeekNumberClick={handleWeekNumberClickv2}
                                        // modifiers={{
                                        //     disabled: { before: today },
                                        // }}
                                        modifiersStyles={{
                                        selected: { backgroundColor: "#2c68bd" },
                                        disabled: { backgroundColor: "#ffffff", borderColor: "#fff" },
                                        }}
                                        // disabled={isDateBeforeToday}
                                        styles={{
                                        day: {
                                            fontSize: "14px",
                                        },
                                        }}
                                    />)
                                    :
                                    (<DayPicker
                                        mode="multiple"
                                        className="cal"
                                        min={1}
                                        selected={selectedDays}
                                        onSelect={(daysSelected: Date[]) => {
                                            setSelectedDays(daysSelected);
                                            selectedDaysRefObj.current = [...daysSelected];
                                        }}
                                        month={currentMonth}
                                        onMonthChange={handleMonthChange}
                                        modifiers={{
                                            disabled: { before: today },
                                        }}
                                        modifiersStyles={{
                                            selected: { backgroundColor: "#2c68bd" },
                                            disabled: { backgroundColor: "#ffffff", borderColor: "#fff" },
                                        }}
                                        disabled={isDateBeforeToday}
                                        styles={{
                                            day: {
                                                fontSize: "14px",
                                            },
                                        }}
                                    />)
                                }
                                </div>
                            </div>                        

                            {isReOccurringShift && isFistModalOpenForReOccurringDayModal ? <RecurringDow /> : null}

                            
                        </div>
                
                    
    
    
            </div>
    
        )
    }

    const ShiftTimeSelectionPage = () =>{

      

    const [startTime, setStartTime] = useState<WorkyTimeObj>(workyStartTimeRefObj.current);    
    const [endTime, setEndTime] = useState<WorkyTimeObj>(workyEndTimeRefObj.current);

    const handleV3StartTimeChange = ({ newWorkyTime }: {  newWorkyTime: WorkyTimeObj }):void => {
      console.log("prev: " + JSON.stringify(workyStartTimeRefObj.current) );
      
      workyStartTimeRefObj.current = { hour: newWorkyTime.hour, minute: newWorkyTime.minute, isAM: newWorkyTime.isAM };
      console.log ( " new workyStartTimeRefObj " + JSON.stringify(workyStartTimeRefObj.current))
      setStartTime(workyStartTimeRefObj.current);
  };

  const handleV3EndTimeChange = ({ newWorkyTime }: {  newWorkyTime: WorkyTimeObj }):void => {
    workyEndTimeRefObj.current = { hour: newWorkyTime.hour, minute: newWorkyTime.minute, isAM: newWorkyTime.isAM };
    setEndTime(workyEndTimeRefObj.current);
};

    return(            
    <div className={isMobile? "new-shift-page-mobile":"new-shift-page"}>

      <div className={isMobile?"new-shift-page-title-mobile":"new-shift-page-title"}>Select<span className="simple-blue"> Time</span></div>
      <div className={isMobile?"new-shift-selection-holder2-mobile":"new-shift-selection-holder2"}>

            <div className={isMobile?"timepicker-title-mobile":"timepicker-title"}>Start Time</div>
            <TimePicker 
                onTimeChange={handleV3StartTimeChange}
                newWorkyTimeObj= { startTime}
                onChangeHelper= {() => {setStartTime(startTime)}}
            />
            <div className={isMobile?"timepicker-title-mobile":"timepicker-title"}>End Time</div>
            <TimePicker 
                onTimeChange={handleV3EndTimeChange}
                newWorkyTimeObj= { endTime}
                onChangeHelper= {() => {setEndTime(endTime)}}
              />
      </div>            
    </div>
    )

    }


    const ShiftTimeSelectionButtons = ()=>{
        const handleShiftTimeSelectionBack = () => {
          setCurrentPage(2)
        };
        const handleShiftTimeSelectionNext = () => {      
            setCurrentPage(4)
        };
      return(
        <>
        <button onClick={handleShiftTimeSelectionBack}className="new-shift-back">Back</button>
        <button onClick={handleShiftTimeSelectionNext} className="new-shift-next">Next</button>

        </>
    )
    }
    

    const ShiftDatePageButtons = ()=>{
        const handlePage2Back = () => {
            setCurrentPage(1)
        };
        const handlePage2Next = () => {
          console.log("selectedDaysRefObj.current " + JSON.stringify(selectedDaysRefObj.current))
            setCurrentPage(3)
        };
        return(
            <>
                <button onClick={handlePage2Back}className="new-shift-back">Back</button>
                <button onClick={handlePage2Next} disabled={!selectedDaysRefObj || !selectedDaysRefObj.current || selectedDaysRefObj.current.length === 0} className="new-shift-next">Next</button>

            </>
        )
    }

    
    
    
    const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const [modalErrOpen, setModalErrOpen] = useState(false);
  const handleErrModalClose = () => setModalErrOpen(false);
    const ShiftRecieptPageButtons = ()=>{
        const handlePage3Back = () => {
            setCurrentPage(3)
        };

        const handlePage3Next = async() => {
            // setCurrentPage(2)

            
        };
        return(
            <>
                <button onClick={handlePage3Back} className="new-shift-back">Back</button>
                <button onClick={(e) =>
                handleSRSubmit({
                    e,
                    selectedRecurringDays: selectedRecurringDaysRefObj.current,
                    selectedDaysRefObj: selectedDaysRefObj.current,
                    numJobbers: finalNumJobbers,
                    selectedJobbers: selectedJobbers,
                    v3StartTimeHourRef: DetermineHourForTweelve(workyStartTimeRefObj.current),
                    v3StartTimeMinuteRef: workyStartTimeRefObj.current.minute,
                    v3EndTimeHourRef: DetermineHourForTweelve(workyEndTimeRefObj.current),
                    v3EndTimeMinuteRef: workyEndTimeRefObj.current.minute,
                    handleModelCallback: setModalOpen,
                    handleModelCallbackError:setModalErrOpen
                  })
                } className="new-shift-next">Submit</button>
            </>
        )
    }


    


    return(
        <div style={{background:"#f5f5f7"}}>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />

            {TopNav({
                firstName: userInfo.firstName,
                lastname: userInfo.lastName,
                companyname: localStorage.getItem('orgName'),
                iconUrl: localStorage.getItem('orgIcon'),
                orgEmail: localStorage.getItem('userEmail'),
            })}
            <div className='srpage-title-holder'>
                <div className={isMobile ? 'srpage1-title-title-mobile':'srpage1-title-title'}>New Shift</div>
            </div>
            {isMobile ? <div className='srpage1-mobile-title'>New Shift</div>:null}
            <div className={isMobile? "new-shift-body-mobile":"new-shift-body"}>
                

                <div className={isMobile? "new-shift-page-holder-mobile":"new-shift-page-holder"}>
                    <div className={isMobile? "new-shift-stepper-mobile":"new-shift-stepper"}>
                        <div className={isMobile? "new-shift-stepper-holder-mobile":"new-shift-stepper-holder"}>
                            {isMobile? (StepperV3Mobile(currentPage)):(StepperV3(currentPage)) }
                        </div>
                    </div>
                    <div className={isMobile? "new-shift-pages-mobile":"new-shift-pages"}>
                        {currentPage === 1 ? <JobberSelectionPage 
                        prevJobbers= {prevJobbers}
                        finalNumJobbers={finalNumJobbers}
                         setFinalNumJobbers={setFinalNumJobbers}
                         selectedJobbers= {selectedJobbers}
                         setSelectedJobbers={setSelectedJobbers}
                         /> : null}
                        {currentPage === 2 ? <ShiftDatePage/> : null}
                        {currentPage === 3 ? <ShiftTimeSelectionPage/> : null}                        
                        {currentPage === 4 ? <ShiftRecieptPage workyStartTimeRefObj={workyStartTimeRefObj.current}
                        workyEndTimeRefObj={workyEndTimeRefObj.current}
                        selectedDaysRefObj={selectedDaysRefObj.current}
                        finalNumJobbers={finalNumJobbers}
                        orgData={orgData}
                        selectedDays={selectedDaysRefObj.current}
                        modelComponentsProps={{modalErrOpen:modalErrOpen,
                           modalOpen:modalOpen,
                           handleClose: handleClose,
                           setModalErrOpen:setModalErrOpen,
                           handleErrModalClose:handleErrModalClose
                          }} 
                        /> : null}
                    </div>
                </div>
                <div className={isMobile? "new-shift-button-holder-mobile":"new-shift-button-holder"}>
                    {currentPage === 1 ? <JobberSelectionPageButtons/> : null}
                    {currentPage === 2 ? <ShiftDatePageButtons/> : null}
                    {currentPage === 3 ? <ShiftTimeSelectionButtons/> : null}
                    {currentPage === 4 ? <ShiftRecieptPageButtons/> : null}
                </div>

            </div>
            <div onClick={()=>{window.location.assign('homepage')}} className='worky-footer'>worky</div>
        </div>
    )
    
}





export {NewShiftV4}