import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { Tooltip } from '@mui/material';
import {
	BillingInfo,
	ShiftPayment,
	WorkyBillPaymentBreakdown,
	ShiftDetails,
	ShiftFormatted,
} from '../tableComponents/PaymentTableInterfaces';
import {formatDateFromEpoch , formatTimeFromEpoch, calculateHoursDifference, cleanBilling} from './paymentsHelper';



const PreviousPaymentsTable = (orgBillingInfo:BillingInfo[]) => {
	console.log("TESTTERRR2 + + "+JSON.stringify(orgBillingInfo));

	function createData(billingCycle: string,billingDate: string,dueDate: string,numShifts: number,total: number, shifts: ShiftFormatted[]) {
		return {
			billingCycle,
			billingDate,
			dueDate,
			numShifts,
			total,
			shifts,
		};
	}
	
	
	const rows = [];
	
	
	interface TablePaginationActionsProps {
		count: number;
		page: number;
		rowsPerPage: number;
		onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
	}
	
	function TablePaginationActions(props: TablePaginationActionsProps) {
		const theme = useTheme();
		const { count, page, rowsPerPage, onPageChange } = props;
	
		const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, 0);
		};
	
		const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page - 1);
		};
	
		const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page + 1);
		};
	
		const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
		};
	
		return (
			<Box sx={{ flexShrink: 0, ml: 2.5 }}>
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label='first page'>
					{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
				</IconButton>
				<IconButton
					onClick={handleBackButtonClick}
					disabled={page === 0}
					aria-label='previous page'>
					{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label='next page'>
					{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
				</IconButton>
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label='last page'>
					{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
				</IconButton>
			</Box>
		);
	}
	
	
	function hasDatePassed(dateString: string): boolean {
		const currentDate = new Date();
		const [day, month, year] = dateString.split('-').map(Number);
	  
		// Months are zero-based in JavaScript, so subtract 1 from the month
		const targetDate = new Date(year, month - 1, day);
	  
		return currentDate > targetDate;
	  }
	
	
	  
	
	
	function Row(props: { row: ReturnType<typeof createData> }) {
		const { row } = props;
		const [open, setOpen] = React.useState(false);
		console.log("ROW DUE DATE --"+ JSON.stringify(row.dueDate));
	
		return (
			<React.Fragment>
				<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
					<TableCell>
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell align='left' component='th' scope='row'>{cleanBilling(row.billingCycle)}</TableCell>
					<TableCell align='left'>{cleanBilling(row.billingDate)}</TableCell>
					{hasDatePassed(row.dueDate) ? <Tooltip placement='right' title='Past Due'><TableCell style={{ fontWeight:"600", color:"red" }} align='left'>{cleanBilling(row.dueDate)}</TableCell></Tooltip> : <TableCell align='left'>{cleanBilling(row.dueDate)}</TableCell>}
					<TableCell align='right'>{row.numShifts}</TableCell>
					<TableCell align='right'>${row.total.toFixed(2)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Box sx={{ margin: 1 }}>
								<Typography variant='h6' gutterBottom component='div'>Shifts Accrued</Typography>
								<Table size='small' aria-label='purchases'>
									<TableHead>
										<TableRow>
											<TableCell align='left'>Start Date</TableCell>
											<TableCell align='left'>Start Time</TableCell>
											<TableCell align='left'>End Time</TableCell>
											<TableCell align='left'>Hours Worked</TableCell>
											<TableCell align='right'># Jobbers</TableCell>
											<TableCell align='right'>Jobber Rate</TableCell>
											{/* <TableCell align='right'>Worky Charge</TableCell> */}
											<TableCell align='right'>Tax </TableCell>
											<TableCell align='right'>Total</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{row.shifts.map((shift, index) => (
									<TableRow key={index}>
										<TableCell align='left'>{shift.startDate}</TableCell>
										<TableCell align='left'>{shift.startTime}</TableCell>
										<TableCell align='left'>{shift.endTime}</TableCell>
										<TableCell align='left'>{shift.hoursWorked}</TableCell>
										<TableCell align='right'>{shift.numJobbers}</TableCell>
										<TableCell align='right'>${shift.jobberRate}/hr</TableCell>
										<TableCell align='right'>${shift.tax.toFixed(2)}</TableCell>
										<TableCell align='right'>${shift.total.toFixed(2)}</TableCell>
									</TableRow>
									))}
								</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		);
	}
	
	
	const populateRows = () => {
		if (!Array.isArray(orgBillingInfo) || orgBillingInfo.length === 0) {
			console.error('Billing data is missing or empty.');
			return;
		}
		console.log("TESTTERRR3  + + "+JSON.stringify(orgBillingInfo));

		const billingCycles: string[] = [];
		const billSentDates: string[] = [];
		const billDueDates: string[] = [];
		const numShiftsPerBillingCycle: number[] = [];
		const totalCostPerBillingCycle: number[] = [];
		const shiftData: ShiftFormatted[][] = [];
	
		for (const item of orgBillingInfo) {
			if (!item.hasOwnProperty('billingCycle') || !item.hasOwnProperty('billSentDate') || !item.hasOwnProperty('billDueDate') || !item.hasOwnProperty('shiftPaymentList')) {
			console.error(`Missing required properties in item. Skipping...`);
			continue;
			}
	
			const billingCycle = item.billingCycle;
			const billSentDate = item.billSentDate;
			const billDueDate = item.billDueDate;
	
			let shiftCount = 0;
			let totalCost = 0;
			const shifts: ShiftFormatted[] = [];
	
			const shiftPaymentList = item.shiftPaymentList;
	
			if (!shiftPaymentList || Object.values(shiftPaymentList).length === 0) {
				console.error(`No shift data found. Skipping...`);
				continue;
			}
	
			for (const shiftKey in shiftPaymentList) {
			const shiftsInList = shiftPaymentList[shiftKey];
			shiftCount += shiftsInList.length;
	
				for (const shift of shiftsInList) {
					const formattedShift: ShiftFormatted = {
						startDate: formatDateFromEpoch(shift.actualStartDateTimeEpochSeconds),
						startTime: formatTimeFromEpoch(shift.actualStartDateTimeEpochSeconds),
						endTime: formatTimeFromEpoch(shift.actualEndDateTimeEpochSeconds),
						hoursWorked: shift.workyBillPaymentBreakdown.totalTimeWorked,
						numJobbers: 1, 
						jobberRate: shift.workyBillPaymentBreakdown.jobberHourlyRate,
						workyCharge: shift.workyBillPaymentBreakdown.orgHourlyRate,
						tax: shift.workyBillPaymentBreakdown.taxAmount,
						total: shift.workyBillPaymentBreakdown.totalCost,
					};
		
					shifts.push(formattedShift);
					totalCost += shift.workyBillPaymentBreakdown.totalCost;
				}
			}
	
			billingCycles.push(billingCycle);
			billSentDates.push(billSentDate);
			billDueDates.push(billDueDate);
			numShiftsPerBillingCycle.push(shiftCount);
			totalCostPerBillingCycle.push(totalCost);
			shiftData.push(shifts);
		}
	
		const minLength = Math.min(
			billingCycles.length,
			billSentDates.length,
			billDueDates.length,
			numShiftsPerBillingCycle.length,
			totalCostPerBillingCycle.length,
			shiftData.length
		);
	
		for (let i = 0; i < minLength; i++) {
			rows.push(createData(
			billingCycles[i],
			billSentDates[i],
			billDueDates[i],
			numShiftsPerBillingCycle[i],
			totalCostPerBillingCycle[i],
			shiftData[i]
			));
		}
	};
	
	populateRows();
	

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 500 }} aria-label='collapsible table'>
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell style={{ fontWeight: 600 }} align='left'>Billing Cycle</TableCell>
						<TableCell style={{ fontWeight: 600 }} align='left'>Billing Date</TableCell>
						<TableCell style={{ fontWeight: 600 }} align='left'>Due Date</TableCell>
						<TableCell style={{ fontWeight: 600 }} align='right'>Number Shifts</TableCell>
						<TableCell style={{ fontWeight: 600 }} align='right'>Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage): rows).map((row) => (
						<Row key={row.billingCycle} row={row} />
					))}
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>   
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
							colSpan={8}
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {'aria-label': 'rows per page',},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};

export { PreviousPaymentsTable };
