interface EmailAddress {
	value: string;
}

interface WorkyObjectMetaData {
	updatedDateTimeEpoch?: number | null;
	updatedDateTime?: string | null;
	version?: number | null;
	submissionDateTimeEpochSeconds?: number | null;
	submissionDateTime?: string | null;
	testData?: boolean | null;
}

interface WorkyTime{
	hour: number,
	minute: number,
}


/**
 * Enum representing various jobber statuses.
 */
enum JobberStatus {
	/**
	 * For any user in Worky, if they belong to an Org/Client or is a Worky
	 * Admin this is their jobber status
	 */
	NOT_JOBBER = 'NOT_JOBBER',

	/**
	 * Jobber has successfully submitted their details to AWS Cognito
	 */
	REGISTERED_ON_COGNITO_COMPLETED = 'REGISTERED_ON_COGNITO_COMPLETED',

	/**
	 * Jobber has successfully submitted their details to AWS Cognito.
	 * AND the jobber has submitted the first Jobber On-boarding form
	 */
	REGISTERED_SUBMITTED_JOBBER_REG_FORM = 'REGISTERED_SUBMITTED_JOBBER_REG_FORM',

	/**
	 * THE Jobber at this point has fully registered all
	 * their details on worky,
	 * registered successfully: email, phone, SR Options
	 *
	 * Now they just need to submit the docs(tax stuff, id stuff)
	 */
	REGISTERED_FULLY_VERIFIED_STILL_NEEDS_TO_SUBMIT_FORMS = 'REGISTERED_FULLY_VERIFIED_STILL_NEEDS_TO_SUBMIT_FORMS',

	/**
	 * The jobber is registered and has uploaded resume
	 */
	REGISTERED_FULLY_VERIFIED_UPLOADED_RESUME = 'REGISTERED_FULLY_VERIFIED_UPLOADED_RESUME',

	/**
	 * The jobber is registered and has uploaded gov id,
	 * Now the jobber registration status should show
	 * that the jobber is in the interview pool
	 * Tell jobber that application is being processed and to wait for contact
	 */
	REGISTERED_FULLY_VERIFIED_UPLOADED_GOV_ID = 'REGISTERED_FULLY_VERIFIED_UPLOADED_GOV_ID',

	/**
	 * The jobber is registered and now need to upload
	 * valid legal proof that they are authorized to work in Canada
	 * This can be:
	 * Canadian Passport, Canadian Permit Resident Card, Student_Visa, Refugee_Doc, Other
	 */
	REGISTERED_FULLY_VERIFIED_UPLOADED_LEGAL_PROOF_OF_WORK = 'REGISTERED_FULLY_VERIFIED_UPLOADED_LEGAL_PROOF_OF_WORK',

	/**
	 * Jobber needs to upload a personal profile picture that is business appropriate
	 */
	REGISTERED_FULLY_VERIFIED_UPLOAD_PROFILE_PICTURE = 'REGISTERED_FULLY_VERIFIED_UPLOAD_PROFILE_PICTURE',

//////////////////////////////////////

	HIRED_START_OF_ON_BOARDING = 'HIRED_START_OF_ON_BOARDING',

	HIRED_SUBMITTED_WHIMS_CONFIRMATION = 'HIRED_SUBMITTED_WHIMS_CONFIRMATION',

	HIRED_UPLOADED_TD1_CAD = 'HIRED_UPLOADED_TD1_CAD',
	HIRED_UPLOADED_TD1_ON = 'HIRED_UPLOADED_TD1_ON',
	HIRED_SUBMITTED_WORKY_JOBBER_CONTRACT = 'HIRED_SUBMITTED_WORKY_JOBBER_CONTRACT',
	HIRED_SUBMITTED_DIRECT_DEPOSIT = 'HIRED_SUBMITTED_DIRECT_DEPOSIT',

	/**
	 * Jobber has submitted the necessary accounting/regulation info to be considered for SRs
	 */
	FULLY_HIRED = 'FULLY_HIRED',

	CANCELLED = 'CANCELLED',

	DROPPED = 'DROPPED',
}


interface CancelShiftRequestObj{
	shiftId: string;
  }


interface ShiftStartEndDetails{
	startDateTimeEpochSec: number;
	endDateTimeEpochSec: number;
}

export { WorkyObjectMetaData, JobberStatus, WorkyTime, CancelShiftRequestObj, ShiftStartEndDetails, EmailAddress };
