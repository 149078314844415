import React, { useState, useEffect, useRef } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Modal from "@mui/material/Modal";
import { AxiosResponse } from "axios";
import { WorkyTimePicker, WorkyTimePickerFuture } from "../common/TimePicker";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { SendErrorEmailv2 } from "../shared-engineering-services/SharedAPICalls";
import { convertDatesToEpochSec } from "../common/TimeUtils";
import { CalcWorkyChargeNotIncludingTax, CalcShiftDuration, CalcTax } from "../common/ShiftServiceUtils";
import {
  Workertype,
  CreateShiftRequestAPIObj,
  ShiftReqResponse,
} from "../jobberViews/objects/ShiftRequestConstants";
import { DayPicker } from "react-day-picker";
import East from "@mui/icons-material/East";
import West from "@mui/icons-material/West";
import { useIsMobile } from "../custom-hooks/useIsMobile";
import { JobberProfileBuilder } from "../orgViews/orgConstants";
import { JobberProfile } from "../orgViews/jobberProfileBuilder";
import { JobberDetailsForOrg } from "../orgViews/OrgUtils";
import {
  RemoteExecutionServiceImpl,
  ServerResponse,
  RemoteGETCallInputObject,
  getGlobalUserDetails,
} from "../remoteExecution/RemoteExecutionServiceImpl";
import CloseIcon from '@mui/icons-material/Close';
import { DefaultComponents, handleSRSubmit, RetrievePreviousJobbersAPICall } from "./SrIngressHelperService";
import { EmailAddress, ShiftStartEndDetails } from "../common/CommonInterfaces";
import { TimePicker, WorkyTimeObj, FormatTime, DetermineHourForTweelve } from "./TimePicker";
import './newshift.css'
import { TopNav, PushToNewSr, PushtoSrHistory } from '../orgViews/OrgCommonComponents';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface ModelComponentsProps {
    modalOpen: boolean;
    handleClose: () => void;
    modalErrOpen: boolean;
    setModalErrOpen: (isOpen: boolean) => void;
    handleErrModalClose: () => void;
}


    
const ModelComponenetsOnSubmission = ({modelComponentsProps} : 
    {modelComponentsProps: ModelComponentsProps}) => {

        const modalOpen = modelComponentsProps.modalOpen;
        const handleClose = modelComponentsProps.handleClose;
        const modalErrOpen= modelComponentsProps.modalErrOpen;
        const handleErrModalClose= modelComponentsProps.handleErrModalClose;
        
    return (
      <div>
        <link rel="stylesheet" href="https://use.typekit.net/rjq7odq.css" />
        <Modal open={modalOpen} onClose={handleClose}>
          <div className="submit-success">
          <button onClick={handleClose} className='modal-close'><CloseIcon/></button>
            <div className="submit-success-title">Success!</div>
            <div className="submit-success-icon">
              <CheckCircleRoundedIcon
                style={{ fontSize: "150px", height: "150px", color: "#6DBB3A" }}
              />
            </div>
            <div className="submit-success-subtitle">
              You've successfully submitted your shift!
            </div>
            <div className="submit-success-subtitle">
              Want to hire more? Click below
            </div>
            <button onClick={PushToNewSr} className="submit-success-button">
              Hire More
            </button>
          </div>
        </Modal>

        <Modal open={modalErrOpen} onClose={handleErrModalClose}>
          <div className="submit-success">
            <div className="submit-success-title">Error</div>
            <div className="submit-success-subtitle">There was an issue!</div>
            <div className="submit-success-subtitle">
              Want to start again? Click below
            </div>
            <button onClick={PushToNewSr} className="submit-success-button">
              Try Again
            </button>
          </div>
        </Modal>

        {/* <Modal open={modalShiftOpen} onClose={handleShiftModalClose}>
          <div className="submit-success">
            <div className="submit-success-title">Shift Length</div>
            <div className="submit-success-subtitle">
              Are you sure this is what you meant?
            </div>
            <div className="submit-success-subtitle">
              Shift length :{" "}
              {v3EndTimeHourRef.current - v3StartTimeHourRef.current} Hr
            </div>
            <div className="shiftback-buttons">
              <button
                onClick={handleShiftback}
                style={{ background: "#cccccc" }}
                className="submit-success-button"
              >
                Go Back
              </button>
              <button
                onClick={handleShiftModalClose}
                className="submit-success-button"
              >
                Looks Good
              </button>
            </div>
          </div>
        </Modal> */}
      </div>
    );
  };

const ShiftRecieptPage = ({
    workyStartTimeRefObj,
    selectedDaysRefObj,
    finalNumJobbers,    
    workyEndTimeRefObj,
    orgData, // Assuming this is another dependency passed from parent component
    selectedDays,
    modelComponentsProps
} : {
    workyStartTimeRefObj: WorkyTimeObj,
    workyEndTimeRefObj: WorkyTimeObj,
    selectedDaysRefObj: Date[],
    finalNumJobbers: number,
    orgData: any,
    selectedDays: Date[],
    modelComponentsProps: ModelComponentsProps,



}) => {
  const isMobile = useIsMobile();

    const totalShiftDuration = CalcShiftDuration({startTimeHour: DetermineHourForTweelve(workyStartTimeRefObj),
        startTimeMinute: workyStartTimeRefObj.minute, endTimeHour: DetermineHourForTweelve(workyEndTimeRefObj),endTimeMinute: workyEndTimeRefObj.minute})

        const totalNumOfShifts = finalNumJobbers * selectedDays.length;
         //  (selectedRecurringDaysRefObj.current && selectedRecurringDaysRefObj.current.length > 0 
         // ?
         // : selectedDays)

         const shiftCostBeforeTax = CalcWorkyChargeNotIncludingTax({shiftDuration: totalShiftDuration,
            numWorkers: finalNumJobbers, numDays: selectedDaysRefObj?.length,
             workerHourlyPayRate: orgData?.orgBillingData?.agreedRatesPerAWorkerType?.WAREHOUSE_ASSOCIATE,
           markUpPrecentage: orgData?.orgBillingData?.workyMarkup})
       const Reciept = () => {
           return(
               <div className={isMobile?"reciept-v2-mobile":"reciept-v2"}>
                   <div className={isMobile? "reciept-col1-mobile":"reciept-col1"}>
                   <div className={isMobile? "reciept-info-holder-mobile":"reciept-info-holder"}>
                           <div className="reciept-item">Number of Workers</div>
                           <div className="reciept-value">{finalNumJobbers}</div>
                       </div>

                       
                       <Accordion disableGutters className="reciept-accordian" style={isMobile?{height:"auto",marginBottom:"0px",boxShadow:"none", minHeight:"50px", display: "flex", flexDirection: "column", justifyContent:"center", width: "100%", background:"white", borderRadius:"20px"}:{height:"auto",marginBottom:"0px",boxShadow:"none", minHeight:"50px", display: "flex", flexDirection: "column", justifyContent:"center", width: "100%", background:"#f5f5f7", borderRadius:"20px"}}>
                           <AccordionSummary
                               expandIcon={<ExpandMoreIcon />}
                               aria-controls="panel1-content"
                               id="panel1-header"
                               style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                           >
                           <><span className='accordian-title'>Shift Date(s)</span> <span style={{marginLeft:"auto", paddingRight:"10px"}} className='reciept-value'>{selectedDaysRefObj.length}</span></>
                           </AccordionSummary> 
                           <AccordionDetails style={{marginBottom:"0px"}}>
                             <div className='accordian-shift-dates'>
                                 {selectedDaysRefObj.map((date, index) => (
                                     <div className={isMobile? "accordian-shift-dates-each-mobile":"accordian-shift-dates-each"} key={index}>{date.toDateString()}</div>
                                 ))}
                             </div>
                         </AccordionDetails>
                       </Accordion>

                       <div className={isMobile? "reciept-info-holder-mobile":"reciept-info-holder"}>
                           <div className="reciept-item">Shift Time</div>
                           <div className="reciept-value">
                               {FormatTime(workyStartTimeRefObj)} - {FormatTime(workyEndTimeRefObj)}
                           </div>
                       </div>

                       <div className={isMobile? "reciept-info-holder-mobile":"reciept-info-holder"}>
                           <div className="reciept-item">Worker Type</div>
                           <div className="reciept-value"> Warehouse Assoicate</div>
                       </div>

                       <div className={isMobile? "reciept-info-holder-mobile":"reciept-info-holder"}>
                           <div className="reciept-item">Worker Pay</div>
                           <div className="reciept-value">${orgData?.orgBillingData?.agreedRatesPerAWorkerType?.WAREHOUSE_ASSOCIATE}/hr</div>
                       </div>

                       <div className={isMobile? "reciept-info-holder-mobile":"reciept-info-holder"}>
                           <div className="reciept-item">Worky Charge</div>
                           <div className="reciept-value">{orgData?.orgBillingData?.workyMarkup * 100}%</div>
                       </div>
                   </div>

                   <div className={isMobile? "reciept-col2-mobile":"reciept-col2"}>
                       <div className={isMobile? "reciept-info-holder-mobile":"reciept-info-holder"}>
                           <div className="reciept-item">Tax</div>
                           <div className="reciept-value">${CalcTax({amount:shiftCostBeforeTax, taxRate:0.13}).toFixed(2)}</div>
                       </div>

                       <div className={isMobile? "reciept-info-holder-mobile":"reciept-info-holder"}>
                           <div className="reciept-item">Total</div>
                           <div className="reciept-value">${shiftCostBeforeTax.toFixed(2)}</div>
                       </div>
                   </div>
                   <ModelComponenetsOnSubmission modelComponentsProps = {modelComponentsProps}/>
               </div>
           )
       }

       
   
       
       return(
           <div className={isMobile? "new-shift-page-mobile":"new-shift-page"}>
               <div className={isMobile?"new-shift-page-title-mobile":"new-shift-page-title"}><span className="simple-blue"> Review</span> & <span className="simple-blue">Submit</span></div>
               <div className={isMobile?"new-shift-selection-holder-mobile":"new-shift-selection-holder"}>
                   <Reciept/>
               </div>
           </div>
       )
};


const JobberSelectionPage = ({ prevJobbers, finalNumJobbers, setFinalNumJobbers,  selectedJobbers, setSelectedJobbers} :
    { prevJobbers: JobberDetailsForOrg[],  finalNumJobbers: number, setFinalNumJobbers: (number) => void, selectedJobbers: EmailAddress[], setSelectedJobbers, }) => { 

        
      const isMobile = useIsMobile();


    const [localSelectedJobbers, setLocalSelectedJobbers] = useState<EmailAddress[]>([])
    

    const decrementNumJobbers = () => {
        if (finalNumJobbers > 1) {
            setFinalNumJobbers(prevNum => prevNum - 1);
        }
      };
  
    const incrementNumJobbers = () => {
        setFinalNumJobbers(prevNum => prevNum + 1);
    };
        
    const NumJobbers = () => {
        return(
          <div className={isMobile? "num-jobbers-v2-mobile":"num-jobbers-v2"}>
          <button className="num-jobbers-button" onClick={decrementNumJobbers}>-</button>

          <input className="num-jobbers-value" min='1' value={finalNumJobbers} type='number' onChange={(e) => setFinalNumJobbers(parseInt(e.target.value) || 0)}/>

          <button className="num-jobbers-button" onClick={incrementNumJobbers}>+</button>
      </div>
        )
    }

  const PreviousJobberSelect = (previousJobberData: JobberDetailsForOrg[]) => {
        
    const handleSelectAll = ({singleJobberEmail, isSelectAll}:{singleJobberEmail?: EmailAddress, isSelectAll: boolean}) => {
        if(isSelectAll){
            const allJobberEmails:EmailAddress[] = previousJobberData.map(
                (jobber) => jobber.userDetails.userEmail
            );

            if (selectedJobbers.length === allJobberEmails.length) {
                setSelectedJobbers([]);
                setLocalSelectedJobbers([])
            } else {
                setSelectedJobbers(allJobberEmails);
                setLocalSelectedJobbers(allJobberEmails)
            }

        }else{
            console.log("singleJobberEmail "+ JSON.stringify(singleJobberEmail) + " selectedJobbers " + JSON.stringify(selectedJobbers))
            if (selectedJobbers.map(jobber => jobber.value).includes(singleJobberEmail.value)) {
                const filteredSelectedJobbers = selectedJobbers.filter((jobber) => jobber.value !== singleJobberEmail.value);
                setSelectedJobbers([...filteredSelectedJobbers]);
            }else{
                setSelectedJobbers([...selectedJobbers, singleJobberEmail]);
            }
        }
            
    };            


        const AvailablePrevJobbers = () => {
          
            return (
              <>
                {previousJobberData.map((jobber, index) => {
                  const jobberProfile: JobberProfileBuilder = {
                    jobberName: jobber.userDetails.firstName + " " + jobber.userDetails.lastName,
                    jobberEmail: jobber.userDetails.userEmail.value,
                    jobberPhone: jobber.userDetails.userPhoneNumber.value,
                    jobberPic: jobber.userProfilePicUrl,
                  };
          
                  return (
                    <div style={{paddingTop:"5px"}}>
                    <div className='each-prev-jobber-holder'>
                        <div className='jobber-holder'>
                          <JobberProfile
                            key={index}
                            hasCheck={true}
                            jobberProfile={jobberProfile}
                        />
                        </div>
                        <div className='checkbox-holder' 
                        onClick={() => handleSelectAll({isSelectAll:false, singleJobberEmail: {value: jobberProfile.jobberEmail}})}>
                            <input
                                type='checkbox'
                                className='prev-jobber-checkbox'
                                value={index}
                                style={{ transform: 'scale(1.21)' }}
                                checked={selectedJobbers.map(email => email.value).includes(jobberProfile.jobberEmail) 
                                || localSelectedJobbers.map(email => email.value).includes(jobberProfile.jobberEmail)}

                                onChange={() => handleSelectAll({isSelectAll:false, singleJobberEmail: {value: jobberProfile.jobberEmail}})}

                            />
                        </div>
                    </div>
                    </div>
                  );
                })}
              </>
            );
        };
          

        return (
            <div className="preffered-jobber-holder-mobile">
                <button onClick={ () =>  handleSelectAll({singleJobberEmail:null, isSelectAll:true})} className='select-all-prev-jobbers'>
                    {selectedJobbers.length === previousJobberData.length
                        ? 'Deselect All'
                        : 'Select All'}
                </button>
                <AvailablePrevJobbers />
            </div>
        );
    };

    

    
    return(
        <div className={isMobile? "new-shift-page-mobile":"new-shift-page"}>
            <div className={isMobile?"new-shift-page-title-mobile":"new-shift-page-title"}>Select<span className="simple-blue"> Workers</span></div>
            <div className={isMobile?"new-shift-selection-holder-mobile":"new-shift-selection-holder"}>

                <div className={isMobile?"num-jobbers-holder-mobile":"num-jobbers-holder"}>
                    <div className="num-jobbers-title">Number Of Workers</div>
                    <NumJobbers/>
                </div>
                {prevJobbers ?
                <div className={isMobile?"preferred-jobbers-holder-mobile":"preferred-jobbers-holder"}>
                <div className="num-jobbers-title">My Workers</div>
                     {(PreviousJobberSelect(prevJobbers))}
                </div>
                 : null}
            </div>
        </div>
    )
}






export {ShiftRecieptPage, JobberSelectionPage}