import React, { useState, useEffect, useRef } from "react";
import { useIsMobile } from '../custom-hooks/useIsMobile';

interface WorkyTimeObj {
    hour:number;
    minute: number;
    isAM:boolean;
}

interface TimePickerProps {
    onTimeChange: (hour: number, minute: number, isAM: boolean) => void;
    initialHour: number;
    initialMinute: number;
    initialIsAM: boolean;
}

const TimePicker = ({ onTimeChange, onChangeHelper, newWorkyTimeObj }:{onTimeChange: ({ newWorkyTime }
        : {  newWorkyTime: WorkyTimeObj })=>void, onChangeHelper: () =>void,newWorkyTimeObj: WorkyTimeObj}) => {
    const [hour, setHour] = useState<number>(newWorkyTimeObj.hour);
    const isMobile = useIsMobile();
    const [minute, setMinute] = useState<number>(newWorkyTimeObj.minute);
    const [isAM, setIsAM] = useState<boolean>(newWorkyTimeObj.isAM);
  
    const handleHourChange = (event) => {
        const newHour = parseInt(event.target.value);
        if(isNaN(newHour)) return;
        
        if (newHour >= 1 && newHour <= 12) {
            setHour(newHour);
            onTimeChange({newWorkyTime: {hour: newHour, minute, isAM} });            
        }else{
            const updatedHour = newHour <= 12 ? newHour : parseInt(event.target.value.charAt(event.target.value.length - 1));
            if(isNaN(updatedHour)||updatedHour===0) return;
            
            setHour(updatedHour);
            onTimeChange({newWorkyTime: {hour: updatedHour, minute, isAM} });            
        }
    };
  
    const handleMinuteChange = (event) => {
        const newMinute = parseInt(event.target.value);
        if (newMinute >= 0 && newMinute <= 59) {
            setMinute(newMinute);
            onTimeChange({newWorkyTime: {hour, minute:newMinute, isAM} });
            
        }else if(newMinute>=59){
            
            setMinute(59);            
            onTimeChange({newWorkyTime: {hour, minute: 59, isAM} });
        }
    };
  
    const handleAMClick = () => {
        if (!isAM) {
            setIsAM(true);
            onTimeChange({newWorkyTime: {hour, minute, isAM: !isAM} });
        }
      };
    
    const handlePMClick = () => {
        if (isAM) {
            setIsAM(false);
            onTimeChange({newWorkyTime: {hour, minute, isAM:!isAM} });
        }
    };
  
    return (
        <div className={isMobile?"timepickerv4-mobile":"timepickerv4"}>
            <input
                type="number"
                min={1}
                max={12}
                value={hour}
                onChange={handleHourChange}
                className={isMobile? "time-input-mobile":"time-input"}
                maxLength={2}
                // className="time-input-mobile"
            />
            :
            <input
                type="number"
                min={0}
                max={59}
                value={minute < 10 ? `0${minute}` : `${minute}`}
                onChange={handleMinuteChange}
                className={isMobile? "time-input-mobile":"time-input"}
                maxLength={2}
                // className="time-input-mobile"
            />
            <div className={isMobile?"ampm-button-holder-mobile":"ampm-button-holder"}>
            {/* <div className="ampm-button-holder-mobile"> */}
                <button className="am-button" onClick={handleAMClick} disabled={isAM}>AM</button>
                <button className="pm-button" onClick={handlePMClick} disabled={!isAM}>PM</button>
            </div>
        </div>
    );
};


const FormatTime = (time: WorkyTimeObj) => {
    const { hour, minute, isAM } = time;
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    const period = isAM ? 'AM' : 'PM';
    return `${formattedHour}:${formattedMinute} ${period}`;
};

const DetermineHourForTweelve = (time: WorkyTimeObj) : number =>{
    return time.isAM ? time.hour : time.hour + 12;
}

export {TimePicker, WorkyTimeObj, TimePickerProps, FormatTime, DetermineHourForTweelve}