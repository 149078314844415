import { AxiosResponse } from 'axios';
import { JobberStatus } from '../common/CommonInterfaces';
import { JobberDTO } from '../jobberViews/objects/JobberRegistrationFormConstants';
import {
	UserType,
	handleRetrieveNewTokens,
	ServerResponse,
	redirectToLogin,
	RemoteExecutionServiceImpl,
	ServerErrorDetails,
	AuthGetCall,
	getGlobalUserDetails,
	WorkySignInResponse,
	WorkyAuthTokenResponse,
	WorkyAuthRefreshRequest,
	isExpiredCalc,
	RemoteGETCallInputObject,
	UpdateLocalCacheWOAuthInfo,
	setGlobalUserDetails,
	BasicJobberDetails,
} from '../remoteExecution/RemoteExecutionServiceImpl';

const remoteCallObject = RemoteExecutionServiceImpl();
const redirectURL = process.env.REACT_APP_BACK_END_SERVER_SIGN_IN_REDIRECT_URL || '';

const getJobberDetailsHelp = async (): Promise<
	AxiosResponse<ServerResponse<JobberDTO, Error>>
> => {
	const getCallInput: RemoteGETCallInputObject = {
		apiUrl: '/v1/jobber/get',
		headers: {},
		queryParams: {},
	};

	return remoteCallObject.remoteGETCallV2<ServerResponse<JobberDTO, Error>>(getCallInput);
};

interface JobberUserDetails {
	jobberStatus: JobberStatus;
}

interface WorkyLoginResult {
	userType: UserType;
	jobberDetails?: JobberUserDetails;
	orgDetails?: any;
}

const handleLoginWithAuthCode = async ({
	code,
}: {
	code: string;
}): Promise<WorkySignInResponse | undefined> => {
	console.log(`Desktop The 'code' parameter is: ${code}`);
	localStorage.setItem('auth_code', code);

	try {
		const resp = await AuthGetCall({
			authCodeInput: code,
			redirectURIInput: redirectURL,
		});

		if (resp && resp.data && resp.data.data) {
			const response: WorkySignInResponse = resp.data.data;

			UpdateLocalCacheWOAuthInfo({
				basicDetails: response.basicJobberDetails,
				input: response.tokens,
			});
			setGlobalUserDetails({
				userInfo: resp.data.data.basicJobberDetails.userDetails,
			});

			// will contain all statuses, user details, and user type
			return response;
		} else {
			console.error('Error retrieving WorkySignInResponse');
			redirectToLogin();
		}
	} catch (error) {
		console.error('Error fetching response:', error);
		redirectToLogin();
	}
};

const handleJobberUserLogin = async ({
	accessToken,
	code,
	expireTime,
	refreshToken,
	userType,
	idToken,
}: {
	accessToken?: string;
	code?: string;
	expireTime?: string;
	refreshToken?: string;
	userType?: string;
	idToken?: string;
}): Promise<WorkySignInResponse | undefined> => {
	// check if user has valid access token that is not expired
	// try the  refresh token
	// check if user has auth code
	// if non work then redirect
	if (userType !== UserType.JOBBER) {
		console.log('not jobber re-directing to login');
		redirectToLogin();
	}

	if (accessToken && expireTime && !isExpiredCalc(expireTime)) {
		console.log('Using access token to retrieve jobber details');

		const res2: AxiosResponse<ServerResponse<JobberDTO, Error>> = await getJobberDetailsHelp();

		const possibleExpireTime = parseInt(expireTime);

		const userEmail = localStorage.getItem('userEmail');

		const tokens: WorkyAuthTokenResponse = {
			accessToken: accessToken,
			idToken: idToken,
			refreshToken: refreshToken,
			expiresInMilliseconds:
				possibleExpireTime && !isNaN(possibleExpireTime) ? possibleExpireTime : 1,
			useremail: userEmail ? { value: userEmail } : { value: '' },
		};

		const basicDetails: BasicJobberDetails = {
			userDetails: getGlobalUserDetails(),
			userType: UserType.JOBBER,
			jobberStatus: res2.data.data.jobberStatus,
		};

		return { tokens: tokens, basicJobberDetails: basicDetails };
	} else if (refreshToken) {
		console.log('for jobber user Access token is invalid, using refresh token');

		const resp = await handleRetrieveNewTokens(refreshToken);
		if (!resp) {
			redirectToLogin();
		}

		const res2: AxiosResponse<ServerResponse<JobberDTO, Error>> = await getJobberDetailsHelp();

		const userEmail = localStorage.getItem('userEmail');
		const possibleExpireTime = parseInt(localStorage.getItem('expire_time'));

		const tokens: WorkyAuthTokenResponse = {
			accessToken: localStorage.getItem('access_token'),
			idToken: localStorage.getItem('id_token'),
			refreshToken: localStorage.getItem('refresh_token'),
			expiresInMilliseconds:
				possibleExpireTime && !isNaN(possibleExpireTime) ? possibleExpireTime : 1,
			useremail: userEmail ? { value: userEmail } : { value: '' },
		};

		const basicDetails: BasicJobberDetails = {
			userDetails: getGlobalUserDetails(),
			userType: UserType.JOBBER,
			jobberStatus: res2.data.data.jobberStatus,
		};

		return { tokens: tokens, basicJobberDetails: basicDetails };
	} else if (code && code !== localStorage.getItem('auth_code')) {
		console.log('for jobber user will login w/ authcode');
		return handleLoginWithAuthCode({ code: code });
	} else {
		console.log(
			'no valid auth code, no valid access token, no valid refresh token for jobber user, request for re-login'
		);
		redirectToLogin();
		return null;
	}
};

const handleOrgUserLogin = async ({
	accessToken,
	code,
	expireTime,
	refreshToken,
	userType,
	idToken,
}: {
	accessToken?: string;
	code?: string;
	expireTime?: string;
	refreshToken?: string;
	userType?: string;
	idToken?: string;
}): Promise<WorkySignInResponse | undefined> => {
	// check if user has valid access token that is not expired
	// try the  refresh token
	// check if user has auth code
	// if non work then redirect

	if (accessToken && expireTime && !isExpiredCalc(expireTime)) {
		console.log('Using access token to retrieve jobber details');
		const possibleExpireTime = parseInt(expireTime);

		const userEmail = localStorage.getItem('userEmail');

		const tokens: WorkyAuthTokenResponse = {
			accessToken: accessToken,
			idToken: idToken,
			refreshToken: refreshToken,
			expiresInMilliseconds:
				possibleExpireTime && !isNaN(possibleExpireTime) ? possibleExpireTime : 1,
			useremail: userEmail ? { value: userEmail } : { value: '' },
		};

		const basicDetails: BasicJobberDetails = {
			userDetails: getGlobalUserDetails(),
			userType: UserType.ORG_MANAGER,
			jobberStatus: null,
		};

		return { tokens: tokens, basicJobberDetails: basicDetails };
	} else if (refreshToken) {
		console.log('for org user Access token is invalid, using refresh token');

		const resp = await handleRetrieveNewTokens(refreshToken);
		if (!resp) {
			redirectToLogin();
		}

		const userEmail = localStorage.getItem('userEmail');
		const possibleExpireTime = parseInt(localStorage.getItem('expire_time'));

		const tokens: WorkyAuthTokenResponse = {
			accessToken: localStorage.getItem('access_token'),
			idToken: localStorage.getItem('id_token'),
			refreshToken: localStorage.getItem('refresh_token'),
			expiresInMilliseconds:
				possibleExpireTime && !isNaN(possibleExpireTime) ? possibleExpireTime : 1,
			useremail: userEmail ? { value: userEmail } : { value: '' },
		};

		const basicDetails: BasicJobberDetails = {
			userDetails: getGlobalUserDetails(),
			userType: UserType.ORG_MANAGER,
			jobberStatus: null,
		};

		return { tokens: tokens, basicJobberDetails: basicDetails };
	} else if (code && code !== localStorage.getItem('auth_code')) {
		console.log('for org user will login w/ authcode');
		return handleLoginWithAuthCode({ code: code });
	} else {
		console.log(
			'no valid auth code, no valid access token, no valid refresh token for org user, request for re-login'
		);
		redirectToLogin();
		return null;
	}
};

const signUserInService = async ({
	accessToken,
	code,
	expireTime,
	refreshToken,
	userType,
	idToken,
}: {
	accessToken?: string;
	code?: string;
	expireTime?: string;
	refreshToken?: string;
	idToken?: string;
	userType?: string;
}): Promise<WorkySignInResponse | undefined> => {
	// check user type

	console.log('curr expire time: ' + expireTime);

	if (userType && userType === UserType.ORG_MANAGER) {
		console.log('user type is org');
		return handleOrgUserLogin({
			accessToken,
			code,
			expireTime,
			refreshToken,
			userType,
			idToken,
		});
	} else if (userType && userType === UserType.JOBBER) {
		console.log('user type is jobber');
		// handleJobberUserLogin();
		return handleJobberUserLogin({
			accessToken,
			code,
			expireTime,
			refreshToken,
			userType,
			idToken,
		});
	} else if (
		userType &&
		(userType === UserType.WORKY_ADMIN || userType === UserType.WORKY_STAFF)
	) {
		console.log('user type is worky staff');
		// handleJobberUserLogin();
		return undefined;
	} else if (code && code !== localStorage.getItem('auth_code')) {
		console.log('during first render of homepage w/ auth code, unknown user type');
		return handleLoginWithAuthCode({ code: code });
	} else {
		console.log('no userType and no auth code, redirecting to IDP');
		redirectToLogin();
	}

	// check if user has valid access token that is not expired
	// try the  refresh token
	// check if user has auth code
	// if non work then redirect
};

export { signUserInService };
