const GenerateRandomNumber = () => {
	return Math.floor(Math.random() * 1000) + 1;
};


const GenerateCurrentEpochSeconds = () =>{
	const currentTimeInSeconds = Math.floor(Date.now() / 1000);
	console.log(" currentTimeInSeconds " + currentTimeInSeconds);
	return currentTimeInSeconds;
}



const IsValidEmail = (email) => {
    // Regular expression for basic email validation
	if(!email) return false;

	try{
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    	return emailRegex.test(email);
	}catch(e){
		console.log("error parsing email: "+ email,e)
		return false;
	}
    
};



export { GenerateRandomNumber, GenerateCurrentEpochSeconds,IsValidEmail };
