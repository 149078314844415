import React, { useState, useEffect } from 'react';
import { AuthGetCall } from '../remoteExecution/WorkyJobberSignIn';
import './basicInfoPage.css'

// URI : jobber-onboarding



const BasicInfoPage = (authObj) =>{


    const sampleAuthObj = {
        data: {
            tokens: {
                accessToken: "str.str.str",
                idToken: "str.str.str",
                refreshToken: "str.str.str",
                useremail: {
                    value: "email+16@gmail.com"
                }
            },
            basicJobberDetails: {
                userDetails: {
                    userEmail: {
                        value: "email+16@gmail.com"
                    },
                    userPhoneNumber: {
                        value: "+19051231123"
                    },
                    lastName: "lastname",
                    firstName: "firstname"
                },
                jobberStatus: "REGISTERED_ON_COGNITO_COMPLETED"
            },
            errorDetails: null
        }
    };
    
    const accessToken = sampleAuthObj.data.tokens.accessToken;
    const idToken = sampleAuthObj.data.tokens.idToken;
    const refreshToken = sampleAuthObj.data.tokens.refreshToken;
    const useremailValue = sampleAuthObj.data.tokens.useremail.value;
    
    const userEmailValue = sampleAuthObj.data.basicJobberDetails.userDetails.userEmail.value;
    const userPhoneNumberValue = sampleAuthObj.data.basicJobberDetails.userDetails.userPhoneNumber.value;
    const lastName = sampleAuthObj.data.basicJobberDetails.userDetails.lastName;
    const firstName = sampleAuthObj.data.basicJobberDetails.userDetails.firstName;
    
    const jobberStatus = sampleAuthObj.data.basicJobberDetails.jobberStatus;
    
    const errorDetails = sampleAuthObj.data.errorDetails;
    







    
    const LoginHeaderContent = ({ firstName, lastName }) => {
        return(
        <>
            <div className='bi-title-d'>Welcome to Worky</div>
            <div className='bi-top-d'>
                <div className='bi-profilepic'></div>
                <div className='bi-name'>Hello<br/>{firstName} {lastName}</div>
            </div>
        </>
        )
    }


    const RegisteredOnCognitoCompletedContent = () => {
        return(<div>RegisteredOnCognitoCompletedContent</div>)
    }

    const RegisteredSubmittedJobberRegFormContent = () => {
        return(<div>RegisteredSubmittedJobberRegFormContent</div>)
    }

    const RegisteredNotVerifiedEmailAndPhoneOnWorkyContent = () => {
        return(<div>RegisteredNotVerifiedEmailAndPhoneOnWorkyContent</div>)
    }

    const RegisteredNotVerifiedEmailOnWorkyContent = () => {
        return(<div>RegisteredNotVerifiedEmailOnWorkyContent</div>)
    }

    const RegisteredFullyVerifiedStillNeedsToSubmitFormsContent = () => {
        return(<div>RegisteredFullyVerifiedStillNeedsToSubmitFormsContent</div>)
    }

    const RegisteredJobberSubmittedAllNecessaryDocsContent = () => {
        return(<div>RegisteredJobberSubmittedAllNecessaryDocsContent</div>)
    }
    
    const RegistrationError = () => {
        return(<div>RegistrationError</div>)
    }




    const RenderLoginContent = (status) => {
        switch (status) {
            case "REGISTERED_ON_COGNITO_COMPLETED":
                return <RegisteredOnCognitoCompletedContent />;
            case "REGISTERED_SUBMITTED_JOBBER_REG_FORM":
                return <RegisteredSubmittedJobberRegFormContent />;
            case "REGISTERED_NOT_VERIFIED_EMAIL_AND_PHONE_ON_WORKY":
                return <RegisteredNotVerifiedEmailAndPhoneOnWorkyContent />;
            case "REGISTERED_NOT_VERIFIED_EMAIL_ON_WORKY":
                return <RegisteredNotVerifiedEmailOnWorkyContent />;
            case "REGISTERED_FULLY_VERIFIED_STILL_NEEDS_TO_SUBMIT_FORMS":
                return <RegisteredFullyVerifiedStillNeedsToSubmitFormsContent />;
            case "REGISTERED_JOBBER_SUBMITTED_ALL_NECESSARY_DOCS":
                return <RegisteredJobberSubmittedAllNecessaryDocsContent />;
            default:
                return <RegistrationError />;
        }
    }
    



    return(
        <div className="app-body">

            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />        

            <div className="nav-vertical">
                <button className="nav-logo">w</button>
                <button className="nav-logout"></button>
            </div>
            
            <LoginHeaderContent firstName={firstName} lastName={lastName} />
            <br/>

            {RenderLoginContent(jobberStatus)}

        </div>    
    )



};



export default BasicInfoPage;