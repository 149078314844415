import React, { useState, useEffect } from 'react';
import './jobberhomepage2.css';
import './jobberaccountsettings.css';
import { MobileNav } from '../jobberViews/JobberCommonComponents';
import axios, { AxiosResponse } from 'axios';
import {
	ServerResponse,
	RemoteExecutionServiceImpl,
	RemoteGETCallInputObject,
	UserDetails,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import { AddressDto } from './objects/JobberRegistrationFormConstants';
import emailjs from 'emailjs-com';
import Modal from '@mui/material/Modal';

const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();
const signOutURI = process.env.REACT_APP_IDP_SIGN_OUT_URL;

interface JobberData {
	userDetails: UserDetails;
	dateOfBirth: string;
	sex: string;
	address: AddressDto;
	workPermitType: string;
	hasSafetyBoots: boolean;
	hasForkliftLicense: boolean;
}

const JobberAccountSettings = () => {
	const handleGeneralEmailSubmit = async (e) => {
		e.preventDefault();

		const serviceID = 'service_3mlm2ls';
		const templateID = 'template_xl6xpjw';
		const userID = 'zKdgUxA7H3dMaCf-m';

		try {
			await emailjs.sendForm(serviceID, templateID, e.target, userID);
			alert('Email sent successfully!');
		} catch (error) {
			alert('An error occurred while sending the email.');
			console.error(error);
		}
	};
	const [modalOpen, setModalOpen] = React.useState(false);
	const handleOpen = () => setModalOpen(true);
	const handleClose = () => setModalOpen(false);
	const jobberEmail = localStorage.getItem('userEmail');

	const [jobberData, setJobberData] = useState<JobberData | null>(null);
	const [firstname, setfirstname] = useState('');
	const [jobberIcon, setJobberIcon] = useState('');

	const getJobberDetails = async () => {
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'v1/jobber/logged-in-email-user/',
			headers: {},
			queryParams: null,
		};

		const jobberDetailsResp: AxiosResponse<ServerResponse<JobberData, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<JobberData, Error>>(getCallInput);

		if (jobberDetailsResp && jobberDetailsResp.data && jobberDetailsResp.data.data) {
			console.log('jobber Details Resp : ' + JSON.stringify(jobberDetailsResp.data.data));
			setJobberData(jobberDetailsResp.data.data);
			setfirstname(jobberDetailsResp.data.data.userDetails.firstName);
		}
	};
	useEffect(() => {
		const fetchData = async () => {
		  await getJobberDetails();
		  await getJobberIcon();
		};
		fetchData();
	  }, []);

	const imageComponent = (url) => {
		if (url) {
			console.log('company url provided');
			return (<img className='account-settings-icon' src={url}/>);
		} else {
			console.log('url was not provided');
			return null;
		}
	};

	const getJobberIcon = async () => {
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'user/profile-pic',
			headers: {},
			queryParams: null,
		};

		const orgIconRes: AxiosResponse<ServerResponse<string, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

		if (orgIconRes && orgIconRes.data && orgIconRes.data.data) {
			console.log('step 2');
			console.log('API provided s3 url: {} ' + orgIconRes.data.data);
			setJobberIcon(orgIconRes.data.data);
		}
	};
	// getJobberIcon();

	const JobberProfile = () => {
		return (
			<div className='jobber-profile-container'>
				<div className='jobber-profile-profile-pic'>{imageComponent(jobberIcon)}</div>
				<div className='jobber-profile-name'>
					{jobberData.userDetails.firstName + ' ' + jobberData.userDetails.lastName}
				</div>
				<div className='jobber-profile-email'>{jobberData.userDetails.userEmail.value}</div>
				<div className='jobber-profile-phone'>
					{jobberData.userDetails.userPhoneNumber.value}
				</div>
			</div>
		);
	};

	const JobberDetails = () => {
		return (
			<div className='jobber-detail-container'>
				<div className='jobber-detail'>
					<div className='jobber-detail-title'>Date of Birth</div>
					<div className='jobber-detail-value'>{jobberData.dateOfBirth}</div>
				</div>
		
				<div className='jobber-detail'>
					<div className='jobber-detail-title'>Address</div>
					<div id='address-value' className='jobber-detail-value'>
						{jobberData.address.street +
							',  ' +
							jobberData.address.city +
							',' +
							'ON ' +
							jobberData.address.postalCode}
					</div>
				</div>
				<div className='jobber-detail'>
					<div className='jobber-detail-title'>Work Status</div>
					<div className='jobber-detail-value'>{jobberData.workPermitType}</div>
				</div>
				<div className='jobber-detail'>
					<div className='jobber-detail-title'>Safety Boots</div>
					<div className='jobber-detail-value'>{jobberData.hasSafetyBoots.toString()}</div>
				</div>
				<div className='jobber-detail' style={{ borderBottom: 'none' }}>
					<div className='jobber-detail-title'>Forklift License</div>
					<div className='jobber-detail-value'>{jobberData.hasForkliftLicense.toString()}</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />

			<div className='mobile-app'>
				<MobileNav />
				{jobberData && <JobberProfile />}
				{jobberData && <JobberDetails />}
				<button onClick={handleOpen} className='report-issue'>
					Having an Issue? Message us.
				</button>
				<Modal open={modalOpen} onClose={handleClose}>
					<form
						id='jobber-modal'
						className='modal-contact'
						onSubmit={handleGeneralEmailSubmit}>
						<div className='srstatus-date'>Having an Issue?</div>
						<div id='modal-title2' className='srstatus-time'>
							We guarantee to get back to you in under 1 hour!
						</div>
						<div id='modal-title3' className='modal-title'>
							Email: <span className='modal-info'>info@worky.pro</span>
						</div>
						<div id='modal-title-2' className='modal-title'>
							Phone: <span className='modal-info'>(647) 494-9090</span>
						</div>
						<input
							className='modal-email'
							name='user_email'
							defaultValue={
								jobberEmail === null || jobberEmail === undefined
									? 'info@worky.pro'
									: jobberEmail
							}
						/>
						<div className='modal-title'>Message:</div>
						<textarea name='user_message' className='modal-message'></textarea>
						<button className='modal-submit'>Send</button>
					</form>
				</Modal>
				<button onClick={() => {window.location.assign(signOutURI);}} className='jobber-profile-logout'>Log Out</button>
			</div>
		</>
	);
};

export { JobberAccountSettings };
