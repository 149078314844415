import React, { useState, useEffect } from 'react';

const GeoLocationJobberService = () => {
  const [location, setLocation] = useState({});

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div>
      {/* {location?.latitude && location?.longitude ? (
        <p>
          Your location is: {location.latitude}, {location.longitude}
        </p>
      ) : (
        <p>Location not available.</p>
      )} */}
    </div>
  );
}


const GeoLocationJobberServiceV2 = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });
    };

    const handleError = (error) => {
      setError(error.message);
    };

    // Request permission to access the user's location
    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  }, []);

  return (
    <div>
      {location && (
        <p>
          Latitude: {location.latitude}, Longitude: {location.longitude}
        </p>
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};


const LocationComponent = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setError(null);
        },
        (err) => {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              setError('User denied the request for Geolocation.');
              break;
            case err.POSITION_UNAVAILABLE:
              setError('Location information is unavailable.');
              break;
            case err.TIMEOUT:
              setError('The request to get user location timed out.');
              break;
            // case err.UNKNOWN_ERROR:
            //   setError('An unknown error occurred.');
            //   break;
            default:
              setError('An unknown error occurred.');
          }
        },
        {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  return (
    <div>
      <button onClick={handleGetLocation}>Get Location</button>
      {location.latitude && location.longitude && (
        <div>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>
        </div>
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};




export {GeoLocationJobberService, GeoLocationJobberServiceV2, LocationComponent}