import React, { useState, useEffect, useRef } from "react";
import {
    Workertype,
  } from "../jobberViews/objects/ShiftRequestConstants";
import { SendErrorEmailv2 } from "../shared-engineering-services/SharedAPICalls";
import { JobberDetailsForOrg } from "../orgViews/OrgUtils";
import {
    TopNav,
    PushToNewSr,
    PushtoSrHistory,
  } from "../orgViews/OrgCommonComponents";
import {
    RemoteExecutionServiceImpl,
    ServerResponse,
    getGlobalUserDetails,
    RemoteGETCallInputObject,
  } from "../remoteExecution/RemoteExecutionServiceImpl";
import { AxiosResponse } from "axios";
import { CreateShiftRequestAPIObj, ShiftReqResponse } from "../jobberViews/objects/ShiftRequestConstants";
import { populateShiftRequestContentV2 } from "../common/ShiftServiceUtils";
import { convertDatesToEpochSec } from "../common/TimeUtils";
import { EmailAddress } from "../common/CommonInterfaces";
const remoteCallObject = RemoteExecutionServiceImpl();
const backendApiBasePathLocal =
  process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;

const DefaultComponents = () => {
    const userInfo = getGlobalUserDetails();
    return (
      <div>
        {TopNav({
          firstName: userInfo.firstName,
          lastname: userInfo.lastName,
          companyname: localStorage.getItem("orgName"),
          iconUrl: localStorage.getItem("orgIcon"),
          orgEmail: localStorage.getItem("userEmail"),
        })}
      </div>
    );
  };



  const RetrievePreviousJobbersAPICall = async({setPrevJobbers}:{setPrevJobbers: (jobbers: JobberDetailsForOrg[]) => void}) =>{
    
      const getCallInput: RemoteGETCallInputObject = {
        apiUrl: backendApiBasePathLocal + 'org/previousJobbers',
        headers: {},
        queryParams: null,
      };
  
      const prevJobbers: AxiosResponse<ServerResponse<JobberDetailsForOrg[], Error>> =
        await remoteCallObject.remoteGETCallV2<ServerResponse<JobberDetailsForOrg[], Error>>(
          getCallInput
        );
  
      if (
        prevJobbers &&
        prevJobbers.data &&
        prevJobbers.data.data &&
        prevJobbers.data.data.length >= 1
      ) {
        console.log('step 3');
        
  
        setPrevJobbers(prevJobbers.data.data);
      }
    
  }


  const handleSRSubmit = async ({
    e,
    selectedRecurringDays,
    numJobbers,
    selectedDaysRefObj,
    v3StartTimeHourRef,
    v3StartTimeMinuteRef,
    v3EndTimeHourRef,
    v3EndTimeMinuteRef,
    handleModelCallback,
    handleModelCallbackError,
    selectedJobbers,
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    selectedRecurringDays: number[];
    numJobbers: number;
    selectedDaysRefObj: Date[];
    v3StartTimeHourRef: number;
    v3StartTimeMinuteRef: number;
    v3EndTimeHourRef: number;
    v3EndTimeMinuteRef: number;
    handleModelCallback: (boolean) => void;
    handleModelCallbackError: (boolean) => void;
    selectedJobbers: EmailAddress[]
  }) => {
    const orgData = JSON.parse(localStorage.getItem("orgData"));

    const apiBody: CreateShiftRequestAPIObj = {
      // firmData: apiFirmdata,
      isReOccurringShift:
        selectedRecurringDays && selectedRecurringDays.length > 0,
      shiftRequestContent: populateShiftRequestContentV2({
        numJobbers: numJobbers,
        shiftStartEndDetails: convertDatesToEpochSec({
          dates: selectedDaysRefObj,
          workyStartTime: {
            hour: v3StartTimeHourRef,
            minute: v3StartTimeMinuteRef,
          },
          workyEndTime: {
            hour: v3EndTimeHourRef,
            minute: v3EndTimeMinuteRef,
          },
        }),
        simpleLocation: orgData.addressDto.city,
        workertype: Workertype.WAREHOUSE_ASSOCIATE,
        requestOrgName: orgData.orgName,
        jobberHourlyRate:
          orgData?.orgBillingData?.agreedRatesPerAWorkerType?.WAREHOUSE_ASSOCIATE,
        selectedJobbersSubset: selectedJobbers,
      }),
      isTestData: true,
    };

    e.preventDefault();
    console.log("apiBody" + JSON.stringify(apiBody));
    try {
      const response: AxiosResponse<ServerResponse<ShiftReqResponse, Error>> =
        await remoteCallObject.remotePOSTCallV2<
          ServerResponse<ShiftReqResponse, Error>,
          CreateShiftRequestAPIObj
        >({
          apiUrl: backendApiBasePathLocal + "shiftrequest/create/",
          object: apiBody,
          headers: { "Content-Type": "application/json" },
        });
      console.log("POST request successful:", response);

      if (
        response &&
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 202)
      ) {
        console.log("200 or 201 or 202");
        handleModelCallback(true);
      }
      // handleModelCallback(true);
    } catch (e) {
      if (
        e.response &&
        (e.response.status === 400 || e.response.status === 500)
      ) {
        console.log("400 or 500");
        await SendErrorEmailv2();
        handleModelCallbackError(true);
      }
      console.error("POST request failed:", e);
    }
  };

  export {DefaultComponents, handleSRSubmit, RetrievePreviousJobbersAPICall}