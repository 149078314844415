import React, { useState, useEffect, FormEvent } from 'react';
import './jobberhomepage2.css';
import { MobileNav } from '../jobberViews/JobberCommonComponents';
import Modal from '@mui/material/Modal';
import emailjs from 'emailjs-com';
import { AxiosResponse } from 'axios';
import {
	ServerResponse,
	RemoteExecutionServiceImpl,
	RemoteGETCallInputObject,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import { DirectDepositRequest, JobberDTO } from './objects/JobberRegistrationFormConstants';
import { CancelShiftRequestAPICall} from '../shared-engineering-services/SharedAPICalls'

const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();

interface AssignedShift {
	shiftId: string;
	shiftStatus: string;
	orgName: string;
	jobberEmail: { value: string };
	jobberHourlyRate: number;
	requestingUser: { value: string };
	requesterFirstName: string;
	requesterLastName: string;
	orgPhoneNum : { value: string };
	orgAddress: {
		street: string;
		unitNumber: string;
		city: string;
		province: string;
		postalCode: string;
		country: string;
		entranceInstructions: string;
		majorIntersection: string;
	};
	shiftDetails: {
		workerType: string;
		simpleLocation: string;
		shiftRequestStartDayEpoch: number;
		startDateTimeEpochSeconds: number;
		endDateTimeEpochSeconds: number;
		startDateTime: string;
		endDateTime: string;
		startDay: null; // Consider modifying this based on actual data type
		workyWorkdayShift: string;
	};
}

interface DisplayAddress {
	street: string;
	unitNumber: string;
	city: string;
	province: string;
	postalCode: string;
	country: string;
	majorIntersection: string;
	entranceInstructions: string;
}

interface DisplayShift {
	shiftStartDate: string;
	shiftStartTime: number;
	shiftEndTime: number;
	shiftID: string;
	shiftHourlyPay: number;
	shiftPayTotal: number;
	shiftAddress: string;
	companyName: string;
	requesterFirstName: string;
	requesterLastName: string;
	orgPhoneNumber : { value: string };
}

const JobberHomePage = () => {
	const handleOpen = (
		shiftDate,
		shiftTime,
		shiftId,
		shiftHourlyPay,
		shiftPayTotal,
		shiftAddress,
		companyName, 
		requesterFirstName,
		requesterLastName,
		 requestingUserPhoneNum
	) => {
		let displayaddress: DisplayAddress = JSON.parse(shiftAddress);
		let addressString =
			displayaddress.street +
			', ' +
			(displayaddress.city.charAt(0).toUpperCase() +
				displayaddress.city.slice(1).toLowerCase()) +
			', ON ' +
			displayaddress.postalCode;


			console.log("sasa       " + requestingUserPhoneNum)
		setModalOpen(true);
		setModalShiftDate(shiftDate);
		setModalShiftTime(shiftTime);
		setModalShiftId(shiftId);
		setModalShiftHourlyPay(shiftHourlyPay);
		setModalShiftTotalPay(shiftPayTotal);
		setModalShiftAddress(addressString);
		setModalCompanyName(companyName);
		setShiftRequestingUserFirstName(requesterFirstName)
		setShiftRequestingUserLastName(requesterLastName)
		setShiftRequestingUserPhoneNum(requestingUserPhoneNum?.value)
		setModalEntranceInstructions(displayaddress.entranceInstructions);
	};
	const handleClose = () => {
		setModalOpen(false);
	};
	const handleCancelClose = () => {
		setCancelModalOpen(false);
	};
	const handleCancelOpen = () => {
		setCancelModalOpen(true);
	};

	const [jobberDTO, setJobberDTO] = useState<JobberDTO | null>(null);
	const [jobberStatus, setJobberStatus] = useState('');
	const [assignedShifts, setAssignedShifts] = useState<AssignedShift[] | null>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalShiftDate, setModalShiftDate] = useState('');
	const [modalShiftTime, setModalShiftTime] = useState('');
	const [modalCancelOpen, setCancelModalOpen] = useState(false);
	const [isReasonProvided, setIsReasonProvided] = useState<boolean>(false);
	const [modalCancelReason, setModalCancelReason] = useState<string>('');

	const [modalShiftId, setModalShiftId] = useState('');
	const [modalShiftHourlyPay, setModalShiftHourlyPay] = useState('');
	const [modalShiftTotalPay, setModalShiftTotalPay] = useState('');
	const [modalShiftRequestingUserFirstName, setShiftRequestingUserFirstName] = useState('');
	const [modalShiftRequestingUserLastName, setShiftRequestingUserLastName] = useState('');
	const [modalShiftRequestingUserPhoneNum, setShiftRequestingUserPhoneNum] = useState('');
	const [modalShiftAddress, setModalShiftAddress] = useState('');
	const [modalCompanyName, setModalCompanyName] = useState('');
	const [modalEntranceInstructions, setModalEntranceInstructions] = useState('');
	const [hasShiftToday, setHasShiftToday] = useState(false);
	const jobberEmail = localStorage.getItem('userEmail');
	const todayShiftBlocks: DisplayShift[] = [];
	const upcomingShiftBlocks: DisplayShift[] = [];

	const getAssignedShifts = async () => {
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'v1/jobber/assignedshifts',
			headers: {},
			queryParams: null,
		};

		const assignedShiftsResp: AxiosResponse<ServerResponse<AssignedShift[], Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<AssignedShift[], Error>>(
				getCallInput
			);

		if (
			assignedShiftsResp &&
			assignedShiftsResp.data &&
			assignedShiftsResp.data.data &&
			assignedShiftsResp.data.data.length > 0
		) {
			//  TODO : GET THIS DATA WORKING
			console.log('assigned Shifts Resp : ' + JSON.stringify(assignedShiftsResp.data.data));
			setAssignedShifts(assignedShiftsResp.data.data);
		} else {
			console.log('No Shifts Assigned');
		}
	};

	const ShiftBlock = (displayShiftArr: DisplayShift[]) => {

		console.log("sasa  asassa displayShiftArr "  + JSON.stringify(displayShiftArr))
		function correctDate(inputDate: string): string {
			const adjustedDate = new Date(
				new Date(inputDate).getTime() + new Date(inputDate).getTimezoneOffset() * 60 * 1000
			);
			return adjustedDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
		}

		function correctEpoch(epoch: number): string {
			const date = new Date(epoch * 1000);
			let hours = date.getHours();
			const minutes = date.getMinutes().toString().padStart(2, '0');
			const amPM = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12 || 12;
			return `${hours}:${minutes} ${amPM}`;
		}

		const shiftBlockArr: JSX.Element[] = [];

		for (let i = 0; i < displayShiftArr.length; i++) {
			let shiftDate = correctDate(displayShiftArr[i].shiftStartDate.slice(0, 10));
			let shiftTime =
				correctEpoch(displayShiftArr[i].shiftStartTime) +
				' - ' +
				correctEpoch(displayShiftArr[i].shiftEndTime);
			let shiftId = displayShiftArr[i].shiftID;
			let shiftHourlyPay = displayShiftArr[i].shiftHourlyPay;
			let shiftPayTotal = displayShiftArr[i].shiftPayTotal;
			let shiftAddress = displayShiftArr[i].shiftAddress;
			let companyName = displayShiftArr[i].companyName;
			let requestingUserFirstName =displayShiftArr[i].requesterFirstName;
			let requestingUserlastName =displayShiftArr[i].requesterLastName;
			let requestingUserPhoneNum =displayShiftArr[i].orgPhoneNumber;
			console.log("requestingUserPhoneNum   " + requestingUserPhoneNum)

			shiftBlockArr.push(
				<div
					className='jobber-shift-block'
					onClick={() =>
						handleOpen(
							shiftDate,
							shiftTime,
							shiftId,
							shiftHourlyPay,
							shiftPayTotal,
							shiftAddress,
							companyName,
							requestingUserFirstName,
							requestingUserlastName,
							requestingUserPhoneNum
						)
					}>
					<div className='jobber-shift-block-date'>{shiftDate}</div>
					<div className='jobber-shift-block-time'>{shiftTime}</div>
					{/* <div className='jobber-shift-block-total-holder'>
						<div className='jobber-shift-block-total'>${shiftPayTotal.toFixed(2)}</div>
					</div> */}
				</div>
			);
		}
		return shiftBlockArr;
	};

	const ShiftsToday = (sampleShifts: AssignedShift[]) => {
		let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
			.toISOString()
			.substring(0, 10);
		console.log('inside shifts today');

		for (let i = 0; i < sampleShifts.length; i++) {
			if (sampleShifts[i].shiftDetails.startDateTime.substring(0, 10) === today) {
				// build the array full of DisplayShift elements
				let todayShift: DisplayShift = {
					shiftStartDate: sampleShifts[i].shiftDetails.startDateTime,
					shiftStartTime: sampleShifts[i].shiftDetails.startDateTimeEpochSeconds,
					shiftEndTime: sampleShifts[i].shiftDetails.endDateTimeEpochSeconds,
					shiftID: sampleShifts[i].shiftId,
					shiftHourlyPay: sampleShifts[i].jobberHourlyRate,
					shiftPayTotal: 0,
					shiftAddress: JSON.stringify(sampleShifts[i].orgAddress),
					companyName: sampleShifts[i].orgName,
					requesterFirstName: sampleShifts[i].requesterFirstName,
					requesterLastName: sampleShifts[i].requesterLastName,
					orgPhoneNumber: sampleShifts[i].orgPhoneNum
				};
				todayShiftBlocks.push(todayShift);
			}
		}
		return todayShiftBlocks && todayShiftBlocks.length > 0 ? (
			<>
				<div
					style={{ marginTop: '0px', paddingTop: '100px' }}
					className='mobile-section-title'>
					Today's Shift (s)
				</div>
				<div className='shifts-container'>{ShiftBlock(todayShiftBlocks)}</div>
			</>
		) : null;
	};

	const ShiftsUpcoming = (sampleShifts: AssignedShift[]) => {
		let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
			.toISOString()
			.substring(0, 10);
		console.log('inside shifts upcoming  sampleShifts ' + JSON.stringify(sampleShifts));

		for (let i = 0; i < sampleShifts.length; i++) {
			if (sampleShifts[i].shiftDetails.startDateTime.substring(0, 10) !== today) {
				// build the array full of DisplayShift elements

				console.log("sampleShifts[i].orgPhoneNumber " + sampleShifts[i].orgPhoneNum)

				let upcomingShift: DisplayShift = {
					shiftStartDate: sampleShifts[i].shiftDetails.startDateTime,
					shiftStartTime: sampleShifts[i].shiftDetails.startDateTimeEpochSeconds,
					shiftEndTime: sampleShifts[i].shiftDetails.endDateTimeEpochSeconds,
					shiftID: sampleShifts[i].shiftId,
					shiftHourlyPay: sampleShifts[i].jobberHourlyRate,
					shiftPayTotal: 0,
					shiftAddress: JSON.stringify(sampleShifts[i].orgAddress),
					companyName: sampleShifts[i].orgName,
					requesterFirstName: sampleShifts[i].requesterFirstName,
					requesterLastName: sampleShifts[i].requesterLastName,
					orgPhoneNumber: sampleShifts[i].orgPhoneNum
				};
				upcomingShiftBlocks.push(upcomingShift);
			}
		}
		

		return upcomingShiftBlocks && upcomingShiftBlocks.length > 0 ? (
			<>
				{hasShiftToday ? (
					<div style={{ paddingTop: '50px' }} className='mobile-section-title'>
						Upcoming Shifts
					</div>
				) : (
					<div className='mobile-section-title'>Upcoming Shifts</div>
				)}
				<div className='shifts-container'>{ShiftBlock(upcomingShiftBlocks)}</div>
			</>
		) : null;
	};

	const getJobberDTO = async (): Promise<AxiosResponse<ServerResponse<JobberDTO, Error>>> => {
		console.log('clicked get Jobber button');
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'v1/jobber/get',
			headers: {},
			queryParams: {},
		};

		const jobberDTOResp: AxiosResponse<ServerResponse<JobberDTO, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<JobberDTO, Error>>(getCallInput);

		if (
			jobberDTOResp &&
			jobberDTOResp.data &&
			jobberDTOResp.data.data &&
			jobberDTOResp.data.data.jobberStatus
		) {
			// console.log("jobberDTOResp: " + JSON.stringify(jobberDTOResp.data.data))
			console.log('JOBBER STATUS: ' + jobberDTOResp.data.data.jobberStatus);
			setJobberDTO(jobberDTOResp.data.data);
			setJobberStatus(jobberDTOResp.data.data.jobberStatus);
		} else {
			console.log('there was an error');
		}
		return jobberDTOResp;
	};

	const openLinkInNewTab = (link) => {
		window.open(link);
	};

	const getWorkyWorksDoc = async () => {
		console.log('clicked JobberContract button');
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'v1/jobber/document/jobberonboardingdoc',
			headers: {},
			queryParams: null,
		};

		const WorkyWorks: AxiosResponse<ServerResponse<string, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

		if (WorkyWorks && WorkyWorks.data && WorkyWorks.data.data) {
			console.log('WorkyWorks : ' + JSON.stringify(WorkyWorks.data.data));
			openLinkInNewTab(WorkyWorks.data.data);
		} else {
			console.log('there was an error');
		}
	};

	useEffect(() => {
		getAssignedShifts();
		getJobberDTO();
		if (todayShiftBlocks.length > 0) {
			setHasShiftToday(true);
		}
	}, []);

	const HowWorkyWorks = () => {
		return (
			<div className='howworkyworks'>
				<button onClick={getWorkyWorksDoc} className='howworkyworks-button'>
					How Worky Works
				</button>
			</div>
		);
	};
	const [modalOpen2, setModalOpen2] = React.useState(false);
		const handleOpen2 = () => setModalOpen2(true);
		const handleClose2 = () => setModalOpen2(false);

	const JobberScreen = () => {
		const [modalOpen2, setModalOpen2] = React.useState(false);
		const handleOpen2 = () => setModalOpen2(true);
		const handleClose2 = () => setModalOpen2(false);
		
		const handleGeneralEmailSubmit = async (e) => {
			e.preventDefault();

			const serviceID = 'service_3mlm2ls';
			const templateID = 'template_xl6xpjw';
			const userID = 'zKdgUxA7H3dMaCf-m';

			try {
				await emailjs.sendForm(serviceID, templateID, e.target, userID);
				alert('Email sent successfully!');
			} catch (error) {
				alert('An error occurred while sending the email.');
				console.error(error);
			}
		};


		

		return (
			<>
				<MobileNav />
				<HowWorkyWorks />
				{!assignedShifts && (
					<>
						<Modal open={modalOpen2} onClose={handleClose2}>
							<form
								id='jobber-modal'
								className='modal-contact'
								onSubmit={handleGeneralEmailSubmit}>
								<div className='srstatus-date'>Having an Issue?</div>
								<div id='modal-title2' className='srstatus-time'>
									We guarantee to get back to you in under 1 hour!
								</div>
								<div id='modal-title3' className='modal-title'>
									Email: <span className='modal-info'>info@worky.pro</span>
								</div>
								<div id='modal-title-2' className='modal-title'>
									Phone: <span className='modal-info'>(647) 494-9090</span>
								</div>
								<input
									className='modal-email'
									name='user_email'
									defaultValue={
										jobberEmail === null || jobberEmail === undefined
											? 'info@worky.pro'
											: jobberEmail
									}
								/>
								<div className='modal-title'>Message:</div>
								<textarea name='user_message' className='modal-message'></textarea>
								<button className='modal-submit'>Send</button>
							</form>
						</Modal>

						<div className='empty-shifts-container'>
							<div className='empty-shifts'>
								<div className='empty-shifts-logo'>worky</div>
								<div className='empty-shifts-holder'>
									<div className='empty-shifts-title'>Welcome to Worky!</div>
									<div className='empty-shifts-text'>
										Looks like you don't have any shifts coming up. Contact us. Let's get you a
										shift today!
									</div>
									<button onClick={handleOpen2} className='empty-shifts-button'>
										Contact Us
									</button>
								</div>
							</div>
						</div>
					</>
				)}
				{assignedShifts && ShiftsToday(assignedShifts)}
				{assignedShifts && ShiftsUpcoming(assignedShifts)}
			</>
		);
	};
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		const isValidInput = inputValue.length <= 200;
		setModalCancelReason(isValidInput ? inputValue : modalCancelReason);
		setIsReasonProvided(isValidInput && inputValue.trim() !== '');
	  };

	const handleCancel = async (e) => {
		e.preventDefault();

		console.log("HIT CANCEL BUTTON MODAL SHIFT ID :  ---- "+ modalShiftId)
		await CancelShiftRequestAPICall({shiftId: modalShiftId})
	
	}

	
	return (
		<>
			<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />
			<Modal open={modalCancelOpen} onClose={handleCancelClose}>
			<div className='shiftblock-modal'>
				<div className='shiftblock-modal-title'>Are you sure you want to Cancel this Shift?</div>
				<input 
				type="text"
				className="cancelReason"
				value={modalCancelReason}
				onChange={handleInputChange}
				/>
				<div className='cancel-button-holder'>
				<button onClick={handleCancelClose} className='modal-action' id='modal-action-cancel'>
					No
				</button>
				<button 
					onClick={handleCancel} 
					className='modal-action' 
					id='modal-action-cancel'
					disabled={!isReasonProvided} // Disable button if reason is not provided
				>
					Yes
				</button>
				</div>
			</div>
			</Modal>
			<Modal open={modalOpen} onClose={handleClose}>
				<div className='shiftblock-modal'>
					<div className='shiftblock-date'>{modalShiftDate}</div>
					<div className='shiftblock-time'>{modalShiftTime}</div>
					<div className='shiftblock-modal-title'>Shift Details</div>
					<div className='shiftblock-modal-details'>
						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Company Name:</span>
							{modalCompanyName}
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Hourly Pay:</span>${modalShiftHourlyPay}/hr
						</div>

						{/* <div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Total Pay:</span>${modalShiftTotalPay}
						</div> */}

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Address:</span>
							{modalShiftAddress}
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Job Type:</span>Warehouse Associate
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Entrance Instructions:</span>
							{modalEntranceInstructions}
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Shift Manager Name:</span>
							{modalShiftRequestingUserFirstName + " " + modalShiftRequestingUserLastName}
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Shift Manager Phone Number:</span>
							{modalShiftRequestingUserPhoneNum}
						</div>
					</div>

					<div className='shiftblock-modal-title'>Modify</div>
					<div className='shiftblock-modal-actions'>
						<button className='modal-action' id='modal-action-report' onClick={handleOpen2}> 
							Report Problem
						</button>
						<button onClick={handleCancelOpen} className='modal-action' id='modal-action-cancel'>
							Cancel Shift
						</button>
						</div>

				</div>
			</Modal>


			<div className='mobile-app'>
				<JobberScreen />
			</div>
		</>
	);
};

export { JobberHomePage };
