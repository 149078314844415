import {CancelShiftRequestObj} from '../common/CommonInterfaces';
import emailjs from 'emailjs-com';

import {
	ServerResponse,
	RemoteExecutionServiceImpl,
	RemoteGETCallInputObject,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import { AxiosResponse } from 'axios';
import {Email, JobberOrgRatingClientResp, JobberProfileResp , JobberProfileReq, JobberResumeReq, JobberResume, GetJobberProfileAPIResp }  from '../orgViews/orgConstants';
import { EmailAddress } from '../common/CommonInterfaces';
import { ca } from 'date-fns/locale';

const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();

const CancelShiftRequestAPICall = async({shiftId}: {shiftId:string}) =>{

    if(!shiftId) return;
    
    const apiBody: CancelShiftRequestObj = {
		shiftId: shiftId,
	};


    try {
        const response: AxiosResponse<ServerResponse<string, Error>> =
            await remoteCallObject.remotePOSTCallV2<ServerResponse<string, Error>,CancelShiftRequestObj>({

                apiUrl: backendApiBasePathLocal + 'shiftassignment/cancel',
                object: apiBody,
                headers: { 'Content-Type': 'application/json' }, 
            });
        console.log('!!!!!POST request successful:', response);

        if (
            response &&
            (response.status === 200 || response.status === 201 || response.status === 202)
        ) {
            console.log('200 or 201 or 202');
            window.location.reload();
        }
    } catch (e) {
        if (e.response && (e.response.status === 400 || e.response.status === 500)) {
            console.log('400 or 500');
        }
        console.error('POST request failed:', e);
    }

}


const SendErrorEmailv2 = async () => {
    // e.preventDefault();

    const serviceID = 'service_3mlm2ls';
    const templateID = 'template_xl6xpjw';
    const userID = 'zKdgUxA7H3dMaCf-m';

    const fullname =
        localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
    const orgemail = localStorage.getItem('userEmail');
    const emailReason = '!URGENT! ERROR SENDING SHIFT REQUEST';
    const emailMessage =
        fullname +
        ' from  ' +
        localStorage.getItem('orgName') +
        ' tried sending a shift request but failed. REQUIRES IMMEDIATE ATTENTION. \n\n' +
        'First Name : ' +
        localStorage.getItem('firstName') +
        '\n' +
        'Last Name : ' +
        localStorage.getItem('lastName') +
        '\n' +
        'Org Name : ' +
        localStorage.getItem('orgName') +
        '\n' +
        'Phone Number : ' +
        localStorage.getItem('userPhoneNumber') +
        '\n' +
        'Email : ' +
        orgemail +
        '\n';

    try {
        // await emailjs.sendForm(serviceID, templateID, "hello", userID);
        await emailjs.send(
            serviceID,
            templateID,
            {
                user_fullname: fullname,
                user_email: orgemail,
                user_reason: emailReason,
                user_message: emailMessage,
            },
            userID
        );

        console.log('Email Sent Successfully!');
    } catch (error) {
        console.log('An error occurred while sending the email.');
        console.error(error);
    }
};



const GetJobberResume = async (jobberEmail: EmailAddress) => {
    const jobberReq2: JobberResumeReq ={
        value:jobberEmail.value
    }

    try{
        const response: AxiosResponse<ServerResponse<JobberResume, Error>> =
        await remoteCallObject.remotePOSTCallV2<ServerResponse<JobberResume, Error>,JobberResumeReq>({
            apiUrl: backendApiBasePathLocal + 'v2/jobber/download/resume/',
            object: jobberReq2,
            headers: { 'Content-Type': 'application/json' },
        });
        console.log('POST request successful:', response);
        if (response && (response.status === 200 || response.status === 201 || response.status === 202)) {
            window.open(response.data.data.toString());
        }
    }catch(e){
        console.error('The worker resume could not be retrieved at this time, please try again later.', e);
        alert('The worker resume could not be retrieved at this time, please try again later.');
    }
    
};



const GetJobberProfile = async (jobberEmail: string): Promise<GetJobberProfileAPIResp> => {
    try {
        if (typeof jobberEmail !== 'string') {
            throw new Error('Invalid jobberEmail type');
        }

        const jobberReq: JobberProfileReq = {
            jobberEmail: { value: jobberEmail },
        };

        const response: AxiosResponse<ServerResponse<JobberProfileResp, Error>> = await remoteCallObject.remotePOSTCallV2<
            ServerResponse<JobberProfileResp, Error>,
            JobberProfileReq
        >({
            apiUrl: backendApiBasePathLocal + 'v2/jobber/profile/details/',
            object: jobberReq,
            headers: { 'Content-Type': 'application/json' },
        });

        console.log('POST request successful:', response);

        if (response && (response.status === 200 || response.status === 201 || response.status === 202)) {
            console.log('here');
            if (response && response.data && response.data.data && response.data.data.jobberQuickSummary) {
                // setProfileText(response.data.data.jobberQuickSummary);
                // setProfilePic(response.data.data.jobberProfilePicS3Url);
                return {
                    jobberProfilePicS3Url: response.data.data.jobberProfilePicS3Url,
                    jobberQuickSummary: response.data.data.jobberQuickSummary,
                    jobberFullDetails: response.data.data.jobberFullDetails,
                    workyJobberRating: response.data.data.workyJobberRating,
                    userDetails: response.data.data.userDetails,
                };
            }
        }
    } catch (e) {
        console.error('Error fetching jobber profile:', e);
        // Replace the undefined function call with the correct one        
    }
    return {
            jobberProfilePicS3Url: '',
            jobberQuickSummary: '',
            jobberFullDetails: '',
            workyJobberRating: '',
            userDetails: {
                    userId: '',
                    firstName: '',
                    lastName: '',
                    userEmail: {value: ''},
                    userPhoneNumber: {value: ''},
            },
        };
}

const GetJobberRatingV3 = async (jobberEmail:string): Promise<JobberOrgRatingClientResp> => {
    const getCallInput: RemoteGETCallInputObject = {
      apiUrl: backendApiBasePathLocal + 'jobber/performance/rating/org/single-jobber',
      headers: {},
      queryParams: {
        jobberEmail: jobberEmail
      }
    };

    try{
        const jobberRating: AxiosResponse<ServerResponse<JobberOrgRatingClientResp, Error>> =
        await remoteCallObject.remoteGETCallV2<ServerResponse<JobberOrgRatingClientResp, Error>>(getCallInput);
  
        if (jobberRating && jobberRating.data && jobberRating.data.data) { 
            return (jobberRating.data.data);
        }
        
    }catch(e){

    }
    
    return {
        jobberDetails: {
            userId: '',
            firstName: '',
            lastName: '',
            userEmail: {value: ''},
            userPhoneNumber: {value: ''},
        },
        orgName: '',   
        jobberOrgRatingDetailMap: new Map(),     
        jobberProfileImageS3Url: '',
    }
  };


export { CancelShiftRequestAPICall, SendErrorEmailv2, GetJobberResume, GetJobberProfile, GetJobberRatingV3 };

