import { AxiosResponse } from "axios";
import React, { useState, useEffect, useRef } from "react";
import { InlineWidget } from "react-calendly";
import {
  RemoteExecutionServiceImpl,
  ServerResponse,
} from "../remoteExecution/RemoteExecutionServiceImpl";

const remoteCallObject = RemoteExecutionServiceImpl();
const backendApiBasePathLocal =
  process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;

interface CalendlyMetaData {
  assignedTo: string;
  eventTypeUuid: string;
  eventTypeName: string;
  eventStartTime: string;
  eventEndTime: string;
  inviteeUuid: string;
  inviteeFullName: string;
  inviteeEmail: string;
  answer1: string;
}

interface CustomerCalendarRequest {
  redirectMetaData: string;
}

const CalendlyComponent = () => {
  return (
    <div className="calendly-app">
      <InlineWidget url="https://calendly.com/worky-pro" />
    </div>
  );
};

const isRedirectFromCalendly = (url: string): boolean => {
  // "https://worky.pro/?assigned_to=Worky%20Engineering%20Team&event_type_uuid=d3da4d7c-691c-4730-b5aa-543f3785b91f&event_type_name=Worky%20Industry%20Trends%20Call&event_start_time=2024-03-14T11%3A00%3A00-04%3A00&event_end_time=2024-03-14T11%3A30%3A00-04%3A00&invitee_uuid=6acfc190-55a0-462e-9030-64848faecdcf&invitee_full_name=Mike&invitee_email=mayyarsecond%40gmail.com&answer_1=Testing%20description"

  // Array of query parameter names
  const queryParamNames: string[] = [
    "assigned_to",
    "event_type_uuid",
    "event_type_name",
    "event_start_time",
    "event_end_time",
    "invitee_uuid",
    "invitee_full_name",
    "invitee_email",
    "answer_1",
  ];

  // Parse the URL to get the query parameters
  const queryString = url.split("?")[1];

  // If query string is present and not empty
  if (queryString && queryString.trim().length > 0) {
    // Split the query parameters
    const queryParams = queryString.split("&");

    // Check if any query parameter matches any of the given query parameter names
    for (const param of queryParams) {
      const [name, _] = param.split("=");
      if (queryParamNames.includes(name)) {
        return true;
      }
    }
  }

  // If no matching query parameter is found
  return false;
};

const persistSalesMetaDataCalendlyRedirect = async () => {
  const urlValues: string = window.location.href;

  try {
    if (!isRedirectFromCalendly(urlValues)) {
      return;
    }

    const response: AxiosResponse<ServerResponse<CalendlyMetaData, Error>> =
      await remoteCallObject.remotePOSTCallV2<
        ServerResponse<CalendlyMetaData, Error>,
        CustomerCalendarRequest
      >({
        apiUrl: backendApiBasePathLocal + "sales/splash-page-calendly/",
        object: { redirectMetaData: window.location.href },
        headers: { "Content-Type": "application/json" },
      });
    // console.log('POST request successful:', response);

    // if (
    //     response &&
    //     (response.status === 200 || response.status === 201 || response.status === 202)
    // ) {
    //     console.log('200 or 201 or 202');

    // }
  } catch (e) {
    if (
      e.response &&
      (e.response.status === 400 || e.response.status === 500)
    ) {
      console.log("400 or 500");
    }
    console.error("POST request failed:", e);
  }
};

export { CalendlyComponent, persistSalesMetaDataCalendlyRedirect };
