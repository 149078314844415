import React, { useState, useEffect, useRef } from 'react';
import { SplashNavbarV2, SplashFooter } from './DesktopSplashV2';
import './careerspage.css'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import Modal from '@mui/material/Modal';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import { MobileWorkyFooter } from './MobileSplashV2';
import {MobileSplashNav} from './MobileSplashV3';
import Popper from '@mui/material/Popper';
import RadarIcon from '@mui/icons-material/Radar';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import EastIcon from '@mui/icons-material/East';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BrushIcon from '@mui/icons-material/Brush';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
const bookDemoLink = "https://calendly.com/worky-pro/30min";
const workyServerIDPSIGNUPURL = process.env.REACT_APP_IDP_SIGN_UP_URL;
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;





const SplashNavbarV3Mobile = () => {
    const [isPassed,setIsPassed] = useState(false);
    const [openTray, setOpenTray] = useState(false);

    const handleTrayToggle = () => {
        setOpenTray(!openTray)
    }

    const handleHomeClick =()=>{
        window.location.assign('/')
    }
    const handleEmployersClick = () => {
        window.location.assign('/?scroll=1630');
    };
    const handleEmployeesClick =()=>{
        window.location.assign('/?scroll=1630')
    }
    const handleContactClick =()=>{
        window.location.assign('/?scroll=5100')
    }

    return(
        <>
        <div className='mobile-splash-nav'>
            <div onClick={() => window.scrollTo({ top: 1, behavior: "smooth" })} className='mobile-splash-logo'>worky</div>
            <div className='mobile-splash-right'>
                {isPassed? <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} id='mobile-splash-trynow-visible' className='mobile-splash-trynow'>Try Now</button>:<button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='mobile-splash-trynow'>Try Now</button>}
                <button onClick={handleTrayToggle} className='mobile-splash-tray'><MenuIcon style={{fontSize:"32px", color:"#7d7d7d"}}/></button>
            </div>
        </div>
        {openTray?(
            <div className='mobile-options-container'>
                <button onClick={handleEmployersClick} className='mobile-option'>Employers</button>
                <button onClick={handleEmployeesClick} className='mobile-option'>Workers</button>
                <button onClick={()=>window.location.assign('/careers')} className='mobile-option'>Careers</button>
                <button onClick={handleContactClick} className='mobile-option'>Contact</button>
                <button onClick={() => window.location.assign(workyServerIDPSIGNINURL)} className='mobile-option'>Sign In</button>
                <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} id="mobile-option-trynow" className='mobile-option'>Try Now</button>
            </div>
        ):null}
        </>
    )
}


const AtWorky = () =>{
    const isMobile = useIsMobile();

    return(
        <div className={isMobile?'at-worky-container-mobile':'at-worky-container'}>
            <div className={isMobile?'at-worky-left-mobile':'at-worky-left'}>
                <div className='at-worky-title-holder'>
                    <div className='at-worky-title'>What makes Worky Great?</div>
                    <div className='at-worky-subtitle'>Discover what makes Worky stand out! With comprehensive benefits, a culture of growth and flexibility, and a commitment to your success and well-being, we offer more than just a job.</div>
                </div>
                <div className={isMobile? 'at-worky-img-mobile' :'at-worky-img'}><img src='/colaborate.jpg'/></div>
            </div>
            

            <div className={isMobile? 'at-worky-info-holder-mobile':'at-worky-info-holder'}>
               

                <div className={isMobile? 'at-worky-info-mobile':'at-worky-info'}>
                    <div className='atworky-top'>
                        <div className='atworky-icon'><FitnessCenterIcon/></div>
                    </div>
                    <div className='atworky-title'>Health & Wellness</div>
                    <div className='atworky-info'>At Worky, we prioritize your well-being. That's why we offer a fully covered gym membership program, supporting your journey to a healthier lifestyle.</div>
                </div>

                <div className={isMobile? 'at-worky-info-mobile':'at-worky-info'}>
                    <div className='atworky-top'>
                        <div className='atworky-icon'><HomeWorkIcon/></div>
                    </div>
                    <div className='atworky-title'>Flexible Working Style</div>
                    <div className='atworky-info'>We embrace a flexible working style. We understand that work-life balance is essential for productivity and well-being. You control your schedule!</div>
                </div>

                <div className={isMobile? 'at-worky-info-mobile':'at-worky-info'}>
                    <div className='atworky-top'>
                        <div className='atworky-icon'><ReplyAllIcon style={{transform:"rotate(90deg)"}}/></div>
                    </div>
                    <div className='atworky-title'>Growth Mindset</div>
                    <div className='atworky-info'>We cultivate a growth mindset culture. Embracing challenges, persisting in the face of setbacks, and continually seeking opportunities is the Worky way!</div>
                </div>

                <div className={isMobile? 'at-worky-info-mobile':'at-worky-info'}>
                    <div className='atworky-top'>
                        <div className='atworky-icon'><AttachMoneyIcon/></div>
                    </div>
                    <div className='atworky-title'>Competitive Salary</div>
                    <div className='atworky-info'>We believe in fairly rewarding our employees for their hard work and dedication. You can trust that your compensation reflects your value and contributions.</div>
                </div>
             
            </div>
        </div>
    )
}

const CurrentOpenings = () =>{
    const isMobile = useIsMobile();

    const Opening = (chip1,chip2,chip3,jobTitle,jobDescription) => {
        return(
            <div className={isMobile? 'opening-mobile':'opening'}>
                <div className={isMobile? 'opening-top-mobile':'opening-top'}>
                    <div className={isMobile? 'opening-topleft-mobile':'opening-topleft'}>
                        <div className='opening-topleft-title'>{jobTitle}</div>
                        <div className='opening-topleft-chips'>
                            <div className='opening-chip'>{chip1}</div>
                            <div className='opening-chip'>{chip2}</div>
                            <div className='opening-chip'>{chip3}</div>
                        </div>
                    </div>
                    <div className={isMobile? 'opening-topright-mobile':'opening-topright'}>
                        <button className={isMobile? 'apply-now-mobile':'apply-now'}>Apply Now</button>
                    </div>
                </div>
                <div className={isMobile? 'opening-bottom-mobile':'opening-bottom'}>{jobDescription}</div>
            </div>
        )
    }

    return(
        <div className={isMobile? 'current-openings-holder-mobile':'current-openings-holder'}>
            <div className='current-openings-title'>Current Openings</div>
            <div className={isMobile? 'current-openings-subtitle-mobile':'current-openings-subtitle'}>Our team is our biggest strength, so if you're bright, bold and after more than just a job, let's get in touch. We look forward to meeting you!</div>
            <div className={isMobile? 'opening-holder-mobile':'opening-holder'}>
                {Opening("Engineering","Toronto, ON","Remote","Data Platform Engineer","Architect, develop, and optimize robust data infrastructure, employing advanced technologies to ensure scalability, reliability, and security in complex data environments. Responsibilities include designing data pipelines, implementing ETL processes, managing cloud-based storage solutions, and collaborating with cross-functional teams to drive data-driven decision-making.")}
                {Opening("Product","Toronto, ON","Remote","Senior Product Designer","Lead the conceptualization, design, and iteration of user-centric digital experiences. Responsibilities include conducting user research, creating wireframes and prototypes, collaborating with cross-functional teams, and championing design best practices to deliver innovative and intuitive products that meet user needs and business objectives.")}
                {Opening("Engineering","Toronto, ON","Remote","Server Side Software Engineer","Spearhead the design, development, and optimization of robust server-side applications and systems. Responsibilities include architecting scalable and efficient backend solutions, implementing complex algorithms, ensuring code quality, performance, and security, and collaborating closely with front-end and infrastructure teams to deliver high-quality software products.")}
                {Opening("Engineering","Toronto, ON","Remote","Senior Front End Software Engineer","Lead the design, development, and optimization of immersive and responsive user interfaces. Responsibilities include crafting maintainable code, implementing best practices in web development, and collaborating with designers and backend engineers to deliver seamless and intuitive user experiences across various platforms and devices.")}
                {Opening("Marketing","Toronto, ON","Remote","Social Media Manager","Strategize, create, and execute compelling social media campaigns across diverse platforms. Responsibilities include content creation, community engagement, analytics monitoring, and leveraging social trends to enhance brand presence and drive engagement.")}
                {Opening("Sales","Toronto, ON","Remote","Regional Sales Manager","Lead and mentor sales teams, develop comprehensive sales strategies, establish and nurture key client relationships, and drive revenue growth. Responsibilities encompass market analysis, sales forecasting, performance evaluation, and collaboration with cross-functional teams to optimize sales processes and achieve targets")}
                
                
            </div>
        </div>
    )
}

const CareersPage = () => {
    const isMobile = useIsMobile();
    const [tryNowModal, setTryNowModal] = useState(false);
    const signupClick = () => {window.location.assign('/?scroll=5950');}
    
    const SplashNavbarV3 = () => {
        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const scrollPosition = urlParams.get('scroll');
            if (scrollPosition) {
                const position = parseInt(scrollPosition, 10);
                if (!isNaN(position)) {window.scrollTo({ top: position, behavior: "smooth" });}
            }
        }, []);
    
    
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
        
        const handleClick = (event: React.MouseEvent<HTMLElement>) => { 
            setAnchorEl(anchorEl ? null : event.currentTarget);
            if (!anchorEl) {setAnchorEl2(null);}
        };
        
        const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl2(anchorEl2 ? null : event.currentTarget);
            if (!anchorEl2) {setAnchorEl(null);}
        };

        const handleBusinessClick =()=>{
            window.location.assign('/?scroll=1475')
        }
        
        const open = Boolean(anchorEl);
        const open2 = Boolean(anchorEl2);
        
        const id = open ? 'simple-popper' : undefined;
        const id2 = open2 ? 'simple-popper2' : undefined;
        
        return(
            <div className='splash-navbar-v3'>
                <div onClick={() => window.location.assign('/?scroll=0')} className='splash-navbar-logo'>worky</div>
                <div className='splash-navbar-menu'>
                    <button aria-describedby={id} type="button" onClick={handleClick} className='splash-navbar-option'>Products<KeyboardArrowDownIcon style={{fontSize:"18px", paddingTop:"2px"}}/></button>
                    <button onClick={handleBusinessClick} className='splash-navbar-option'>Businesses</button>
                    {/* <button onClick={handleProductClick2} className='splash-navbar-option'>Associates</button> */}
                    <button aria-describedby={id2} type="button" onClick={handleClick2}  className='splash-navbar-option'>Careers<KeyboardArrowDownIcon style={{fontSize:"18px", paddingTop:"2px"}}/></button>
                    <button onClick={()=>window.open(bookDemoLink)} id='demo-option' className='splash-navbar-option'>Book Demo</button>
                    
                    <Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex:"5"}}>
                        <div className='popper-drawer'>
                            <div className='popper-drawer-title'>PRODUCTS</div>
                            <div className='popper-drawer-line'></div>
    
                            <div className='popper-products-holder-inner'>
                                <button onClick={() => window.location.assign('/products?product=1')} className='popper-product'>
                                    <div className='popper-product-inner-img'>
                                        <img src='./virtual-check-in.jpg'></img>
                                    </div>
                                    <div className='popper-product-inner-info'><RadarIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Virtual Clock</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=2')} className='popper-product'>
                                    <div className='popper-product-inner-img'><img src='./worky-ipad-v2.jpg'></img></div>
                                    <div className='popper-product-inner-info'><AdsClickIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Portal</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=3')} className='popper-product'>
                                    <div className='popper-product-inner-img'>
                                        <img src='./worky-algo-mockup.jpg'/>
                                    </div>
                                    <div className='popper-product-inner-info'><AutoAwesomeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Smart Match</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=4')} className='popper-product'>
                                    <div className='popper-product-inner-img'><img src='./workypay.jpg'></img></div>
                                    <div className='popper-product-inner-info'><CurrencyExchangeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Pay</div>
                                </button>
                                <button onClick={() => window.location.assign('/products')} id='view-all-popper-product'>
                                    <div className='view-all-products-icon'><EastIcon/></div>
                                    View All
                                </button>
                            </div>
                        </div>
                    </Popper>
    
                    <Popper id={id2} open={open2} anchorEl={anchorEl2} style={{zIndex:"5"}}>
                        <div className='popper-drawer'>
                            <div className='popper-drawer-title'>CAREERS</div>
                            <div className='popper-drawer-line'></div>
                            <div className='popper-careers-holder-inner'>
                                <button onClick={() => window.location.assign('/careers?scroll=1400')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Data Platform Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=1650')} className='popper-job'>
                                    <div className='popper-job-icon'><BrushIcon/></div>
                                    <div className='popper-job-title'>Senior Product Designer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=1950')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Server Side Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2200')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Senior Front End Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                    <div className='popper-job-icon'><PhoneIphoneIcon/></div>
                                    <div className='popper-job-title'>Social Media Manager</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                    <div className='popper-job-icon'><TrendingUpIcon/></div>
                                    <div className='popper-job-title'>Regional Sales Manager</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers')} id='view-all-jobs' className='popper-job'>
                                    <div id='view-all-jobs-title' className='popper-job-title'>View All</div>
                                    <div id='view-all-jobs-icon' className='popper-job-icon'><EastIcon/></div>
                                </button>
                            </div>
                        </div>
                    </Popper>
    
                </div>
                <div className='splash-navbar-buttons'>
                    <button onClick={() => window.location.assign(workyServerIDPSIGNINURL)} className='splash-navbar-login'>Login</button>
                    {/* HEREHERE */}
                    {/* <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='splash-navbar-trynow'>Try Now</button> */}
                    <button onClick={()=>setTryNowModal(true)} className='splash-navbar-trynow'>Try Now</button>
                </div>
                
            </div>
        )
    }

    const handleOpeningsClick = () =>{
    if(isMobile){
        window.scrollTo({ top: 2400, behavior: "smooth" })
    }else{
        window.scrollTo({ top: 1200, behavior: "smooth" })}
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const scrollPosition = urlParams.get('scroll');
        // console.log(window.scrollY)
        if (scrollPosition) {
            const position = parseInt(scrollPosition, 10);
            if (!isNaN(position)) {window.scrollTo({ top: position, behavior: "smooth" });}
        }
    }, []);


    return(

        <div className='splash-body'>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />        
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='' />
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>
            <Modal open={tryNowModal} onClose={() => setTryNowModal(false)}>
					<div className='try-now-modal'>
                        <button onClick={() => setTryNowModal(false)} className='try-now-modal-close'><CloseIcon/></button>
                        <div className='try-now-modal-title'>Employer</div>
                        <div className='try-now-modal-button-holder'>
                            <button onClick={signupClick} className='try-now-modal-button'>
                                <div className='try-now-modal-button-title'>Employer</div>
                                <div className='try-now-modal-button-subtitle'>Get started today and save over 20% annually!</div>
                                <div className='try-now-modal-button-icon'><BusinessIcon style={{fontSize:"35px"}}/></div>
                            </button>
                            {/* <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='try-now-modal-button'>
                                <div className='try-now-modal-button-title'>Associate</div>
                                <div className='try-now-modal-button-subtitle'>Get started today and save 20% annually!</div>
                                <div className='try-now-modal-button-icon'><EngineeringIcon style={{fontSize:"35px"}}/></div>
                            </button> */}
                            
                        </div>
					</div>
            </Modal>

            {isMobile? <MobileSplashNav/>:<SplashNavbarV3/>}
            <div className='careers-header'>
                <div className="products-display-title">Careers</div>
                <div style={{margin:"auto", marginTop:"5px"}} className='products-display-subtitle'>Explore Opportunities and Join Our Team Today!</div>
                <button onClick={handleOpeningsClick} className='careers-openings'>Current Openings</button>
                {/* <div className='careers-launch'>
                    <div className='careers-img-holder'><img src='/sales-woman.jpg'/></div>
                    <div className='careers-img-holder'><img src='/developer-woman.jpg' style={{marginLeft:"-150px", height:"100%",width:"600px"}}/></div>
                    <div className='careers-img-holder'><img src='/engineer-guy.jpg'/></div>
                    <div className='careers-img-holder'><img src='/marketing-woman.jpg'/></div>
                </div> */}
            </div>
            <AtWorky/>
            <CurrentOpenings/>
            {isMobile? <MobileWorkyFooter/>:<SplashFooter/>}
        </div>

    )

}


export {CareersPage, SplashNavbarV3Mobile}