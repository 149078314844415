import React, { useState, useEffect, useRef } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Modal from "@mui/material/Modal";
import { AxiosResponse } from "axios";
import { WorkyTimePicker, WorkyTimePickerFuture } from "../common/TimePicker";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { SendErrorEmailv2 } from "../shared-engineering-services/SharedAPICalls";
import { convertDatesToEpochSec } from "../common/TimeUtils";
import { populateShiftRequestContentV2 } from "../common/ShiftServiceUtils";
import {
  Workertype,
  CreateShiftRequestAPIObj,
  ShiftReqResponse,
} from "../jobberViews/objects/ShiftRequestConstants";
import { DayPicker } from "react-day-picker";
import East from "@mui/icons-material/East";
import West from "@mui/icons-material/West";
import { PushToNewSr } from "../orgViews/OrgCommonComponents";
import { useIsMobile } from "../custom-hooks/useIsMobile";
import { JobberProfileBuilder } from "../orgViews/orgConstants";
import { JobberProfile } from "../orgViews/jobberProfileBuilder";
import { JobberDetailsForOrg } from "../orgViews/OrgUtils";
import {
  RemoteExecutionServiceImpl,
  ServerResponse,
  RemoteGETCallInputObject,
} from "../remoteExecution/RemoteExecutionServiceImpl";
import { DefaultComponents, handleSRSubmit } from "./SrIngressHelperService";
import { ShiftStartEndDetails } from "../common/CommonInterfaces";

const remoteCallObject = RemoteExecutionServiceImpl();
const backendApiBasePathLocal =
  process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;

const today = new Date();
const isDateBeforeToday = (date) => {
  return date < today;
};

const SRIngressStepManager = () => {
  const [numJobbers, setNumJobbers] = useState(1);
  const [prevJobbers, setPrevJobbers] = useState<JobberDetailsForOrg[] | null>(
    null
  );
  // succesful modal submission
  // succesful modal submission
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);

  // error modal submission
  const [modalErrOpen, setModalErrOpen] = useState(false);
  const handleErrModalClose = () => setModalErrOpen(false);

  // unusual shift length modal
  const [modalShiftOpen, setModalShiftOpen] = useState(false);
  const handleShiftModalClose = () => setModalShiftOpen(false);
  const handleShiftback = () => {
    let prevPage = currentPage - 1;
    prevPage = prevPage <= 0 ? 0 : prevPage;
    setCurrentPage(prevPage);
    setModalShiftOpen(false);
  };

  const selectedDaysRefObj = useRef<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState(today);
  const [confirmedSelection, setConfirmedSelection] = useState(false);
  const [selectedRecurringDays, setSelectedRecurringDays] =
    useState<number[]>(null);
  const [selectedJobbers, setSelectedJobbers] = useState([]);
  const [isReocurringShift, setIsReocurringShift] = useState(false);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const [hasPrevJobbers, setHasPrevJobbers] = useState(true);
  const v3StartTimeHourRef = useRef(null);
  const v3StartTimeMinuteRef = useRef(null);
  const v3EndTimeHourRef = useRef(null);
  const v3EndTimeMinuteRef = useRef(null);
  const handleV3StartTimeHourChange = (newStartTime: number) => {
    v3StartTimeHourRef.current = newStartTime;
  };

  const handleV3StartTimeMinuteChange = (newStartTime: number) => {
    v3StartTimeMinuteRef.current = newStartTime;
  };

  const handleV3EndTimeHourChange = (newEndTime: number) => {
    v3EndTimeHourRef.current = newEndTime;
  };

  const handleV3EndTimeMinuteChange = (newEndTime: number) => {
    v3EndTimeMinuteRef.current = newEndTime;
  };

  const isMobile = useIsMobile();

  const [currentPage, setCurrentPage] = useState(1);

  const handleMonthChange = (newMonth) => {
    setCurrentMonth(newMonth);
  };

  const makePrevJobbersApiCall = async () => {
    const getCallInput: RemoteGETCallInputObject = {
      apiUrl: backendApiBasePathLocal + "org/previousJobbers",
      headers: {},
      queryParams: null,
    };

    const prevJobbers: AxiosResponse<
      ServerResponse<JobberDetailsForOrg[], Error>
    > = await remoteCallObject.remoteGETCallV2<
      ServerResponse<JobberDetailsForOrg[], Error>
    >(getCallInput);

    if (
      prevJobbers &&
      prevJobbers.data &&
      prevJobbers.data.data &&
      prevJobbers.data.data.length >= 1
    ) {
      console.log("step 3");
      setHasPrevJobbers(false);

      setPrevJobbers(prevJobbers.data.data);
    }
  };

  const handleBackClick = () => {
    window.scrollTo({ top: 1, behavior: "smooth" });
    if (currentPage > 1) {
      let prevPage = currentPage - 1;
      prevPage = prevPage <= 0 ? 0 : prevPage;
      setCurrentPage(prevPage);
    }
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo({ top: 1, behavior: "smooth" });
  };

  const BackButton = () => {
    return (
      <div>
        {isMobile ? (
          <button
            className="card-content-d-back-mobile"
            onClick={handleBackClick}
          >
            <West />
          </button>
        ) : (
          <button className="card-content-d-back" onClick={handleBackClick}>
            Back
          </button>
        )}
      </div>
    );
  };

  const DayPickerPageNextButton = () => {
    useEffect(() => {}, [selectedDaysRefObj]);
    return (
      <div>
        {isMobile ? (
          <button
            className="card-content-d-next-mobile"
            onClick={handleNextClick}
            disabled={selectedDaysRefObj.current.length <= 0}
          >
            <East />
          </button>
        ) : (
          <button
            className="card-content-d-next"
            onClick={handleNextClick}
            disabled={selectedDaysRefObj.current.length <= 0}
          >
            Next
          </button>
        )}
      </div>
    );
  };

  const JobberSelectionPage = () => {
    const handlePlusClick = () => {
      setNumJobbers(numJobbers + 1);
    };
    const handleMinusClick = () => {
      if (numJobbers > 1) {
        setNumJobbers(numJobbers - 1);
      }
    };
    console.log(hasPrevJobbers);

    const PreviousJobberSelect = (
      previousJobberData: JobberDetailsForOrg[]
    ) => {
      const handleSelectAll = () => {
        const allJobberEmails = previousJobberData.map(
          (jobber) => jobber.userDetails.userEmail.value
        );

        if (selectedJobbers.length === allJobberEmails.length) {
          setSelectedJobbers([]);
        } else {
          setSelectedJobbers(allJobberEmails);
        }
      };

      const handleJobberSelect = (email) => {
        if (selectedJobbers.includes(email)) {
          setSelectedJobbers(selectedJobbers.filter((e) => e !== email));
        } else {
          setSelectedJobbers([...selectedJobbers, email]);
        }
      };

      const AvailablePrevJobbers = () => {
        return (
          <>
            {previousJobberData.map((jobber, index) => {
              const jobberProfile: JobberProfileBuilder = {
                jobberName:
                  jobber.userDetails.firstName +
                  " " +
                  jobber.userDetails.lastName,
                jobberEmail: jobber.userDetails.userEmail.value,
                jobberPhone: jobber.userDetails.userPhoneNumber.value,
                jobberPic: jobber.userProfilePicUrl,
              };

              return (
                <div style={{ paddingTop: "5px" }}>
                  <div className="each-prev-jobber-holder">
                    <div className="jobber-holder">
                      <JobberProfile
                        key={index}
                        hasCheck={true}
                        jobberProfile={jobberProfile}
                      />
                    </div>
                    <div
                      className="checkbox-holder"
                      onClick={() =>
                        handleJobberSelect(jobberProfile.jobberEmail)
                      }
                    >
                      <input
                        type="checkbox"
                        className="prev-jobber-checkbox"
                        value={index}
                        style={{ transform: "scale(1.21)" }}
                        checked={selectedJobbers.includes(
                          jobberProfile.jobberEmail
                        )}
                        onChange={() =>
                          handleJobberSelect(jobberProfile.jobberEmail)
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        );
      };

      return (
        <>
          <button onClick={handleSelectAll} className="select-all-prev-jobbers">
            {selectedJobbers.length === previousJobberData.length
              ? "Deselect All"
              : "Select All"}
          </button>
          <AvailablePrevJobbers />
        </>
      );
    };

    return (
      <div className={isMobile ? "srpage1-mobile" : "srpage1"}>
        <div className="srpage1-right">
          <div
            className={
              isMobile
                ? "srpage1-left-subtitle-mobile"
                : "srpage1-left-subtitle"
            }
          >
            Number Of Workers
          </div>
          <div
            className={
              isMobile ? "srpage1-numjobbers-mobile" : "srpage1-numjobbers"
            }
          >
            <button
              className="srpage1-numjobbers-minus"
              onClick={handleMinusClick}
            >
              -
            </button>
            <input
              type="number"
              className="srpage1-numjobbers-value"
              value={numJobbers}
              onChange={(e) => setNumJobbers(parseInt(e.target.value) || 0)}
              min="1"
            />
            <button
              className="srpage1-numjobbers-plus"
              onClick={handlePlusClick}
            >
              +
            </button>
          </div>
        </div>

        {prevJobbers ? (
          <div className={isMobile ? "srpage3-left-mobile" : "srpage3-left"}>
            <div
              className={
                isMobile
                  ? "srpage1-left-subtitle-mobile"
                  : "srpage1-left-subtitle"
              }
            >
              Previous Workers
            </div>
            <div className="srpage3-jobber-holder">
              {PreviousJobberSelect(prevJobbers)}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const handleWeekNumberClickv2 = (weekNumber: number, startOfWeek: Date[]) => {
    console.log(" startOfWeek " + startOfWeek);

    const handleWeekClick = (weekNumberInAYear: Date) => {
      const dayValuesWithInWeek: Date[] = [];
      for (let i = 0; i < 7; i++) {
        const day = new Date(weekNumberInAYear);

        day.setDate(weekNumberInAYear.getDate() + i);

        if (selectedRecurringDays.includes(i)) {
          dayValuesWithInWeek.push(day);
        }
      }
      return dayValuesWithInWeek;
    };

    if (startOfWeek.length > 0) {
      const startOfClickedWeek = new Date(startOfWeek[0]);
      const daysInNewWeek = handleWeekClick(startOfClickedWeek);

      console.log("daysInNewWeek " + daysInNewWeek);
      //   console.log("selectedWeeks" + selectedWeeks)

      let valuesfound = 0;

      for (let i = 0; i < daysInNewWeek.length; i++) {
        const potStartWeekDay = daysInNewWeek[i];
        // console.log(potStartWeekDay)

        if (
          selectedDays.some(
            (date) => date.getTime() === potStartWeekDay.getTime()
          )
        ) {
          valuesfound++;
          console.log(potStartWeekDay);
        }
      }
      console.log(valuesfound);
      // deselect
      if (valuesfound === daysInNewWeek.length) {
        const filteredDates: Date[] = [];
        for (let i = 0; i < selectedDays.length; i++) {
          if (
            !daysInNewWeek.some(
              (date) => date.getTime() === selectedDays[i].getTime()
            )
          ) {
            filteredDates.push(selectedDays[i]);
          }
        }
        // setSelectedWeeks(filteredDates);
        setSelectedDays(filteredDates);
        selectedDaysRefObj.current = [...filteredDates];
      }
      // add elements
      else {
        console.log("here in sssss " + JSON.stringify(daysInNewWeek));
        const resultingSelectedDays: Date[] = [
          ...selectedDays,
          ...daysInNewWeek,
        ];
        setSelectedDays([...resultingSelectedDays]);
        selectedDaysRefObj.current = [...resultingSelectedDays];
      }
    }
  };

  const DateSelectionPage = () => {
    console.log(selectedDays)
    const handleYesSelect = () => {
      setSelectedDays([]);
      setIsReocurringShift(true);
    };

    const handleNoSelect = () => {
      setSelectedDays([]);
      setIsReocurringShift(false);
      setConfirmedSelection(false);
      setSelectedRecurringDays(null);
    };

    const Recurring = () => {
      const RecurringDow = () => {
        const [tempSelectedDays, setTempSelectedDays] = useState([]);
        console.log("TEST 12345 : " + selectedRecurringDays);

        const toggleDaySelection = (day) => {
          const index = tempSelectedDays.indexOf(day);
          if (index === -1) {
            setTempSelectedDays([...tempSelectedDays, day]); // Add the day if it's not already selected
          } else {
            const newSelectedDays = [...tempSelectedDays];
            newSelectedDays.splice(index, 1); // Remove the day if it's already selected
            setTempSelectedDays(newSelectedDays);
          }
        };

        const confirmSelection = () => {
          setSelectedRecurringDays(tempSelectedDays);
          setConfirmedSelection(true);
          setSelectedDays([]);
          setIsReocurringShift(false); // Close the modal after confirmation
        };

        const isDaySelected = (day) => tempSelectedDays.includes(day);

        return (
          <Modal
            open={isReocurringShift}
            onClose={() => setIsReocurringShift(false)}
          >
            <div className="recurring-shift-modal">
              <div className="recurring-shift-modal-title">
                Which days of the week?
              </div>
              <div className="recurring-shift-modal-dow-holder">
                {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                  <button
                    key={day}
                    className={`recurring-dow ${
                      isDaySelected(day) ? "selected" : ""
                    }`}
                    onClick={() => toggleDaySelection(day)}
                  >
                    {["SU", "MO", "TU", "WE", "TH", "FR", "SA"][day]}
                  </button>
                ))}
              </div>
              <button className="recurring-confirm" onClick={confirmSelection}>
                Confirm
              </button>
            </div>
          </Modal>
        );
      };

      return (
        <div className="srpage2-recurring">
          <div className="srpage2-recurring-title">
            Is this a Reocurring shift?
          </div>
          <div className="srpage2-recurring-switch">
            {isReocurringShift || confirmedSelection ? (
              <button onClick={handleYesSelect} className="calendar-toggle">
                Yes
              </button>
            ) : (
              <button
                id="toggleClosed"
                onClick={handleYesSelect}
                className="calendar-toggle"
              >
                Yes
              </button>
            )}
            {isReocurringShift || confirmedSelection ? (
              <button
                id="toggleClosed"
                onClick={handleNoSelect}
                className="calendar-toggle"
              >
                No
              </button>
            ) : (
              <button onClick={handleNoSelect} className="calendar-toggle">
                No
              </button>
            )}

            <RecurringDow />
          </div>
        </div>
      );
    };

    return (
      <div>
        {selectedRecurringDays ? (
          <DayPicker
            showWeekNumber
            showOutsideDays={false}
            fixedWeeks
            min={1}
            selected={selectedDays}
            month={currentMonth}
            onMonthChange={handleMonthChange}
            onWeekNumberClick={handleWeekNumberClickv2}
            // modifiers={{
            //     disabled: { before: today },
            // }}
            modifiersStyles={{
              selected: { backgroundColor: "#2c68bd" },
              disabled: { backgroundColor: "#ffffff", borderColor: "#fff" },
            }}
            // disabled={isDateBeforeToday}
            styles={{
              day: {
                fontSize: "14px",
              },
            }}
          />
        ) : (
          <DayPicker
            mode="multiple"
            className="cal"
            min={1}
            selected={selectedDays}
            onSelect={(daysSelected: Date[]) => {
              setSelectedDays(daysSelected);
              selectedDaysRefObj.current = [...daysSelected];
            }}
            month={currentMonth}
            onMonthChange={handleMonthChange}
            modifiers={{
              disabled: { before: today },
            }}
            modifiersStyles={{
              selected: { backgroundColor: "#2c68bd" },
              disabled: { backgroundColor: "#ffffff", borderColor: "#fff" },
            }}
            disabled={isDateBeforeToday}
            styles={{
              day: {
                fontSize: "14px",
              },
            }}
          />
        )}

        <Recurring />
      </div>
    );
  };

  const NextButtonForNumOfJobbers = () => {
    return (
      <button
        className="card-content-d-next"
        onClick={handleNextClick}
        // disabled={numJobbers <= 0}
      >
        Next
      </button>
    );
  };

  const SetShiftStartAndEndTimePage = () => {
    return (
      <div className={isMobile ? "srpage2-right-mobile" : "srpage2-right"}>
        {isMobile ? null : (
          <div className="srpage2-right-title">Shift Time</div>
        )}
        <div className="srpage2-right-inner">
          <div className="srpage2-right-subtitle">Start Time</div>
          <WorkyTimePicker
            onFinalTimeChangeHour={handleV3StartTimeHourChange}
            onFinalTimeChangeMinute={handleV3StartTimeMinuteChange}
          />
          <div className="srpage2-right-subtitle">End Time</div>
          <WorkyTimePickerFuture
            onFinalTimeChangeHour={handleV3EndTimeHourChange}
            onFinalTimeChangeMinute={handleV3EndTimeMinuteChange}
          />
        </div>
      </div>
    );
  };

  const NextButtonForShiftTimesComponent = () => {
    return (
      <div>
        {isMobile ? (
          <button
            className="card-content-d-submit-mobile"
            onClick={(e) =>
              handleSRSubmit({
                e,
                selectedRecurringDays,
                selectedDaysRefObj: selectedDaysRefObj.current,
                numJobbers: numJobbers,
                v3StartTimeHourRef: v3StartTimeHourRef.current,
                v3StartTimeMinuteRef: v3StartTimeMinuteRef.current,
                v3EndTimeHourRef: v3EndTimeHourRef.current,
                v3EndTimeMinuteRef: v3EndTimeMinuteRef.current,
                handleModelCallback: setModalOpen,
                handleModelCallbackError:setModalErrOpen,
                selectedJobbers:[]
              })
            }
          >
            Submit <CheckIcon style={{ marginRight: "-10px" }} />
          </button>
        ) : (
          <button
            className="card-content-d-submit"
            onClick={(e) =>
                handleSRSubmit({
                    e,
                    selectedRecurringDays,
                    selectedDaysRefObj: selectedDaysRefObj.current,
                    numJobbers: numJobbers,
                    v3StartTimeHourRef: v3StartTimeHourRef.current,
                    v3StartTimeMinuteRef: v3StartTimeMinuteRef.current,
                    v3EndTimeHourRef: v3EndTimeHourRef.current,
                    v3EndTimeMinuteRef: v3EndTimeMinuteRef.current,
                    handleModelCallback: setModalOpen,
                    handleModelCallbackError:setModalErrOpen,
                    selectedJobbers:[]
                  })
                }
            
          >
            Submit
          </button>
        )}
      </div>
    );
  };

  const ModelComponenetsOnSubmission = () => {
    return (
      <div>
        <link rel="stylesheet" href="https://use.typekit.net/rjq7odq.css" />
        <Modal open={modalOpen} onClose={handleClose}>
          <div className="submit-success">
            <div className="submit-success-title">Success!</div>
            <div className="submit-success-icon">
              <CheckCircleRoundedIcon
                style={{ fontSize: "150px", height: "150px", color: "#6DBB3A" }}
              />
            </div>
            <div className="submit-success-subtitle">
              You've successfully submitted your shift!
            </div>
            <div className="submit-success-subtitle">
              Want to hire more? Click below
            </div>
            <button onClick={PushToNewSr} className="submit-success-button">
              Hire More
            </button>
          </div>
        </Modal>

        <Modal open={modalErrOpen} onClose={handleErrModalClose}>
          <div className="submit-success">
            <div className="submit-success-title">Error</div>
            <div className="submit-success-subtitle">There was an issue!</div>
            <div className="submit-success-subtitle">
              Want to start again? Click below
            </div>
            <button onClick={PushToNewSr} className="submit-success-button">
              Try Again
            </button>
          </div>
        </Modal>

        {/* <Modal open={modalShiftOpen} onClose={handleShiftModalClose}>
          <div className="submit-success">
            <div className="submit-success-title">Shift Length</div>
            <div className="submit-success-subtitle">
              Are you sure this is what you meant?
            </div>
            <div className="submit-success-subtitle">
              Shift length :{" "}
              {v3EndTimeHourRef.current - v3StartTimeHourRef.current} Hr
            </div>
            <div className="shiftback-buttons">
              <button
                onClick={handleShiftback}
                style={{ background: "#cccccc" }}
                className="submit-success-button"
              >
                Go Back
              </button>
              <button
                onClick={handleShiftModalClose}
                className="submit-success-button"
              >
                Looks Good
              </button>
            </div>
          </div>
        </Modal> */}
      </div>
    );
  };

  if (currentPage <= 1) {
    return (
      <div className="org-app">
        <DefaultComponents />
        {/* "Jobbers Selection Page" */}
        <JobberSelectionPage />
        <NextButtonForNumOfJobbers />
      </div>
    );
  } else if (currentPage === 2) {
    return (
      <div className="org-app">
        <DefaultComponents />
        <DateSelectionPage />
        <DayPickerPageNextButton />
        <BackButton />
      </div>
    );
  } else if (currentPage === 3) {
    return (
      <div className="org-app">
        <div>
          {selectedDaysRefObj.current.length > 0 && (
            <p>{JSON.stringify(selectedDaysRefObj.current)}</p>
          )}
        </div>
        <SetShiftStartAndEndTimePage />
        <NextButtonForShiftTimesComponent />
        <BackButton />
        <ModelComponenetsOnSubmission/>
      </div>
    );
  } else {
    return (
      <div className="org-app">
        <DefaultComponents />
        "error"
      </div>
    );
  }
};

export { SRIngressStepManager };
