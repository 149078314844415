import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { BillingInfo } from './PaymentTableInterfaces'
import { cleanShiftDate, cleanShiftTime} from './shiftsAssignedTable';

// interface declaration for props
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }
  




// page actions 
function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </Box>
    );
}
  






// helper function to create rows for table to render
function createData(startDate: string, startTime: string, endTime: string,hoursWorked:number, numJobbers: number, jobberRate: number,tax: number, total: number) {
    return { 
        startDate, 
        startTime, 
        endTime, 
        hoursWorked,
        numJobbers, 
        jobberRate, 
        tax, 
        total};
}


const ShiftsCompletedTable = (combinedBillingInfo:BillingInfo[]) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows,setRows]=useState([]);


    useEffect(() => {
      const processData = (billingCycleData: BillingInfo[]) => {
        if (!billingCycleData) {
          return;
        }
        const resultData = [];
        // ... rest of the processData function
        console.log("!!!!!STEP1: " + JSON.stringify(billingCycleData))
      billingCycleData.forEach((billingCycle) => {
        const { shiftPaymentList } = billingCycle;
        console.log("!!!!!STEP2: " + JSON.stringify(shiftPaymentList))

    
        if (shiftPaymentList) {
          Object.keys(shiftPaymentList).forEach((date) => {
            const datePayments = shiftPaymentList[date];
            console.log("!!!!!STEP3: " + JSON.stringify(datePayments))

            if (datePayments) {
              datePayments.forEach((shiftPayment) => {
                const {
                  workyBillPaymentBreakdown,
                  paymentDueDate,
                } = shiftPayment;
                console.log("!!!!!STEP4: " + JSON.stringify(shiftPayment))

                const startDate = cleanShiftDate(shiftPayment.actualStartDateTime);
                const startTime = cleanShiftTime(shiftPayment.actualStartDateTimeEpochSeconds);
                const endTime = cleanShiftTime(shiftPayment.actualEndDateTimeEpochSeconds);
                const hoursWorked = workyBillPaymentBreakdown.totalTimeWorked;
                const numJobbers = 1;
                const jobberRate = workyBillPaymentBreakdown.jobberHourlyRate;
                const total = workyBillPaymentBreakdown.totalCost;
                const tax = parseFloat((total * workyBillPaymentBreakdown.taxRate).toFixed(2));
                console.log("!!!!!STEP5: " + JSON.stringify(datePayments))

                const data = createData(
                  startDate,
                  startTime.toString(),
                  endTime.toString(),
                  hoursWorked,
                  numJobbers,
                  jobberRate,
                  tax,
                  total
                );
                resultData.push(data);
              });
            }
          });
        }
      });
        setRows(resultData);
      };
      processData(combinedBillingInfo);
    }, [combinedBillingInfo]); // Run the effect when combinedBillingInfo changes


    const processData = (billingCycleData: BillingInfo[]) => {
      if (!billingCycleData){return}
      const resultData = [];
      
    
      // setRows(resultData);
    };
    
    
    // Sample usage
    // processData(combinedBillingInfo); // Replace yourData with the actual variable containing the provided data
    






    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight:600 }} align="left">Start Date</TableCell>
              <TableCell style={{ fontWeight:600 }} align="left">Start Time</TableCell>
              <TableCell style={{ fontWeight:600 }} align="left">End Time</TableCell>
              <TableCell style={{ fontWeight:600 }} align="left"># Hours</TableCell>
              <TableCell style={{ fontWeight:600 }} align="right"># Workers</TableCell>
              <TableCell style={{ fontWeight:600 }} align="right">Worker Rate</TableCell>
              <TableCell style={{ fontWeight:600 }} align="right">Tax ($)</TableCell>
              <TableCell style={{ fontWeight:600 }} align="right">Total ($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.startDate}>
                <TableCell style={{ width: 160 }} component="th" scope="row" align="left">{row.startDate}</TableCell>
                <TableCell style={{ width: 160 }} align="left">{row.startTime}</TableCell>
                <TableCell style={{ width: 160 }} align="left">{row.endTime}</TableCell>
                <TableCell style={{ width: 160 }} align="left">{row.hoursWorked}</TableCell>
                <TableCell style={{ width: 160 }} align="right">{row.numJobbers}</TableCell>
                <TableCell style={{ width: 160 }} align="right">{"$"+row.jobberRate+"/hr"}</TableCell>
                <TableCell style={{ width: 160 }} align="right">{"$"+row.tax}</TableCell>
                <TableCell style={{ width: 160 }} align="right">{"$"+row.total}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={8}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
}










export {ShiftsCompletedTable}