interface ShiftRequestDto {
	firmData: FirmDataDto;
	shiftRequestContent: ShiftRequestUnitDtoV2[];
	isTestData: boolean;
	shiftRequestId?: string;
	shiftRequestStatus?: ShiftRequestStatus;
	requesterUsername?: string;
	submissionDateTimeEpochSeconds?: number;
	submissionDateTimeEst?: string; // Time should be in the following syntax --> YYYY-MM-DDTHH:MM:SSZ => 2023-08-26T09:00:00Z
	billableReceipt?: BillableReceipt;
	lastJobberCandidatePoolNotificationDateTimeEpoch?: number;
	lastJobberCandidatePoolNotificationDateTime?: string | null;
}

enum ShiftRequestStatus {
	CREATED = 'CREATED',
	QUEUED = 'QUEUED',
	PROCESSING = 'PROCESSING',
	PROCESSING_JOBBERS_CANDIDATE_POOL_NOTIFIED = 'PROCESSING_JOBBERS_CANDIDATE_POOL_NOTIFIED',
	SCHEDULED = 'SCHEDULED',
	ERROR_CANNOT_SCHEDULE_NO_JOBBER_ACCEPTED = 'ERROR_CANNOT_SCHEDULE_NO_JOBBER_ACCEPTED',
	ERROR_CANNOT_SCHEDULE_NO_JOBBER_ACCEPTED_ONE_OF_THE_SR_UNITS = 'ERROR_CANNOT_SCHEDULE_NO_JOBBER_ACCEPTED_ONE_OF_THE_SR_UNITS',
	ERROR_CANNOT_SCHEDULE_UNABLE_TO_SEND_SMS_TO_NOTIFY_JOBBERS = 'ERROR_CANNOT_SCHEDULE_UNABLE_TO_SEND_SMS_TO_NOTIFY_JOBBERS',
	JOBBER_ASSIGNED_ACCEPTED = 'JOBBER_ASSIGNED_ACCEPTED',
}

enum ServicedCity {
	MISSISSAUGA = 'MISSISSAUGA',
	TORONTO = 'TORONTO',
	BRAMPTON = 'BRAMPTON',
	OAKVILLE = 'OAKVILLE',
	VAUGHAN = 'VAUGHAN',
	MARKHAM = 'MARKHAM',
	MILTON = 'MILTON',
}

//   add phone number field to firmdatadto?
interface FirmDataDto {
	firmId: string;
	facilityId: string;
	orgRequestingUseremail: EmailAddress;
	location: string;
}

interface EmailAddress {
	value: string;
}

interface ShiftRequestUnitDtoV2 {
	simpleLocation: string;
	workerType: Workertype;
	requestOrgName: string;
	jobberHourlyRate: number;
	startDateOfSRUnitEpochSeconds: number;
	endDateOfSRUnitEpochSeconds: number;
	preferredJobbers: { value: string }[];
	isTestData: boolean;
}

interface ShiftRequestUnitDto {
	simplelocation: string;
	workerType: Workertype;
	requestOrgName: string;
	jobberHourlyRate: number;
	shiftRequestUnitId?: string;
	parentShiftRequestId?: string;
	shiftRequestUnitStatus: ShiftRequestStatus;
	dowValue: DowValue;
	submissionDateTimeEpochSeconds?: number | null;
	startDateTimeEpochSeconds: number;
	endDateTimeEpochSeconds: number;
	submissionDateTime?: string | null;
	startDateTime: string;
	endDateTime: string;
	requesterUsername: string;
	assignedJobberUsername?: string | null;
	assignedJobberFirstName?: string | null;
	assignedJobberLastName?: string | null;
	assignedJobberPhoneNumber?: PhoneNumber | null;
	srUnitHourStartTime: number;
	srUnitHourEndTime: number;
	isTestData: boolean;
}

enum Workertype {
	WAREHOUSE_ASSOCIATE = 'WAREHOUSE_ASSOCIATE',
	FORKLIFT_OPERATOR = 'FORKLIFT_OPERATOR',
	GENERAL_LABOUR = 'GENERAL_LABOUR',
}

enum DowValue {
	MON = 'MON',
	TUE = 'TUE',
	WED = 'WED',
	THR = 'THR',
	FRI = 'FRI',
	SAT = 'SAT',
	SUN = 'SUN',
}

interface PhoneNumber {
	value: string;
}

interface BillableReceipt {
	totalHoursRequest: number;
	totalCost: number;
}

interface PreferredJobbers {
	value: string[];
}

interface ShiftReqResponse {
	data: {
		shiftRequestId: string;
		shiftRequestStatus: string;
		requesterUserEmail: { value: string };
		submissionDateTimeEpochSeconds: number;
		submissionDateTimeEst: string;
		workyCorporateCustomer: {
			orgName: string;
			addressDto: {
				street: string;
				unitNumber: string;
				city: string;
				province: string;
				postalCode: string;
				country: string;
				majorIntersection: string;
				entranceInstructions: string;
			};
			orgBillingData: {
				orgName: string;
				orgEmail: { value: string };
				orgPhoneNum: { value: string };
				workyMarkup: number;
				daysUntilPaymentIsDue: number;
				billingFrequency: number;
				billingTimeInterval: string;
			};
			corporateClientUserList: {
				userDetails: {
					userEmail: { value: string };
					userPhoneNumber: { value: string };
					lastName: string;
					firstName: string;
				};
			}[];
		};
		lastJobberPoolNotifTime: null | string;
		lastJobberPoolNotifTimeEpochSec: null | number;
		testData: boolean;
		shiftRequestContent: ShiftRequestUnit[];
	};
	errorDetails: null;
}

interface ShiftRequestUnit {
	shiftRequestUnitId: string;
	parentShiftRequestId: string;
	shiftRequestStatus: string;
	workerType: string;
	simpleLocation: string;
	orgName: string;
	jobberHourlyRate: number;
	orgHourlyRate: number;
	startDateTimeEpochSeconds: number;
	endDateTimeEpochSeconds: number;
	startDateTime: string;
	endDateTime: string;
	startDay: string;
	startTime: number;
	submissionDateTimeEpochSeconds: number;
	submissionDateTime: string;
	requesterUseremail: { value: string };
	requesterPhoneNum: { value: string };
	orgAddress: {
		street: string;
		unitNumber: string;
		city: string;
		province: string;
		postalCode: string;
		country: string;
		majorIntersection: string;
		entranceInstructions: string;
	};
	lastJobberPoolNotifTime: null | string;
	lastJobberPoolNotifTimeEpochSec: null | number;
	preferredJobbers: { value: string }[];
	updatedDateTimeEpoch: number | null;
	updatedDateTime: string | null;
	version: string | null;
	testData: boolean;
}

interface CreateShiftRequestAPIObj {
	isReOccurringShift: boolean;
	shiftRequestContent: ShiftRequestUnitDtoV2[];
	isTestData: boolean;

}

export {
	ShiftRequestDto,
	ShiftRequestStatus,
	FirmDataDto,
	EmailAddress,
	ShiftRequestUnitDto,
	ShiftRequestUnitDtoV2,
	Workertype,
	DowValue,
	PhoneNumber,
	BillableReceipt,
	PreferredJobbers,
	ShiftRequestUnit,
	ShiftReqResponse,
	CreateShiftRequestAPIObj
};
