import React, { useState, useEffect } from 'react';
import UserDetailsForm from '../remoteExecution/jobber-ingress'
import './userform.css';
import './jobberIntake.css'



function JobberIntake() {
    

    
    const [showLogin, setShowLogin] = useState(true);
    useEffect(()=>{

    },[showLogin])

    const handleSignInClick = () => {
        setShowLogin(true);
    };

    const handleJoinNowClick = () => {
        setShowLogin(false);
        
    };    


    const returnedUIComponent = (<div className='loginholder-d'>
    <input placeholder='Username' type="text" className='login-user-d'></input>
    <input placeholder="Password" type="password" className='login-pass-d'></input>
    <button className='login-button'>Login</button>
</div>);    

    return (
        <div className='body'>
        <div className='view-d'>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />
            {/* {UserDetailsForm()} */}

            <div className='left-d'>
                <a href='/'><div className='left-logo-d'>worky</div></a>

                <div className='optionholder-d'>
                    <button className={`option-d ${showLogin ? 'active' : ''}`} onClick={handleSignInClick}>Sign In</button>
                    <button className={`option-d ${!showLogin ? 'active' : ''}`} onClick={handleJoinNowClick}>Join Now</button>
                </div>
                {/* {returnedUIComponent} */}
                
                {/*
                ensures that react hooks error is resolved 
                https://react.dev/warnings/invalid-hook-call-warning#
                **/UserDetailsForm(showLogin)}

                {/* {showLogin && (
                    <div className='loginholder-d'>
                        <input placeholder='Username' type="text" className='login-user-d'></input>
                        <input placeholder="Password" type="password" className='login-pass-d'></input>
                        <button className='login-button'>Login</button>
                    </div>
                )}

                {!showLogin && (
                    <div className='signupholder-d'>
                        
                        {temp()}
                    </div>
                )} */}

            </div>

            <div className='right-d'>
                {/* <div className='right-title-d'>Hire <span class='right-span'>Faster</span> and <span class='right-span'>Cheaper </span>with <span class='right-span'>Worky.</span></div> */}
                {/* <div className='right-img-d'></div> */}
            </div>
        </div>
        </div>
    );
}

export default JobberIntake;