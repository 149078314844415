import React, { useState, useEffect } from 'react';
import './operations.css'
// import { WorkyTimePicker, WorkyTimePickerFuture } from '../common/TimePicker';
import { JobberProfile } from '../orgViews/jobberProfileBuilder';
import { Calendar } from 'react-native-big-calendar'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { DayPicker } from 'react-day-picker';


const events2 = [
  { title: 'Meeting', start: new Date() }
]

// base
function CalenderTestv3() {
  const [selectedWeeks, setSelectedWeeks] = useState<Date[]>([]);
  
  const handleWeekClick = (startOfWeek: Date) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      if (i !== 0 && i !== 6 && i !== 3) { // Exclude Thursday and Saturday
        days.push(day);
      }
    }
    return days;
  };

  const handleWeekNumberClick = (weekNumber: number, startOfWeek: Date[]) => {
    if (startOfWeek.length > 0) {
      const startOfClickedWeek = new Date(startOfWeek[0]);
      const newWeek = handleWeekClick(startOfClickedWeek);
      setSelectedWeeks(prevWeeks => {
        const isSelected = prevWeeks.some(prevWeek =>
          prevWeek.getTime() === startOfClickedWeek.getTime()
        );
        if (isSelected) {
          return prevWeeks.filter(prevWeek =>
            prevWeek.getTime() !== startOfClickedWeek.getTime()
          );
        } else {
          return [...prevWeeks, ...newWeek];
        }
      });
    }


  };

  const footer = selectedWeeks.length > 0 ? (
    <p>You selected {selectedWeeks.map(day => day.toDateString()).join(', ')}.</p>
  ) : (
    <p>Please pick a week.</p>
  );

  return (
    <div>
      <DayPicker 
        showWeekNumber
        showOutsideDays 
        fixedWeeks 
        selected={selectedWeeks}
        onWeekNumberClick={handleWeekNumberClick} // Handle week number click
        footer={footer} />
    </div>
  );
}
// sucess in restricting duplicates
const CalenderTestv10 = ({ weekNumbers }: { weekNumbers: number[] }) => {
  const [selectedWeeks, setSelectedWeeks] = useState<Date[]>([]);
  
  const handleWeekClick = (startOfWeek: Date) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      // if (i !== 0 && i !== 6 && i !== 3) { // Exclude Thursday and Saturday
      //   days.push(day);
      // }
      if (weekNumbers.includes(i)) { // Exclude Thursday and Saturday
        days.push(day);
      }
    }
    return days;
  };

  const handleWeekNumberClickv2 = (weekNumber: number, startOfWeek: Date[]) => {
    if (startOfWeek.length > 0) {
      const startOfClickedWeek = new Date(startOfWeek[0]);
      const newWeek = handleWeekClick(startOfClickedWeek);

      console.log("startOfWeek" + startOfWeek)
      console.log("selectedWeeks" + selectedWeeks)

      let valuesfound = 0;

      for(let i=0; i<newWeek.length; i++){
        const potStartWeekDay = newWeek[i];
        // console.log(potStartWeekDay)
        if(selectedWeeks.some(date => date.getTime() === potStartWeekDay.getTime())) {
          valuesfound++;
          console.log(potStartWeekDay);
        }
      }
      console.log(valuesfound);
      if (valuesfound === newWeek.length){
        const filteredDates:Date[] = [];
        for(let i=0; i<selectedWeeks.length; i++){
          if(!newWeek.some(date => date.getTime() === selectedWeeks[i].getTime())) {
            filteredDates.push(selectedWeeks[i])
          }
        }
        setSelectedWeeks(filteredDates);

      }else{
        setSelectedWeeks(prevWeeks => {
          const isSelected = prevWeeks.some(prevWeek =>
            prevWeek.getTime() === startOfClickedWeek.getTime()
          );
          if (isSelected) {
            return prevWeeks.filter(prevWeek =>
              prevWeek.getTime() !== startOfClickedWeek.getTime()
            );
          } else {
            return [...prevWeeks, ...newWeek];
          }
        });
      }
    }
  };
  

  return (
    <div>
      <DayPicker 
        showWeekNumber
        showOutsideDays 
        fixedWeeks 
        selected={selectedWeeks}
        onWeekNumberClick={handleWeekNumberClickv2} // Handle week number click
      />
    </div>
  );
}






const Operations = () =>{




  return(

    <div className='tester'>

      <CalenderTestv10 weekNumbers={[1, 2, 3, 4]} />

    </div>
  )
}

export {Operations};