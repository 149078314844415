const monthShortNames: Record<number, string> = {
	1: 'Jan',
	2: 'Feb',
	3: 'Mar',
	4: 'Apr',
	5: 'May',
	6: 'Jun',
	7: 'Jul',
	8: 'Aug',
	9: 'Sep',
	10: 'Oct',
	11: 'Nov',
	12: 'Dec',
};

interface WorkyWorkdayShift {
	date: string;
	time: string;
	unassigned: number;
	assigned: number;
}

function combineDateAndTimeToEpoch(dateString: string, timeString: string): number {
	console.log(dateString);
	console.log(timeString);

	const combinedDateTimeString = `${dateString}T${timeString}:00`;
	const combinedDateTimeEpoch = new Date(combinedDateTimeString).getTime() / 1000;
	console.log('orgutils combine date time epoch + ' + combinedDateTimeEpoch);
	return combinedDateTimeEpoch;
}

function padTime(timeString: string): string {
	const [hours, minutes] = timeString.split(':');
	const paddedMinutes = minutes.padEnd(2, '0');
	const paddedHours = hours.padStart(2, '0');

	return `${paddedHours}:${paddedMinutes}`;
}

function formatTimeString(input: string): string {
    const [startTime, endTime] = input.split('_');

    const formatTime = (time: string): string => {
        const [hour, minute] = time.split(':');
        const formattedHour = parseInt(hour, 10);

        if (formattedHour === 12) {
            return `${formattedHour}${minute !== '00' ? `:${minute}` : ''}pm`;
        } else if (formattedHour > 12) {
            return `${formattedHour - 12}${minute !== '00' ? `:${minute}` : ''}pm`;
        } else {
            return `${formattedHour === 0 ? 12 : formattedHour}${
                minute !== '00' ? `:${minute}` : ''
            }am`;
        }
    };

    const formattedStartTime = formatTime(startTime);
    const formattedEndTime = formatTime(endTime);

    const isNextDay = endTime < startTime;
    return `${formattedStartTime} - ${formattedEndTime}${isNextDay ? " (next day)" : ""}`;
}


const convertTo12HourFormat = (time24) => {
	const [hour, minute] = time24.split(':');
	let period = 'AM';
	let hour12 = parseInt(hour, 10);

	if (hour12 >= 12) {
		period = 'PM';
		if (hour12 !== 12) {
			hour12 -= 12;
		}
	}

	if (hour12 === 0) {
		hour12 = 12;
	}
	if (time24) {
		return `${hour12}:${minute.padStart(2, '0')} ${period}`;
	} else return;
};

const extractDate = (selectedDays) => {
	const strDaysArr = [];
	for (let i = 0; i < selectedDays.length; i++) {
		strDaysArr.push(selectedDays[i].toString().slice(0, -42));
	}
	console.log(strDaysArr.toString());
	return strDaysArr.toString();
};

const determineWorkyTime = (hour: number): string => {
	if (!hour) return null;

	if (hour < 12) {
		return hour + 'am';
	} else if (hour === 12) {
		return hour + 'pm';
	} else {
		const convertedHour = hour - 12;
		console.log('org utils determine worky time : ' + convertedHour);
		return convertedHour + 'pm';
	}
};

const getHour = (timeString: string): number => {
	if (!timeString) return null;
	const hourNum = Number(timeString.substring(0, 2));
	return hourNum;
};
const getMins = (timeString: string): number => {
	if (!timeString) return null;
	const minsNum = Number(timeString.substring(3, 5));
	return minsNum;
};

const determineTime = (determineTime: string): string => {
	// const inputString = '2023-12-23T04:00_10:00';
	// TODO FIX THIS LOGIC TO INCORPORATE FOR 5:30PM AND NOT ONLY 5PM
	console.log('org utils determine time : ' + determineTime);
	const regex = /T(\d{2}:\d{2})_(\d{2}:\d{2})/;
	const match = determineTime.match(regex);

	if (match) {
		const startTime = parseInt(match[1], 10); // Value after "T" and before the first "_"
		const endTime = parseInt(match[2], 10); // Value after the first "_"

		console.log('------------' + endTime);

		const startTimeStr = determineWorkyTime(startTime);
		const endTimeStr = determineWorkyTime(endTime);
		return startTimeStr + '-' + endTimeStr;
	} else {
		console.log('No match found');
	}
	return null;
};

const parseMonthAndDayV2 = (input: string): string => {
	const month = parseInt(input.slice(5, 7));
	const day = parseInt(input.slice(8, 10));
	let monthName: string;
	switch (month) {
		case 1:
			monthName = 'Jan';
			break;
		case 2:
			monthName = 'Feb';
			break;
		case 3:
			monthName = 'Mar';
			break;
		case 4:
			monthName = 'Apr';
			break;
		case 5:
			monthName = 'May';
			break;
		case 6:
			monthName = 'Jun';
			break;
		case 7:
			monthName = 'Jul';
			break;
		case 8:
			monthName = 'Aug';
			break;
		case 9:
			monthName = 'Sep';
			break;
		case 10:
			monthName = 'Oct';
			break;
		case 11:
			monthName = 'Nov';
			break;
		case 12:
			monthName = 'Dec';
			break;
		default:
			monthName = 'Err';
			break;
	}
	let returnString = monthName + ' ' + day.toString();
	return returnString;
};

const generateWorkyWorkdayShiftInput = ({
	workyWorkdayShiftInput,
	assignedSRCount,
	inProgressSRCount,
}: {
	workyWorkdayShiftInput: string;
	assignedSRCount: number;
	inProgressSRCount: number;
}): WorkyWorkdayShift => {
	console.log('Generating WorkyWorkDay for input: ', workyWorkdayShiftInput);

	const dateString = workyWorkdayShiftInput; //'2023-12-23T04:00_10:00';
	const time = determineTime(dateString);
	const date = parseMonthAndDayV2(dateString);

	console.log('orgutils - dateString : ' + dateString);
	console.log('orgutils - time : ' + time);
	console.log('orgutils - date : ' + date);

	if (time && date && assignedSRCount !== null && inProgressSRCount !== null) {
		return {
			date: date,
			time: time,
			unassigned: inProgressSRCount,
			assigned: assignedSRCount,
		};
	}

	console.log('not found: ');
	return null;
};

interface ShiftRequest {
	shiftRequestUnitId: string;
	parentShiftRequestId: string;
	shiftRequestStatus: string;
	workerType: string;
	workyWorkdayShift: string;
	simpleLocation: string;
	orgName: string;
	jobberHourlyRate: number;
	orgHourlyRate: number;
	startDateTimeEpochSeconds: number;
	endDateTimeEpochSeconds: number;
	startDateTime: string;
	endDateTime: string;
	startDay: string;
	startTime: number;
	submissionDateTimeEpochSeconds: number;
	submissionDateTime: string;
	requesterUseremail: {
		value: string;
	};
	requesterPhoneNum: {
		value: string;
	};
	requesterFirstName: string;
	requesterLastName: string;
	orgAddress: {
		street: string;
		unitNumber: string;
		city: string;
		province: string;
		postalCode: string;
		country: string;
		majorIntersection: string;
		entranceInstructions: string;
	};
	lastJobberPoolNotifTime: null | string;
	lastJobberPoolNotifTimeEpochSec: null | number;
	preferredJobbers: {
		value: string;
	}[];
	updatedDateTimeEpoch: number;
	workyActionsTrace: WorkyAction[];
	updatedDateTime: string;
	version: number;
	testData: boolean;
	assignedJobberProfilePicS3URL:string;
	assignedJobberUserDetails: {
		firstName: string;
		lastName: string;
		userPhoneNumber: {value:string;};
		userEmail:{value:string;};
	};
}

interface UserDetails {
	userid?: string | null;
	createdDateTime?: string | null;
	userEmail: EmailAddress;
	userPhoneNumber: PhoneNumber;
	lastName: string;
	firstName: string;
}

interface JobberDetailsForOrg {
	userDetails: UserDetails;
	userProfilePicUrl?: string;
}

interface EmailAddress {
	value: string;
}

interface PhoneNumber {
	value: string;
}

interface WorkyAction {
	action: string;
	actorId: string;
	reason: string;
	eventTime: string;
	eventTimeEpochSec: number;
}

const calculateShiftTotals = (
	startTimeHour,
	startTimeMinute,
	endTimeHour,
	endTimeMinute,
	numberJobbers,
	numberDays,
	orgHourlyRate
) => {
	// total number of hours
	const hours1 = endTimeHour;
	const minutes1 = endTimeMinute;
	const hours2 = startTimeHour;
	const minutes2 = startTimeMinute;

	const totalMinutes1 = hours1 * 60 + minutes1;
	const totalMinutes2 = hours2 * 60 + minutes2;
	const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);
	let shiftDuration = differenceInMinutes / 60;

	if (shiftDuration > 10) {
		shiftDuration -= 1;
	} else if (shiftDuration >= 5) {
		shiftDuration -= 0.5;
	}

	// number of workers
	const numWorkers = numberJobbers;
	// number of days
	const numDays = numberDays;
	// worker pay
	const orgPay = orgHourlyRate;
	// tax
	const workerTax = 1.13;

	const totalShiftCharge = shiftDuration * numWorkers * numDays * workerTax * orgPay;
	const totalTax = totalShiftCharge * 0.13;
	return ['$' + totalTax.toFixed(2), '$' + totalShiftCharge.toFixed(2)];
	// maybe need to add a disclaimer -- timing charges etc...
};

export {
	ShiftRequest,
	monthShortNames,
	WorkyWorkdayShift,
	determineWorkyTime,
	determineTime,
	parseMonthAndDayV2,
	generateWorkyWorkdayShiftInput,
	convertTo12HourFormat,
	combineDateAndTimeToEpoch,
	padTime,
	getHour,
	getMins,
	formatTimeString,
	extractDate,
	calculateShiftTotals,
	UserDetails,
	JobberDetailsForOrg,
};
