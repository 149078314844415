import React, { useState, useEffect, useRef } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './mobilesplashv2.css';
import emailjs from 'emailjs-com';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import {CalendlyComponent, persistSalesMetaDataCalendlyRedirect} from '../calendly-integration/CalendlyIntegrationMasterClass'

const workyServerIDPSIGNUPURL = process.env.REACT_APP_IDP_SIGN_UP_URL;
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;



const MobileSplashNav = () => {
    const [isPassed,setIsPassed] = useState(false);
    const [openTray, setOpenTray] = useState(false);
    useEffect(() => {
        // Check if the query parameter 'scroll' is present in the URL
        const urlParams = new URLSearchParams(window.location.search);
        const scrollPosition = urlParams.get('scroll');

        if (scrollPosition) {
            // Scroll to the specified position
            window.scrollTo({ top: parseInt(scrollPosition), behavior: "smooth" });
        }
    }, []);
    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY || document.documentElement.scrollTop;
          const hasScrolledPast300px = scrollPosition > 500;
          setIsPassed(hasScrolledPast300px);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    const handleEmployersClick = () => {
        setOpenTray(false)
        window.scrollTo({ top: 3130, behavior: "smooth" });
    }

    const handleWorkersClick = () => {
        setOpenTray(false)
        window.scrollTo({ top: 4100, behavior: "smooth" });
    }

    const handleContactClick = () => {
        setOpenTray(false)
        window.scrollTo({ top: 5100, behavior: "smooth" });
    }

    const handleTrayToggle = () => {
        setOpenTray(!openTray)
    }

    return(
        <>
        <div className='mobile-splash-nav'>
            <div onClick={() => window.scrollTo({ top: 1, behavior: "smooth" })} className='mobile-splash-logo'>worky</div>
            <div className='mobile-splash-right'>
                {isPassed? <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} id='mobile-splash-trynow-visible' className='mobile-splash-trynow'>Try Now</button>:<button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='mobile-splash-trynow'>Try Now</button>}
                <button onClick={handleTrayToggle} className='mobile-splash-tray'><MenuIcon style={{fontSize:"32px", color:"#7d7d7d"}}/></button>
            </div>
        </div>
        {openTray?(
            <div className='mobile-options-container'>
                <button onClick={handleEmployersClick} className='mobile-option'>Employers</button>
                <button onClick={handleWorkersClick} className='mobile-option'>Workers</button>
                <button onClick={()=>window.location.assign('/careers')} className='mobile-option'>Careers</button>
                <button onClick={handleContactClick} className='mobile-option'>Contact</button>
                <button onClick={() => window.location.assign(workyServerIDPSIGNINURL)} className='mobile-option'>Sign In</button>
                <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} id="mobile-option-trynow" className='mobile-option'>Try Now</button>
            </div>
        ):null}
        </>
    )
}

const Slider = () => {
    const images = [
        "./splash/desktop-slide/pic11-new.jpg",
        "./splash/desktop-slide/pic2-new.jpg",
        "./splash/desktop-slide/pic3-new.jpg",
        "./splash/desktop-slide/pic10-new.jpg",
        "./splash/desktop-slide/pic5-new.jpg",
        "./splash/desktop-slide/pic6-new.jpg",
        "./splash/desktop-slide/pic7-new.jpg",
        "./splash/desktop-slide/pic9-new.jpg",
        "./splash/desktop-slide/pic12-new.jpg",
        "./splash/desktop-slide/pic13-new.jpg",
    ];

    return (
        <Fade cssClass='mobile-fade' infinite={true} duration={2000} arrows={false} canSwipe={true} autoplay={true} pauseOnHover={false}>
           
            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[0]} />
            </div>

            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[8]} />
            </div>

            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[9]} />
            </div>

            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[2]} />
            </div>

            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[7]} />
            </div>

            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[1]} />
            </div>

            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[3]} />
            </div>

            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[4]} />
            </div>

            <div className="mobile-each-slide">
                <img className="mobile-slide-img" src={images[5]} />
            </div>

        </Fade>
      );
}

const MobileSplash1 = () => {
    const TEXTS = ['General Labourers','Warehouse Associates', 'Forklift Drivers', 'Material Handlers','Food Processors', 'Movers', 'Order Pickers', 'Machine Operators'];
    const [index, setIndex] = useState(0);
    
    useEffect(() => {
        const intervalId = setInterval(
          () => setIndex((index) => index + 1),
          2000, 
        );
        return () => clearTimeout(intervalId);
      }, []);

    return(
        <div className='mobile-splash-section1'>
            <div className='mobile-splash-section1-title-holder'>
                <div className='mobile-splash-section1-subtitle'>The Worlds First</div>
                <div className='mobile-splash-section1-title'>Instant Staffing Solution</div>
                <div className='mobile-splash-section1-text'>Hire <TextTransition style={{ margin: "7px 4px 0px 4px", color:"#2c68bd", fontWeight:600 }} springConfig={presets.default} inline>{TEXTS[index % TEXTS.length]}</TextTransition> with <br/><span style={{fontWeight:"700", color:"#2c68bd"}}>worky</span></div>
                <div className='mobile-splash-section1-button-holder'>
                <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='mobile-splash-section1-button'>Find Work</button>
                <button onClick={() => window.scrollTo({ top: 5100, behavior: "smooth" })} className='mobile-splash-section1-button'>Hire Workers</button>
            </div>
            </div>
            
            <div className='mobile-splash-section1-slider'><Slider/></div>
            {/* <div className='mobile-splash-section1-slider'></div> */}

        </div>
    )


}

const MobileOntime = () => {
    return(
        <div className='mobile-ontime-holder'>
            <div className='mobile-ontime-inner'>
                <div className='mobile-ontime'>
                    <div className='mobile-ontime-title'>Average Savings Per Shift</div>
                    <div className='mobile-ontime-value'>30%</div>
                </div>
                <div className='mobile-ontime'>
                    <div className='mobile-ontime-title'>Available In</div>
                    <div className='mobile-ontime-value'><img src='./canadaus-mobile.jpg'/></div>
                </div>
                <div className='mobile-ontime'>
                    <div className='mobile-ontime-title'>Shifts Filled in Under</div>
                    <div className='mobile-ontime-value'>60s</div>
                </div>
            </div>
        </div>

    )
}

const MobileSplash2 = () => {

    return(
        <div className='mobile-splash-section7-container'>
            <div className='mobile-section7-title'>Instant Staffing. Anywhere. Anytime.</div>
            <div className='mobile-section7-subtitle'>Get your shifts filled within seconds!<br/>Worky Gives you access to over 10,000 active employees available at your fingertips.<br/><span className='simple-bold'>The Best Workers, Fastest Response. Guaranteed.</span></div>
            <button onClick={() => window.scrollTo({ top: 5100, behavior: "smooth" })} id='mobile-section7-button' className='mobile-splash-section1-button'>Try Now</button>
            <div className='mobile-section7-info-holder'>
                <div className='mobile-section7-info-container'>
                    <div className='mobile-section7-info-icon'><img className='mobile-section7-img' src='./splash/fast.png'></img></div>
                    <div className='mobile-section7-info-title'>Fast</div>
                    <div className='mobile-section7-info-content'>At Worky, we prioritize <span className='simple-bold'>Speed</span> and <span className='simple-bold'>Efficiency</span>.<br/> We deliver <span className='simple-bold'>Instant</span> results. <br/>Hire your employees within <span className='simple-bold'>1 Hour!</span> <br/>Hire Fast. Worky It.</div>
                </div>

                <div className='mobile-section7-info-container'>
                    <div className='mobile-section7-info-icon'><img className='mobile-section7-img' src='./splash/reliable.png'></img></div>
                    <div className='mobile-section7-info-title'>Reliable</div>
                    <div className='mobile-section7-info-content'>At Worky, we're on a mission: deliver the <span className='simple-bold'>perfect</span> candidate <span className='simple-bold'>EVERY</span> time. <br/>We guarantee clients the top candidates at highly competitive rates.</div>
                </div>

                <div className='mobile-section7-info-container'>
                    <div className='mobile-section7-info-icon'><img className='mobile-section7-img' src='./splash/safe.png'></img></div>
                    <div className='mobile-section7-info-title'>Safe</div>
                    <div className='mobile-section7-info-content'>At Worky, we <span className='simple-bold'>prioritize safety</span>. We ensure the well-being of our employees and clients by guaranteeing <span className='simple-bold'>safe work environments</span>, with <span className='simple-bold'>regular training</span> on the latest safety standards.</div>
                </div>
            </div>
        </div>


    )
}

const PartnerSlider = () => {
    const images = [
        "./splash/regal.jpg",
        "./splash/logo4.png",
        "./splash/logo2.png",
        // "./splash/logo3.png",
    ];

    return (
        <Fade cssClass='mobile-partner-fade' infinite={true} duration={1500} arrows={false} canSwipe={true} autoplay={true} pauseOnHover={false}>
            <div className="mobile-partner-each-slide"><img className="mobile-partner-slide-img" src={images[0]} /></div>
            <div className="mobile-partner-each-slide"><img className="mobile-partner-slide-img" src={images[1]} /></div>
            <div className="mobile-partner-each-slide"><img className="mobile-partner-slide-img" src={images[2]} /></div>
            {/* <div className="mobile-partner-each-slide"><img className="mobile-partner-slide-img" src={images[3]} /></div> */}
        </Fade>
      );
}

const MobileOurPartners = () => {

    return(
        <div className='mobile-splash-our-partners'>
            <div className='mobile-splash-partners-title'>Our Trusted Partners</div>
            <div className='mobile-splash-partners-subtitle'>Join our Team of Employers</div>

            <div className='mobile-splash-partners-container'>
                <PartnerSlider/>
            </div>
        </div>
    )
}

const MobileEmployers = () => {
    const employersText = "Empowering businesses of every scale, Worky is the World's First  seamless on-demand labor solution. From a single worker to a workforce of 100, our platform works for you! With Worky, your shifts are all filled in under an hour!";

    return (
      <div className="mobile-splash-employers">
        <div className="mobile-splash-text-holder">
          <div className="mobile-splash-employers-title">For<br />Employers</div>
          <div className="mobile-splash-employers-text">{employersText}</div>
          <button onClick={() => window.scrollTo({ top: 5100, behavior: "smooth" })} className="mobile-splash-employers-button">Hire Now</button>
        </div>

        <div className="mobile-splash-img-holder">
            <img src="./splash/Mockup.png"/>
        </div>
    </div>)
}

const MobileWorkers = () => {
    const workersText = "Say goodbye to rigid schedules—get paid on your own terms with Worky. We offer the flexibility you need for a hassle-free work-life balance. Take control as your own boss and start earning today!";

    return (
      <div style={{paddingTop:"50px"}} className="mobile-splash-employers">
        <div className="mobile-splash-text-holder">
          <div className="mobile-splash-employers-title">For<br />Workers</div>
          <div className="mobile-splash-employers-text">{workersText}</div>
          <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} style={{marginBottom:"75px"}} className="mobile-splash-employers-button">Join Now</button>
        </div>

        <div className="mobile-splash-img-holder">
            <img src="./splash/phonearray.jpg"/>
        </div>
    </div>)
}


const MobileSplashContact = () => {
    const [isFormSubmitted, setFormSubmitted] = useState(false);

    const handleGeneralEmailSubmit = async (e) => {
        e.preventDefault();

        if (isFormSubmitted) {
            return;
          }
          setFormSubmitted(true);


        const serviceID = 'service_3mlm2ls';
        const templateID = 'template_xl6xpjw';
        const userID = 'zKdgUxA7H3dMaCf-m';
    
        try {
            await emailjs.sendForm(serviceID, templateID, e.target, userID);
            alert('Email sent successfully!');
          } catch (error) {
            alert('An error occurred while sending the email.');
            console.error(error);
          } finally {
            setFormSubmitted(false);
          }
    };
    


    return(
        <div className='mobile-splash-contact'>
            <div className='mobile-splash-contact-title'>Get in Touch</div>
            <div className='mobile-splash-contact-subtitle'>Have a Question? Want to Hire today?</div>
            <div className='mobile-splash-contact-contactinfo-holder'>
                <div className='mobile-splash-contact-contactinfo'>
                <EmailOutlinedIcon style={{fontSize:"20px"}} />
                <div className='mobile-splash-contact-contactinfo-info'>info@worky.pro</div>
                </div>
                <div className='mobile-splash-contact-contactinfo'>
                <PhoneOutlinedIcon style={{fontSize:"20px"}} />
                <div className='mobile-splash-contact-contactinfo-info'>(647) 494 9090</div>
                </div>
            </div>
            <div className='mobile-splash-contact-container'>
                <form onSubmit={handleGeneralEmailSubmit}>

                    <div className='mobile-splash-input-title'>Full Name:</div>
                    <input type="text" name="user_fullname" className='mobile-splash-input' required/>
                    <div className='mobile-splash-input-title'>Email Address:</div>
                    <input type="email" name="user_email" className='mobile-splash-input' required/>

                    <div className='mobile-splash-input-title'>Reason:</div>
                    <select id='mobile-splash-reason' className="mobile-splash-input" name="user_reason" required>
                        <option value="" disabled selected>Select a reason</option>
                        <option value="General Inquiry">General Inquiry</option>
                        <option value="Technical Support">Technical Support</option>
                        <option value="Feedback">Feedback</option>
                        <option value="Other">Other</option>
                    </select>

                    <div className='mobile-splash-input-title'>Message:</div>
                    <textarea className='mobile-splash-input' name="user_message" placeholder="Message" required></textarea>
                    <div className='mobile-splash-button-holder'>
                        <button className='mobile-splash-submit'>{isFormSubmitted ? 'Submitting...' : 'Submit'}</button>
                    </div>
                </form>
                
            </div>
        </div>

    )
}

const MobileWorkyFooter = () => {

    return(
        <div className='mobile-splash-footer'>
            <div className='mobile-splash-footer-title'>worky</div>
            <div className='mobile-splash-footer-socials'>
                <div id='social-facebook' className='mobile-splash-footer-social'></div>
                <div id='social-instagram' className='mobile-splash-footer-social'></div>
                <div id='social-twitter' className='mobile-splash-footer-social'></div>
                <div id='social-linkedin' className='mobile-splash-footer-social'></div>
                <div id='social-tiktok' className='mobile-splash-footer-social'></div>
            </div>
        </div>
    )
}




const MobileSplashV2 = () => {

    persistSalesMetaDataCalendlyRedirect()
    return(
        <div className='mobile-splash-body'>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />        
            
            <MobileSplashNav/>
            <MobileSplash1/>
            <MobileOntime/>
            <MobileSplash2/>
            <MobileOurPartners/>
            <MobileEmployers/>
            <MobileWorkers/>
            <CalendlyComponent/>
            <MobileSplashContact/>
            <MobileWorkyFooter/>
        </div>
    )

}



export {MobileSplashV2,MobileWorkyFooter, MobileSplashNav}