import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Modal, Table, TableCell, TableRow, TableContainer, TableHead, TableBody, TableFooter, TablePagination, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { SRIPResponseData, sortDataByWorkyWorkdayShift } from '../OrgHomepage';
import { RemoteExecutionServiceImpl, ServerResponse } from '../../remoteExecution/RemoteExecutionServiceImpl';
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();



// interface declaration for props
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }
  




// page actions 
function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </Box>
    );
}
  












  





const ShiftsAssignedTable = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(prevState => !prevState);
    const [orgSRIPResponse, setOrgSRIPResponse] = useState<SRIPResponseData[] | null>(null);
    const [rows, setRows] = useState([]);

    console.log("orgSRIPResponse : "+ orgSRIPResponse);


    useEffect(() => {
      const fetchData = async () => {
        await getOrgSRIP();
      };
      fetchData();
      }, []);


    const getOrgSRIP = async () => {
      const getCallInput = {
        apiUrl: backendApiBasePathLocal + 'shiftrequest/SRIP',
        headers: {},
        queryParams: null,
      };
  
      try {
        const orgSRIPResponse = await remoteCallObject.remoteGETCallV2<ServerResponse<SRIPResponseData[], Error>>(getCallInput);
  
        if (orgSRIPResponse !== null && orgSRIPResponse.data != null && orgSRIPResponse.data.data ) {
          console.log('step4');
          const sortedData: SRIPResponseData[] = sortDataByWorkyWorkdayShift(orgSRIPResponse.data.data);
          setOrgSRIPResponse(sortedData);
          determineSRIP(sortedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    //helper function to populate rows with data for createData function
    // const rows = [];

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };





    





    const determineSRIP = (orgSRIPResponse) => {
      const resultData = [];  

      const calcWorkyChargeWTax = (shiftDuration, numWorkers, workerHourlyPayRate, ): number => {
        const charge = 1.13 * shiftDuration * numWorkers * workerHourlyPayRate;
        return Math.round(charge * 100) / 100;
      };
    
      orgSRIPResponse.forEach((shiftRequest) => {
        const { assignedShiftRequests } = shiftRequest;
    
        assignedShiftRequests.forEach((assignedShift) => {
          const startDate = assignedShift.startDateTime;
          const startTime = assignedShift.startDateTimeEpochSeconds;
          const endTime = assignedShift.endDateTimeEpochSeconds;
          const hoursWorked = cleanHoursWorked(endTime - startTime); // Assuming startTime and endTime are in hours
          const numJobbers = assignedShiftRequests.length; // You may adjust this based on your data
          const jobberRate = assignedShift.jobberHourlyRate;
          const total = calcWorkyChargeWTax(hoursWorked, numJobbers, assignedShift.orgHourlyRate);
          const tax = parseFloat((total*0.13).toFixed(2));
    
          const data = createData(
            startDate,
            startTime.toString(),
            endTime.toString(),
            hoursWorked,
            numJobbers,
            jobberRate,
            tax,
            total
          );
          resultData.push(data);
        });
      });
    
      setRows(resultData);
    };





    // helper function to create rows for table to render
    function createData(startDate: string, startTime: string, endTime: string, hoursWorked:number, numJobbers: number, jobberRate: number, tax:number, total: number) {
      const cancelBtn = <button className='shiftCancel' onClick={() => toggleModal()}>Cancel</button>;
      return { 
          startDate, 
          startTime, 
          endTime, 
          hoursWorked, 
          numJobbers, 
          jobberRate, 
          tax,
          total, 
          cancel: cancelBtn 
      };
    }

    console.log("ROWS ROWS " + rows)
    return (
      <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight:600 }} align="center">Shift Date</TableCell>
              <TableCell style={{ fontWeight:600 }} align="center">Start Time</TableCell>
              <TableCell style={{ fontWeight:600 }} align="center">End Time</TableCell>
              <TableCell style={{ fontWeight:600 }} align="center"># Hours</TableCell>
              <TableCell style={{ fontWeight:600 }} align="center"># Workers</TableCell>
              <TableCell style={{ fontWeight:600 }} align="center">Worker Rate</TableCell>
              <TableCell style={{ fontWeight:600 }} align="center">Tax (%)</TableCell>
              <TableCell style={{ fontWeight:600 }} align="center">Total ($)</TableCell>
              <TableCell style={{ fontWeight:600 }} align="center">Cancel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.startDate}>
                <TableCell style={{ width: 160 }}component="th" scope="row" align="center">{cleanShiftDate(row.startDate)}</TableCell>
                <TableCell style={{ width: 160 }} align="center">{cleanShiftTime(row.startTime)}</TableCell>
                <TableCell style={{ width: 160 }} align="center">{cleanShiftTime(row.endTime)}</TableCell>
                <TableCell style={{ width: 160 }} align="center">{row.hoursWorked}</TableCell>
                <TableCell style={{ width: 160 }} align="center">{row.numJobbers}</TableCell>
                <TableCell style={{ width: 160 }} align="center">{"$"+row.jobberRate+"/hr"}</TableCell>
                <TableCell style={{ width: 160 }} align="center">{row.tax}</TableCell>
                <TableCell style={{ width: 160 }} align="center">{row.total}</TableCell>
                <TableCell style={{ width: 160 }} align="center">{row.cancel}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={8}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Modal open={modalOpen} onClose={toggleModal}>
        <div className='shift-cancel-modal'>
            <h2 id="modal-title">Do you want to cancel this shift?</h2>
            <p id="modal-description">Shift details: </p>
            <div className='modal-button-holder'>
              <button className='modal-button'>Yes</button>
              <button onClick={toggleModal} style={{background:'#2c68bd',color:'white'}} className='modal-button'>No</button>
            </div>
        </div>
      </Modal>
      </>
    );
}

const cleanShiftDate = (dateString ): string => {
  let cleanDate = dateString.substring(0,10)
  const dateObject = new Date(cleanDate);
  const formattedDate = dateObject.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
  return formattedDate;
};

const cleanShiftTime = (epochSeconds: number): string => {
  const dateObject = new Date(epochSeconds * 1000);
  const formattedTime = dateObject.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true,});
  return formattedTime;
};


const cleanHoursWorked = (epochSeconds: number): number => {
  let timeWorked = epochSeconds / 3600;

  if (timeWorked > 10) {
    timeWorked -= 1;
  } else if (timeWorked >= 5) {
    timeWorked -= 0.5;
  }

  const roundedTimeWorked = parseFloat(timeWorked.toFixed(2));
  return roundedTimeWorked;
};








export {ShiftsAssignedTable, cleanShiftDate, cleanShiftTime }