import * as React from 'react';


function formatDateFromEpoch(epoch: number): string {
	function getMonthName(month: number): string {
		const months = [
		  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
		  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
		];
	  
		return months[month - 1];
	  }
	  

	const date = new Date(epoch * 1000); // Convert seconds to milliseconds
  
	const day = date.getDate();
	const month = getMonthName(date.getMonth() + 1); // Months are zero-based
	const year = date.getFullYear();
  
	return `${month} ${day} ${year}`;
  }

  function formatTimeFromEpoch(epoch: number): string {
	const date = new Date(epoch * 1000); // Convert seconds to milliseconds
  
	const hours = date.getHours();
	const minutes = date.getMinutes();
  
	const amOrPm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
	return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  }

  function calculateHoursDifference(startEpoch: number, endEpoch: number): number {
	return parseFloat(((endEpoch - startEpoch)/3600).toFixed(2));
  }

  function cleanBilling(input: string): string {
    function getMonthName(month: number): string {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        return months[month - 1];
    }

    const dateParts = input.split('_');
    if (dateParts.length === 2) {
        // Date range format
        const [start, end] = dateParts.map(date => {
            const [day, month, year] = date.split('-');
            return `${getMonthName(parseInt(month, 10))} ${parseInt(day, 10)} ${year}`;
        });

        return `${start} - ${end}`;
    } else if (dateParts.length === 1) {
        // Single date format
        const [day, month, year] = dateParts[0].split('-');
        return `${getMonthName(parseInt(month, 10))} ${parseInt(day, 10)} ${year}`;
    } else {
        // Invalid input
        return "Invalid date format";
    }
}



  export {formatDateFromEpoch,formatTimeFromEpoch,calculateHoursDifference,cleanBilling }