import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {SplashPage} from './splashComponent/SplashPage'
import {MainCompDesktop} from './holder/MainCompDesktop';
import {SRIngressStepManager} from './sr-ingress/SRIngressStepManager'
import {Operations} from './holder/Operations';
import jobberIntake from './holder/jobberIntake';
import BasicInfoPage from './jobberViews/BasicInfoPage';
import { AuthGetCall } from './remoteExecution/WorkyJobberSignIn'
import { WorkyHomePage } from './jobberViews/WorkyHomePage';
import { ShiftHistory } from './orgViews/ShiftHistory';
import { CurrentShifts } from './orgViews/CurrentShifts'
import { JobberHomePage } from './jobberViews/JobberHomePage'
import { JobberAccountSettings } from './jobberViews/JobberAccountSettings'
import { JobberPreviousShifts } from './jobberViews/JobberPreviousShifts'
import { OrgAccountSettings } from './orgViews/OrgAccountSettings'
import { JobberImportantDocs } from './jobberViews/JobberImportantDocs'
import { CareersPage } from './splashComponent/CareersPage'
import { ProductsPage } from './splashComponent/ProductsPage'
import { NewShiftV4 }  from './sr-ingress/NewShiftV4'
import { MyWorkforce } from './orgViews/MyWorkforce'
import { TestingExperimentPage } from './testing-experiments/TestingExperimentPage'


function App() {  

  
  return (
    <Router>
      <Switch>      
        <Route path="/testing-experiments" component={TestingExperimentPage} />     
        <Route path="/homepage" component={WorkyHomePage} />     
        <Route path="/new-shift" component={NewShiftV4} />
        <Route path="/shift-history" component={ShiftHistory} />
        <Route path="/current-shifts" component={CurrentShifts} />
        <Route path="/account" component={OrgAccountSettings} />
        <Route path="/my-workforce" component={MyWorkforce} />


        


        <Route path="/jobber-homepage" component={JobberHomePage} />
        <Route path="/jobber-previous-shifts" component={JobberPreviousShifts} />
        <Route path="/jobber-account-settings" component={JobberAccountSettings} />
        <Route path="/jobber-important-docs" component={JobberImportantDocs} />


        <Route path="/opex" component={Operations} />
        <Route path="/jobber-onboarding" component={BasicInfoPage} />
        <Route path="/testAuth" component={AuthGetCall} />


        <Route path="/splashpage" component={SplashPage} />
        <Route path="/careers" component={CareersPage} />
        <Route path="/products" component={ProductsPage} />
        <Route path="" component={SplashPage} />
      </Switch>     
    </Router>
  );
}

export default App;
