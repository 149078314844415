import React, { useEffect, useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid, Container, Paper, Alert, Stack } from '@mui/material';
import { RemoteExecutionServiceImpl, ServerResponse } from './RemoteExecutionServiceImpl';
import axios, { AxiosResponse } from 'axios';
import { AlertComp } from './alerts';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';


enum WorkyFileType {
	RESUME = 'RESUME',
	GOV_ID = 'GOV_ID',
	WHIMS_CERT = 'WHIMS_CERT',
	TD1_CANADA = 'TD1_CANADA',
	TD1_ONTARIO = 'TD1_ONTARIO',
	WORK_PERMIT = 'WORK_PERMIT',
	PROFILE_PICTURE = 'PROFILE_PICTURE',
	WORKY_JOBBER_CONTRACT = 'WORKY_JOBBER_CONTRACT',
	OTHER = 'OTHER',
}

interface FormResp {
	data: string;
	errorDetails: string;
}

const remoteCallObject = RemoteExecutionServiceImpl();

const UserFileUpload = ( fileType: WorkyFileType) => {
	const history = useHistory();
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [fileName, setFileName] = useState('');

	const [alert, setAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	const [isValidUploadType, setIsValidUploadType] = useState(true);

	useEffect(() => {
		console.log('state file: ', selectedFile);
	}, [selectedFile]);

	const handleFileChange = (event) => {
		const file = event.target.files?.[0];
		setSelectedFile(file);
		setFileName(file.name);
	};

	const checkFileType = (file: File, fileType: WorkyFileType): boolean => {
		console.log('checking file type');
		console.log('file' + file);

		// if its a resume and its not a pdf - alert
		if (fileType === WorkyFileType.RESUME && file.type !== 'application/pdf') {
			console.log(
				'block one, RESUME: worky file type is : ' +
					fileType +
					'actual file type is : ' +
					file.type
			);
			setAlert(true);
			setAlertText('Please upload a PDF file.');
			setIsValidUploadType(false);
			return false;
		}

		//if filetype is a gov id and its not a jpeg
		else if (fileType === WorkyFileType.GOV_ID) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
				console.log(
					'block two, GOVID: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a JPEG, JPG or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		} else if (fileType === WorkyFileType.PROFILE_PICTURE) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
				console.log(
					'block three, GOVID: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a JPEG, JPG or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		}

		// if filetype is work permit and its not a pdf/jpg/png
		else if (fileType === WorkyFileType.WORK_PERMIT) {
			if (
				file.type !== 'image/jpeg' &&
				file.type !== 'image/png' &&
				file.type !== 'application/pdf'
			) {
				console.log(
					'block four, WORKpermit: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a PDF, JPEG, JPG, or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		}
		return true;
	};

	const handleUploadSubmit = async (event: FormEvent) => {
		event.preventDefault();
		console.log('submit pressed');

		if (checkFileType(selectedFile, fileType)) {
			const formDataReq = new FormData();
			formDataReq.append('file', selectedFile, selectedFile.name);
			formDataReq.append('fileType', fileType);

			console.log('form data request body', formDataReq);

			try {
				const response: AxiosResponse<ServerResponse<FormResp, Error>> =
					await remoteCallObject.remotePOSTCallV2<ServerResponse<FormResp, Error>, FormData>({
						apiUrl: '/v1/document/file/upload',
						object: formDataReq,
						headers: { 'Content-Type': 'multipart/form-data' },
					});
				console.log('POST request successful:', response);

				if (response.status === 200 || response.status === 201 || response.status === 202) {
					console.log('redirected to homepage after successful submit');
					// history.push('/homepage');
					window.location.reload();
				}
			} catch (e) {
				if (e.response && e.response.status === 413) {
					setAlertText('File size is too large. Please upload a smaller file.');
					setAlert(true);
				} else {
					setAlertText('POST request failed');
					setAlert(true);
					console.error('POST request failed', e);
				}
			}
		} else {
			console.error('No file selected');
		}
	};

	return (
		<>
			<form onSubmit={handleUploadSubmit}>
				<div className='jobber-file-upload'>
					<input
						onChange={handleFileChange}
						className='inputfile'
						id='file'
						name='file'
						type='file'
						accept='.pdf, .png, .jpg, .jpeg'></input>
					<label id='label' htmlFor='file'>
						{fileName}
					</label>
				</div>
				<button type='submit' className='jobber-upload-next'>
					Next
				</button>
				{alert ? <AlertComp alertMsg={alertText} onClose={() => {}} /> : null}
			</form>
		</>
	);
};


const UserFileUploadV2 = (filename, fileType: WorkyFileType) => {

	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [fileName, setFileName] = useState('');

	const [alert, setAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	const [isValidUploadType, setIsValidUploadType] = useState(true);

	useEffect(() => {
		console.log('state file: ', selectedFile);
	}, [selectedFile]);

	const handleFileChange = (event) => {
		const file = event.target.files?.[0];
		setSelectedFile(file);
		setFileName(file.name);
	};

	const checkFileType = (file: File, fileType: WorkyFileType): boolean => {
		console.log('checking file type');
		console.log('file' + file);

		// if its a resume and its not a pdf - alert
		if (fileType === WorkyFileType.RESUME && file.type !== 'application/pdf') {
			console.log(
				'block one, RESUME: worky file type is : ' +
					fileType +
					'actual file type is : ' +
					file.type
			);
			setAlert(true);
			setAlertText('Please upload a PDF file.');
			setIsValidUploadType(false);
			return false;
		}

		//if filetype is a gov id and its not a jpeg
		else if (fileType === WorkyFileType.GOV_ID) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
				console.log(
					'block two, GOVID: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a JPEG, JPG or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		} else if (fileType === WorkyFileType.PROFILE_PICTURE) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
				console.log(
					'block three, GOVID: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a JPEG, JPG or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		}

		// if filetype is work permit and its not a pdf/jpg/png
		else if (fileType === WorkyFileType.WORK_PERMIT) {
			if (
				file.type !== 'image/jpeg' &&
				file.type !== 'image/png' &&
				file.type !== 'application/pdf'
			) {
				console.log(
					'block four, WORKpermit: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a PDF, JPEG, JPG, or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		}
		return true;
	};

	const handleUploadSubmit = async (event: FormEvent) => {
		event.preventDefault();
		console.log('submit pressed');

		if (checkFileType(selectedFile, fileType)) {
			const formDataReq = new FormData();
			formDataReq.append('file', selectedFile, selectedFile.name);
			formDataReq.append('fileType', fileType);

			console.log('form data request body', formDataReq);

			try {
				const response: AxiosResponse<ServerResponse<FormResp, Error>> =
					await remoteCallObject.remotePOSTCallV2<ServerResponse<FormResp, Error>, FormData>({
						apiUrl: '/v1/document/file/upload',
						object: formDataReq,
						headers: { 'Content-Type': 'multipart/form-data' },
					});
				console.log('POST request successful:', response);

				if (response.status === 200 || response.status === 201 || response.status === 202) {
					console.log('redirected to homepage after successful submit');
					// history.push('/homepage');
					window.location.reload();
				}
			} catch (e) {
				if (e.response && e.response.status === 413) {
					setAlertText('File size is too large. Please upload a smaller file.');
					setAlert(true);
				} else {
					setAlertText('POST request failed');
					setAlert(true);
					console.error('POST request failed', e);
				}
			}
		} else {
			console.error('No file selected');
		}
	};

	return (
		<form onSubmit={handleUploadSubmit}>
				<input
					onChange={handleFileChange}
					className='file-upload-container1'
					id='file'
					name='file'
					type='file'
					accept='.pdf, .png, .jpg, .jpeg'></input>
				<div className='filename'>{filename}</div>
				<div className='fileuploadicon'><CloudUploadOutlinedIcon style={{color:"#bbbbbb", fontSize:"45px"}}/></div>					<label className='file-upload-container1' htmlFor='file'>
					{fileName}
				</label>
			<button type='submit' className='jobber-upload-next'>Next</button>
			{alert ? <AlertComp alertMsg={alertText} onClose={() => {}} /> : null}
		</form>
	);
};


const UserFileUploadV3 = (filename, fileType: WorkyFileType, handleFileUploadSubmit) => {
	const history = useHistory();
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [fileName, setFileName] = useState('');

	const [alert, setAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	const [isValidUploadType, setIsValidUploadType] = useState(true);

	useEffect(() => {
		console.log('state file: ', selectedFile);
	}, [selectedFile]);

	const handleFileChange = (event) => {
		const file = event.target.files?.[0];
		setSelectedFile(file);
		setFileName(file.name);
	};

	const checkFileType = (file: File, fileType: WorkyFileType): boolean => {
		console.log('checking file type');
		console.log('file' + file);

		// if its a resume and its not a pdf - alert
		if (fileType === WorkyFileType.RESUME && file.type !== 'application/pdf') {
			console.log(
				'block one, RESUME: worky file type is : ' +
					fileType +
					'actual file type is : ' +
					file.type
			);
			setAlert(true);
			setAlertText('Please upload a PDF file.');
			setIsValidUploadType(false);
			return false;
		}

		//if filetype is a gov id and its not a jpeg
		else if (fileType === WorkyFileType.GOV_ID) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
				console.log(
					'block two, GOVID: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a JPEG, JPG or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		} else if (fileType === WorkyFileType.PROFILE_PICTURE) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
				console.log(
					'block three, GOVID: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a JPEG, JPG or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		}

		// if filetype is work permit and its not a pdf/jpg/png
		else if (fileType === WorkyFileType.WORK_PERMIT) {
			if (
				file.type !== 'image/jpeg' &&
				file.type !== 'image/png' &&
				file.type !== 'application/pdf'
			) {
				console.log(
					'block four, WORKpermit: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a PDF, JPEG, JPG, or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		}
		return true;
	};

	const handleUploadSubmit = async (event: FormEvent) => {
		event.preventDefault();
		console.log('submit pressed');

		if (checkFileType(selectedFile, fileType)) {
			const formDataReq = new FormData();
			formDataReq.append('file', selectedFile, selectedFile.name);
			formDataReq.append('fileType', fileType);

			console.log('form data request body', formDataReq);

			try {
				const response: AxiosResponse<ServerResponse<FormResp, Error>> =
					await remoteCallObject.remotePOSTCallV2<ServerResponse<FormResp, Error>, FormData>({
						apiUrl: '/v1/document/file/upload',
						object: formDataReq,
						headers: { 'Content-Type': 'multipart/form-data' },
					});
				console.log('POST request successful:', response);

				if (response.status === 200 || response.status === 201 || response.status === 202) {
					console.log('redirected to homepage after successful submit');
					// history.push('/homepage');
					window.location.reload();
				}
			} catch (e) {
				if (e.response && e.response.status === 413) {
					setAlertText('File size is too large. Please upload a smaller file.');
					setAlert(true);
				} else {
					setAlertText('POST request failed');
					setAlert(true);
					console.error('POST request failed', e);
				}
			}
		} else {
			console.error('No file selected');
		}
	};

	return (
		<form onSubmit={handleUploadSubmit}>
				<input
					onChange={handleFileChange}
					className='file-upload-container1'
					id='file'
					name='file'
					type='file'
					accept='.pdf, .png, .jpg, .jpeg'></input>
				<div className='filename'>{filename}</div>
				<div className='fileuploadicon'><CloudUploadOutlinedIcon style={{color:"#bbbbbb", fontSize:"45px"}}/></div>					<label className='file-upload-container1' htmlFor='file'>
					{fileName}
				</label>
			<button type='submit' className='jobber-upload-next'>Next</button>
			{alert ? <AlertComp alertMsg={alertText} onClose={() => {}} /> : null}
		</form>
	);
};



  

export { UserFileUpload, WorkyFileType , UserFileUploadV2, UserFileUploadV3, FormResp};
