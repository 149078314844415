import React, { useState } from 'react';
import { Alert, Stack } from '@mui/material';

interface AlertCompProps {
    alertMsg: string;
    onClose: () => void; // Define the type for onClose prop
}

const AlertComp: React.FC<AlertCompProps> = ({ alertMsg, onClose }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        onClose(); // Invoke the onClose callback passed from the parent component
    };

    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            {open && (
                <Alert onClose={handleClose} severity="error" sx={{ width: '92%'}}>
                    {alertMsg}
                </Alert>
            )}
        </Stack>
    );
};

export { AlertComp };
