import{ShiftStartEndDetails, WorkyTime} from './CommonInterfaces'

const convertWorkyTimeToEpochSec = (workyTime: WorkyTime): number =>{
    const dateObj = new Date();
    dateObj.setHours(workyTime.hour, workyTime.minute);

    const estEpochTime = new Date(
        dateObj.toLocaleString('en-US', { timeZone: 'America/New_York' })
    );

    return Math.floor(estEpochTime.getTime() / 1000);
} 


const convertDateToEpochSec = (dateObj: Date): number =>{
    

    const estEpochTime = new Date(dateObj.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    return Math.floor(estEpochTime.getTime() / 1000);
} 


const convertDatesToEpochSec = ({dates, workyStartTime, workyEndTime} 
    : {dates: Date[], workyStartTime: WorkyTime, workyEndTime: WorkyTime})
    : ShiftStartEndDetails[] =>{
    
        const epochTimes: ShiftStartEndDetails[] = dates.map((date) => {
            const dateStartObj = new Date(date);
            dateStartObj.setHours(workyStartTime.hour, workyStartTime.minute);
            
            const dateEndObj = new Date(date);
            dateEndObj.setHours(workyEndTime.hour, workyEndTime.minute);

        return {startDateTimeEpochSec: convertDateToEpochSec(dateStartObj), endDateTimeEpochSec: convertDateToEpochSec(dateEndObj)}
    });

    return epochTimes;
} 


export{convertWorkyTimeToEpochSec, convertDatesToEpochSec}