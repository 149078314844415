import React, { useState, useEffect } from 'react';
import './jobberhomepage2.css';
import './jobberaccountsettings.css';
import { MobileNav } from '../jobberViews/JobberCommonComponents';
import axios, { AxiosResponse } from 'axios';
import {
	ServerResponse,
	RemoteExecutionServiceImpl,
	RemoteGETCallInputObject,
	UserDetails,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import { AddressDto } from './objects/JobberRegistrationFormConstants';
import emailjs from 'emailjs-com';
import Modal from '@mui/material/Modal';
import { Tooltip } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();
const signOutURI = process.env.REACT_APP_IDP_SIGN_OUT_URL;

const openLinkInNewTab = (link) => {
    window.open(link);
};
const HiredStartOfOnBoarding = () => {
    console.log(' HiredStartOfOnBoarding ');

    
    const [selectedLanguage, setSelectedLanguage] = useState('english');
    console.log("selectedLanguage "+selectedLanguage)
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
      };

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const documentUrls = {
        'employment-standards-poster': {
          'english': 'https://files.ontario.ca/mltsd-employment-standards-poster-en-2020-09-08.pdf',
          'french' : 'https://files.ontario.ca/mltsd-employment-standards-poster-fr-2020-09-08.pdf',
          'arabic': 'https://files.ontario.ca/mltsd-employment-standards-poster-arabic-2020-09-08.pdf',
          'hindi': 'https://files.ontario.ca/mltsd-employment-standards-poster-hindi-2020-09-08.pdf',
          'portuguese': 'https://files.ontario.ca/mltsd-employment-standards-poster-portuguese-2020-09-08.pdf',
          'punjabi': 'https://files.ontario.ca/mltsd-employment-standards-poster-punjabi-2020-09-08.pdf',
          'spanish': 'https://files.ontario.ca/mltsd-employment-standards-poster-spanish-2020-09-08.pdf',
          'tagalog': 'https://files.ontario.ca/mltsd-employment-standards-poster-tagalog-2020-09-08.pdf',
          'thai': 'https://files.ontario.ca/mltsd-employment-standards-poster-thai-2020-09-08.pdf',
          'simplified-chinese': 'https://files.ontario.ca/mltsd-employment-standards-poster-simplified-chinese-2020-09-08.pdf',
          'traditional-chinese': 'https://files.ontario.ca/mltsd-employment-standards-poster-traditional-chinese-2020-09-08.pdf',
          'ukrainian': 'https://files.ontario.ca/mltsd-employment-standards-poster-ukrainian-2020-09-08.pdf',
          'urdu': 'https://files.ontario.ca/mltsd-employment-standards-poster-urdu-2020-09-08.pdf',
        },

        'hours-of-work-and-overtime-pay': {
            'english': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-en-2021-06-07.pdf',
            'french' : 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-fr-2021-06-07.pdf',
            'arabic': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-arabic-2021-06-07.pdf',
            'hindi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-hindi-2021-06-07.pdf',
            'portuguese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-portuguese-2021-06-07.pdf',
            'punjabi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-punjabi-2021-06-07.pdf',
            'spanish': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-spanish-2021-06-07.pdf',
            'tagalog': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-tagalog-2021-06-07.pdf',
            'thai': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-thai-2021-06-07.pdf',
            'simplified-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-sim-chinese-2021-06-07.pdf',
            'traditional-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-tra-chinese-2021-06-07.pdf',
            'ukrainian': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-uk-2021-06-07.pdf',
            'urdu': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-urdu-2021-06-07.pdf',
          },
          'temporary-help-agency-document': {
            'english': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-en-2021-06-07.pdf',
            'french': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-fr-2021-06-07.pdf',
            'arabic': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-arabic-2021-06-07.pdf',
            'hindi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-hindi-2021-06-07.pdf',
            'portuguese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-portuguese-2021-06-07.pdf',
            'punjabi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-punjabi-2021-06-07.pdf',
            'spanish': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-spanish-2021-06-07.pdf',
            'tagalog': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-tagalog-2021-06-07.pdf',
            'thai': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-thai-2021-06-07.pdf',
            'simplified-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-sim-chinese-2021-06-07.pdf',
            'traditional-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-tra-chinese-2021-06-07.pdf',
            'ukrainian': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-uk-2021-06-07.pdf',
            'urdu': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-urdu-2021-06-07.pdf',
          },
          'employment-standards-act': {
            'english': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-en-2021-06-07.pdf',
            'french' : 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-fr-2021-06-07.pdf',
            'arabic': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-arabic-2021-06-07.pdf',
            'hindi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-hindi-2021-06-07.pdf',
            'portuguese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-portuguese-2021-06-07.pdf',
            'punjabi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-punjabi-2021-06-07.pdf',
            'spanish': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-spanish-2021-06-07.pdf',
            'tagalog': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-tagalog-2021-06-07.pdf',
            'thai': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-thai-2021-06-07.pdf',
            'simplified-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-sim-chinese-2021-06-07.pdf',
            'traditional-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-tra-chinese-2021-06-07.pdf',
            'ukrainian': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-uk-2021-06-07.pdf',
            'urdu': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-urdu-2021-06-07.pdf',
          },
          
    };

    const getWhimisDoc = async () => {
    
        console.log("clicked whimis button")
        const getCallInput: RemoteGETCallInputObject = {
            apiUrl: backendApiBasePathLocal + 'v1/jobber/document/whims/',
            headers: {},
            queryParams: null,
        };
    
        const whimisResponse: AxiosResponse<ServerResponse<string, Error>> =
            await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);
    
        if (whimisResponse && whimisResponse.data && whimisResponse.data.data) {
            console.log("whimisResponse : "+JSON.stringify(whimisResponse.data.data))
            openLinkInNewTab(whimisResponse.data.data);
        }
        else {
            console.log("there was an error")
        }
    };

    const getDocs = (docName) => {
        const url = documentUrls[docName]?.[selectedLanguage];
    
        if (url) {
          openLinkInNewTab(url);
          console.log(`Opened ${docName} in ${selectedLanguage}`);
        } else {
          console.log(`Document URL not found for ${docName} in ${selectedLanguage}`);
        }
      };

    return (
        <div className='important-docs-container'>
            <div className='important-docs-container-inner'>
                    <div className='lang-selection2'>
                    <label style={{ marginRight: '5px', fontSize: '13px' }} htmlFor="language">Select a language:</label>
                        <select id="language" name="language" value={selectedLanguage} onChange={handleLanguageChange}>
                            <option value="english">English</option>
                            <option value="french">French</option>
                            <option value="arabic">Arabic</option>
                            <option value="hindi">Hindi</option>
                            <option value="portuguese">Portuguese</option>
                            <option value="punjabi">Punjabi</option>
                            <option value="spanish">Spanish</option>
                            <option value="tagalog">Tagalog</option>
                            <option value="thai">Thai</option>
                            <option value="simplified-chinese">Simplified Chinese</option>
                            <option value="traditional-chinese">Traditional Chinese</option>
                            <option value="ukrainian">Ukrainian</option>
                            <option value="urdu">Urdu</option>
                        </select>
                    </div>
                    <button onClick={() => getDocs("employment-standards-poster")} className='whimis-download-container'>
                        <div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
                        <div className='document-info'>Employment Standards Poster</div>
                        <div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
                    </button>

                    <button onClick={getWhimisDoc} className='whimis-download-container'>
                        <div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
                        <div className='document-info'>Worky WHIMIS</div>
                        <div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
                    </button>

                    <button onClick={() => getDocs("hours-of-work-and-overtime-pay")} className='whimis-download-container'>
                        <div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
                        <div className='document-info'>Work Hours & Overtime Pay</div>
                        <div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
                    </button>

                    <button onClick={() => getDocs("temporary-help-agency-document")} className='whimis-download-container'>
                        <div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
                        <div className='document-info'>Temporary Help Agency Information Document</div>
                        <div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
                    </button>

                    <button onClick={() => getDocs("employment-standards-act")} className='whimis-download-container'>
                        <div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
                        <div className='document-info'>Employment Standards Act</div>
                        <div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
                    </button>
                    </div>
                </div>

    );
}





const JobberImportantDocs = () => {
	


	return (
		<>
			<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />

			<div className='mobile-app'>
				<MobileNav />
                <HiredStartOfOnBoarding/>
			</div>
		</>
	);
};

export { JobberImportantDocs };
