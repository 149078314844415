import React, { useState, useEffect, FormEvent } from 'react';
import { JobberStatus } from '../common/CommonInterfaces';
import { JobberDTO,DirectDepositRequest } from './objects/JobberRegistrationFormConstants';
import {
	useMediaQuery,
	Grid,
	Container,
	Paper,
	Alert,
	Stack,
	Tooltip,
	Typography,
	Modal,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './jobberhomepage.css';
import UserDetailsForm from '../remoteExecution/jobber-ingress';
import { UserFileUpload, WorkyFileType, FormResp } from '../remoteExecution/UserFileUpload';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { AxiosResponse } from 'axios';
import { AlertComp } from '../remoteExecution/alerts';

import {
	ServerResponse,
	RemoteExecutionServiceImpl,
	RemoteGETCallInputObject,
} from '../remoteExecution/RemoteExecutionServiceImpl';
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl(); 

const case5Text = (
	<>
		What's Considered as Legal Proof? <br />
		1. Canadian Passport <br />
		2. Permanent Resident Card <br />
		3. Work Permit <br />
		4. Study Permit with Work Authorization <br />
		5. Open Work Permit <br />
		6. Confirmation of Permanent Residence (COPR) <br />
		7. Temporary Resident Visa (TRV) <br />
		8. Labour Market Impact Assessment (LMIA) Approval
	</>
);
const case4Text = (
	<>
		What's Considered an Official Government ID? <br />
		1. Driver's License <br />
		2. Ontario Photo Card <br />
		3. Canadian Passport <br />
		4. Health Card <br />
		5. Permanent Resident Card <br />
	</>
);


const UserFileUploadV4 = (loginPage, filename, fileType: WorkyFileType) => {

	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [fileName, setFileName] = useState('');

	const [alert, setAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	const [isValidUploadType, setIsValidUploadType] = useState(true);

	const handleFileChange = (event) => {
		const file = event.target.files?.[0];
		setSelectedFile(file);
		setFileName(file.name);
	};

	const checkFileType = (file: File, fileType: WorkyFileType): boolean => {
		console.log('checking file type');
		console.log('file' + file);

		// if its a resume and its not a pdf - alert
		if (fileType === WorkyFileType.RESUME && file.type !== 'application/pdf') {
			console.log(
				'block one, RESUME: worky file type is : ' +
					fileType +
					'actual file type is : ' +
					file.type
			);
			setAlert(true);
			setAlertText('Please upload a PDF file.');
			setIsValidUploadType(false);
			return false;
		}

		//if filetype is a gov id and its not a jpeg
		else if (fileType === WorkyFileType.GOV_ID) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
				console.log(
					'block two, GOVID: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a JPEG, JPG or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		} else if (fileType === WorkyFileType.PROFILE_PICTURE) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
				console.log(
					'block three, GOVID: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a JPEG, JPG or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		}

		// if filetype is work permit and its not a pdf/jpg/png
		else if (fileType === WorkyFileType.WORK_PERMIT) {
			if (
				file.type !== 'image/jpeg' &&
				file.type !== 'image/png' &&
				file.type !== 'application/pdf'
			) {
				console.log(
					'block four, WORKpermit: worky file type is : ' +
						fileType +
						'actual file type is : ' +
						file.type
				);
				setAlert(true);
				setAlertText('Please upload a PDF, JPEG, JPG, or PNG file.');
				setIsValidUploadType(false);
				return false;
			}
		}
		return true;
	};

	const handleUploadSubmit = async (event: FormEvent) => {
		event.preventDefault();
		console.log('submit pressed');

		if (checkFileType(selectedFile, fileType)) {
			const formDataReq = new FormData();
			formDataReq.append('file', selectedFile, selectedFile.name);
			formDataReq.append('fileType', fileType);

			console.log('form data request body', formDataReq);

			try {
				const response: AxiosResponse<ServerResponse<FormResp, Error>> =
					await remoteCallObject.remotePOSTCallV2<ServerResponse<FormResp, Error>, FormData>({
						apiUrl: '/v1/document/file/upload',
						object: formDataReq,
						headers: { 'Content-Type': 'multipart/form-data' },
					});
				console.log('POST request successful:', response);

				if (response.status === 200 || response.status === 201 || response.status === 202) {
					console.log('redirected to homepage after successful submit');
					window.location.reload();
				}
			} catch (e) {
				if (e.response && e.response.status === 413) {
					setAlertText('File size is too large. Please upload a smaller file.');
					setAlert(true);
				} else {
					setAlertText('POST request failed');
					setAlert(true);
					console.error('POST request failed', e);
				}
			}
		} else {
			console.error('No file selected');
		}
	};

	return (
		<form onSubmit={handleUploadSubmit}>
				<label style={{marginTop:"20px"}} className='file-upload-container' htmlFor='file'>
						<input
							onChange={handleFileChange}
							id='file'
							name='file'
							type='file'
							accept='.pdf, .png, .jpg, .jpeg'
						/>
						{fileName ? <div className='filename'>{fileName}</div> : <div className='filename'>{filename}</div>}
						<div className='fileuploadicon'>
							<CloudUploadOutlinedIcon style={{ color: '#bbbbbb', fontSize: '45px' }} />
						</div>
					</label>
				<div className='first-login-page-container'>
					<button className='first-login-page-left' disabled><WestIcon style={{color:"#4c4c4c", fontSize:"large"}} /></button>
					<div className='first-login-pages'>
						{loginPage === 1 ? <div className='dot' id='dot-active'></div> : <div className='dot'></div>}
						{loginPage === 2 ? <div className='dot' id='dot-active'></div> : <div className='dot'></div>}
						{loginPage === 3 ? <div className='dot' id='dot-active'></div> : <div className='dot'></div>}
						{loginPage === 4 ? <div className='dot' id='dot-active'></div> : <div className='dot'></div>}
						{loginPage === 5 ? <div className='dot' id='dot-active'></div> : <div className='dot'></div>}
					</div>
					
					{!fileName ?<Tooltip title="Please upload a file" placement='left'>
							<button type='submit' className='first-login-page-right' disabled><EastIcon style={{color:"#4c4c4c", fontSize:"large"}}/></button></Tooltip>:<button type='submit' className='first-login-page-right'><EastIcon style={{color:"#4c4c4c", fontSize:"large"}}/></button>}
				</div>
				{alert ? <AlertComp alertMsg={alertText} onClose={() => {}} /> : null}
			</form>
	);
};






const LoadingComponent = () => {
	return (
		<div className='loading-screen'>
			<button className='worky-loading-icon'>w</button>
			<div className='loading-text'>Loading</div>
		</div>
	);
};

//case1 - complete
const RegisteredOnCognitoCompletedContent = () => {
	console.log('Case1 : RegisteredOnCognitoCompletedContent ');
	return <div>{UserDetailsForm(false)}</div>;
};

// case2 - complete
const RegisteredSubmittedJobberRegFormContent = () => {
	console.log('Case1 : RegisteredSubmittedJobberRegFormContent ');
	return (
		<div className='ji-submit-success-screen2'>
			<div className='ji-submit-success-icon'></div>
			<div className='ji-submit-success-title'>Success!</div>
			<div className='ji-submit-success-subtext2'>
				<br />
				Your information was sent successfully. We are so happy to have you on board!
				<br />
				<br />
				<span style={{ fontWeight: '600', fontSize: '20px' }}>
					Please check your email for next steps!
				</span>
				<br />
				<br />
				The email will be titled:
				<span style={{ fontWeight: '600' }}> Worky Inc Registration Email</span>
			</div>
		</div>
	);
};

// case3: upload resume screen - complete
const RegisteredFullyVerifiedStillNeedsToSubmitFormsContentUploadResume = () => {
	console.log(' RegisteredFullyVerifiedStillNeedsToSubmitFormsContent ');
	return (
		<div className='jobber-upload-screen'>
			<div className='jobber-upload-title'>Upload Your Resume</div>
			<div className='jobber-upload-subtext'>
				Click below to Upload your file. Please upload a PDF file only. <span style={{fontWeight:600}}>Max file size: 3MB</span>
			</div>
			{UserFileUpload(WorkyFileType.RESUME)}
		</div>
	);
};

// case4:upload gov id screen - complete
const RegisteredFullyVerifiedUploadGovId = () => {
	console.log(' RegisteredFullyVerifiedUploadResume ');
	return (
		<div className='jobber-upload-screen'>
			<div className='jobber-upload-title'>Upload Your Official Government ID</div>
			<div className='jobber-upload-subtext'>
				Click below to Upload your file. Please upload a JPEG, JPG, or PNG. <span style={{fontWeight:600}}>Max file size: 3MB</span>
				<Tooltip
					title={<Typography sx={{ textAlign: 'left', fontSize: 12 }}>{case4Text}</Typography>}
					placement='right'>
					<InfoOutlinedIcon style={{ marginLeft: '10px', fontSize: 16 }} />
				</Tooltip>
			</div>
			{UserFileUpload(WorkyFileType.GOV_ID)}
		</div>
	);
};

// case5:upload legal proof of work screen - complete
const RegisteredFullyVerifiedUploadGovID = () => {
	console.log(' RegisteredFullyVerifiedUploadGovID ');
	return (
		<div className='jobber-upload-screen'>
			<div className='jobber-upload-title'>Upload Legal Proof that you Can Work in Canada</div>
			<div className='jobber-upload-subtext'>
				Click below to Upload your file. <span style={{fontWeight:600}}>Max file size: 3MB</span>
				<Tooltip
					title={<Typography sx={{ textAlign: 'left', fontSize: 12 }}>{case5Text}</Typography>}
					placement='right'>
					<InfoOutlinedIcon style={{ marginLeft: '10px', fontSize: 16 }} />
				</Tooltip>
			</div>
			{/* <UserFileUpload /> */}

			{UserFileUpload(WorkyFileType.WORK_PERMIT)}
		</div>
	);
};

// case6 : upload user profile pic
const RegisteredFullyVerifiedUploadProfilePicture = () => {
	console.log(' RegisteredFullyVerifiedUploadProfilePicture ');
	return (
		<div className='jobber-upload-screen'>
			<div className='jobber-upload-title'>Upload A Profile Picture</div>
			<div className='jobber-upload-subtext'>
				Click below to Upload your file. Please ensure it is workplace friendly. Please upload
				a JPEG, JPG, or PNG. <span style={{fontWeight:600}}>Max file size: 3MB</span>
			</div>
			{UserFileUpload(WorkyFileType.PROFILE_PICTURE)}
		</div>
	);
};

// case7: jobber homepage ting - todo
const RegisteredFullyVerifiedUploadLegalProofOfWork = () => {
	console.log(' RegisteredFullyVerifiedUploadLegalProofOfWork ');
	return (
		<>
			<div className='ji-submit-success-screen2'>
				<div className='ji-submit-success-icon'></div>
				<div className='ji-submit-success-title'>Success!</div>
				<div className='ji-submit-success-subtext2'>
					<br />
					<br />
					<br />
					<span style={{ fontWeight: '600', fontSize: '20px' }}>You're all set!</span>
					<br />
					<br />
					<span style={{ fontWeight: '500' }}>We're getting you set up on our end.</span>{' '}
					<br />
					Our team will reach out to you shortly with open shifts!
				</div>
			</div>
		</>
	);
};

// case8 - complete
const RegistrationError = () => {
	console.log(' RegistrationError ');
	return (
		<div className='ji-submit-success-screen2'>
			<div className='ji-submit-nosuccess-icon'></div>
			<div className='ji-submit-success-title2'>Something Went Wrong</div>
			<div className='ji-submit-success-subtext2'>
				<br />
				This is strange. Your information was not processed correctly.
				<br />
				<br />
				<span style={{ fontWeight: '500' }}>Please reach out to us: info@worky.pro</span>
			</div>
		</div>
	);
};

const openLinkInNewTab = (link) => {
	window.open(link);
};


const getWhimisDoc = async () => {
    
	console.log("clicked whimis button")
	const getCallInput: RemoteGETCallInputObject = {
		apiUrl: backendApiBasePathLocal + 'v1/jobber/document/whims/',
		headers: {},
		queryParams: null,
	};

	const whimisResponse: AxiosResponse<ServerResponse<string, Error>> =
		await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

	if (whimisResponse && whimisResponse.data && whimisResponse.data.data) {
		console.log("whimisResponse : "+JSON.stringify(whimisResponse.data.data))
		openLinkInNewTab(whimisResponse.data.data);
	}
	else {
		console.log("there was an error")
	}
};



	const confirmWorkyWhimis = async () => {
		// confirmJobberWHIMS
		try {
			const response: AxiosResponse<ServerResponse<String, Error>> =
				await remoteCallObject.remotePOSTCallV2<ServerResponse<String, Error>, String>({
					apiUrl: '/v1/jobber/confirmjobberwhims',
					object: '{}',
					headers: { 'Content-Type': 'application/json' },
				});
			console.log('POST request successful:', response);

			if (response.status === 200 || response.status === 201 || response.status === 202) {
				console.log('redirected to homepage after successful submit');
				// getJobberDTO();
				window.location.reload();
			}
		} catch (e) {
			if (e.response && e.response.status === 413) {
				console.log("error confirming whimis", e)
			} else {
				console.log("error confirming whimis", e)
			}
		}


	}



const HiredStartOfOnBoarding = () => {
	console.log(' HiredStartOfOnBoarding ');

	
	const [selectedLanguage, setSelectedLanguage] = useState('english');
	console.log("selectedLanguage "+selectedLanguage)
	const handleLanguageChange = (event) => {
		setSelectedLanguage(event.target.value);
	  };

	const [isChecked, setIsChecked] = useState(false);
	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const documentUrls = {
		'employment-standards-poster': {
		  'english': 'https://files.ontario.ca/mltsd-employment-standards-poster-en-2020-09-08.pdf',
		  'french' : 'https://files.ontario.ca/mltsd-employment-standards-poster-fr-2020-09-08.pdf',
		  'arabic': 'https://files.ontario.ca/mltsd-employment-standards-poster-arabic-2020-09-08.pdf',
		  'hindi': 'https://files.ontario.ca/mltsd-employment-standards-poster-hindi-2020-09-08.pdf',
		  'portuguese': 'https://files.ontario.ca/mltsd-employment-standards-poster-portuguese-2020-09-08.pdf',
		  'punjabi': 'https://files.ontario.ca/mltsd-employment-standards-poster-punjabi-2020-09-08.pdf',
		  'spanish': 'https://files.ontario.ca/mltsd-employment-standards-poster-spanish-2020-09-08.pdf',
		  'tagalog': 'https://files.ontario.ca/mltsd-employment-standards-poster-tagalog-2020-09-08.pdf',
		  'thai': 'https://files.ontario.ca/mltsd-employment-standards-poster-thai-2020-09-08.pdf',
		  'simplified-chinese': 'https://files.ontario.ca/mltsd-employment-standards-poster-simplified-chinese-2020-09-08.pdf',
		  'traditional-chinese': 'https://files.ontario.ca/mltsd-employment-standards-poster-traditional-chinese-2020-09-08.pdf',
		  'ukrainian': 'https://files.ontario.ca/mltsd-employment-standards-poster-ukrainian-2020-09-08.pdf',
		  'urdu': 'https://files.ontario.ca/mltsd-employment-standards-poster-urdu-2020-09-08.pdf',
		},

		'hours-of-work-and-overtime-pay': {
			'english': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-en-2021-06-07.pdf',
			'french' : 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-fr-2021-06-07.pdf',
			'arabic': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-arabic-2021-06-07.pdf',
			'hindi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-hindi-2021-06-07.pdf',
			'portuguese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-portuguese-2021-06-07.pdf',
			'punjabi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-punjabi-2021-06-07.pdf',
			'spanish': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-spanish-2021-06-07.pdf',
			'tagalog': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-tagalog-2021-06-07.pdf',
			'thai': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-thai-2021-06-07.pdf',
			'simplified-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-sim-chinese-2021-06-07.pdf',
			'traditional-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-tra-chinese-2021-06-07.pdf',
			'ukrainian': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-uk-2021-06-07.pdf',
			'urdu': 'https://files.ontario.ca/mltsd-june-2021/mltsd-work-and-overtime-pay-urdu-2021-06-07.pdf',
		  },
		  'temporary-help-agency-document': {
			'english': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-en-2021-06-07.pdf',
			'french': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-fr-2021-06-07.pdf',
			'arabic': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-arabic-2021-06-07.pdf',
			'hindi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-hindi-2021-06-07.pdf',
			'portuguese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-portuguese-2021-06-07.pdf',
			'punjabi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-punjabi-2021-06-07.pdf',
			'spanish': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-spanish-2021-06-07.pdf',
			'tagalog': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-tagalog-2021-06-07.pdf',
			'thai': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-thai-2021-06-07.pdf',
			'simplified-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-sim-chinese-2021-06-07.pdf',
			'traditional-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-tra-chinese-2021-06-07.pdf',
			'ukrainian': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-uk-2021-06-07.pdf',
			'urdu': 'https://files.ontario.ca/mltsd-june-2021/mltsd-temporary-help-agency-assignment-employees-urdu-2021-06-07.pdf',
		  },
		  'employment-standards-act': {
			'english': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-en-2021-06-07.pdf',
			'french' : 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-fr-2021-06-07.pdf',
			'arabic': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-arabic-2021-06-07.pdf',
			'hindi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-hindi-2021-06-07.pdf',
			'portuguese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-portuguese-2021-06-07.pdf',
			'punjabi': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-punjabi-2021-06-07.pdf',
			'spanish': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-spanish-2021-06-07.pdf',
			'tagalog': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-tagalog-2021-06-07.pdf',
			'thai': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-thai-2021-06-07.pdf',
			'simplified-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-sim-chinese-2021-06-07.pdf',
			'traditional-chinese': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-tra-chinese-2021-06-07.pdf',
			'ukrainian': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-uk-2021-06-07.pdf',
			'urdu': 'https://files.ontario.ca/mltsd-june-2021/mltsd-your-rights-as-a-foreign-national-urdu-2021-06-07.pdf',
		  },
		  
	};


	const getDocs = (docName) => {
		const url = documentUrls[docName]?.[selectedLanguage];
	
		if (url) {
		  openLinkInNewTab(url);
		  console.log(`Opened ${docName} in ${selectedLanguage}`);
		} else {
		  console.log(`Document URL not found for ${docName} in ${selectedLanguage}`);
		}
	  };
// here

	console.log(' HiredNeedsToSubmitWHIMSContent ');
	return (
		<div className='first-login-container'>
			<div className='first-login-worky'>worky</div>
			<div className='first-login-info-holder'>
				<div className='page-container'>
					<div className='first-login-title'>Keeping You Informed</div>
					{/* <div className='page-title'>Required Documents</div> */}
					<div className='page-subtitle'>Please carefully read and understand the concepts in the documents below.</div>
					<div className='lang-selection'>
					<label style={{ marginRight: '5px', fontSize: '13px' }} htmlFor="language">Select a language:</label>
						<select id="language" name="language" value={selectedLanguage} onChange={handleLanguageChange}>
							<option value="english">English</option>
							<option value="french">French</option>
							<option value="arabic">Arabic</option>
							<option value="hindi">Hindi</option>
							<option value="portuguese">Portuguese</option>
							<option value="punjabi">Punjabi</option>
							<option value="spanish">Spanish</option>
							<option value="tagalog">Tagalog</option>
							<option value="thai">Thai</option>
							<option value="simplified-chinese">Simplified Chinese</option>
							<option value="traditional-chinese">Traditional Chinese</option>
							<option value="ukrainian">Ukrainian</option>
							<option value="urdu">Urdu</option>
						</select>
					</div>
					<button onClick={() => getDocs("employment-standards-poster")} className='whimis-download-container'>
						<div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
						<div className='document-info'>Employment Standards Poster</div>
						<div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
					</button>

					<button onClick={getWhimisDoc} className='whimis-download-container'>
						<div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
						<div className='document-info'>Worky WHIMIS</div>
						<div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
					</button>

					<button onClick={() => getDocs("hours-of-work-and-overtime-pay")} className='whimis-download-container'>
						<div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
						<div className='document-info'>Work Hours & Overtime Pay</div>
						<div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
					</button>

					<button onClick={() => getDocs("temporary-help-agency-document")} className='whimis-download-container'>
						<div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
						<div className='document-info'>Temporary Help Agency Information Document</div>
						<div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
					</button>

					<button onClick={() => getDocs("employment-standards-act")} className='whimis-download-container'>
						<div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
						<div className='document-info'>Employment Standards Act</div>
						<div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
					</button>



					<div className='whimis-checkbox-container'>
						<input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
						<div className='whimis-confirmation-text'>
							I confirm receipt of WHMIS training, access, and understanding of: Employment Standards Poster, Worky WHMIS, Work Hours & Overtime Pay, Temporary Help Agency Info, and Employment Standards Act. I acknowledge responsibility to familiarize and adhere to the content.
						</div>
					</div>

					<div className='first-login-page-container'>
						<button className='first-login-page-left' disabled>
							<WestIcon style={{ color: "#ebebeb", fontSize: "large" }} />
						</button>

						<div className='first-login-pages'>
							<div className='dot' id='dot-active'></div>
							<div className='dot'></div>
							<div className='dot'></div>
							<div className='dot'></div>
							<div className='dot'></div>
						</div>

						{isChecked ?
							<button onClick={confirmWorkyWhimis} className='first-login-page-right'>
								<EastIcon style={{ color: "#4c4c4c", fontSize: "large" }} />
							</button> :
							<Tooltip title="Please read the documents and accept the checkbox above" placement='left'>
							<button className='first-login-page-right' disabled>
								<EastIcon style={{ color: "#4c4c4c", fontSize: "large" }} />
							</button>
							</Tooltip>
						}
					</div>

				</div>
			</div>
		</div>
	);
}

const getTDCanada = async () => {
	console.log("clicked TD1CANADA button")
	const getCallInput: RemoteGETCallInputObject = {
		apiUrl: backendApiBasePathLocal + 'v1/jobber/document/td1/ca',
		headers: {},
		queryParams: null,
	};

	const TDCanada: AxiosResponse<ServerResponse<string, Error>> =
		await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

	if (TDCanada && TDCanada.data && TDCanada.data.data) {
		console.log("TDCanada : "+JSON.stringify(TDCanada.data.data))
		openLinkInNewTab(TDCanada.data.data);
	}
	else {
		console.log("there was an error")
	}
};

const getJobberContract = async () => {
	console.log("clicked JobberContract button")
	const getCallInput: RemoteGETCallInputObject = {
		apiUrl: backendApiBasePathLocal + 'v1/jobber/document/workyjobbercontract',
		headers: {},
		queryParams: null,
	};

	const JobberContract: AxiosResponse<ServerResponse<string, Error>> =
		await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

	if (JobberContract && JobberContract.data && JobberContract.data.data) {
		console.log("JobberContract : "+JSON.stringify(JobberContract.data.data))
		openLinkInNewTab(JobberContract.data.data);
	}
	else {
		console.log("there was an error")
	}
};



const HiredSubmittedWhimisConfirmation = () => {
	console.log(' HiredSubmittedWhimisConfirmation ');

	return(
		<div className='first-login-container'>
			<div className='first-login-worky'>worky</div>
			<div className='first-login-info-holder'>
				<div className='page-container'>

					<div className='first-login-title'>Getting You Registered</div>
					<div className='page-title'>Tax Documents</div>
					<div className='page-subtitle'>Please download and fill out tax documents below.</div>

					<button onClick={getTDCanada} className='whimis-download-container'>
						<div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
						<div className='document-info'>canada_td1_2023.pdf</div>
						<div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
					</button>

					{UserFileUploadV4(2,'Canada Tax Form', WorkyFileType.TD1_CANADA)}
				</div>
			</div>
		</div>
	)
};


const HiredNeedsToSubmitTD1CADContent = () => {
	console.log(' HiredNeedsToSubmitTD1CADContent ');
	return(
		<div className='first-login-container'>
			<div className='first-login-worky'>worky</div>
			<div className='first-login-info-holder'>
				<div className='page-container'>
					<div className='first-login-title'>Getting You Registered</div>
					<div className='page-title'>Tax Documents</div>
					<div className='page-subtitle'>Please download and fill out tax documents below.</div>
					<button onClick={getTDOntario} className='whimis-download-container'>
						<div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
						<div className='document-info'>ontario_td1_2023.pdf</div>
						<div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
					</button>
					{UserFileUploadV4(3,'Ontario Tax Form', WorkyFileType.TD1_ONTARIO)}
				</div>
			</div>
		</div>
	);
	
};



const HiredNeedsToSubmitTD1ONContent = () => {
	console.log(' HiredNeedsToSubmitTD1ONContent ');
	const [transitNumber, setTransitNumber] = useState(Number);
const [institutionNumber, setInstitutionNumber] = useState(Number);
const [accountNumber, setAccountNumber] = useState(Number);
const [cancelModalOpen, setCancelModalOpen] = useState(false);
const handleCancelModalClose = () => setCancelModalOpen(false)

const handleDD = async () =>{

	const directDepositRequestBody: DirectDepositRequest = {
		bankNumber: institutionNumber,
		transitBranchNumber: transitNumber,
		accountNumber: accountNumber,
	}

	try {
		const response: AxiosResponse<ServerResponse<String, Error>> =
			await remoteCallObject.remotePOSTCallV2<ServerResponse<String, Error>, DirectDepositRequest>({
				apiUrl: '/v1/jobber/directdepositinfo',
				object: directDepositRequestBody,
				headers: { 'Content-Type': 'application/json' },
			});
		console.log('POST request successful:', response);

		if (response.status === 200 || response.status === 201 || response.status === 202) {
			console.log('redirected to homepage after successful submit');
			window.location.reload();
		}
	} catch (e) {
		if (e.response && e.response.status === 413) {
			console.log("error confirming whimis", e)
		} else {
			console.log("error confirming whimis", e)
		}
	}
}

const handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
	e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
  };
 
	return(
		<div className='first-login-container'>

			<Modal open={cancelModalOpen} onClose={handleCancelModalClose}>
				<div className='cancel-shift-modal'>
					<div className='srstatus-modal-title'>Is this the correct Direct Deposit Info?</div>

					<div className='modal-dd-info'>
						<div className='direct-deposit-title'>Transit Number:  <span className='simple-bold'> {transitNumber}</span></div>
						<div className='direct-deposit-title'>Institution Number:   <span className='simple-bold'> {institutionNumber}</span></div> 
						<div className='direct-deposit-title'>Account Number:   <span className='simple-bold'> {accountNumber}</span></div> 
					</div>

					<div className='cancel-shift-button-holder'>
						<button onClick={handleCancelModalClose} id='cancel-shift-button-yes' className='cancel-shift-button'>No</button>
						<button onClick={handleDD} id='cancel-shift-button-no' className='cancel-shift-button'>Yes</button>
					</div>
				</div>
			</Modal>




			<div className='first-login-worky'>worky</div>
			<div className='first-login-info-holder'>
				<div className='page-container'>
					<div className='first-login-title'>Getting You Paid</div>
					<div className='page-title'>Direct Deposit Info</div>
					<div className='page-subtitle'>Please carefully input your direct deposit information.</div>
					<div className='direct-deposit-container'>
						<div className='direct-deposit-title'>Transit Number</div>
						<input
							pattern="[0-9]*"
							type="text"
							onInput={handleNumericInput}
							onBlur={(e) => setTransitNumber(Number(e.target.value))}
							className='direct-deposit-info'
						/>
						<div className='direct-deposit-title'>Institution Number</div>
						<input
							pattern="[0-9]*"
							type="text"
							onBlur={(e) => setInstitutionNumber(Number(e.target.value))}
							onInput={handleNumericInput}
							className='direct-deposit-info'
						/>
						<div className='direct-deposit-title'>Account Number</div>
						<input
							pattern="[0-9]*"
							type="text"
							onInput={handleNumericInput}
							onBlur={(e) => setAccountNumber(Number(e.target.value))}
							className='direct-deposit-info'
						/>
					</div>
					<div className='first-login-page-container'>
						<button className='first-login-page-left' disabled>
							<WestIcon style={{ color: "#4c4c4c", fontSize: "large" }} />
						</button>
						<div className='first-login-pages'>
							<div className='dot' ></div>
							<div className='dot' ></div>
							<div className='dot' ></div>
							<div className='dot' id='dot-active'></div>
							<div className='dot' ></div> 
						</div>

						{!(transitNumber&&institutionNumber&&accountNumber) ?
						<Tooltip title='Please input your direct deposit info' placement='left'>
							<button onClick={handleDD} className='first-login-page-right' disabled>
								<EastIcon style={{ color: "#4c4c4c", fontSize: "large" }} />
							</button>
						</Tooltip>:
						<button onClick={()=>setCancelModalOpen(true)} className='first-login-page-right'>
							<EastIcon style={{ color: "#4c4c4c", fontSize: "large" }} />
						</button>
						}
					</div>
				</div>
			</div>
		</div>

	)
};


const CancelledContent = () => {
	console.log(' CancelledContent ');
	return (
		<div className='ji-submit-success-screen2'>
			<br />
			Registration Cancelled
			<br />
		</div>
	);
};

const getTDOntario = async () => {
	console.log("clicked TD1ONTARIO button")
	const getCallInput: RemoteGETCallInputObject = {
		apiUrl: backendApiBasePathLocal + 'v1/jobber/document/td1/ontario',
		headers: {},
		queryParams: null,
	};

	const TDOntario: AxiosResponse<ServerResponse<string, Error>> =
		await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

	if (TDOntario && TDOntario.data && TDOntario.data.data) {
		console.log("TDOntario : "+JSON.stringify(TDOntario.data.data))
		openLinkInNewTab(TDOntario.data.data);
	}
	else {
		console.log("there was an error")
	}
};


const HiredSubmittedWorkyJobberContractContent = () => {
	console.log(' HiredSubmittedWorkyJobberContractContent ');
	return (
		<div className='first-login-container'>
			<div className='first-login-worky'>worky</div>
			<div className='first-login-info-holder'>
				<div className='first-login-title'>Verifying, Hang Tight</div>
				<div className='page-title'>We are verifying your account details. We will reach out as soon as you're ready!</div>
				<div className='document-verification'></div>
			</div>
		</div>
	);
};

const HiredSubmittedDirectDepositContent = () => {
	console.log(' HiredSubmittedDirectDepositContent ');

	return(
		<div className='first-login-container'>
			<div className='first-login-worky'>worky</div>
			<div className='first-login-info-holder'>
				<div className='page-container'>

					<div className='first-login-title'>Getting You Hired</div>
					<div className='page-title'>Employment Contract</div>
					<div className='page-subtitle'>Please Download, Sign and Upload employment contract.</div>

					<button onClick={getJobberContract} className='whimis-download-container'>
						<div className='document-icon'><DescriptionOutlinedIcon style={{ color: "#bbbbbb", fontSize: "22.5px" }} /></div>
						<div className='document-info'>worky-employee.pdf</div>
						<div className='download-icon'><FileDownloadOutlinedIcon style={{ color: "#2c68bd", fontSize: "20px" }} /></div>
					</button>

					{UserFileUploadV4(5,'Worky Employee Contract', WorkyFileType.WORKY_JOBBER_CONTRACT)}

				</div>
			</div>
		</div>
	)
};

// conditional render switch
const RegistrationStatusSwitch = (jobberStatus) => {
	switch (jobberStatus) {
		case JobberStatus.REGISTERED_ON_COGNITO_COMPLETED:
			return <RegisteredOnCognitoCompletedContent />;

		case JobberStatus.REGISTERED_SUBMITTED_JOBBER_REG_FORM:
			return <RegisteredSubmittedJobberRegFormContent />;

		case JobberStatus.REGISTERED_FULLY_VERIFIED_STILL_NEEDS_TO_SUBMIT_FORMS:
			return <RegisteredFullyVerifiedStillNeedsToSubmitFormsContentUploadResume />;

		case JobberStatus.REGISTERED_FULLY_VERIFIED_UPLOADED_RESUME:
			return <RegisteredFullyVerifiedUploadGovId />;

		case JobberStatus.REGISTERED_FULLY_VERIFIED_UPLOADED_GOV_ID:
			return <RegisteredFullyVerifiedUploadGovID />;

		case JobberStatus.REGISTERED_FULLY_VERIFIED_UPLOADED_LEGAL_PROOF_OF_WORK:
			return <RegisteredFullyVerifiedUploadProfilePicture />;

		case JobberStatus.REGISTERED_FULLY_VERIFIED_UPLOAD_PROFILE_PICTURE:
			return <RegisteredFullyVerifiedUploadLegalProofOfWork />;


		// TODO intermediray state while we try to hire them
		case JobberStatus.HIRED_START_OF_ON_BOARDING:
			return <HiredStartOfOnBoarding />; //shows whimis screen

		case JobberStatus.HIRED_SUBMITTED_WHIMS_CONFIRMATION:
			return <HiredSubmittedWhimisConfirmation />; // shows td1 cad

		case JobberStatus.HIRED_UPLOADED_TD1_CAD:
			return <HiredNeedsToSubmitTD1CADContent />; // shows td1 ontario

		case JobberStatus.HIRED_UPLOADED_TD1_ON:
			return <HiredNeedsToSubmitTD1ONContent />; // shows dd screen

		case JobberStatus.HIRED_SUBMITTED_DIRECT_DEPOSIT:
			return <HiredSubmittedDirectDepositContent />; // shows contract screen

		case JobberStatus.HIRED_SUBMITTED_WORKY_JOBBER_CONTRACT:
			return <HiredSubmittedWorkyJobberContractContent />;

		case JobberStatus.FULLY_HIRED:
			window.location.assign('/jobber-homepage');

		case JobberStatus.CANCELLED:
			return <CancelledContent />;

		default:
			return <RegistrationError />;
	}
};

// conditional render switch

const JobberHomePageDesktop = ({ jobberStatus }) => {

	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 920);

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(window.innerWidth > 920);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const ImageDiv = () => {
		if (isDesktop) {
			return (
				<Grid item xs={false} sm={4} md={4}>
					<div className='jhp-worky-logo'>worky</div>
					<div className='jhp-title'>Welcome to Worky, this will only take a minute :)</div>
					<img src='/coin-walk.png' alt='Coin Walk' style={{ width: '75%', height: '75%' }} />
				</Grid>
			);
		} else {
			return <></>;
		}
	};

	const MobileTitleDiv = () => {
		if (!isDesktop) {
			return (
				<>
					<div className='jhp-worky-logo2'>worky</div>
					<div className='jhp-title'>Welcome to Worky, this will only take a minute :)</div>
				</>
			);
		} else {
			return <></>;
		}
	};

	return (
		<><div className='ji-total-container'>
			<Grid
				className='grid1'
				container
				spacing={0}
				sx={{ minHeight: '90vh', height: 'auto', width: '94vw' }}
				alignItems='center'
				justifyContent='center'
				>
				{ImageDiv()}
				<Grid item xs={12} sm={8} md={8}>
					{MobileTitleDiv()}
					{RegistrationStatusSwitch(jobberStatus)}
				</Grid>
			</Grid></div>
		</>
	);
};

export { JobberHomePageDesktop };
