import axios, { AxiosResponse } from 'axios';
import { GenerateCurrentEpochSeconds } from '../utils/GeneralUtils';
import { JobberStatus } from '../common/CommonInterfaces';
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;
//TODO NEED TO MODIFY REFRESH TOKE URL AS IT IS FAILING
const workyServerREFRESHTOKENAPIURL = process.env.REACT_APP_BACK_END_SERVER_REFRESH_URI_LOCAL;
const BACK_END_SERVER_BASE_PATH_LOCAL = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const workyServerAPIURL = process.env.REACT_APP_BACK_END_SERVER_SIGN_IN_URI_LOCAL;
const redirectURL = process.env.REACT_APP_BACK_END_SERVER_SIGN_IN_REDIRECT_URL;
// import { WorkyServiceResponseStatus, ServerErrorDetails } from './RemoteExeConsts.ts';

/**
 *
 *
 * Interfaces Defined
 *
 *
 *
 */

/**
 * Defines the input obj for the Get call
 * Ensure that the URL is just the base url w/ out hardcoded query params
 */
interface RemoteGETCallInputObject {
	apiUrl: string;
	queryParams: Record<string, string>;
	headers: Record<string, string>;
}
/**
 * Defines the input obj for the POST call
 * The variable N defines the type of input object that is sent in the request
 */
interface RemotePOSTCallInputObject<N> {
	apiUrl: string;
	headers: Record<string, string>;
	object: N;
}

enum WorkyServiceResponseStatus {
	SUCCESS = 'SUCCESS',
	RECOVERABLE = 'RECOVERABLE',
	UNRECOVERABLE = 'UNRECOVERABLE',
	UNDEFINED = 'UNDEFINED',
}

interface ServerErrorDetails<E> {
	message: string;
	status: WorkyServiceResponseStatus | null;
	clazz: new (...args: any[]) => E;
}

interface ServerResponse<T, E> {
	data: T | null;
	errorDetails: ServerErrorDetails<E> | null;
}

/**
 * Defines the input obj for the POST call
 * The variable N defines the type of input object that is sent in the request
 */
interface RemotePOSTCallInputObject<N> {
	apiUrl: string;
	headers: Record<string, string>;
	object: N;
}

interface RemoteExecutionObject {
	remoteGETCallV2: <T>(getCallInput: RemoteGETCallInputObject) => Promise<AxiosResponse<T>>;
	remotePOSTCallV2: <T, N>(
		postCallInput: RemotePOSTCallInputObject<N>
	) => Promise<AxiosResponse<T>>;
}

/**
 *
 *
 * Interfaces Defined
 *
 *
 *
 */

/**
 *
 *
 * Globally used API defined
 *
 *
 */
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
console.log('backendApiBasePathLocal ' + backendApiBasePathLocal);
const api = axios.create({
	baseURL: backendApiBasePathLocal,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		// other default headers
	},
});

/**
 *
 *
 * Globally used API defined
 *
 *
 */

// Add an Axios interceptor to include the access token in the request headers

const handleRetrieveNewTokens = async (inputRefreshToken: string) => {
	if (inputRefreshToken === null || inputRefreshToken === undefined) {
		inputRefreshToken = localStorage.getItem('refresh_token');
	}
	const resp: AxiosResponse<
		ServerResponse<WorkyAuthTokenResponse, Error>,
		any
	> = await AuthGetCallRefreshToken({
		refreshToken: inputRefreshToken,
	});
	if (!resp) {
		console.log('unable to retrieve new tokens: {}', resp);
		return false;
	}
	UpdateLocalCacheWOAuthInfo({ basicDetails: null, input: resp.data.data });
	return true;
};

api.interceptors.request.use(async (config) => {
	const requestPath = config.url;
	// Corresponds to careers.scoutsecond.server.config.AuthConfig
	if (requestPath && requestPath.includes('/auth')) {
		console.log('Call to unsecured backend API, not appending tokens: ', requestPath);
		return config;
	}
	// Replace 'your_access_token' with the actual access token or retrieve it from your state management
	const accessToken = localStorage.getItem('access_token');
	const expireTime = localStorage.getItem('expire_time');
	const refreshToken = localStorage.getItem('refresh_token');
	const isExpired = isExpiredCalc(expireTime);
	if (accessToken && !isExpired) {
		console.log(' access token present in inteceptor');
		config.headers.Authorization = `Bearer ${accessToken}`;
	} else if (accessToken && isExpired) {
		await handleRetrieveNewTokens(refreshToken);
	} else {
		console.log(' token not present in interceptor');
		// window.location.assign(workyServerIDPSIGNINURL);
	}

	return config;
});

/**
 * This method is how Worky makes remote GET calls
 * The parameter of T defines what the expected return obj is of this API call
 * The method returns a Promise obj of type AxiosResponse which has the return data of type T
 * @param getCallInput is the input GET obj
 * @returns the expected return obj of Type T
 */
const remoteGETCallV2 = async <T>(
	getCallInput: RemoteGETCallInputObject
): Promise<AxiosResponse<T>> => {
	try {
		console.log(`Attempt of remote GETCallV2: ${getCallInput.apiUrl} `);
		const response: AxiosResponse<T> = await api.get(getCallInput.apiUrl, {
			params: getCallInput.queryParams,
			headers: getCallInput.headers,
		});
		console.log(
			`Result of remote GETCallV2: ${
				getCallInput.apiUrl
			} w/ typeof: ${typeof response.data} result`,
			response.data
		);
		// return {data: response.data, status: response.status};
		return response;
	} catch (error) {
		// Check if the error status code is unauthorized (HTTP status code 401)
		if (error.response && error.response.status === 401) {
			// Redirect the user to the login page
			console.log('During: {} User is not signed in redirecting to relogin', getCallInput);
			await handleRetrieveNewTokens(localStorage.getItem('refresh_token'));
			await retryGETCall(getCallInput);
			return;
		} else {
			console.log(
				`Error while making remote get call to: ${getCallInput.apiUrl} w/ error:`,
				error
			);
		}

		throw error;
	}
};

/**
 * This method is how Worky makes remote POST calls
 * It accepts 2 argument types of T and N
 * T is the expected return type
 * N is the expected input object type(the POST.body.data type)
 * @param postCallInput default post call obj
 * @returns the expected return obj of Type T
 */
const remotePOSTCallV2 = async <T, N>(
	postCallInput: RemotePOSTCallInputObject<N>
): Promise<AxiosResponse<T>> => {
	try {
		console.log(
			`Making remotePOSTCallV2 to: ${postCallInput.apiUrl} w/ data: `,
			postCallInput.object
		);
		const response: AxiosResponse<T> = await api.post(
			postCallInput.apiUrl,
			postCallInput.object,
			{
				headers: postCallInput.headers,
			}
		);
		console.log(
			`Result of remote remotePOSTCallV2 : ${postCallInput.apiUrl} w/ result`,
			response.data
		);
		return response;
	} catch (error) {
		// Check if the error status code is unauthorized (HTTP status code 401)
		if (error.response && error.response.status === 401) {
			// Redirect the user to the login page
			console.log(
				'During post: {} User is not signed in redirecting to relogin',
				postCallInput
			);
			//Get new tokens, using refresh call
			await handleRetrieveNewTokens(localStorage.getItem('refresh_token'));
			await retryPOSTCall(postCallInput);
			//make the same call again
			//if this fails then you can really say the call failed
			// window.location.assign(workyServerIDPSIGNINURL);
			return;
		} else {
			console.log(
				`Error while making post get call to: ${postCallInput.apiUrl} w/ error:`,
				error
			);
		}

		throw error;
	}
};

const retryGETCall = async <T>(
	getCallInput: RemoteGETCallInputObject
): Promise<AxiosResponse<T>> => {
	console.log('Required retry for get w/ input: {}', getCallInput);
	try {
		const response: AxiosResponse<T> = await api.get(getCallInput.apiUrl, {
			params: getCallInput.queryParams,
			headers: getCallInput.headers,
		});

		if (response && response.status == 401) {
			redirectToLogin();
		}
		return response;
	} catch (e) {
		if (e && e.response && e.response.status == 401) {
			redirectToLogin();
		} else {
			console.log('re try call failed for input: {}', getCallInput);
		}
	}
};

const retryPOSTCall = async <T, N>(
	postCallInput: RemotePOSTCallInputObject<N>
): Promise<AxiosResponse<T>> => {
	console.log('Required retry due to stale tokens for req: {}', postCallInput);
	try {
		const response: AxiosResponse<T> = await api.post(
			postCallInput.apiUrl,
			postCallInput.object,
			{
				headers: postCallInput.headers,
			}
		);
		if (response && response.status == 401) redirectToLogin();
		return response;
	} catch (e) {
		if (e && e.response && e.response.status == 401) {
			redirectToLogin();
		}
	}
};

const redirectToLogin = () => {
	console.log('Redirecting ');
	window.location.assign(workyServerIDPSIGNINURL);
};

const RemoteExecutionServiceImpl = (): RemoteExecutionObject => {
	const callRemoteGetCallV2Helper = async <T>(
		getCallInput: RemoteGETCallInputObject
	): Promise<AxiosResponse<T>> => {
		return remoteGETCallV2<T>(getCallInput);
	};

	const callRemotePOSTCALLV2Helper = async <T, N>(
		postCallInput: RemotePOSTCallInputObject<N>
	): Promise<AxiosResponse<T>> => {
		return remotePOSTCallV2(postCallInput);
	};
	return {
		remoteGETCallV2: callRemoteGetCallV2Helper,
		remotePOSTCallV2: callRemotePOSTCALLV2Helper,
	};
};

const remotePOSTCallV2Unsecured = async <T, N>(
	postCallInput: RemotePOSTCallInputObject<N>
): Promise<AxiosResponse<T>> => {
	try {
		console.log(
			`Making remotePOSTCallV2 to: ${postCallInput.apiUrl} w/ data: `,
			postCallInput.object
		);
		const response: AxiosResponse<T> = await axios.post(
			BACK_END_SERVER_BASE_PATH_LOCAL + postCallInput.apiUrl,
			postCallInput.object,
			{
				headers: postCallInput.headers,
			}
		);
		console.log(
			`Result of remote remotePOSTCallV2 : ${postCallInput.apiUrl} w/ result`,
			response.data
		);
		return response;
	} catch (error) {
		// Check if the error status code is unauthorized (HTTP status code 401)
		if (error.response && error.response.status === 401) {
			// Redirect the user to the login page
			console.log(
				'During post: {} User is not signed in redirecting to relogin',
				postCallInput
			);
			// window.location.assign(workyServerIDPSIGNINURL);
			redirectToLogin();
			return;
		} else {
			console.log(
				`Error while making post get call to: ${postCallInput.apiUrl} w/ error:`,
				error
			);
		}

		throw error;
	}
};

// export { ServerResponse, ServerErrorDetails };

/***
 *
 * Sign in stuff
 *
 *
 *
 */

interface userEmail {
	value: string;
}
interface PhoneNumber {
	value: string;
}

interface WorkyAuthTokenRequest {
	authCode: string;
	redirectURI: string;
}

interface WorkyAuthRefreshRequest {
	refreshToken: string;
	isQA: boolean;
}

interface WorkySignInResponse {
	tokens: WorkyAuthTokenResponse;
	basicJobberDetails: BasicJobberDetails;
}

interface BasicJobberDetails {
	userDetails: UserDetails;
	jobberStatus?: JobberStatus;
	userType: UserType;
}

interface UserDetails {
	userId: string;
	userEmail: userEmail;
	userPhoneNumber: PhoneNumber;
	lastName: string;
	firstName: string;
}

enum UserType {
	JOBBER = 'JOBBER',
	ORG_MANAGER = 'ORG_MANAGER',
	WORKY_STAFF = 'WORKY_STAFF',
	WORKY_ADMIN = 'WORKY_ADMIN',
}

interface WorkyAuthTokenResponse {
	accessToken: string;
	idToken: string;
	refreshToken: string;
	useremail: userEmail;
	expiresInMilliseconds: number;
}

const isExpiredCalc = (expireTime: string): boolean => {
	if (!expireTime) return false;
	const expireTimeValue = parseInt(expireTime);
	if (!expireTimeValue || isNaN(expireTimeValue)) return false;
	const currentEpochSeconds = GenerateCurrentEpochSeconds();
	if (expireTimeValue < currentEpochSeconds) {
		console.log(
			'access token is expired expireTime: ' +
				expireTime +
				' currentEpochSeconds: ' +
				currentEpochSeconds
		);
		return true;
	} else {
		console.log(
			'access token is valid expireTime: ' +
				expireTime +
				' currentEpochSeconds: ' +
				currentEpochSeconds
		);
		return false;
	}
};

const UpdateLocalCacheWOAuthInfo = ({
	basicDetails,
	input,
}: {
	basicDetails?: BasicJobberDetails;
	input: WorkyAuthTokenResponse;
}) => {
	if (!input) {
		console.log('given token info is null, cannot update localStorage', input);
	}

	const accessToken = input.accessToken;
	const refreshToken = input.refreshToken;
	const expireTime = input.expiresInMilliseconds;
	localStorage.setItem('access_token', accessToken);

	if (refreshToken) {
		localStorage.setItem('refresh_token', refreshToken);
	}
	if (expireTime) {
		localStorage.setItem('expire_time', expireTime.toString());
	}

	if (basicDetails && basicDetails.userType) {
		localStorage.setItem('user_type', basicDetails.userType);
	}

	console.log('updated local storage');
};

const remoteCallObject = RemoteExecutionServiceImpl();

const AuthGetCall = async ({
	authCodeInput,
	redirectURIInput,
}: {
	authCodeInput: string;
	redirectURIInput: string;
}): Promise<AxiosResponse<ServerResponse<WorkySignInResponse, Error>> | null> => {
	try {
		const tempResp: WorkyAuthTokenRequest = {
			authCode: authCodeInput,
			redirectURI: redirectURIInput,
		};

		const response: AxiosResponse<ServerResponse<WorkySignInResponse, Error>> =
			await remoteCallObject.remotePOSTCallV2<
				ServerResponse<WorkySignInResponse, Error>,
				WorkyAuthTokenRequest
			>({
				apiUrl: workyServerAPIURL,
				// apiUrl: '/tst',
				object: tempResp,
				headers: { 'Content-Type': 'application/json' },
			});

		console.log('POST request successful:', response);
		return response;
	} catch (e) {
		redirectToLogin();
		console.error('POST request failed:', e);
		return null;
	}
};

const determineIfItIsQA = (url) => {
	if (redirectURL === null || redirectURL === undefined || redirectURL.includes('localhost')) {
		console.log('Redirect URL is null, undefined, or includes "localhost"');
		return true;
	} else {
		return false;
	}
};

const AuthGetCallRefreshToken = async ({
	refreshToken,
}: {
	refreshToken: string;
}): Promise<AxiosResponse<ServerResponse<WorkyAuthTokenResponse, Error>> | null> => {
	if (!refreshToken) {
		console.log('Refresh token is null, will not make call ', refreshToken);
		return null;
	}

	const isQA = determineIfItIsQA(redirectURL);

	try {
		const req: WorkyAuthRefreshRequest = {
			refreshToken: refreshToken,
			isQA: isQA,
		};

		const response: AxiosResponse<ServerResponse<WorkyAuthTokenResponse, Error>> =
			await remotePOSTCallV2Unsecured<
				ServerResponse<WorkyAuthTokenResponse, Error>,
				WorkyAuthRefreshRequest
			>({
				apiUrl: workyServerREFRESHTOKENAPIURL,
				// apiUrl: '/tst',
				object: req,
				headers: { 'Content-Type': 'application/json' },
			});

		console.log(' for refresh token POST request successful:', response);
		return response;
	} catch (e) {
		console.log('Error unable to use refresh token to get new tokens due to: ', e);
		redirectToLogin();
		return null;
	}
};

const setGlobalUserDetails = ({ userInfo }: { userInfo: UserDetails }) => {
	if (!userInfo) return;

	localStorage.setItem('firstName', userInfo.firstName);
	localStorage.setItem('lastName', userInfo.lastName);
	localStorage.setItem('userId', userInfo.userId);

	if (userInfo.userEmail) {
		localStorage.setItem('userEmail', userInfo.userEmail.value);
	}

	if (userInfo.userPhoneNumber) {
		localStorage.setItem('userPhoneNumber', userInfo.userPhoneNumber.value);
	}
};

const getGlobalUserDetails = (): UserDetails => {
	const firstName = localStorage.getItem('firstName');
	const lastName = localStorage.getItem('lastName');
	const userId = localStorage.getItem('userId');
	const userEmail = localStorage.getItem('userEmail');
	const userPhoneNumber = localStorage.getItem('userPhoneNumber');

	return {
		firstName: firstName,
		userId: userId,
		lastName: lastName,
		userEmail: { value: userEmail },
		userPhoneNumber: { value: userPhoneNumber },
	};
};

// export { ServerResponse,RemoteExecutionServiceImpl, ServerErrorDetails };
export {
	UserType,
	ServerResponse,
	RemoteExecutionServiceImpl,
	ServerErrorDetails,
	AuthGetCall,
	WorkySignInResponse,
	WorkyAuthRefreshRequest,
	isExpiredCalc,
	UpdateLocalCacheWOAuthInfo,
	RemoteGETCallInputObject,
	redirectToLogin,
	handleRetrieveNewTokens,
	UserDetails,
	setGlobalUserDetails,
	getGlobalUserDetails,
	WorkyAuthTokenResponse,
	BasicJobberDetails,
};
