import React, { useState, useEffect, useRef } from 'react';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import { SplashNavbarV3Mobile } from './CareersPage';
import { SplashFooter } from './DesktopSplashV2';
import { MobileWorkyFooter } from './MobileSplashV2';
import EastIcon from '@mui/icons-material/East';
import StarIcon from '@mui/icons-material/Star';
import PaidIcon from '@mui/icons-material/Paid';
import CheckIcon from '@mui/icons-material/Check';
import Accordion from '@mui/material/Accordion';
import { MobileSplashNav } from './MobileSplashV3';
import Modal from '@mui/material/Modal';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RadarIcon from '@mui/icons-material/Radar';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Popper from '@mui/material/Popper';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BrushIcon from '@mui/icons-material/Brush';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import './productspage.css'

const bookDemoLink = "https://calendly.com/worky-pro/30min";
const workyServerIDPSIGNUPURL = process.env.REACT_APP_IDP_SIGN_UP_URL;
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;


const ProductPricingOne = () => {
    const portaltext="Presenting our location-based clock-in and clock-out system for effortless time tracking. With real-time GPS verification and an intuitive interface, it enhances efficiency, ensuring precise time logs. Completely adaptable to your business requirements."
    const [isAnnual,setIsAnnual] = useState(true)
    const isMobile = useIsMobile();

    const ProductMobile = () => {
        return(
        <div className='products-page-container'>
            <div className='products-display-container-mobile'>
                <div className='products-display-title'>Virtual Clock</div>
                <div className='products-display-subtitle-mobile'>{portaltext}</div>
                <div className='products-display-carousel-holder-mobile'>
                    <img src='./virtual-check-in.jpg'/>
                </div>
            </div>
        </div>
        )

    }

    const ProductDesktop = () => {
        return(
            <div className='products-page-container'>
                <div className='products-display-container'>
                    <div className='products-display-title'>Virtual Clock</div>
                    <div className='products-display-subtitle'>{portaltext}</div>
                    <div className='products-display-carousel-holder'>
                        <div className='product-display-carousel-item'><img src="./virtual-check-in.jpg"/></div>

                    </div>
                </div>
    
            
    {/* <div className='products-plan-container'>
        <div className='products-plan-top-container'>
            <div className='products-plan-title'>Choose Your Plan</div>
            <div className='products-plan-billing-switch-container'>
                {isAnnual? (<button onClick={()=>setIsAnnual(true)} id='products-plan-billing-switch-active' className='products-plan-billing-switch'>Annual</button>):(<button onClick={()=>setIsAnnual(true)} className='products-plan-billing-switch'>Annual</button>)}
                {!isAnnual? (<button onClick={()=>setIsAnnual(false)} id='products-plan-billing-switch-active' className='products-plan-billing-switch'>Monthly</button>):(<button onClick={()=>setIsAnnual(false)} className='products-plan-billing-switch'>Monthly</button>)}
            </div>
            <div className='save-tag'>Save 20% with Annual Billing</div>
        </div>

        <div className='products-plan-bottom-container'>


            
            


            
            <div className='products-plan-option'>
                <div className='products-plan-inner-tag'></div>
                <div className='products-plan-inner-title'>Standard</div>
                <div className='products-plan-inner-subtitle'>Get <span className='simple-bold'>DAILY</span> Access for<br/>1-10 Workers</div>
                <div className='products-plan-inner-price'>{isAnnual? "$288/mo" : "$322/mo"}</div>
                <div className='products-plan-inner-subprice'>{isAnnual? "$3,456 billed annually" : ""}</div>
                
                <button className='products-plan-inner-button'>Get Started</button>
                
                <div className='products-plan-inner-features-holder'>
                    <div className='products-plan-inner-features-title'>Standard features include:</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Platform</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 1-5 Workplaces</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                      
                </div>
            </div>
            


            
            <div className='products-plan-option'>
                
                <div id='most-popular' className='products-plan-inner-tag'><StarIcon style={{fontSize:"12px"}}/>Most Popular</div>
                <div className='products-plan-inner-title'>Business</div>
                <div className='products-plan-inner-subtitle'>Get <span className='simple-bold'>DAILY</span> Access for<br/>11-20 Workers</div>
                <div className='products-plan-inner-price'>{isAnnual? "$518/mo" : "$580/mo"}</div>
                <div className='products-plan-inner-subprice'>{isAnnual? "$6,216 billed annually" : ""}</div>
                
                <button className='products-plan-inner-button'>Get Started</button>
                
                <div className='products-plan-inner-features-holder'>
                    <div className='products-plan-inner-features-title'>All Standard features, plus:</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>  
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Platform</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 6-10 Workplaces</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                           
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky SmartMatch</div>                           
                </div>
            </div>
            

            <div className='products-plan-option'>
                <div className='products-plan-inner-tag'></div>
                <div className='products-plan-inner-title'>Executive</div>
                <div className='products-plan-inner-subtitle'>Get <span className='simple-bold'>DAILY</span> Access for<br/>21-40 Workers</div>
                <div className='products-plan-inner-price'>{isAnnual? "$932/mo" : "$1044/mo"}</div>
                <div className='products-plan-inner-subprice'>{isAnnual? "$11,184 billed annually" : ""}</div>
                
                <button className='products-plan-inner-button'>Get Started</button>
                
                <div className='products-plan-inner-features-holder'>
                    <div className='products-plan-inner-features-title'>All Business features, plus:</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>  
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Platform</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 6-10 Workplaces</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                                                
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky SmartMatch</div>                                                
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Portal</div>                                                
                </div>
            </div>
            


            
            <div className='products-plan-option'>
                <div id='enhanced-plan' className='products-plan-inner-tag'><AutoAwesomeIcon style={{fontSize:"12px"}}/>Enhanced Plan</div>
                <div className='products-plan-inner-title'>Enterprise</div>
                <div id='enhanced-subtitle' className='products-plan-inner-subtitle'>For 40+ Workers</div>
                
                <div className='products-plan-inner-subprice'>Call +1 647 494 9090</div>
                
                <button className='products-plan-inner-button'>Contact Sales</button>
                
                <div className='products-plan-inner-features-holder'>
                    <div className='products-plan-inner-features-title'>Other benefits include:</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Platform</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 6-10 Workplaces</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                                                
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky SmartMatch</div>                                                
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Portal</div>                            
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Pay</div>                            
                </div>
            </div>
            


            


        </div>

    </div> */}


    </div>
        )
    }

    return(<>{isMobile? (<ProductMobile/>):(<ProductDesktop/>)}</>)
}
const ProductPricingTwo = () => {
    const portaltext="Introducing our check-in and check-out tablet for seamless time tracking. With real-time updates, user-friendly design, and advanced security, it streamlines operations, ensuring accurate records. Fully customizable to fit your business needs."
    
    const isMobile = useIsMobile();

    const ProductMobile = () => {
        return(
        <div className='products-page-container'>
            <div className='products-display-container-mobile'>
                <div className='products-display-title'>Worky Portal</div>
                <div className='products-display-subtitle-mobile'>{portaltext}</div>
                <div className='products-display-carousel-holder-mobile'>
                    <img src='./worky-ipad-v2.jpg'/>
                </div>
            </div>
        </div>
        )

    }

    const ProductDesktop = () => {
        return(
            <div className='products-page-container'>
                <div className='products-display-container'>
                    <div className='products-display-title'>Worky Portal</div>
                    <div className='products-display-subtitle'>{portaltext}</div>
                    <div className='products-display-carousel-holder'>
                        <div className='product-display-carousel-item'><img src="./worky-ipad-v2.jpg"/></div>
                    </div>
                </div>
    
            
        


    </div>
        )
    }

    return(<>{isMobile? (<ProductMobile/>):(<ProductDesktop/>)}</>)
}
const ProductPricingThree = () => {
    const portaltext="Discover our AI-powered job matching system for optimal staffing. Utilizing cutting-edge algorithms, it swiftly pairs the most qualified worker to each shift based on numerous criteria. Efficient, intelligent, and tailored to your specific business needs."
    const [isAnnual,setIsAnnual] = useState(true)
    const isMobile = useIsMobile();

    const ProductMobile = () => {
        return(
        <div className='products-page-container'>
            <div className='products-display-container-mobile'>
                <div className='products-display-title'>Smart Match</div>
                <div className='products-display-subtitle-mobile'>{portaltext}</div>
                <div className='products-display-carousel-holder-mobile'>
                    <img src='./worky-algo-mockup.jpg'/>
                </div>
            </div>

        </div>
        )

    }

    const ProductDesktop = () => {
        return(
            <div className='products-page-container'>
                <div className='products-display-container'>
                    <div className='products-display-title'>Smart Match</div>
                    <div className='products-display-subtitle'>{portaltext}</div>
                    <div className='products-display-carousel-holder'>
                        <div className='product-display-carousel-item'><img id='desktop-smart-match-product' src="./worky-algo-mockup.jpg"></img></div>
                        {/* <button id='wide-carousel-item' className='product-display-carousel-item'></button> */}
                        {/* <button className='product-display-carousel-item'></button> */}
                    </div>
                </div>
            </div>
        )
    }

    return(<>{isMobile? (<ProductMobile/>):(<ProductDesktop/>)}</>)
}
const ProductPricingFour = () => {
    const portaltext="Introducing our comprehensive shift management and payroll system. Offering real-time shift tracking, precise logging of payments due and received, and detailed records of past shifts. Easily manage payroll and pay employees in one click!"
    const [isAnnual,setIsAnnual] = useState(true)
    const isMobile = useIsMobile();

    const ProductMobile = () => {
        return(
        <div className='products-page-container'>
            <div className='products-display-container-mobile'>
                <div className='products-display-title'>Worky Pay</div>
                <div className='products-display-subtitle-mobile'>{portaltext}</div>
                <div className='products-display-carousel-holder-mobile'>
                    <img src='./workypay.jpg'/>
                </div>
            </div>


        </div>
        )

    }

    const ProductDesktop = () => {
        return(
            <div className='products-page-container'>
                <div className='products-display-container'>
                    <div className='products-display-title'>Worky Pay</div>
                    <div className='products-display-subtitle'>{portaltext}</div>
                    <div className='products-display-carousel-holder'>
                    <div className='product-display-carousel-item'><img id='desktop-smart-match-product' src="./workypay.jpg"/></div>

                    </div>
                </div>
    
            


    </div>
        )
    }

    return(<>{isMobile? (<ProductMobile/>):(<ProductDesktop/>)}</>)
}
const ProductPricingFive = () => {
    const portaltext="Introducing our AI Voice Agent for managing phone calls with precision. It filters calls based on context, onboards workers, and gathers client information effortlessly. Fully customizable to fit your business needs."
    const [isAnnual,setIsAnnual] = useState(true)
    const isMobile = useIsMobile();

    const ProductMobile = () => {
        return(
        <div className='products-page-container'>
            <div className='products-display-container-mobile'>
                <div className='products-display-title'>Worky Agent</div>
                <div className='products-display-subtitle-mobile'>{portaltext}</div>
                <div className='products-display-carousel-holder-mobile'>
                    <img src='./worky-ai.jpg'/>
                </div>
            </div>

        </div>
        )

    }

    const ProductDesktop = () => {
        return(
            <div className='products-page-container'>
                <div className='products-display-container'>
                    <div className='products-display-title'>Worky Agent</div>
                    <div className='products-display-subtitle'>{portaltext}</div>
                    <div className='products-display-carousel-holder'>
                    <div className='product-display-carousel-item'><img id='desktop-ai-product' src="./worky-ai.jpg"/></div>

                    </div>
                </div>


    </div>
        )
    }

    return(<>{isMobile? (<ProductMobile/>):(<ProductDesktop/>)}</>)
}
const CommonQuestions = () => {
    const signupClick = () => {window.location.assign('/?scroll=6650');}
    const isMobile = useIsMobile();
    const [isAnnual,setIsAnnual] = useState(true);
    return(
    <>
        {isMobile?(
        <>
            <div className='products-plan-container'>
                <div className='products-plan-top-container-mobile'>
                    <div className='products-plan-title'>Choose Your Plan</div>
                    <div className='products-plan-billing-switch-container'>
                        {isAnnual? (<button onClick={()=>setIsAnnual(true)} id='products-plan-billing-switch-active' className='products-plan-billing-switch'>Annual</button>):(<button onClick={()=>setIsAnnual(true)} className='products-plan-billing-switch'>Annual</button>)}
                        {!isAnnual? (<button onClick={()=>setIsAnnual(false)} id='products-plan-billing-switch-active' className='products-plan-billing-switch'>Monthly</button>):(<button onClick={()=>setIsAnnual(false)} className='products-plan-billing-switch'>Monthly</button>)}
                    </div>
                    <div className='save-tag-mobile'>Save 20% with Annual Billing</div>
                </div>
            </div>

            <div className='products-plan-bottom-container-mobile'>
            
            <div className='products-plan-option-mobile'>
                <div className='products-plan-inner-tag-mobile'></div>
                <div className='products-plan-inner-title-mobile'>Standard</div>
                <div className='products-plan-inner-subtitle'>Get <span className='simple-bold'>DAILY</span> Access for<br/>1-20 Workers</div>
                <div className='products-plan-inner-price'>{isAnnual? "$48/mo" : "$56/mo"}</div>
                <div className='products-plan-inner-subprice'>{isAnnual? "$576 billed annually" : ""}</div>
                
                <button className='products-plan-inner-button'>Get Started</button>
                
                <div className='products-plan-inner-features-holder'>
                    <div className='products-plan-inner-features-title'>Standard features include:</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Reporting & Analytics Dashboard </div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Hassle Free New Worker Onboarding</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 1-3 Workplaces</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                       
                </div>
            </div>
            
            <div className='products-plan-option-mobile'>
                <div id='most-popular' className='products-plan-inner-tag'><StarIcon style={{fontSize:"12px"}}/>Most Popular</div>
                <div className='products-plan-inner-title'>Business</div>
                <div className='products-plan-inner-subtitle'>Get <span className='simple-bold'>DAILY</span> Access for<br/>21-60 Workers</div>
                <div className='products-plan-inner-price'>{isAnnual? "$90/mo" : "$104/mo"}</div>
                <div className='products-plan-inner-subprice'>{isAnnual? "$1,080 billed annually" : ""}</div>
                
                <button className='products-plan-inner-button'>Get Started</button>
                
                <div className='products-plan-inner-features-holder'>
                    <div className='products-plan-inner-features-title'>Business plan includes:</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Platform</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky SmartMatch</div>                     
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Reporting & Analytics Dashboard </div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Hassle Free New Worker Onboarding</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 1-8 Workplaces</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                       
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Automated Worker & Manager SMS Reminders</div>                      
                </div>
            </div>

            <div className='products-plan-option-mobile'>
                <div id='enhanced-plan' className='products-plan-inner-tag'><AutoAwesomeIcon style={{fontSize:"12px"}}/>Enhanced Plan</div>
                <div className='products-plan-inner-title'>Enterprise</div>
                <div id='enhanced-subtitle' className='products-plan-inner-subtitle'>For 60+ Workers</div>
                <div className='products-plan-inner-subprice'>Call +1 647 494 9090</div>
                
                <button className='products-plan-inner-button'>Contact Sales</button>
                
                <div className='products-plan-inner-features-holder'>
                    <div className='products-plan-inner-features-title'>Other benefits include:</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Platform</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky SmartMatch</div>                     
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to WorkyPay</div>                     
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to WorkyAgent (Beta)</div>                     
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Reporting & Analytics Dashboard </div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Hassle Free New Worker Onboarding</div>
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 1-8 Workplaces</div>                      
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                       
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Automated Worker & Manager SMS Reminders</div>                                                             
                    <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Custom Software Support & Features</div>                                        
                </div>
            </div>

            </div>
        </>
        ):(
        <div className='products-plan-container'>
            <div className='products-plan-top-container'>
                <div className='products-plan-title'>Choose Your Plan</div>
                <div className='products-plan-billing-switch-container'>
                    {isAnnual? (<button onClick={()=>setIsAnnual(true)} id='products-plan-billing-switch-active' className='products-plan-billing-switch'>Annual</button>):(<button onClick={()=>setIsAnnual(true)} className='products-plan-billing-switch'>Annual</button>)}
                    {!isAnnual? (<button onClick={()=>setIsAnnual(false)} id='products-plan-billing-switch-active' className='products-plan-billing-switch'>Monthly</button>):(<button onClick={()=>setIsAnnual(false)} className='products-plan-billing-switch'>Monthly</button>)}
                </div>
                <div className='save-tag'>Save 20% with Annual Billing</div>
            </div>

            <div className='products-plan-bottom-container'>
        
            

            
                <div className='products-plan-option'>
                    <div className='products-plan-inner-tag'></div>
                    <div className='products-plan-inner-title'>Standard</div>
                    <div className='products-plan-inner-subtitle'>Get <span className='simple-bold'>DAILY</span> Access for<br/>1-20 Workers</div>
                    <div className='products-plan-inner-price'>{isAnnual? "$48/mo" : "$56/mo"}</div>
                    <div className='products-plan-inner-subprice'>{isAnnual? "$576 billed annually" : ""}</div>
                    
                    <button className='products-plan-inner-button'>Get Started</button>
                    
                    <div className='products-plan-inner-features-holder'>
                        <div className='products-plan-inner-features-title'>Standard features include:</div>
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Reporting & Analytics Dashboard </div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Hassle Free New Worker Onboarding</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 1-3 Workplaces</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                                       
                    </div>
                </div>
                


                
                <div className='products-plan-option'>
                    
                    <div id='most-popular' className='products-plan-inner-tag'><StarIcon style={{fontSize:"12px"}}/>Most Popular</div>
                    <div className='products-plan-inner-title'>Business</div>
                    <div className='products-plan-inner-subtitle'>Get <span className='simple-bold'>DAILY</span> Access for<br/>21-60 Workers</div>
                    <div className='products-plan-inner-price'>{isAnnual? "$90/mo" : "$104/mo"}</div>
                    <div className='products-plan-inner-subprice'>{isAnnual? "$1,080 billed annually" : ""}</div>
                    
                    <button className='products-plan-inner-button'>Get Started</button>
                    
                    <div className='products-plan-inner-features-holder'>
                        <div className='products-plan-inner-features-title'>Business plan includes:</div>
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Platform</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky SmartMatch</div>                     
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Reporting & Analytics Dashboard </div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Hassle Free New Worker Onboarding</div>
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 1-8 Workplaces</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                       
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Automated Worker & Manager SMS Reminders</div>                      
                    </div>
                </div>
                

                
                <div className='products-plan-option'>
                    <div id='enhanced-plan' className='products-plan-inner-tag'><AutoAwesomeIcon style={{fontSize:"12px"}}/>Enhanced Plan</div>
                    <div className='products-plan-inner-title'>Enterprise</div>
                    <div id='enhanced-subtitle' className='products-plan-inner-subtitle'>For 60+ Workers</div>
                    <div className='products-plan-inner-subprice'>Call +1 647 494 9090</div>
                    
                    <button className='products-plan-inner-button'>Contact Sales</button>
                    
                    <div className='products-plan-inner-features-holder'>
                        <div className='products-plan-inner-features-title'>Other benefits include:</div>
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>24/7 Support</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky Platform</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to Worky SmartMatch</div>                     
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to WorkyPay</div>                     
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Access to WorkyAgent (Beta)</div>                     
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Reporting & Analytics Dashboard </div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Hassle Free New Worker Onboarding</div>
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Geofencing for 1-8 Workplaces</div>                      
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Worker Geolocation Tracking</div>                       
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Automated Worker & Manager SMS Reminders</div>                                                             
                        <div className='products-plan-inner-feature'><CheckIcon style={{fontSize:"14px"}}/>Custom Software Support & Features</div>                                        
                    </div>
                </div>
            


        


            </div>

        </div>
        )}
        <div className='common-questions-holder'>
            <div className='common-questions-title'>Common Questions</div>
            <div className='common-questions-subtitle'>Have another question? We are here to help.</div>
            <button onClick={signupClick} className='common-questions-button'>Contact Us</button>
            <div className={isMobile?'common-questions-accordian-mobile':'common-questions-accordian'}>
                <Accordion style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "70px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <div className='accordian-question'>Can your software integrate with other HR and payroll systems?</div>
                    </AccordionSummary>

                    <AccordionDetails style={{ marginBottom: "0px" }}>
                        <div className='accordian-answer'>Yes, our software is designed to seamlessly integrate with various HR and payroll systems. This allows staffing agencies to synchronize data across platforms, reducing manual data entry and ensuring consistency. Our API support enables easy integration with all popular systems. For more specific integrations please contact us directly.</div>
                    </AccordionDetails>

                </Accordion>


                <Accordion style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "70px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <div className='accordian-question'>How secure is your system for managing check-ins and check-outs?</div>
                    </AccordionSummary>

                    <AccordionDetails style={{ marginBottom: "0px" }}>
                        <div className='accordian-answer'>Security is a top priority for us. Our system uses encrypted data transmission and secure authentication methods. Additionally, our geofencing feature minimizes unauthorized access, ensuring that only authorized personnel can check in and out.</div>
                    </AccordionDetails>

                </Accordion>


                <Accordion style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "70px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <div className='accordian-question'>What kind of support and training do you offer?</div>
                    </AccordionSummary>

                    <AccordionDetails style={{ marginBottom: "0px" }}>
                        <div className='accordian-answer'>We offer extensive support and training services, including onboarding sessions, user manuals, video tutorials, and a dedicated customer support team. Our goal is to ensure that our clients can fully utilize our software to its maximum potential and address any issues promptly.</div>
                    </AccordionDetails>

                </Accordion>


                <Accordion style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "70px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <div className='accordian-question'>How can your smart matching algorithm help reduce turnover rates?</div>
                    </AccordionSummary>

                    <AccordionDetails style={{ marginBottom: "0px" }}>
                        <div className='accordian-answer'>Our smart matching algorithm helps reduce turnover rates by ensuring that workers are placed in jobs that best match their skills, location, experience, and preferences. By improving job satisfaction and fit, workers are more likely to stay longer, reducing turnover and the associated costs for staffing agencies.</div>
                    </AccordionDetails>

                </Accordion>

                
                <Accordion style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "70px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <div className='accordian-question'>How does your system handle shift changes and schedule updates?</div>
                    </AccordionSummary>

                    <AccordionDetails style={{ marginBottom: "0px" }}>
                        <div className='accordian-answer'>Our system allows administrators to easily manage shift changes and schedule updates. Workers are notified of any changes in real-time through the platform. This reduces confusion and ensures that everyone is aware of their current schedule.</div>
                    </AccordionDetails>

                </Accordion>


                <Accordion style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "70px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <div className='accordian-question'>How do you ensure that the correct worker is checking-in/out?</div>
                    </AccordionSummary>

                    <AccordionDetails style={{ marginBottom: "0px"}}>
                        <div className='accordian-answer'>Our systems ensure that the correct worker is checking in or out at all times by using a combination of secure authentication methods. Both our manual and geofenced check-ins verify the identity of the worker and prevent unauthorized check-ins.</div>
                    </AccordionDetails>

                </Accordion>


            
            </div>
        </div>
    </>)
}


const ProductsPage = () => {
    const isMobile = useIsMobile();
    const [tryNowModal, setTryNowModal] = useState(false);
    const signupClick = () => {window.location.assign('/?scroll=5950');}

    const SplashNavbarV3 = () => {
        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const scrollPosition = urlParams.get('scroll');
            if (scrollPosition) {
                const position = parseInt(scrollPosition, 10);
                if (!isNaN(position)) {window.scrollTo({ top: position, behavior: "smooth" });}
            }
        }, []);
    
    
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
        
        const handleClick = (event: React.MouseEvent<HTMLElement>) => { 
            setAnchorEl(anchorEl ? null : event.currentTarget);
            if (!anchorEl) {setAnchorEl2(null);}
        };
        
        const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl2(anchorEl2 ? null : event.currentTarget);
            if (!anchorEl2) {setAnchorEl(null);}
        };

        const handleBusinessClick =()=>{
            window.location.assign('/?scroll=1475')
        }
        
        const open = Boolean(anchorEl);
        const open2 = Boolean(anchorEl2);
        
        const id = open ? 'simple-popper' : undefined;
        const id2 = open2 ? 'simple-popper2' : undefined;
        
        return(
            <div className='splash-navbar-v3'>
                <div onClick={() => window.location.assign('/?scroll=0')} className='splash-navbar-logo'>worky</div>
                <div className='splash-navbar-menu'>
                    <button aria-describedby={id} type="button" onClick={handleClick} className='splash-navbar-option'>Products<KeyboardArrowDownIcon style={{fontSize:"18px", paddingTop:"2px"}}/></button>
                    <button onClick={handleBusinessClick} className='splash-navbar-option'>Businesses</button>
                    {/* <button onClick={handleProductClick2} className='splash-navbar-option'>Associates</button> */}
                    <button aria-describedby={id2} type="button" onClick={handleClick2}  className='splash-navbar-option'>Careers<KeyboardArrowDownIcon style={{fontSize:"18px", paddingTop:"2px"}}/></button>
                    <button onClick={()=>window.open(bookDemoLink)} id='demo-option' className='splash-navbar-option'>Book Demo</button>
                    
                    <Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex:"5"}}>
                        <div className='popper-drawer'>
                            <div className='popper-drawer-title'>PRODUCTS</div>
                            <div className='popper-drawer-line'></div>
    
                            <div className='popper-products-holder-inner'>
                                <button onClick={() => window.location.assign('/products?product=1')} className='popper-product'>
                                    <div className='popper-product-inner-img'>
                                        <img src='./virtual-check-in.jpg'></img>
                                    </div>
                                    <div className='popper-product-inner-info'><RadarIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Virtual Clock</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=2')} className='popper-product'>
                                    <div className='popper-product-inner-img'><img src='./worky-ipad-v2.jpg'></img></div>
                                    <div className='popper-product-inner-info'><AdsClickIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Portal</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=3')} className='popper-product'>
                                    <div className='popper-product-inner-img'>
                                        <img src='./worky-algo-mockup.jpg'/>
                                    </div>
                                    <div className='popper-product-inner-info'><AutoAwesomeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Smart Match</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=4')} className='popper-product'>
                                    <div className='popper-product-inner-img'><img src='./workypay.jpg'></img></div>
                                    <div className='popper-product-inner-info'><CurrencyExchangeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Pay</div>
                                </button>
                                <button onClick={() => window.location.assign('/products')} id='view-all-popper-product'>
                                    <div className='view-all-products-icon'><EastIcon/></div>
                                    View All
                                </button>
                            </div>
                        </div>
                    </Popper>
    
                    <Popper id={id2} open={open2} anchorEl={anchorEl2} style={{zIndex:"5"}}>
                        <div className='popper-drawer'>
                            <div className='popper-drawer-title'>CAREERS</div>
                            <div className='popper-drawer-line'></div>
                            <div className='popper-careers-holder-inner'>
                                <button onClick={() => window.location.assign('/careers?scroll=1400')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Data Platform Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=1650')} className='popper-job'>
                                    <div className='popper-job-icon'><BrushIcon/></div>
                                    <div className='popper-job-title'>Senior Product Designer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=1950')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Server Side Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2200')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Senior Front End Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                    <div className='popper-job-icon'><PhoneIphoneIcon/></div>
                                    <div className='popper-job-title'>Social Media Manager</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                    <div className='popper-job-icon'><TrendingUpIcon/></div>
                                    <div className='popper-job-title'>Regional Sales Manager</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers')} id='view-all-jobs' className='popper-job'>
                                    <div id='view-all-jobs-title' className='popper-job-title'>View All</div>
                                    <div id='view-all-jobs-icon' className='popper-job-icon'><EastIcon/></div>
                                </button>
                            </div>
                        </div>
                    </Popper>
    
                </div>
                <div className='splash-navbar-buttons'>
                    <button onClick={() => window.location.assign(workyServerIDPSIGNINURL)} className='splash-navbar-login'>Login</button>
                    {/* HEREHERE */}
                    {/* <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='splash-navbar-trynow'>Try Now</button> */}
                    <button onClick={()=>setTryNowModal(true)} className='splash-navbar-trynow'>Try Now</button>
                </div>
                
            </div>
        )
    }
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const product = urlParams.get('product');
        if (product) {
            const productId = parseInt(product, 10);
            if (!isNaN(productId)) {
                setCurrentProduct(productId);
            }
        }
    }, []);

    const [currentProduct, setCurrentProduct] = useState(1);

    return(
        <div className='splash-body'>
            <Modal open={tryNowModal} onClose={() => setTryNowModal(false)}>
					<div className='try-now-modal'>
                        <button onClick={() => setTryNowModal(false)} className='try-now-modal-close'><CloseIcon/></button>
                        <div className='try-now-modal-title'>Employers</div>
                        <div className='try-now-modal-button-holder'>
                            <button onClick={signupClick} className='try-now-modal-button'>
                                <div className='try-now-modal-button-title'>Employer</div>
                                <div className='try-now-modal-button-subtitle'>Get started today and save over 20% annually!</div>
                                <div className='try-now-modal-button-icon'><BusinessIcon style={{fontSize:"35px"}}/></div>
                            </button>
                        </div>
					</div>
            </Modal>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />        
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='' />
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>
        

            {isMobile? <MobileSplashNav/>:<SplashNavbarV3/>}
            {isMobile?
            (
                <div className='products-tabs-container-mobile'>
                    {currentProduct == 1 ? (<button onClick={()=>setCurrentProduct(1)} id='product-tab-mobile-active' className='product-tab-mobile'>Virtual Clock</button>):(<button onClick={()=>setCurrentProduct(1)} className='product-tab-mobile'>Virtual Clock</button>)}
                    {currentProduct == 2 ? (<button onClick={()=>setCurrentProduct(2)} id='product-tab-mobile-active' className='product-tab-mobile'>Worky Portal</button>):(<button onClick={()=>setCurrentProduct(2)} className='product-tab-mobile'>Worky Portal</button>)}
                    {currentProduct == 3 ? (<button onClick={()=>setCurrentProduct(3)} id='product-tab-mobile-active' className='product-tab-mobile'>Smart Match</button>):(<button onClick={()=>setCurrentProduct(3)} className='product-tab-mobile'>Smart Match</button>)}
                    {currentProduct == 4 ? (<button onClick={()=>setCurrentProduct(4)} id='product-tab-mobile-active' className='product-tab-mobile'>Worky Pay</button>):(<button onClick={()=>setCurrentProduct(4)} className='product-tab-mobile'>Worky Pay</button>)}
                    {currentProduct == 5 ? (<button onClick={()=>setCurrentProduct(5)} id='product-tab-mobile-active' className='product-tab-mobile'>Worky Agent</button>):(<button onClick={()=>setCurrentProduct(5)} className='product-tab-mobile'>Worky Agent</button>)}
                </div>
            ):(
                <div className='product-tabs-container'>
                    <div className='product-tabs-container-inner'>
                        {currentProduct == 1 ? (<button onClick={()=>setCurrentProduct(1)} className='product-tab' id='product-tab-active'>Virtual Clock</button>):(<button onClick={()=>setCurrentProduct(1)} className='product-tab'>Virtual Clock</button>)}
                        {currentProduct == 2 ? (<button onClick={()=>setCurrentProduct(2)}  className='product-tab' id='product-tab-active'>Worky Portal</button>):(<button onClick={()=>setCurrentProduct(2)} className='product-tab'>Worky Portal</button>)}
                        {currentProduct == 3 ? (<button onClick={()=>setCurrentProduct(3)}  className='product-tab' id='product-tab-active'>Smart Match</button>):(<button onClick={()=>setCurrentProduct(3)} className='product-tab'>Smart Match</button>)}
                        {currentProduct == 4 ? (<button onClick={()=>setCurrentProduct(4)}  className='product-tab' id='product-tab-active'>Worky Pay</button>):(<button onClick={()=>setCurrentProduct(4)} className='product-tab'>Worky Pay</button>)}
                        {currentProduct == 5 ? (<button onClick={()=>setCurrentProduct(5)}  className='product-tab' id='product-tab-active'>Worky Agent</button>):(<button onClick={()=>setCurrentProduct(5)} className='product-tab'>Worky Agent</button>)}
                    </div>
                </div>
            )}

            {currentProduct == 1 ? (<ProductPricingOne/>):(null)}
            {currentProduct == 2 ? (<ProductPricingTwo/>):(null)}
            {currentProduct == 3 ? (<ProductPricingThree/>):(null)}
            {currentProduct == 4 ? (<ProductPricingFour/>):(null)}
            {currentProduct == 5 ? (<ProductPricingFive/>):(null)}
            
            <CommonQuestions/>

            {isMobile? <MobileWorkyFooter/>:<SplashFooter/>}

        </div>
    )
}



export {ProductsPage}