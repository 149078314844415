import React, { useState, useEffect, useLayoutEffect } from 'react';
import { AxiosResponse } from 'axios';
import { signUserInService } from '../auth/WorkySignInService';
import './jobberhomepage.css';
import UserDetailsForm from '../remoteExecution/jobber-ingress';
import { JobberHomePageDesktop } from './JobberHomePageDesktop';
import {
	UserType,
	handleRetrieveNewTokens,
	ServerResponse,
	redirectToLogin,
	RemoteExecutionServiceImpl,
	ServerErrorDetails,
	AuthGetCall,
	WorkySignInResponse,
	WorkyAuthRefreshRequest,
	isExpiredCalc,
	RemoteGETCallInputObject,
	UpdateLocalCacheWOAuthInfo,
	setGlobalUserDetails,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import { OrgHomePage } from '../orgViews/OrgHomepage';
import { JobberDTO } from './objects/JobberRegistrationFormConstants';

const remoteCallObject = RemoteExecutionServiceImpl();

const redirectURL = process.env.REACT_APP_BACK_END_SERVER_SIGN_IN_REDIRECT_URL;
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;

const WorkyHomePage = () => {
	console.log('here in home page');
	const [loading, setLoading] = useState(true);
	const [jobberStatus, setJobberStatus] = useState(null);
	const [userType, setUserType] = useState(null);
	const [sfirstName, setFirstName] = useState(null);
	const [slastName, setLastName] = useState(null);
	const [userDetails, setUserDetails] = useState(null);

	const getJobberDetailsHelp = async () => {
		if (userType === UserType.ORG_MANAGER) {
			if (localStorage.getItem('access_token')) {
				setLoading(false);
			}
			setUserType(UserType.ORG_MANAGER);
		} else {
			const getCallInput: RemoteGETCallInputObject = {
				apiUrl: '/v1/jobber/get',
				headers: {},
				queryParams: null,
			};

			const res2: AxiosResponse<ServerResponse<JobberDTO, Error>> =
				await remoteCallObject.remoteGETCallV2<ServerResponse<JobberDTO, Error>>(getCallInput);

			console.log('res2res2res2: ' + JSON.stringify(res2));

			if (res2 && res2.data && res2.data.data) {
				setJobberStatus(res2.data.data.jobberStatus);
			}

			if (localStorage.getItem('access_token')) {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		console.log('re render ');
		try {
			const loadData = async () => {
				const url = new URL(window.location.href);
				const code = url.searchParams.get('code');
				const accessToken = localStorage.getItem('access_token');
				const expireTime = localStorage.getItem('expire_time');
				const idToken = localStorage.getItem('id_token');
				const refreshToken = localStorage.getItem('refresh_token');
				const userTypeFromStorage = localStorage.getItem('user_type');
				const respFromSignIn: WorkySignInResponse = await signUserInService({
					code: code,
					accessToken: accessToken,
					expireTime: expireTime,
					refreshToken: refreshToken,
					userType: userTypeFromStorage,
					idToken: idToken,
				});

				// if (respFromSignIn || respFromSignIn === undefined || respFromSignIn === null) {
				// 	console.error('No resp from: signUserInService redirecting ');
				// 	redirectToLogin();
				// }
				setUserType(respFromSignIn.basicJobberDetails.userType);
				setJobberStatus(respFromSignIn.basicJobberDetails.jobberStatus);
				setUserDetails(respFromSignIn.basicJobberDetails.userDetails);
				UpdateLocalCacheWOAuthInfo({
					basicDetails: respFromSignIn.basicJobberDetails,
					input: respFromSignIn.tokens,
				});
				setGlobalUserDetails({ userInfo: respFromSignIn.basicJobberDetails.userDetails });
				setLoading(false);
			};

			loadData();
		} catch (e) {
			console.log('Error while attempting to retrieve jobber details', e);
			redirectToLogin();
		}
	}, []);

	return (
		<>
			{loading ? (
				<div className='loading-screen'>
					<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />

					<button className='worky-loading-icon'>w</button>
					<div className='loading-text'>Loading</div>
				</div>
			) : userType === UserType.ORG_MANAGER ? (
				<OrgHomePage userDetails={userDetails} />
			) : (
				<div className='App'>
					<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />
					<JobberHomePageDesktop jobberStatus={jobberStatus} />
				</div>
			)}
		</>
	);
};

export { WorkyHomePage };
