import React, {useState, useEffect} from 'react';

import SplashPageDesktop from './desktop_splash';
import SplashPageMobile from './mobile_splash';
import {DesktopSplashV2} from './DesktopSplashV2';
import {MobileSplashV2} from './MobileSplashV2';
import {MobileSplashV3} from './MobileSplashV3';


function SplashPage() {



  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 920);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 920);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  console.log("Inside the actual react page version, routed")

  return (
    <div className="App">
      {isDesktop ? <DesktopSplashV2 /> : <MobileSplashV3 />}
      {/* <DesktopSplashV2 /> */}
    </div>
  );

}

export {SplashPage};
