import React, { useState, useEffect, useRef , useMemo} from 'react';
import axios, { AxiosResponse } from 'axios';
import emailjs from 'emailjs-com';
import {
	ServerResponse,
	redirectToLogin,
	RemoteExecutionServiceImpl,
	ServerErrorDetails,
	AuthGetCall,
	WorkySignInResponse,
	WorkyAuthRefreshRequest,
	isExpiredCalc,
	RemoteGETCallInputObject,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import Modal from '@mui/material/Modal';

import './shifthistory.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ShiftsCompletedTable } from './tableComponents/shiftsCompletedTable';
import { ShiftsAssignedTable } from './tableComponents/shiftsAssignedTable';
import { PaymentsDueTable } from './tableComponents/paymentsDueTable';
import { PreviousPaymentsTable } from './tableComponents/prevPaymentsTable';
import { TopNav } from './OrgCommonComponents';
import { Tooltip } from '@mui/material';
import { SRIPResponseData, sortDataByWorkyWorkdayShift } from './OrgHomepage';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { useIsMobile } from '../custom-hooks/useIsMobile';


import {
	BillingInfo,
	ShiftPayment,
	WorkyBillPaymentBreakdown,
	ShiftDetails,
} from './tableComponents/PaymentTableInterfaces';
const remoteCallObject = RemoteExecutionServiceImpl();
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;


const PaymentsV2 = (orgBillingInfo,orgBillingInfoPaid) => {

	console.log("TESTTERRR1 + + "+JSON.stringify(orgBillingInfo));
    const isMobile = useIsMobile();

	return (
		<div className={isMobile?'payments-container-mobile':'payments-container'}>
			<div className={isMobile?'payments-title-mobile':'payments-title'}>Payments</div>
			<div className='payments-holder'>
				<Accordion style={{boxShadow:"none", minHeight:"70px", display: "flex", flexDirection: "column", justifyContent:"center", width: "100%", }}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1-content"
					id="panel1-header"
					style={{display:"flex", justifyContent:"center", alignItems:"center"}}
					>
						<Tooltip placement='left' title='Outstanding Bills'>
							<InfoOutlinedIcon sx={{ fontSize: '15px',paddingTop:"5px", paddingRight:"5px" }} />
						</Tooltip>{' '}
						<span className='payments-inner-title'>Payments Due</span>

					</AccordionSummary> 
					<AccordionDetails style={{padding:"0"}}>
					{PaymentsDueTable(orgBillingInfo)}
					</AccordionDetails>
				</Accordion>

				<Accordion style={{boxShadow:"none", minHeight:"70px", display: "flex", flexDirection: "column", justifyContent:"center", width: "100%", }}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1-content"
					id="panel1-header"
					style={{display:"flex", justifyContent:"center", alignItems:"center"}}
					>
						<Tooltip placement='left' title='Records of Previous Bills'>
						<InfoOutlinedIcon sx={{ fontSize: '15px',paddingTop:"5px", paddingRight:"5px" }} />
						</Tooltip>{' '}
						<span className='payments-inner-title'>Previous Payments</span>

					</AccordionSummary>
					<AccordionDetails style={{padding:"0", paddingBottom:"15px"}}>
						{PreviousPaymentsTable(orgBillingInfoPaid)}
					</AccordionDetails>
				</Accordion>

			</div>
		</div>
	);



};


const ShiftHistoryTabV2 = (combinedBillingInfo:BillingInfo[]) => {
    const isMobile = useIsMobile();

	return (
		<div className={isMobile?'shifthistory-container-mobile':'shifthistory-container'}>
			<div className={isMobile?'shifthistory-title-mobile':'shifthistory-title'}>Shift History</div>
			<div className='shifthistory-holder'>

			<Accordion style={{boxShadow:"none", minHeight:"70px", display: "flex", flexDirection: "column", justifyContent:"center", width: "100%", }}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1-content"
					id="panel1-header"
					style={{display:"flex", justifyContent:"center", alignItems:"center"}}
					>
						<Tooltip placement='left' title='Shift timings + worker specifics'>
							<InfoOutlinedIcon sx={{ fontSize: '15px',paddingTop:"5px", paddingRight:"5px" }} />
						</Tooltip>{' '}
						<span className='payments-inner-title'>Scheduled Shifts</span>

					</AccordionSummary> 
					<AccordionDetails style={{padding:"0"}}>
						{ShiftsAssignedTable()}
					</AccordionDetails>
				</Accordion>

				<Accordion style={{boxShadow:"none", minHeight:"70px", display: "flex", flexDirection: "column", justifyContent:"center", width: "100%", }}>
					<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1-content"
					id="panel1-header"
					style={{display:"flex", justifyContent:"center", alignItems:"center"}}
					>
						<Tooltip placement='left' title='Shifts that have been completed'>
							<InfoOutlinedIcon sx={{ fontSize: '15px',paddingTop:"5px", paddingRight:"5px" }} />
						</Tooltip>{' '}
						<span className='payments-inner-title'>Shifts Completed</span>

					</AccordionSummary> 
					<AccordionDetails style={{padding:"0", paddingBottom:"15px"}}>
							{ShiftsCompletedTable(combinedBillingInfo)}
					</AccordionDetails>
				</Accordion>

		
			</div>
		</div>
	);
};

const ShiftHistory = () => {
	const isMobile = useIsMobile();
	const [orgBillingInfo, setOrgBillingInfo] = useState<BillingInfo[] | null>(null);
	const [orgBillingInfoPaid, setOrgBillingInfoPaid] = useState<BillingInfo[] | null>(null);
	const [orgSRIPResponse, setOrgSRIPResponse] = useState<SRIPResponseData[] | null>(null);
	const combinedBillingInfo = useMemo(() => {
        if (orgBillingInfo !== null && orgBillingInfoPaid !== null) {
            return [...orgBillingInfo, ...orgBillingInfoPaid];
        }
        return null;
    }, [orgBillingInfo, orgBillingInfoPaid]);


	console.log("MEMO combined billing info : " + JSON.stringify(combinedBillingInfo));



	const firstName = localStorage.getItem('firstName');
	const lastName = localStorage.getItem('lastName');
	const userId = localStorage.getItem('userId');
	const userEmail = localStorage.getItem('userEmail');
	const iconUrl = localStorage.getItem('orgIcon');

	const userPhoneNumber = localStorage.getItem('userPhoneNumber');
	const companyname = localStorage.getItem('orgName');


	console.log("!!!!!!!"+orgSRIPResponse)

	const getOrgPayments = async () => {
		console.log('call to api');

		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: '/shiftpayment/v2/org/unpaid',
			headers: {},
			queryParams: null,
		};

		const billingResp: AxiosResponse<ServerResponse<BillingInfo[], Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<BillingInfo[], Error>>(
				getCallInput
			);

		console.log('API resp : {} ', billingResp.data);
		setOrgBillingInfo(billingResp.data.data);
	};

	const getOrgPaidPayments = async () => {
		console.log('call to api');

		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: '/shiftpayment/org/paid',
			headers: {},
			queryParams: null,
		};

		const billingResp: AxiosResponse<ServerResponse<BillingInfo[], Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<BillingInfo[], Error>>(
				getCallInput
			);

		console.log('API resp : {} ', billingResp.data);
		setOrgBillingInfoPaid(billingResp.data.data);
	};

	


	console.log("orgBillingInfoPaid: "+ orgBillingInfoPaid)


	useEffect(() => {
		const fetchData = async () => {
		  await getOrgPayments();
		  await getOrgPaidPayments();
		//   await getOrgSRIP();
		};
		fetchData();
	  }, []);


	//   const billingInfoStr = JSON.stringify(orgBillingInfo);
	//   console.log('org billing info ---!!! ' + billingInfoStr);

	return (
		<div className='org-app'>
			<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />

			<TopNav
				firstName={firstName}
				lastname={lastName}
				companyname={companyname}
				iconUrl={iconUrl}
				orgEmail={userEmail}
			/>

			{PaymentsV2(orgBillingInfo,orgBillingInfoPaid)}
			{ShiftHistoryTabV2(combinedBillingInfo)}
			{/* <ShiftHistoryTab /> */}

			<div onClick={()=>{window.location.assign('homepage')}} className='worky-footer'>worky</div>
		</div>
	);
};

export { ShiftHistory };
