import React, { useState, useEffect } from 'react';
import{ GeoLocationJobberService, GeoLocationJobberServiceV2, LocationComponent } from '../geo-location/JobberGeoLocationService';
import exp from 'constants';

const TestingExperimentPage = () => {
    return (
        <div>
            <h1>Testing Experiment Page</h1>
            <GeoLocationJobberService />
            <GeoLocationJobberServiceV2 />
            <LocationComponent />
        </div>
    );
}

export {TestingExperimentPage}