import React, { useState, useEffect, useRef } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import Slide from '@mui/material/Slide';
import 'react-slideshow-image/dist/styles.css';
import './mobilesplashv3.css';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import Modal from '@mui/material/Modal';
import RadarIcon from '@mui/icons-material/Radar';
import MenuIcon from '@mui/icons-material/Menu';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import Popper from '@mui/material/Popper';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DevicesIcon from '@mui/icons-material/Devices';
import BoltIcon from '@mui/icons-material/Bolt';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import VerifiedIcon from '@mui/icons-material/Verified';
import emailjs from 'emailjs-com';
import CheckIcon from '@mui/icons-material/Check';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BrushIcon from '@mui/icons-material/Brush';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BusinessIcon from '@mui/icons-material/Business';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import TextField from '@mui/material/TextField';

import {CalendlyComponent, persistSalesMetaDataCalendlyRedirect} from '../calendly-integration/CalendlyIntegrationMasterClass'
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';

const workyServerIDPSIGNUPURL = process.env.REACT_APP_IDP_SIGN_UP_URL;
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;
const bookDemoLink = "https://calendly.com/worky-pro/30min";



const MobileSplashNav = () => {
    const [isPassed,setIsPassed] = useState(false);
    const [openTray, setOpenTray] = useState(false);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const scrollPosition = urlParams.get('scroll');
        if (scrollPosition) {
            const position = parseInt(scrollPosition, 10);
            if (!isNaN(position)) {window.scrollTo({ top: position, behavior: "smooth" });}
        }
    }, []);
    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY || document.documentElement.scrollTop;
          const hasScrolledPast300px = scrollPosition > 500;
          setIsPassed(hasScrolledPast300px);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    const handleEmployersClick = () => {
        setOpenTray(false)
        window.scrollTo({ top: 3130, behavior: "smooth" });
    }

    const handleWorkersClick = () => {
        setOpenTray(false)
        window.scrollTo({ top: 4100, behavior: "smooth" });
    }

    const handleContactClick = () => {
        setOpenTray(false)
        window.scrollTo({ top: 5100, behavior: "smooth" });
    }

    const handleTrayToggle = () => {
        setOpenTray(!openTray)
    }

    const [expanded, setExpanded] = React.useState<string | false>('');

    const [tryNow, setTryNow] = useState(false);

    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
      };
    const signupClick = () => {
        window.location.assign('/scroll=5950')
    }
    const handleMobileTryNowClick = () => {
        window.location.assign('/?scroll=6600')
        setOpenTray(false)
    }
    return(
        <>
        <div className='mobile-splash-nav-v2'>
            <div onClick={() => window.location.assign('/')} className='mobile-splash-logo'>worky</div>
            <div className='mobile-splash-right'>
                {isPassed? <button onClick={handleMobileTryNowClick} id='mobile-splash-trynow-visible' className='mobile-splash-trynow'>Try Now</button>:<button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='mobile-splash-trynow'>Try Now</button>}
                <button onClick={handleTrayToggle} className='mobile-splash-tray'><MenuIcon style={{fontSize:"32px", color:"#7d7d7d"}}/></button>
            </div>
        </div>
        
        <Modal open={tryNow} onClose={() => setTryNow(false)}>
            <div className='try-now-modal'>
                <button onClick={() => setTryNow(false)} className='try-now-modal-close'><CloseIcon/></button>
                <div className='try-now-modal-title'>Employer</div>
                <div className='try-now-modal-button-holder'>
                    <button onClick={signupClick} className='try-now-modal-button'>
                        <div className='try-now-modal-button-title'>Employer</div>
                        <div className='try-now-modal-button-subtitle'>Get started today and save over 20% annually!</div>
                        <div className='try-now-modal-button-icon'><BusinessIcon style={{fontSize:"35px"}}/></div>
                    </button>
                </div>
            </div>
        </Modal>


        

        <Modal open={openTray} onClose={() => setOpenTray(false)}>
            <Slide direction="down" in={openTray} mountOnEnter unmountOnExit>
                <div className='mobile-splash-tray-v2'>
                    <button onClick={() => setOpenTray(false)} className='try-now-modal-close'><CloseIcon/></button>
                    <div onClick={() => window.location.assign('/')} className='mobile-splash-tray-v2-worky'>worky</div>
                    <div className='mobile-splash-tray-v2-button-holder'>

                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{boxShadow:"none", margin:"0px",display:"flex",justifyContent:"center",color:"#d7d7d7" ,padding:"0px"}} className="mobile-splash-tray-accordian">
                            <AccordionSummary style={{height:"60px"}} expandIcon={<ExpandMoreIcon />} className='product-mobile-accordian' aria-controls="panel1d-content" id="panel1d-header">Products</AccordionSummary>
                            <AccordionDetails>
                                <div className='accordian-product-popdown'>
                                    <button onClick={() => window.location.assign('/products?product=1')} className='popper-product'>
                                        <div className='popper-product-inner-img'>
                                            <img src='./virtual-check-in.jpg'></img>
                                        </div>
                                        <div className='popper-product-inner-info'><RadarIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Virtual Clock</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/products?product=2')} className='popper-product'>
                                        <div className='popper-product-inner-img'><img src='./worky-ipad-v2.jpg'></img></div>
                                        <div className='popper-product-inner-info'><AdsClickIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Portal</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/products?product=3')} className='popper-product'>
                                        <div className='popper-product-inner-img'>
                                            <img src='./worky-algo-mockup.jpg'/>
                                        </div>
                                        <div className='popper-product-inner-info'><AutoAwesomeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Smart Match</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/products?product=4')} className='popper-product'>
                                        <div className='popper-product-inner-img'><img src='./workypay.jpg'/></div>
                                        <div className='popper-product-inner-info'><CurrencyExchangeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Pay</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/products')} id='view-all-popper-product'>
                                        <div className='view-all-products-icon'><EastIcon/></div>
                                        View All
                                    </button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        
                        <button className='mobile-splash-tray-v2-option'>Businesses</button>
                        
                        {/* <button className='mobile-splash-tray-v2-option'>Associates</button> */}
                        
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}  style={{boxShadow:"none", color:"#d7d7d7"}} className="mobile-splash-tray-accordian">
                            <AccordionSummary style={{height:"60px"}} expandIcon={<ExpandMoreIcon />} className='product-mobile-accordian' aria-controls="panel1d-content" id="panel1d-header">Careers</AccordionSummary>
                            <AccordionDetails>
                                <div className='accordian-careers-popdown'>
                                    <button onClick={() => window.location.assign('/careers?scroll=1400')} className='popper-job'>
                                        <div className='popper-job-icon'><DataObjectIcon/></div>
                                        <div className='popper-job-title'>Data Platform Engineer</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/careers?scroll=1650')} className='popper-job'>
                                        <div className='popper-job-icon'><BrushIcon/></div>
                                        <div className='popper-job-title'>Senior Product Designer</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/careers?scroll=1950')} className='popper-job'>
                                        <div className='popper-job-icon'><DataObjectIcon/></div>
                                        <div className='popper-job-title'>Server Side Engineer</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/careers?scroll=2200')} className='popper-job'>
                                        <div className='popper-job-icon'><DataObjectIcon/></div>
                                        <div className='popper-job-title'>Senior Front End Engineer</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                        <div className='popper-job-icon'><PhoneIphoneIcon/></div>
                                        <div className='popper-job-title'>Social Media Manager</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                        <div className='popper-job-icon'><TrendingUpIcon/></div>
                                        <div className='popper-job-title'>Regional Sales Manager</div>
                                    </button>
                                    <button onClick={() => window.location.assign('/careers')} id='view-all-jobs' className='popper-job'>
                                        <div id='view-all-jobs-title' className='popper-job-title'>View All</div>
                                        <div id='view-all-jobs-icon' className='popper-job-icon'><EastIcon/></div>
                                    </button>
                                </div>
                            </AccordionDetails>
                        
                        </Accordion>
                        
                        <button onClick={()=>window.open(bookDemoLink)} className='mobile-splash-tray-v2-option'>Book Demo</button>
                        <br/><br/>
                        <button onClick={() => window.location.assign(workyServerIDPSIGNINURL)} className='mobile-splash-tray-v2-option'>Login</button>
                        <button onClick={handleMobileTryNowClick} className='mobile-splash-tray-v2-option'>Try Now</button>
                    
                    </div>
                    
                    
                    
                </div>
            </Slide>
        </Modal>
        
        
        </>
    )
}


const SplashSection1V2 = () => {
    const TEXTS = ['FASTER','EASIER','CHEAPER'];
    const [index, setIndex] = useState(0);
    const isMobile = useIsMobile();
    useEffect(() => {
        const intervalId = setInterval(() => setIndex((index) => index + 1),2000,);
        return () => clearTimeout(intervalId);
    }, []);
           
    return(
        <> 
            <div className='splash-section1-holder-v2-mobile'>
                <div className='splash-slider-container-mobile'>
                    
                    {/* <SliderTop/> */}
                    <>
                    <div className='splash-overlay'>
                        <div className='splash-overlay-title-mobile'><span className='gradient-blue'>Boost</span> your Workforce!</div>
                        {/* <div className='splash-overlay-subtitle'>Worky, the world's first subscription service for warehouse solutions & temporary staff!</div> */}
                        {/* <div className='splash-overlay-subtitle'>Equip your agency with<span className='gradient-blue'> Big Tech Innovation!</span> </div> */}
                        <div className='splash-overlay-subtitle-mobile'>Save over<span className='gradient-blue'> 20% </span> and <span className='gradient-blue'>optimize</span> your Client Relations</div>
                        <div className='splash-overlay-buttons'>
                            <button onClick={()=>window.scrollTo({ top: 5975, behavior: "smooth" })} className='splash-overlay-button'>Get Started <div className='rocket-icon'><RocketLaunchIcon/></div></button>
                            <button onClick={()=>window.open(bookDemoLink)} className='splash-overlay-button'>Book a Demo <div className='devices-icon'><DevicesIcon/></div></button>
                        </div>
                    </div>
                    <div className='splash-behind-mobile'>
                        <div className='splash-behind-third-img-mobile'>
                            <div className='splash-behind-third-img-overlay'>
                                <div className='third-img-overlay-title'>SHIFT SUBMITTED <CheckCircleRounded style={{fontSize:"16px",color:"#29D637"}}/></div>
                                <div className='third-img-overlay-bottom'>
                                    <div className='third-img-overlay-bottom-date'>June 30th</div>
                                    <div className='third-img-overlay-bottom-time'>
                                        <div className='third-img-overlay-bottom-time-inner'>9AM </div>-
                                        <div className='third-img-overlay-bottom-time-inner'> 5PM</div>
                                    </div>
                                </div>
                            </div>
                            <img src='./booking-shift-splash.jpg'></img>
                        </div>

                        <div className='splash-behind-sec-img-mobile'>
                            <div className='splash-behind-sec-img-overlay'>
                                    <div className='third-img-overlay-title'>SHIFT ACCEPTED <CheckCircleRounded style={{fontSize:"16px",color:"#29D637"}}/></div>
                                    <div className='third-img-overlay-middle'>
                                        <div className='third-img-overlay-middle-tag'><AutoAwesomeIcon style={{fontSize:"14px"}}/>TOP ASSOCIATE</div>
                                    </div>
                                    <div className='third-img-overlay-bottom'>
                                        <div className='third-img-overlay-bottom-date'>June 30th</div>
                                        <div className='third-img-overlay-bottom-time'>
                                            <div className='third-img-overlay-bottom-time-inner'>9AM </div>-
                                            <div className='third-img-overlay-bottom-time-inner'> 5PM</div>
                                        </div>
                                    </div>
                                </div>
                            <div className='jobber-accept-img-holder'>
                                <img src='./jobber-accept.jpg'></img>
                            </div>
                        </div>

                        <div className='splash-behind-main-img'>
                            <img src='./worky-laptop-mockup.jpg'></img>
                        </div>
                    </div>
                    </>

                </div>


            </div>     
            <div className='splash-partner-container-mobile'>
            <div className='splash-our-partners'>
                    {/* <div className='splash-our-partners-overlay'>Our<br/>Partners</div> */}
                    <div className='splash-our-partners-top'>Trusted by 100+ agencies in America & Canada</div>
                    <div className={isMobile? 'splash-our-partners-carousel-mobile':'splash-our-partners-carousel'}>
                        <div className='splash-our-partners-carousel-partner'>
                            <img src='./company1.jpg'/>
                        </div>
                        <div className='splash-our-partners-carousel-partner'>
                            <img src='./company2.jpg'/>
                        </div>
                        <div className='splash-our-partners-carousel-partner'>
                            <img src='./company3.jpg'/>
                        </div>
                        <div className='splash-our-partners-carousel-partner'>
                            <img src='./company4.jpg'/>
                        </div>
                    </div>
                </div>
                {/* <div className='splash-partner-title-mobile'>Trusted by <span className='simple-bold'>10k+</span> freeworkers and <span className='simple-bold'>1k+ teams</span></div> */}
            </div> 

            <div className='worky-mission-mobile'>
                Worky empowers teams to <span className='gradient-blue'>do more for less.</span>
            </div>
            <div className='worky-mission-details-mobile'>
                <div className='worky-mission-block'>
                    <div className='worky-mission-block-icon'><BoltIcon/></div>
                    <div className='worky-mission-block-title'>Fast</div>
                    <div className='worky-mission-block-text'>We know the<span className='gradient-blue'> importance of speed!</span> That's why our platform insures all your shifts are filled within<span className='gradient-blue'> 1 minute!</span></div>
                </div>

                <div className='worky-mission-block'>
                    <div className='worky-mission-block-icon'><VerifiedIcon/></div>
                    <div className='worky-mission-block-title'>Reliable</div>
                    <div className='worky-mission-block-text'>When you succeed, we succeed! Worky uses <span className='gradient-blue'>AI</span> to vet workers, ensuring <span className='gradient-blue'>highest standards of reliability!</span></div>
                </div>

                <div className='worky-mission-block'>
                    <div className='worky-mission-block-icon'><HealthAndSafetyIcon/></div>
                    <div className='worky-mission-block-title'>Safe</div>
                    <div className='worky-mission-block-text'>All Worky associates undergo <span className='gradient-blue'>routine training</span>, and our platform is fully <span className='gradient-blue'>encrypted end-to-end.</span></div>
                
                </div>
            </div>
        </>
    )
}

const ForEmployersMobile = () => {
    return(
        <div className='worker-employer-box-mobile'>
            <div className='products-display-title'><span className='gradient-blue'>For Employers</span></div>

            <div className='worker-employer-box-img-mobile'>
                            <img src='./worky-mockup-v2.jpg' />
                        </div>
                        <div className='worker-employer-box-info-mobile'>

                            <div className='worker-employer-box-info-section'>
                                <div className='worker-employer-box-info-title'><LooksOneIcon/>Dispatch + Track Employees</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Submit shifts for employees using AI</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Ensures only the best workers</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Track Real-time location</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View Virtual Check-ins</div>
                            </div>

                            <div className='worker-employer-box-info-section'>
                                <div className='worker-employer-box-info-title'><LooksTwoIcon/>Budget for the week</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Set a Weekly budget</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Track Real-time spend</div>
                            </div>
                            
                            <div className='worker-employer-box-info-section'>
                                <div className='worker-employer-box-info-title'><Looks3Icon/>View your Payments</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View all previous shifts</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View all previous payments</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View all previous WorkyPays</div>
                            </div>

                            <div className='worker-employer-box-info-section'>
                                <div className='worker-employer-box-info-title'><Looks4Icon/>Pay Employees Directly</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Pay workers directly</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Set your Pay Schedule</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Save on Payroll Costs</div>
                            </div>

                        </div>
                        
                    </div>
    )
}
const ForEmployeesMobile = () => {
    return(
        <div className='worker-employer-box-mobile'>
            <div className='products-display-title'><span className='gradient-blue'>For Associates</span></div>

            <div className='worker-employer-box-img-mobile'>
            <img src='./phonearray2.jpg' />
                        </div>
                        <div className='worker-employer-box-info-mobile'>

                        <div className='worker-employer-box-info-section'>
                                <div className='worker-employer-box-info-title'><LooksOneIcon/>Take Control of YOUR Time</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>YOUR schedule!</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>YOUR area!</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>YOUR field!</div>
                            </div>

                            <div className='worker-employer-box-info-section'>
                                <div className='worker-employer-box-info-title'><LooksTwoIcon/>Build Your Profile</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Set a Profile to get noticed</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Build your skills</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Get more Shifts!</div>
                            </div>
                            
                            <div className='worker-employer-box-info-section'>
                                <div className='worker-employer-box-info-title'><Looks3Icon/>View all Previous Shifts</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Track your Past Shifts</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Ensure Correct Hours</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Ensure Correct Payments</div>
                            </div>

                            <div className='worker-employer-box-info-section'>
                                <div className='worker-employer-box-info-title'><Looks4Icon/>View all Upcoming Shifts</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View your Next Shift</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Pick up more Shifts!</div>
                                <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Cancel Shifts</div>
                            </div>

                        </div>
                        
                    </div>
    )
}

const ProductOneMobile = () => {
    const portaltext="Presenting our location-based clock-in and clock-out system for effortless time tracking. With real-time GPS verification and an intuitive interface, it enhances efficiency, ensuring precise time logs. Completely adaptable to your business requirements."
    return(
        <div className='worky-product-container-mobile'>

            <div className='worky-product-content-container-mobile'>
                <div className='worky-product-title-mobile'><RadarIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Virtual Clock</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=1')} className='worky-product-button-mobile'>Learn More</button>
            </div>
            <div className='worky-product-img-container-mobile'>
                <img src='./virtual-check-in.jpg'></img>
            </div>
        </div>
    )
}
// new
const ProductTwoMobile = () => {
    const portaltext="Introducing our check-in and check-out tablet for seamless time tracking. With real-time updates, user-friendly design, and advanced security, it streamlines operations, ensuring accurate records. Fully customizable to fit your business needs."
    return(
        <div className='worky-product-container-mobile'>

            <div className='worky-product-content-container-mobile'>
                <div className='worky-product-title-mobile'><AdsClickIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Worky Portal</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=2')} className='worky-product-button-mobile'>Learn More</button>
            </div>
            <div className='worky-product-img-container-mobile'>
                <img src='./worky-ipad-v2.jpg'/>
            </div>

            
        </div>
    )
}
// new
const ProductThreeMobile = () => {
    const portaltext="Discover our AI-powered job matching system for optimal staffing. Utilizing cutting-edge algorithms, it swiftly pairs the most qualified worker to each shift based on numerous criteria. Efficient, intelligent, and tailored to your specific business needs."
    return(
        <div className='worky-product-container-mobile'>
            <div className='worky-product-content-container-mobile'>
                <div className='worky-product-title-mobile'><AutoAwesomeIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Smart Match</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=3')} className='worky-product-button-mobile'>Learn More</button>
            </div>
            <div style={{marginTop:"-80px"}} className='worky-product-img-container-mobile'>
                <img src='./worky-algo-mockup.jpg'/>
            </div>
        </div>
    )
}
// new
const ProductFourMobile = () => {
    const portaltext="Introducing our comprehensive shift management and payroll system. Offering real-time shift tracking, precise logging of payments due and received, and detailed records of past shifts. Easily manage payroll and pay employees in one click!"
    return(
        <div className='worky-product-container-mobile'>


            <div className='worky-product-content-container-mobile'>
                <div className='worky-product-title-mobile'><CurrencyExchangeIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Worky Pay</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=4')} className='worky-product-button-mobile'>Learn More</button>
            </div>
            
            <div className='worky-product-img-container-mobile'>
            <img src='./workypay.jpg'/>
            </div>
        </div>
    )
}
const SplashContactV2 = () => {
    const [isFormSubmitted, setFormSubmitted] = useState(false);

    const handleGeneralEmailSubmit = async (e) => {
        e.preventDefault();

        if (isFormSubmitted) {
            return;
        }
        setFormSubmitted(true);

        const serviceID = 'service_3mlm2ls';
        const templateID = 'template_xl6xpjw';
        const userID = 'zKdgUxA7H3dMaCf-m';

        try {
            await emailjs.sendForm(serviceID, templateID, e.target, userID);
            alert('Email sent successfully!');
        } catch (error) {
            alert('An error occurred while sending the email.');
            console.error(error);
        } finally {
            setFormSubmitted(false);
        }
    };

    return (
        <>
            <div className='splash-contact-container-mobile'>
                <div className='splash-contact-title-v2'><span className='gradient-blue'>Lets Get Started!</span></div>
                <div className='splash-contact-subtitle-v2-mobile'>A live agent is waiting for you on the other side!</div>
                <div className='splash-contact-buttons-v2-mobile'>
                    <button className='splash-contact-button-v2-mobile'><CallIcon/>(647) 494-9090</button>
                    <button className='splash-contact-button-v2-mobile'><MailIcon/>info@worky.pro</button>
                </div>
            </div>

            <form className='splash-contact-inner-mobile' onSubmit={handleGeneralEmailSubmit}>
                
                <TextField id="standard-basic" label="First Name" variant="standard" className='standard-basic-input-mobile' name="user_firstname" required />
                <TextField id="standard-basic" label="Last Name" variant="standard" className='standard-basic-input-mobile' name="user_lastname" required />
                <TextField id="standard-basic" label="Email" type="email" variant="standard" className='standard-basic-input-mobile' name="user_email" required />
                <TextField id="standard-basic" label="Phone Number" type="number" variant="standard" className='standard-basic-input-mobile' name="user_phone" required />
                <TextField id="standard-basic" label="Business Name" variant="standard" className='standard-basic-input-mobile' name="user_businessname" required />
                <TextField id="standard-basic" label="Province" variant="standard" className='standard-basic-input-mobile' name="user_province" required />
                <TextField
                    id="standard-multiline-static"
                    label="Tell Us About Your Business"
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="standard"
                    className='standard-basic-message-mobile'
                    name="user_message"
                    required
                />

                <>
                <button style={{marginTop:"20px"}} onClick={()=>window.open(bookDemoLink)} id='get-started-demo' className='get-started-button'>Book A Demo</button>
                <button style={{marginTop:"",marginBottom:"40px"}} type="submit" value="Submit" className='get-started-button'>{isFormSubmitted ? 'Submitting...' : 'Send'}</button>
                </>

            </form>
        </>
    )
}

const MobileWorkyFooter = () => {

    return(
        <div className='mobile-splash-footer'>
            <div className='mobile-splash-footer-title'>worky</div>
            <div className='mobile-splash-footer-socials'>
                <div id='social-facebook' className='mobile-splash-footer-social'></div>
                <div id='social-instagram' className='mobile-splash-footer-social'></div>
                <div id='social-twitter' className='mobile-splash-footer-social'></div>
                <div id='social-linkedin' className='mobile-splash-footer-social'></div>
                <div id='social-tiktok' className='mobile-splash-footer-social'></div>
            </div>
        </div>
    )
}


const MobileSplashV3 = () => {


    return(

            <div className='mobile-splash-v2'>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />        
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='' />
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>
            <MobileSplashNav/>
            <SplashSection1V2/>
            {/* <ForEmployersMobile/> */}
            {/* <ForEmployeesMobile/> */}
            {/* <div style={{marginTop:""}} className='worky-mission-mobile'><span className='gradient-blue'>Scale effortlessly </span>with Worky's <span className='gradient-blue'> warehouse solutions</span></div> */}
            <ProductOneMobile/>
            <ProductTwoMobile/>
            <ProductThreeMobile/>
            <ProductFourMobile/>
            <SplashContactV2/>
            <MobileWorkyFooter/>
            </div>

    )
}

export {MobileSplashV3, MobileSplashNav}