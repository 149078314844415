import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios, { AxiosResponse } from 'axios';
import Rating from '@mui/material/Rating';
import {
	handleRetrieveNewTokens,
	ServerResponse,
	redirectToLogin,
	RemoteExecutionServiceImpl,
	ServerErrorDetails,
	AuthGetCall,
	WorkySignInResponse,
	WorkyAuthRefreshRequest,
	isExpiredCalc,
	RemoteGETCallInputObject,
	UpdateLocalCacheWOAuthInfo,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import {OrgMetaData} from './orgConstants';
import { TopNav, PushToNewSr, PushtoSrHistory } from './OrgCommonComponents';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import './myworkforce.css'
import { JobberProfileBuilder, GetJobberProfileAPIResp, JobberOrgRatingClientResp, OrgSpecifiedJobberPerformanceCategory, CreateOrgRatingForJobberReq, JobberPerformanceOrg,OrgAllJobberRatingsResp } from './orgConstants';
import{ JobberProfileComponent} from './JobberProfileComponentOpen';
import {GetJobberResume, GetJobberProfile} from '../shared-engineering-services/SharedAPICalls'
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;

const copyToClipboard = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      console.log("Copied to clipboard: " + value);
    } catch (err) {
      console.error("Unable to copy to clipboard:", err);
    }
};

const MyWorkforce = () => {
    const [refreshKey, setRefreshKey] = useState(0);
    const remoteCallObject = RemoteExecutionServiceImpl();
    const [orgIcon, setOrgIcon] = useState<string | null>(null);
	const [orgMetaData, setOrgMetadata] = useState<OrgMetaData | null>(null);
	const [jobberRatingData, setJobberRatingData] = useState<OrgAllJobberRatingsResp | null>(null);
    const firstName = localStorage.getItem('firstName');
	const lastName = localStorage.getItem('lastName');
	const userId = localStorage.getItem('userId');
	const userEmail = localStorage.getItem('userEmail');
    const iconUrl = localStorage.getItem('orgIcon');
	const userPhoneNumber = localStorage.getItem('userPhoneNumber');
    const companyname= localStorage.getItem('orgName'); 
    const isMobile = useIsMobile();
    const handleRefreshCallback = () => {
        console.log(" heheheheh ")
        setRefreshKey(refreshKey+1)
        getAllJobberRating();
    }

    const getOrgIcon = async () => {
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'org/iconv2',
			headers: {},
			queryParams: null,
		};

		const orgIconRes: AxiosResponse<ServerResponse<string, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

		if (orgIconRes && orgIconRes.data && orgIconRes.data.data) {			
			localStorage.setItem('orgIcon', orgIconRes.data.data);
			setOrgIcon(orgIconRes.data.data);
		}
	};

    const getOrgMetadata = async () => {
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'org/metadata',
			headers: {},
			queryParams: null,
		};

		const orgMetadataRes: AxiosResponse<ServerResponse<OrgMetaData, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<OrgMetaData, Error>>(getCallInput);

		if (orgMetadataRes && orgMetadataRes.data && orgMetadataRes.data.data) {
			console.log('step 3');
			localStorage.setItem('orgName', orgMetadataRes.data.data.orgName);
			localStorage.setItem('orgData', JSON.stringify(orgMetadataRes.data.data));
			setOrgMetadata(orgMetadataRes.data.data);
		}
	};

    const getAllJobberRating = async () => {
        const getCallInput: RemoteGETCallInputObject = {
          apiUrl: backendApiBasePathLocal + 'jobber/performance/rating/org/all-jobber',
          headers: {},
          queryParams: null,
        };
  
        const jobberRating: AxiosResponse<ServerResponse<OrgAllJobberRatingsResp, Error>> =
            await remoteCallObject.remoteGETCallV2<ServerResponse<OrgAllJobberRatingsResp, Error>>(getCallInput);

          setJobberRatingData(jobberRating.data.data);

          
      };
  


	useEffect(() => {
		getOrgMetadata();
		getOrgIcon();
        getAllJobberRating();
	}, []);



    
    const WorkforceJobberBuilder = () => {
        const [modalOpen, setModalOpen] = useState(false);
        const handleClose = () => {	setModalOpen(false); }

        const [selectedJobberFullProfile, setSelectedJobberFullProfile] = useState<JobberProfileBuilder>(null);
        
        // useEffect( ()=>{
        //     renderJobbers();
        // },[refreshKey])

    const handleCardClick = async(jobberDetails: JobberOrgRatingClientResp) => {
        
        const jobberProfileDetails: GetJobberProfileAPIResp = await GetJobberProfile(jobberDetails.jobberDetails.userEmail.value);
        
        
        setSelectedJobberFullProfile({jobberEmail: jobberDetails.jobberDetails.userEmail.value,
             jobberName: jobberDetails.jobberDetails.firstName + ' ' + jobberDetails.jobberDetails.lastName,
              jobberPhone: jobberDetails.jobberDetails.userPhoneNumber.value,
               jobberPic: jobberDetails.jobberProfileImageS3Url,
            jobberProfileQuickSummary: jobberProfileDetails?.jobberQuickSummary,
            jobberOrgRatingDetailMap: jobberDetails.jobberOrgRatingDetailMap
        }
            );
               setModalOpen(true);
    };
        
        const renderJobbers = () => {
            if (!jobberRatingData || !jobberRatingData.jobberOrgRatingClientRespMap) {
                return null; // Handle case when data is not available
            }
        
            return Object.entries(jobberRatingData.jobberOrgRatingClientRespMap).map(([key, value]) => {
                if(!value){return}

                const jobberOrgRatingClientResp: JobberOrgRatingClientResp = value;

                
                
                const jobberOrgRatingDetailMapStringKey = {};   
                Object.entries(jobberOrgRatingClientResp.jobberOrgRatingDetailMap).forEach(([value, key]) => {
                    // Insert key-value pair into the new map
                    jobberOrgRatingDetailMapStringKey[value]= key;
                });
                

                // if(jobberOrgRatingDetailMapStringKey[key.value])
        
                
                
                return (
                    <div
                    className='workforce-jobber-card'
                    key={key}
                    onClick={() => handleCardClick(jobberOrgRatingClientResp)}
                >
                        <img className='workforce-jobber-card-icon' src={jobberOrgRatingClientResp.jobberProfileImageS3Url} alt='Jobber Icon' />
                        <div className='workforce-jobber-card-name'>{jobberOrgRatingClientResp.jobberDetails.firstName} {jobberOrgRatingClientResp.jobberDetails.lastName}</div>
                        <div className='workforce-jobber-card-stars'><Rating size="medium" name="half-rating-read" defaultValue={5} precision={0.5} readOnly /></div>
                        <div className='workforce-jobber-card-contact-holder'>
                            <div className='workforce-jobber-card-contact-email'>{jobberOrgRatingClientResp.jobberDetails.userEmail.value}</div>
                            <div className='workforce-jobber-card-contact-phone'>{jobberOrgRatingClientResp.jobberDetails.userPhoneNumber.value}</div>
                        </div>
                    </div>
                );
            });
        };
    
        return (
            <div className={isMobile ? 'workforce-holder-mobile' : 'workforce-holder'}>
                {renderJobbers()}
             
            {selectedJobberFullProfile && modalOpen
            ? <JobberProfileComponent modalOpen={modalOpen}
             setModalOpen={setModalOpen}
              jobberProfile={selectedJobberFullProfile}              
              handleRefreshCallback = {handleRefreshCallback} /> 
            : null}
        
            
            
            </div>
        );
    };



    return(
        <>
            <div className='org-app'>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />       

            <TopNav
                firstName={firstName}
                lastname={lastName}
                companyname={companyname}
                iconUrl={iconUrl}
                orgEmail={userEmail}
            />


            <div className={isMobile ? 'myworkforce-title-mobile':'myworkforce-title'}>My Workforce</div>

            <WorkforceJobberBuilder/>

			<div onClick={()=>{window.location.assign('homepage')}} className='worky-footer'>worky</div>


            </div>
        </>
    )
}


export {MyWorkforce}