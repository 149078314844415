import React, { useState, useEffect, useRef } from 'react';
import Modal from '@mui/material/Modal';
import emailjs from 'emailjs-com';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import Slide from '@mui/material/Slide';
import './mobileNav.css';
import MenuIcon from '@mui/icons-material/Menu';
import Popper from '@mui/material/Popper';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CallIcon from '@mui/icons-material/Call';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';



const PushToNewSr = () => {
	window.location.assign('/new-shift');
};
const PushtoSrHistory = () => {
    window.location.assign('/shift-history');
}
const PushtoMyWorkforce = () => {
    window.location.assign('/my-workforce');
}
const PushtoCurrentShifts = () => {
    window.location.assign('/current-shifts');
}
const PushtoHomepage = () => {
    window.location.assign('/homepage');
}
const PushtoOrgSettings = () => {
    window.location.assign('/account')
}
const signOutURI = process.env.REACT_APP_IDP_SIGN_OUT_URL;



const TopNav = ({
	firstName,
	lastname,
	companyname,
	iconUrl,
	orgEmail,
}: {
	firstName: string;
	lastname: string;
	companyname: string;
	iconUrl?: string;
	orgEmail: string;
}) => {
    
    const [modalOpen, setModalOpen] = useState(false);
	const handleOpen = () => {setModalOpen(true);}
	const handleClose = () => setModalOpen(false);
    
    const isMobile = useIsMobile();


	const handleGeneralEmailSubmit = async (e) => {
        e.preventDefault();
    
        const serviceID = 'service_3mlm2ls';
        const templateID = 'template_xl6xpjw';
        const userID = 'zKdgUxA7H3dMaCf-m';

        try {
          await emailjs.sendForm(serviceID, templateID, e.target, userID);
          alert('Email sent successfully!');
        } catch (error) {
          alert('An error occurred while sending the email.');
          console.error(error);
        }
      };

    const defaultPic = './org-default-icon.png';

	const imageComponent = (url) => {
		if (url) {
			console.log('company url provided');
			return (<img className='topnav-org-icon' src={url}/>);
		} else {
			console.log('url was not provided');
			return (<img className='topnav-org-icon' src={defaultPic}/>);
		}
	};

    const DesktopNav = () => {
        return(
            <div className='topnav-holder'>
            <div className='topnav-cushion-left'></div>
            <div className='topnav'>
                <button onClick={PushtoHomepage} className='topnav-logo'>worky <span className='company-name'> {companyname}</span></button>
                <div className='topnav-option-holder'>
                    {window.location.pathname.includes('/current-shifts') ? <button onClick={PushtoCurrentShifts} className='topnav-option-hover'>Planned Shifts</button> : <button onClick={PushtoCurrentShifts} className='topnav-option'>Planned Shifts</button>}
                    {window.location.pathname.includes('/new-shift') ? <button onClick={PushToNewSr} className='topnav-option-hover'>New Shift</button> : <button onClick={PushToNewSr} className='topnav-option'>New Shift</button>}
                    {window.location.pathname.includes('/shift-history') ? <button onClick={PushtoSrHistory} className='topnav-option-hover'>Shift History</button> : <button onClick={PushtoSrHistory} className='topnav-option'>Shift History</button>}
                    {window.location.pathname.includes('/my-workforce') ? <button onClick={PushtoMyWorkforce} className='topnav-option-hover'>Workforce</button> : <button onClick={PushtoMyWorkforce} className='topnav-option'>Workforce</button>}
                    <button onClick={handleOpen} className='topnav-option'>Contact Us</button>
                    
                    <Modal open={modalOpen} onClose={handleClose}>
                        <form className='modal-contact' onSubmit={handleGeneralEmailSubmit}>
                            <button onClick={handleClose} className='modal-close'><CloseIcon/></button>

                            <div className='srstatus-date'>Having an Issue?</div>
                            <div id="modal-title2" className='srstatus-time'>We guarantee to get back to you in under 1 hour!</div>
                            <div className='modal-title'>Email: <span className='modal-info'>info@worky.pro</span></div>
                            <div id='modal-title-2' className='modal-title'>Phone: <span className='modal-info'>(647) 494-9090</span></div>
                            <input className='modal-email' name="user_email" defaultValue={orgEmail === null || orgEmail === undefined? 'info@worky.pro':orgEmail}/>
                            <div className='modal-title'>Message:</div>
                            <textarea name="user_message" className='modal-message'></textarea>
                            <button className='modal-submit'>Send</button>
                        </form>
                    </Modal>

                </div>
                <div className='topnav-right'>
                    <div className='topnav-right-static'>
                        <div className='topnav-right-name'>{firstName + ' ' + lastname}</div> 
                        <div className='topnav-right-icon'>{imageComponent(iconUrl)}</div>
                    </div>

                    <div className='topnav-right-hover'>
                        <button onClick={PushtoOrgSettings} className='topnav-right-signout'>Account Settings</button>
                    </div>

                </div>
            </div>
            <div className='topnav-cushion-right'></div>
        </div>
        )
    }

    


    const MobileNav = () => {
        const [tray, setTray] = useState(false);
        const handleCloseTray = () => {
            setTray(false)
        }
        const handleOpenTray = () => {
            setTray(true)
        }
        const appTray = useRef(null);

        const defaultPic = './org-default-icon.png';
        
        // const [openProfile, setOpenProfile] = useState(false);
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const handlePopper = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
        const open = Boolean(anchorEl);
        const id = open ? 'mobile-profile-popper-open' : undefined;


        return(
            <>
                <div className='mobile-app-nav-v2'>
                    <div className='mobile-app-nav-left'>
                        <button onClick={handleOpenTray} className='mobile-app-tray'><MenuIcon style={{fontSize:"32px", color:"#7d7d7d"}}/></button>
                        <div onClick={PushtoHomepage} className='mobile-app-logo'>worky</div>
                    </div>

                    <div className='mobile-app-nav-right'>
                        <div onClick={handlePopper} className='mobile-inner-profile'>
                            <div className='mobile-profile-icon'><img src={iconUrl ? iconUrl:defaultPic}/></div>
                                <Popper placement={'bottom-end'} id={id} open={open} anchorEl={anchorEl}>
                                    <div className='profile-info-popper-point-container'></div>        
                                    <div className='profile-info-popper'>
                                        <div className='popper-row-name'>{firstName + ' ' + lastname}</div>
                                        <div className='popper-line'></div>
                                        <div onClick={PushtoOrgSettings} className='popper-row'>
                                            <div className='popper-row-icon'><ManageAccountsOutlinedIcon style={{fontSize:"18px"}}/></div>
                                            <div className='popper-row-info'>Account Settings</div>
                                        </div>
                                        <div onClick={handleOpen} className='popper-row'>
                                            <div className='popper-row-icon'><CallOutlinedIcon style={{fontSize:"18px"}}/></div>
                                            <div className='popper-row-info'>Help</div>
                                        </div>
                                        <div className='popper-line'></div>
                                        <div onClick={() => {window.location.assign(signOutURI);}} className='popper-row'>
                                            <div className='popper-row-icon'><LogoutIcon style={{fontSize:"18px"}}/></div>
                                            <div className='popper-row-info'>Log Out</div>
                                        </div>
                                    </div>
                                </Popper>
                        </div>
                    </div>
                </div>
                {/* APP TRAY */}
                <Modal open={tray} onClose={handleCloseTray}>
                    <Slide in={tray} container={appTray.current}>
                        <div ref={appTray} className='app-tray'>
                            <div id='tray-worky-logo' onClick={PushtoHomepage} className='mobile-app-logo'>worky</div>

                            {window.location.pathname.includes('/homepage') ?
                            (<div onClick={PushtoHomepage} className='app-tray-row'>
                                <div className='app-tray-icon'><HomeOutlinedIcon style={{fontSize:"18px", color:"#4c4c4c"}}/></div>
                                <div style={{fontWeight:600}} className='app-tray-info'>Home</div>
                            </div>):
                            (<div onClick={PushtoHomepage} className='app-tray-row'>
                            <div className='app-tray-icon'><HomeOutlinedIcon style={{fontSize:"18px"}}/></div>
                            <div className='app-tray-info'>Home</div>
                            </div>)
                            }


                            {window.location.pathname.includes('/current-shifts') ?
                            (<div onClick={PushtoCurrentShifts} className='app-tray-row'>
                                <div className='app-tray-icon'><CalendarMonthOutlinedIcon style={{fontSize:"18px", color:"#4c4c4c"}}/></div>
                                <div style={{fontWeight:600}} className='app-tray-info'>Planned Shifts</div>
                            </div>):
                            (<div onClick={PushtoCurrentShifts} className='app-tray-row'>
                            <div className='app-tray-icon'><CalendarMonthOutlinedIcon style={{fontSize:"18px"}}/></div>
                            <div className='app-tray-info'>Planned Shifts</div>
                            </div>)
                            }


                            {window.location.pathname.includes('/new-shift') ?
                            (<div onClick={PushToNewSr} className='app-tray-row'>
                                <div className='app-tray-icon'><AddBoxOutlinedIcon style={{fontSize:"18px", color:"#4c4c4c"}}/></div>
                                <div style={{fontWeight:600}} className='app-tray-info'>New Shift</div>
                            </div>):
                            (<div onClick={PushToNewSr} className='app-tray-row'>
                            <div className='app-tray-icon'><AddBoxOutlinedIcon style={{fontSize:"18px"}}/></div>
                            <div className='app-tray-info'>New Shift</div>
                            </div>)
                            }


                            {window.location.pathname.includes('/shift-history') ?
                            (<div onClick={PushtoSrHistory} className='app-tray-row'>
                                <div className='app-tray-icon'><HistoryIcon style={{fontSize:"18px", color:"#4c4c4c"}}/></div>
                                <div style={{fontWeight:600}} className='app-tray-info'>Shift History</div>
                            </div>):
                            (<div onClick={PushtoSrHistory} className='app-tray-row'>
                                <div className='app-tray-icon'><HistoryIcon style={{fontSize:"18px"}}/></div>
                                <div className='app-tray-info'>Shift History</div>
                            </div>)
                            }


                            {window.location.pathname.includes('/my-workforce') ?
                            (<div onClick={PushtoMyWorkforce} className='app-tray-row'>
                                <div className='app-tray-icon'><PeopleOutlinedIcon style={{fontSize:"18px", color:"#4c4c4c"}}/></div>
                                <div style={{fontWeight:600}} className='app-tray-info'>Workforce</div>
                            </div>):
                            (<div onClick={PushtoMyWorkforce} className='app-tray-row'>
                                <div className='app-tray-icon'><PeopleOutlinedIcon style={{fontSize:"18px"}}/></div>
                                <div className='app-tray-info'>Workforce</div>
                            </div>)
                            }


                            {/* contact us */}
                            <div onClick={handleOpen} className='app-tray-row'>
                                <div className='app-tray-icon'><CallOutlinedIcon style={{fontSize:"18px"}}/></div>
                                <div className='app-tray-info'>Contact Us</div>
                            </div>


                            <button onClick={handleCloseTray} className='app-tray-close'></button>
                        </div>
                    </Slide>
                </Modal>

                <Modal open={modalOpen} onClose={handleClose}>
                    <form className='modal-contact' onSubmit={handleGeneralEmailSubmit}>
                        <button onClick={handleClose} className='modal-close'><CloseIcon/></button>
                        <div className='srstatus-date'>Having an Issue?</div>
                        <div id="modal-title2" className='srstatus-time'>We guarantee to get back to you in under 1 hour!</div>
                        <div className='modal-title'>Email: <span className='modal-info'>info@worky.pro</span></div>
                        <div id='modal-title-2' className='modal-title'>Phone: <span className='modal-info'>(647) 494-9090</span></div>
                        <input className='modal-email' name="user_email" defaultValue={orgEmail === null || orgEmail === undefined? 'info@worky.pro':orgEmail}/>
                        <div className='modal-title'>Message:</div>
                        <textarea name="user_message" className='modal-message'></textarea>
                        <button className='modal-submit'>Send</button>
                    </form>
                </Modal>
            
            </>
        )
    }

	return (
        <>
        {isMobile ? (<MobileNav/>):(<DesktopNav/>)}
        </>
	);
};

export { TopNav, PushToNewSr , PushtoSrHistory };
