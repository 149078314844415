import React, { useState, useEffect, useRef } from "react";
import Rating from "@mui/material/Rating";
import {
  JobberProfileBuilder,
  Email,
  JobberOrgRatingClientResp,
  JobberPerformanceOrg,
} from "../orgViews/orgConstants";
import { EmailAddress } from "../common/CommonInterfaces";
const CopyToClipboard = async (value: string) => {
  try {
    await navigator.clipboard.writeText(value);
    console.log("Copied to clipboard: " + value);
  } catch (err) {
    console.error("Unable to copy to clipboard:", err);
  }
};

const ExtractStarRating = (jobberRating: Map<Email, JobberPerformanceOrg>) => {
  if (jobberRating) {
    const orgEmail = localStorage.getItem("userEmail");
    const emailMapKey = `EmailAddress(value=${orgEmail})`;
    const jobberRatingEnum =
      jobberRating[emailMapKey]?.jobberPerformanceOrgDetails?.category;

    switch (jobberRatingEnum) {
      case "DEFAULT_WAITING_FOR_REVIEW":
        return 0;
      case "OUTSTANDING":
        return 5;
      case "EXCEEDS_EXPECTATION":
        return 4;
      case "MEET_EXPECTATION":
        return 3;
      case "UNSATISFACTORY":
        return 2;
      default:
        return 4;
    }
  }

  return 4;
};

const JobberStarRating = ({
  jobberRating,
}: {
  jobberRating: JobberProfileBuilder;
}) => {
  const starValue = ExtractStarRating(jobberRating.jobberOrgRatingDetailMap);
  if (jobberRating) {
    return (
      <div className="jobber-profile-modal-stars-holder">
        <Rating
          name="half-rating-read"
          defaultValue={starValue}
          precision={0.5}
          readOnly
        />
      </div>
    );
  }
};

const JobberViewRating = ({
    jobberRating,
  }: {
    jobberRating: JobberProfileBuilder;
  }) => {
  // Component to extract reviewer name
  const ViewRatingBuilderV2 = () => {
    // const jobberRating = responseData.data; // Assuming responseData is the name of your response object
    const jobberOrgRatingDetailMap = jobberRating.jobberOrgRatingDetailMap;

    return (
      <>
        {Object.keys(jobberOrgRatingDetailMap).map((emailKey) => {
          const performanceDetails =
            jobberOrgRatingDetailMap[emailKey].jobberPerformanceOrgDetails;

          return (
            <div key={emailKey}>
              <div className="org-rating">
                <div className="org-rating-name">
                  <ExtractedReviewerName
                    firstName={performanceDetails.requestFirstName}
                    lastName={performanceDetails.requestLastName}
                  />
                </div>
                <div className="org-rating-value">
                  <div className="org-rating-value-star">
                    <ExtractedReviewerStarRating
                      category={performanceDetails.category}
                    />
                  </div>
                  <div className="org-rating-value-text">
                    <ExtractedReviewerReason
                      reason={performanceDetails.reason}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  const ExtractedReviewerName = ({ firstName, lastName }) => {
    return (
      <>
        {firstName} {lastName}
      </>
    );
  };

  // Component to extract reviewer star rating
  const ExtractedReviewerStarRating = ({ category }) => {
    switch (category) {
      case "DEFAULT_WAITING_FOR_REVIEW":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={0}
            precision={0.5}
            readOnly
          />
        );
      case "OUTSTANDING":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={5}
            precision={0.5}
            readOnly
          />
        );
      case "EXCEEDS_EXPECTATION":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={4}
            precision={0.5}
            readOnly
          />
        );
      case "MEET_EXPECTATION":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={3}
            precision={0.5}
            readOnly
          />
        );
      case "UNSATISFACTORY":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={2}
            precision={0.5}
            readOnly
          />
        );
      default:
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={0}
            precision={0.5}
            readOnly
          />
        );
    }
  };

  // Component to extract reviewer reason
  const ExtractedReviewerReason = ({ reason }) => {
    return <>{reason}</>;
  };

  const JobberViewRating = ({
    jobberRating,
  }: {
    jobberRating: JobberProfileBuilder;
  }) => {
    // Component to extract reviewer name
    const ExtractedReviewerName = ({ firstName, lastName }) => {
      return (
        <>
          {firstName} {lastName}
        </>
      );
    };

    // Component to extract reviewer star rating
    const ExtractedReviewerStarRating = ({ category }) => {
      switch (category) {
        case "DEFAULT_WAITING_FOR_REVIEW":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={0}
              precision={0.5}
              readOnly
            />
          );
        case "OUTSTANDING":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={5}
              precision={0.5}
              readOnly
            />
          );
        case "EXCEEDS_EXPECTATION":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={4}
              precision={0.5}
              readOnly
            />
          );
        case "MEET_EXPECTATION":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={3}
              precision={0.5}
              readOnly
            />
          );
        case "UNSATISFACTORY":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={2}
              precision={0.5}
              readOnly
            />
          );
        default:
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={0}
              precision={0.5}
              readOnly
            />
          );
      }
    };

    // Component to extract reviewer reason
    const ExtractedReviewerReason = ({ reason }) => {
      return <>{reason}</>;
    };

    

    return (
      <div className="jobber-profile-view-ratings">
        <div className="jobber-profile-view-ratings-title">Ratings</div>
        <ViewRatingBuilderV2 />
      </div>
    );
  };

  return (
    <div className="jobber-profile-view-ratings">
      <div className="jobber-profile-view-ratings-title">Ratings</div>
      <ViewRatingBuilderV2 />
    </div>
  );
};

export { CopyToClipboard, ExtractStarRating, JobberStarRating, JobberViewRating };
