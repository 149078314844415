import React, { useEffect, useState, ChangeEvent, FormEvent, FocusEvent, useRef } from 'react';
import { RemoteExecutionServiceImpl, ServerResponse } from './RemoteExecutionServiceImpl';
import { JobberStatus } from '../common/CommonInterfaces';
import Box from '@mui/joy/Box';
import Checkbox from '@mui/joy/Checkbox';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Tooltip from '@mui/material/Tooltip';
import { AxiosResponse } from 'axios';
import { AlertComp } from './alerts';
import {
	WORK_PERMIT_TYPE,
	DAYS_OF_THE_WEEK,
	PROVINCES,
	Address,
	DowValue,
	ShiftTime,
	DOW,
	WorkPermitType,
	JobberDTO,
	AddressDto,
	JobberRegistrationFormRequest,
	ServicedCity,
	VALID_MONTH_ARRAY,
} from '../jobberViews/objects/JobberRegistrationFormConstants';
// const backendApiBasePathHTTPS = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_HTTPS;
const remoteCallObject = RemoteExecutionServiceImpl();

const initialJobberRegistrationFormRequest: JobberRegistrationFormRequest = {
	socialInsuranceNumber: '',
	dateOfBirth: '',
	sex: '',
	address: {
		street: '',
		unitNumber: '',
		city: '',
		province: '',
		postalCode: '',
		country: '',
		majorIntersection: '',
	},
	transportation: '',
	hasPersonalVehicle: false,
	hasSafetyBoots: false,
	specialSkills: [],
	hasForkliftLicense: false,
	availTimes: [],
	shiftTimes: [],
	workPermitType: WorkPermitType.CANADIAN_CITIZEN,
	servicedCities: [],
};

const UserDetailsForm = (showLogin: boolean) => {
	const nextButtonRef = useRef(null);

	const [DOBMonth, setDOBMonth] = useState('');
	const [DOBDay, setDOBDay] = useState('');
	const [DOBYear, setDOBYear] = useState('');
	const [JISex, setJISex] = useState('');
	const [safetyShoes, setSafetyShoes] = useState(false);
	const [forklift, setForklift] = useState(false);
	const [legallyAllowed, setLegallyAllowed] = useState(true);
	const [workPermitType, setWorkPermitType] = useState(WorkPermitType.CANADIAN_CITIZEN);
	// address refactor
	const [jobberCountry, setJobberCountry] = useState('');
	const [jobberProvince, setJobberProvince] = useState('');
	const [jobberPostalCode, setJobberPostalCode] = useState('');
	const [jobberCity, setJobberCity] = useState('');
	const [jobberUnitNumber, setJobberUnitNumber] = useState('');
	const [jobberStreet, setJobberStreet] = useState('');
	// address refactor

	const [alert, setAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	//jobber intake days refactor
	const [selectedDays, setSelectedDays] = useState([]);
	//jobber intake days refactor

	//jobber intake time refactor
	const [selectedTimes, setSelectedTimes] = useState([]);
	//jobber intake time refactor

	//jobber intake time refactor
	const [servicedCities, setServicedCities] = useState([]);
	//jobber intake time refactor

	const [employerInput, setEmployerInput] = useState('');
	const [previousEmployers, setPreviousEmployers] = useState([]);

	const [sucessfulSubmit, setSucessfulSubmit] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);

	const handleNextPage = () => {
		setCurrentPage(currentPage + 1);
	};

	const handlePreviousPage = () => {
		setCurrentPage(currentPage - 1);
	};

	const handleEmployerInputChange = (e) => {
		setEmployerInput(e.target.value);
	};

	const handleEmployerKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault(); // Prevent the form from being submitted

			if (employerInput.trim() !== '' && previousEmployers.length < 5) {
				setPreviousEmployers([...previousEmployers, employerInput]);
				setEmployerInput('');
			}
		}
	};

	const renderPreviousEmployers = () => {
		return (
			<div className='company-container'>
				{previousEmployers.map((employer, index) => (
					<div className='other-company' key={index}>
						{employer}
					</div>
				))}
			</div>
		);
	};

	const [JobberRegistrationFormRequest, setJobberRegistrationFormRequest] =
		useState<JobberRegistrationFormRequest>(initialJobberRegistrationFormRequest);

	const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, fieldName) => {
		const { name, value } = e.target;

		//shift times = morning afternoon night
		if (fieldName === 'shiftTimes') {
			setJobberRegistrationFormRequest((prevData) => {
				const updatedTimes = new Set(prevData[fieldName]);
				updatedTimes.add(value);
				return { ...prevData, [fieldName]: Array.from(updatedTimes) };
			});
		}
		//availtimes = mon tue wed
		else if (fieldName === 'availTimes') {
			setJobberRegistrationFormRequest((prevData) => {
				const updatedDays = new Set(prevData[fieldName]);
				updatedDays.add(value);
				return { ...prevData, [fieldName]: Array.from(updatedDays) };
			});
		}
	};

	const JITimesHelpers = (value) => {
		if (selectedTimes.includes(value)) {
			setSelectedTimes(selectedTimes.filter((day) => day !== value));
		} else {
			setSelectedTimes([...selectedTimes, value]);
		}
	};

	const JIDaysHelpers = (value) => {
		if (selectedDays.includes(value)) {
			setSelectedDays(selectedDays.filter((day) => day !== value));
		} else {
			setSelectedDays([...selectedDays, value]);
		}
	};

	const ServicedCitiesHelpers = (value) => {
		if (servicedCities.includes(value)) {
			setServicedCities(servicedCities.filter((day) => day !== value));
		} else {
			setServicedCities([...servicedCities, value]);
		}
	};

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		console.log('submitted');

		const DOBString = DOBMonth + '/' + DOBDay + '/' + DOBYear;

		const dows = [];

		for (const day in selectedDays) {
			for (const time in selectedTimes) {
				dows.push({
					day: selectedDays[day],
					availStartTime: selectedTimes[time],
					availEndTime: 24,
				});
			}
		}

		const jobberAddress: AddressDto = {
			street: jobberStreet,
			unitNumber: jobberUnitNumber,
			city: jobberCity,
			province: jobberProvince,
			postalCode: jobberPostalCode,
			country: jobberCountry,
		};
		const apiBody: JobberRegistrationFormRequest = {
			availTimes: dows,
			previousTempEmployers: previousEmployers,
			workPermitType: workPermitType,
			isLegallyAllowedToWork: legallyAllowed,
			dateOfBirth: DOBString,
			sex: JISex,
			hasSafetyBoots: safetyShoes,
			hasForkliftLicense: forklift,
			address: jobberAddress,
			socialInsuranceNumber: '',
			servicedCities: servicedCities,
		};

		console.log(servicedCities);
		try {
			const response: AxiosResponse<ServerResponse<JobberDTO, Error>> =
				await remoteCallObject.remotePOSTCallV2<ServerResponse<JobberDTO, Error>,JobberRegistrationFormRequest>({
					apiUrl: 'v1/jobber/JobberRegistrationForm/submit',
					object: apiBody,
					headers: { 'Content-Type': 'application/json' },
				});
			console.log('POST request successful:', response);

			if (
				response &&
				(response.status === 200 || response.status === 201 || response.status === 202)
			) {
				setSucessfulSubmit(true);
			}
		} catch (e) {
			if (e.response && (e.response.status === 400 || e.response.status === 500)) {
				console.log('400 or 500');
				setAlertText('You have empty fields. Please fill in the rest and resubmit.');
				setAlert(true);
			}
			console.error('POST request failed:', e);
		}
	};

	const page2Element = () => {
		return (
			<div>
				{currentPage === 2 && (
					<div className='page2'>
						{/* DOW workable */}
						<div id='label1' className='jobber-label'>
							What Days Can You Worky?
						</div>
						<div className='dow-holder'>
							<Box sx={{ width: '100%' }}>
								<List
									orientation='horizontal'
									wrap
									sx={{ '--List-gap': '8px', '--ListItem-radius': '20px' }}>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Monday'
											value={DowValue.MON}
											onChange={(e) => JIDaysHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Tuesday'
											value={DowValue.TUE}
											onChange={(e) => JIDaysHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Wednesday'
											value={DowValue.WED}
											onChange={(e) => JIDaysHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Thursday'
											value={DowValue.THR}
											onChange={(e) => JIDaysHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Friday'
											value={DowValue.FRI}
											onChange={(e) => JIDaysHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Saturday'
											value={DowValue.SAT}
											onChange={(e) => JIDaysHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Sunday'
											value={DowValue.SUN}
											onChange={(e) => JIDaysHelpers(e.target.value)}
										/>
									</ListItem>
								</List>
							</Box>
						</div>

						{/* remove nexus card and change  title of const + passport */}

						{/* TOD workable */}
						<div id='label2' className='jobber-label'>
							What Time Can You Worky?
						</div>
						<div className='shifttimes-holder'>
							<Box sx={{ width: '100%' }}>
								<List
									orientation='horizontal'
									wrap
									sx={{ '--List-gap': '8px', '--ListItem-radius': '20px' }}>
									<Tooltip title='Start: 12AM' placement='bottom'>
										<ListItem
											sx={{
												backgroundColor: 'white',
												paddingTop: '0px',
												paddingBottom: '0px',
												paddingLeft: '15px',
												paddingRight: '15px',
											}}>
											<Checkbox
												sx={{ fontSize: '13px', fontWeight: '450' }}
												overlay
												disableIcon
												label='Midnight'
												value={ShiftTime.MIDNIGHT}
												onChange={(e) => JITimesHelpers(e.target.value)}
											/>
										</ListItem>
									</Tooltip>
									<Tooltip title='Start: 8AM' placement='bottom'>
										<ListItem
											sx={{
												backgroundColor: 'white',
												paddingTop: '0px',
												paddingBottom: '0px',
												paddingLeft: '15px',
												paddingRight: '15px',
											}}>
											<Checkbox
												sx={{ fontSize: '13px', fontWeight: '450' }}
												overlay
												disableIcon
												label='Morning'
												value={ShiftTime.MORNING}
												onChange={(e) => JITimesHelpers(e.target.value)}
											/>
										</ListItem>
									</Tooltip>
									<Tooltip title='Start: 12PM' placement='bottom'>
										<ListItem
											sx={{
												backgroundColor: 'white',
												paddingTop: '0px',
												paddingBottom: '0px',
												paddingLeft: '15px',
												paddingRight: '15px',
											}}>
											<Checkbox
												sx={{ fontSize: '13px', fontWeight: '450' }}
												overlay
												disableIcon
												label='Noon'
												value={ShiftTime.NOON}
												onChange={(e) => JITimesHelpers(e.target.value)}
											/>
										</ListItem>
									</Tooltip>
									<Tooltip title='Start: 8PM' placement='bottom'>
										<ListItem
											sx={{
												backgroundColor: 'white',
												paddingTop: '0px',
												paddingBottom: '0px',
												paddingLeft: '15px',
												paddingRight: '15px',
											}}>
											<Checkbox
												sx={{ fontSize: '13px', fontWeight: '450' }}
												overlay
												disableIcon
												label='Night'
												value={ShiftTime.NIGHT}
												onChange={(e) => JITimesHelpers(e.target.value)}
											/>
										</ListItem>
									</Tooltip>
								</List>
							</Box>
						</div>

						{/* Serviced Cities */}
						<div className='jobber-label'>Which Cities would you Prefer to Work in?</div>
						<div className='shifttimes-holder'>
							<Box sx={{ width: '100%' }}>
								<List
									orientation='horizontal'
									wrap
									sx={{ '--List-gap': '8px', '--ListItem-radius': '20px' }}>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Mississauga'
											value={ServicedCity.MISSISSAUGA}
											onChange={(e) => ServicedCitiesHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Toronto'
											value={ServicedCity.TORONTO}
											onChange={(e) => ServicedCitiesHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Brampton'
											value={ServicedCity.BRAMPTON}
											onChange={(e) => ServicedCitiesHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Oakville'
											value={ServicedCity.OAKVILLE}
											onChange={(e) => ServicedCitiesHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Vaughan'
											value={ServicedCity.VAUGHAN}
											onChange={(e) => ServicedCitiesHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Markham'
											value={ServicedCity.MARKHAM}
											onChange={(e) => ServicedCitiesHelpers(e.target.value)}
										/>
									</ListItem>
									<ListItem
										sx={{
											backgroundColor: 'white',
											paddingTop: '0px',
											paddingBottom: '0px',
											paddingLeft: '15px',
											paddingRight: '15px',
										}}>
										<Checkbox
											sx={{ fontSize: '13px', fontWeight: '450' }}
											overlay
											disableIcon
											label='Milton'
											value={ServicedCity.MILTON}
											onChange={(e) => ServicedCitiesHelpers(e.target.value)}
										/>
									</ListItem>
								</List>
							</Box>
						</div>

						{/* LEGALLY ALLOWED ?   */}
						<div className='radio-holder'>
							<div className='jobber-label'>Legally Work in Canada?</div>
							<div className='inner-radio-holder'>
								<input
									type='radio'
									name='LegallyAllowedOption'
									value='true'
									onChange={(e) => setLegallyAllowed(true)}></input>
								Yes
								<input
									type='radio'
									name='LegallyAllowedOption'
									value='false'
									onChange={(e) => setLegallyAllowed(false)}></input>
								No
							</div>
						</div>

						{/* FORKLIFT License  */}
						<div className='radio-holder'>
							<div className='jobber-label'>Have a Forklift License?</div>
							<div className='inner-radio-holder'>
								<input
									type='radio'
									name='ForkliftOption'
									value='true'
									onChange={(e) => setForklift(true)}></input>
								Yes
								<input
									type='radio'
									name='ForkliftOption'
									value='false'
									onChange={(e) => setForklift(false)}></input>
								No
							</div>
						</div>

						{/* SAFETY SHOES  */}
						<div className='radio-holder'>
							<div className='jobber-label'>Have Safety Boots?</div>
							<div className='inner-radio-holder'>
								<input
									type='radio'
									name='SafetyShoesOption'
									onChange={(e) => setSafetyShoes(true)}
									value='true'></input>
								Yes
								<input
									type='radio'
									name='SafetyShoesOption'
									onChange={(e) => setSafetyShoes(false)}
									value='false'></input>{' '}
								No
							</div>
						</div>

						{/* PREV EMPLOYERS */}
						<div className='prev-employ-holder'>
							<div className='jobber-label'>
								List 5 Previous Workplaces you worked through a Staffing Agency.
							</div>
							<input
								placeholder='Enter your last Employers then press Enter.'
								id='prev-employ'
								className='jobber-input'
								type='text'
								value={employerInput}
								onChange={handleEmployerInputChange}
								onKeyPress={handleEmployerKeyPress}
							/>
							{/* Displaying previously entered employers */}
							<div className='previous-employers'>{renderPreviousEmployers()}</div>
						</div>

						{/* Work status /pr/citizen */}
						<div className='workstatus-holder'>
							<div className='jobber-label'>What is your Work Status?</div>

							<select
								className='jobber-input'
								id='jobber-workstatus'
								value={workPermitType}
								onChange={(e) => setWorkPermitType(e.target.value as WorkPermitType)}>
								{WORK_PERMIT_TYPE.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						</div>

						<button className='prev' type='button' onClick={handlePreviousPage}>
							Previous
						</button>

						{workPermitType === null || safetyShoes===null || forklift===null || legallyAllowed===null || servicedCities.length === 0 || selectedTimes.length === 0 || selectedDays.length === 0 ? (<button className='submit-button' type='submit' disabled>Submit</button>):(<button className='submit-button' type='submit'>Submit</button>)}
					</div>
				)}
			</div>
		);
	};

	/**
	 * how to resolve react hooks error https://react.dev/warnings/invalid-hook-call-warning#
	 */
	if (sucessfulSubmit) {
		return (
			<div className='ji-submit-success-screen2'>
				<div className='ji-submit-success-icon'></div>
				<div className='ji-submit-success-title'>Success</div>
				<div className='ji-submit-success-subtext2'>
					<br />
					Your information was sent successfully. We are so happy to have you on board!
					<br />
					<br />
					<span style={{ fontWeight: '600', fontSize: '20px' }}>
						Please check your email for next steps!
					</span>
					<br />
					<br />
					The email will be titled:
					<span style={{ fontWeight: '500' }}> Worky Inc Registration Email</span>
				</div>
			</div>
		);
	}

	const handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
		e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
	  };
	  

	  // Define a function to check the completion status
	  const isFormComplete = () => {
		return (
		  DOBMonth !== '' &&
		  DOBDay !== '' &&
		  DOBYear !== '' &&
		  jobberStreet !== '' &&
		  jobberCity !== '' &&
		  jobberPostalCode !== '' &&
		  jobberProvince !== '' &&
		  jobberCountry !== ''
		);
	  };

	return (
		<div className='jobber-onboarding'>
			<div className='jobber-onboarding-state-holder'>
				<div className='card-info-de-progress'>
					<div className='stateholder'>
						<div id={`state1-${currentPage}`} className='progress-state'></div>
						<div id={`state2-${currentPage}`} className='progress-state'></div>
					</div>
					<div className='ji-statetextholder'>
						<div id={`statetext1-${currentPage}`} className='ji-progress-text'>
							Basic Info
						</div>
						<div id={`statetext2-${currentPage}`} className='ji-progress-text'>
							Date & Time
						</div>
					</div>

					<div className='progress-bar'>
						<div className={`ji-progress-bar-inner-${currentPage}`}></div>
					</div>
				</div>
			</div>

			<form className='jobber-form' onSubmit={handleSubmit}>
				{currentPage === 1 && (
					<div className='page1'>
						{/* DOB */}
						<div className='jobber-label'>What is your Date of Birth?</div>
						<div className='jobber-dob-holder'>
							<div className='jobber-dob-field-label-holder'>
								<div id='jobber-dob-label' className='jobber-input'>
									Month
								</div>
								<div id='jobber-dob-label' className='jobber-input'>
									Day
								</div>
								<div id='jobber-dob-label' className='jobber-input'>
									Year
								</div>
							</div>

							<input
								onChange={(e) => setDOBMonth(e.target.value)}
								value={DOBMonth}
								name='dob-month'
								id='jobber-dob-field'
								className='jobber-input'
								type='number'
								inputMode='numeric'
								pattern='\d*'
								onInput={handleNumericInput}
								placeholder='MM'
							/>

							<input
								onChange={(e) => setDOBDay(e.target.value)}
								onInput={handleNumericInput}
								value={DOBDay}
								name='dob-day'
								id='jobber-dob-field'
								className='jobber-input'
								type='number'
								placeholder='DD'>
									
							</input>

							<input
								onChange={(e) => setDOBYear(e.target.value)}
								onInput={handleNumericInput}
								value={DOBYear}
								name='dob-year'
								id='jobber-dob-field'
								className='jobber-input'
								type='number'
								placeholder='YYYY'>
							</input>

						</div>

						{/* GENDER */}
						{/* <div className='jobber-label'>What is your Gender?</div> */}
						{/* <select
							// name='address.province'
							id='ji-sex'
							value={JISex}
							onChange={(e) => setJISex(e.target.value)}
							className='jobber-input'>
							<option value=''>Gender</option>
							<option value='MALE'>Male</option>
							<option value='FEMALE'>Female</option>
							<option value='OTHER'>Other</option>
						</select> */}

						<div id='label3' className='jobber-label'>
							What's your Address?
						</div>
						<div className='ji-tempholder'>
							<input
								type='text'
								id='ji-streetname'
								placeholder='Street Address'
								name='address.street'
								value={jobberStreet}
								onChange={(e) => setJobberStreet(e.target.value)}
								className='jobber-input'
							/>

							{/* address - unitnumber */}
							<input
								placeholder='Unit'
								type='number'
								id='ji-unit'
								name='address.unitNumber'
								value={jobberUnitNumber}
								onChange={(e) => setJobberUnitNumber(e.target.value)}
								className='jobber-input'
							/>

							{/* address - city */}
							<input
								type='text'
								id='ji-city'
								name='address.city'
								placeholder='City'
								value={jobberCity}
								onChange={(e) => setJobberCity(e.target.value)}
								className='jobber-input'
							/>

							<input
								type='postal'
								id='ji-postalcode'
								name='address.postalCode'
								placeholder='Postal Code'
								value={jobberPostalCode}
								onChange={(e) => setJobberPostalCode(e.target.value)}
								className='jobber-input'
							/>

							{/* address - province */}
							<select
								name='address.province'
								id='ji-province'
								value={jobberProvince}
								onChange={(e) => setJobberProvince(e.target.value)}
								className='jobber-input'>
								<option value=''>Province/State</option>
								{PROVINCES.map((province) => (
									<option key={province.display} value={province.display}>
										{province.display}
									</option>
								))}
							</select>

							{/* address - country */}
							<select
								name='address.country'
								id='ji-country'
								value={jobberCountry}
								onChange={(e) => setJobberCountry(e.target.value)}
								className='jobber-input'>
								<option value=''>Country</option>
								<option value='CANADA'>Canada</option>
							</select>
						</div>
						{DOBMonth === '' || DOBDay === '' || DOBYear === '' || jobberStreet === '' || jobberCity === '' || jobberPostalCode === '' || jobberProvince === '' || jobberCountry === '' ? (<button className='next' type='button' onClick={handleNextPage} disabled>Next</button>):(<button className='next' type='button' onClick={handleNextPage}>Next</button>)}
						
					</div>
				)}
				{page2Element()}
				{alert ? <AlertComp alertMsg={alertText} onClose={() => {}} /> : null}
			</form>
		</div>
	);
};

export default UserDetailsForm;
