import React, { useState, useEffect, useRef } from 'react';
import './jobberProfile.css';
import Modal from '@mui/material/Modal';
import{GetJobberResume} from '../shared-engineering-services/SharedAPICalls';
import { Tooltip } from '@mui/material';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import { AxiosResponse } from 'axios';
import { RemoteExecutionServiceImpl,
         ServerResponse,
         getGlobalUserDetails,
         RemoteGETCallInputObject,
        } from '../remoteExecution/RemoteExecutionServiceImpl';

import { JobberProfileResp , JobberProfileReq, JobberResumeReq, JobberResume } from './orgConstants';
import { JobberProfileBuilder, JobberOrgRatingClientResp, OrgSpecifiedJobberPerformanceCategory, CreateOrgRatingForJobberReq, JobberPerformanceOrg } from './orgConstants';

const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();
const orgEmail = localStorage.getItem('userEmail')


const JobberProfile = ({hasCheck, jobberProfile}:{hasCheck:boolean, jobberProfile:JobberProfileBuilder}) =>{
    const isMobile = useIsMobile();
    const [modalOpen, setModalOpen] = useState(false);
    const [profileText,setProfileText] = useState('Profile summary not available');
    const [profilePic, setProfilePic] = useState('');
    const [jobberRating, setJobberRating] = useState<JobberOrgRatingClientResp | null>(null);
    const defaultPic = './org-default-icon.png';
    const emailMapKey = `EmailAddress(value=${orgEmail})`;

    const setJobberProfileDefault = ()=>{
        setProfileText("No profile summary found")
        setProfilePic("")
    }

    const handleOpen = (jobberEmail) => {
        setModalOpen(true); 
        getJobberProfile(jobberEmail);
        getJobberRatingV2();
        
    }

    const handleClose = () => {	setModalOpen(false); }


    // API CALLS

    const getJobberProfile = async (jobberEmail: string) => {
        try {
          if (typeof jobberEmail !== 'string') {
            throw new Error('Invalid jobberEmail type');
          }
    
          const jobberReq: JobberProfileReq = {
            jobberEmail: { value: jobberEmail },
          };
    
          const response: AxiosResponse<ServerResponse<JobberProfileResp, Error>> = await remoteCallObject.remotePOSTCallV2<
            ServerResponse<JobberProfileResp, Error>,
            JobberProfileReq
          >({
            apiUrl: backendApiBasePathLocal + 'v2/jobber/profile/details/',
            object: jobberReq,
            headers: { 'Content-Type': 'application/json' },
          });
    
          console.log('POST request successful:', response);
    
          if (response && (response.status === 200 || response.status === 201 || response.status === 202)) {
            console.log('here');
            if (response && response.data && response.data.data && response.data.data.jobberQuickSummary) {
              setProfileText(response.data.data.jobberQuickSummary);
              setProfilePic(response.data.data.jobberProfilePicS3Url);
            } else {
              setJobberProfileDefault();
            }
          } else {
            setJobberProfileDefault();
          }
        } catch (e) {
          console.error('Error fetching jobber profile:', e);
          setJobberProfileDefault();
        }
    };
    

    const getJobberRatingV2 = async () => {
      const getCallInput: RemoteGETCallInputObject = {
        apiUrl: backendApiBasePathLocal + 'jobber/performance/rating/org/single-jobber',
        headers: {},
        queryParams: {
          jobberEmail: jobberProfile.jobberEmail
        }
      };

      const jobberRating: AxiosResponse<ServerResponse<JobberOrgRatingClientResp, Error>> =
        await remoteCallObject.remoteGETCallV2<ServerResponse<JobberOrgRatingClientResp, Error>>(getCallInput);

        if (jobberRating && jobberRating.data && jobberRating.data.data) { 
            setJobberRating(jobberRating.data.data);
        }
    };





    // HELPER FUNCTIONS

    const copyToClipboard = async (value: string) => {
        try {
          await navigator.clipboard.writeText(value);
          console.log("Copied to clipboard: " + value);
        } catch (err) {
          console.error("Unable to copy to clipboard:", err);
        }
    };

    const extractStarRating = () => {
      if (jobberRating){
          const jobberRatingEnum = jobberRating.jobberOrgRatingDetailMap[emailMapKey]?.jobberPerformanceOrgDetails?.category;
          
          switch (jobberRatingEnum) {
            case "DEFAULT_WAITING_FOR_REVIEW":
                return 0;
            case "OUTSTANDING":
                return 5;
            case "EXCEEDS_EXPECTATION":
                return 4;
            case "MEET_EXPECTATION":
                return 3;
            case "UNSATISFACTORY":
                return 2;
            default:
                return 4;
        }
        

      }


    }








    // BUILDER FUNCTIONS

    const JobberStarRating = () => {
      const starValue = extractStarRating();
      if(jobberRating){
      return(
        <div className='jobber-profile-modal-stars-holder'>
          <Rating name="half-rating-read" defaultValue={starValue} precision={0.5} readOnly />
        </div>
      )}
    }

    const JobberViewRating = () => {
        // Component to extract reviewer name
        const ExtractedReviewerName = ({ firstName, lastName }) => {
            return (
                <>
                    {firstName} {lastName}
                </>
            );
        }

        // Component to extract reviewer star rating
        const ExtractedReviewerStarRating = ({ category }) => {
          switch (category) {
            case "DEFAULT_WAITING_FOR_REVIEW":
                return (<Rating size="small" name="half-rating-read" defaultValue={0} precision={0.5} readOnly />);
            case "OUTSTANDING":
                return (<Rating size="small" name="half-rating-read" defaultValue={5} precision={0.5} readOnly />);
            case "EXCEEDS_EXPECTATION":
                return <Rating size="small" name="half-rating-read" defaultValue={4} precision={0.5} readOnly />;
            case "MEET_EXPECTATION":
                return <Rating size="small" name="half-rating-read" defaultValue={3} precision={0.5} readOnly />;
            case "UNSATISFACTORY":
                return <Rating size="small" name="half-rating-read" defaultValue={2} precision={0.5} readOnly />;
            default:
                return (<Rating size="small" name="half-rating-read" defaultValue={0} precision={0.5} readOnly />);
        }
        }

        // Component to extract reviewer reason
        const ExtractedReviewerReason = ({ reason }) => {
            return (
                <>
                    {reason}
                </>
            );
        }

       

        const ViewRatingBuilderV2 = () => {
          // const jobberRating = responseData.data; // Assuming responseData is the name of your response object
          const jobberOrgRatingDetailMap = jobberRating.jobberOrgRatingDetailMap;
      
          return (
              <>
                  {Object.keys(jobberOrgRatingDetailMap).map((emailKey) => {
                      const performanceDetails = jobberOrgRatingDetailMap[emailKey].jobberPerformanceOrgDetails;
      
                      return (
                          <div key={emailKey}>
                              <div className='org-rating'>
                                  <div className='org-rating-name'>
                                      <ExtractedReviewerName firstName={performanceDetails.requestFirstName} lastName={performanceDetails.requestLastName} />
                                  </div>
                                  <div className='org-rating-value'>
                                      <div className='org-rating-value-star'>
                                          <ExtractedReviewerStarRating category={performanceDetails.category} />
                                      </div>
                                      <div className='org-rating-value-text'>
                                          <ExtractedReviewerReason reason={performanceDetails.reason} />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      );
                  })}
              </>
          );
        };


        return(
          <div className='jobber-profile-view-ratings'>
              <div className='jobber-profile-view-ratings-title'>Ratings</div>
              <ViewRatingBuilderV2/>
          </div>
        )
    }
   
    const JobberSendRating = () => {
      const [value, setValue] = useState<number | null>(4);
      const [review, setReview] = useState<string>("");
      
      const getCategoryFromRating = (rating: number): string => {
        switch (rating) {
            case 0:
                return "UNSATISFACTORY";
            case 1:
                return "UNSATISFACTORY";
            case 2:
                return "UNSATISFACTORY";
            case 3:
                return "MEET_EXPECTATION";
            case 4:
                return "EXCEEDS_EXPECTATION";
            case 5:
                return "OUTSTANDING";
            
            
        }
      };


    const handleSubmitReview = async () => {
        const category = getCategoryFromRating(value);
        const requestBody:CreateOrgRatingForJobberReq = {
            jobberEmail: { value: jobberProfile.jobberEmail },
            category: category as OrgSpecifiedJobberPerformanceCategory,
            reason: review ? review : null,
            shiftId: null,
            shiftStartDateTimeEpochSec: null
        };
        console.log("Submitting review with body:", requestBody);
        // Perform your API request with the requestBody


        const response: AxiosResponse<ServerResponse<JobberPerformanceOrg, Error>> =
            await remoteCallObject.remotePOSTCallV2<ServerResponse<JobberPerformanceOrg, Error>,CreateOrgRatingForJobberReq>({
                apiUrl: backendApiBasePathLocal + 'jobber/performance/rating/org/create',
                object: requestBody,
                headers: { 'Content-Type': 'application/json' },
            });
        console.log('POST request successful:', response);
        if (response && (response.status === 200 || response.status === 201 || response.status === 202)) {
            getJobberRatingV2();
        }


    };


      return(
        <div className='jobber-profile-send-ratings'>
            <Rating
                className='jobber-rating-send-stars'
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => { setValue(newValue); }}
            />
            <input
                className='jobber-rating-send-input'
                placeholder='Leave a review'
                maxLength={100}
                value={review}
                onChange={(event) => setReview(event.target.value)}
            />
            <button onClick={handleSubmitReview} className='jobber-rating-send-button'><KeyboardBackspaceRoundedIcon sx={{ fontSize: "20px", rotate: "90deg" }} /></button>
        </div>
      )
    }



    


    return(
        <>
            {hasCheck ? 
              (<Tooltip placement='right' title="View Profile">
                <div onClick={() => handleOpen(jobberProfile.jobberEmail)} className='jobber-profile2'>
                    <div className='jobber-profile-icon2'><img src={jobberProfile.jobberPic ? jobberProfile.jobberPic : profilePic ? profilePic : defaultPic}/></div>
                    <div className='jobber-profile-name2'>{jobberProfile.jobberName}</div>
                </div>
              </Tooltip>)
                :
              (<Tooltip placement='right' title="View Profile">
                <div onClick={() => handleOpen(jobberProfile.jobberEmail)} className='jobber-profile1'>
                    <div className='jobber-profile-icon1'><img src={jobberProfile.jobberPic ? jobberProfile.jobberPic : profilePic ? profilePic : defaultPic}/></div>
                    <div className='jobber-profile-name1'>{jobberProfile.jobberName}</div>
                </div>
              </Tooltip>)
            }
            {jobberRating ?
            (<Modal open={modalOpen} onClose={handleClose} disableAutoFocus={true}>
                <div className={isMobile? 'jobber-profile-modal-mobile':'jobber-profile-modal'}>
                <button onClick={handleClose} className='modal-close'><CloseIcon/></button>

                    <div className='jobber-profile-modal-top'>
                        <div className='jobber-profile-modal-top-icon'><img src={jobberProfile.jobberPic ? jobberProfile.jobberPic : profilePic ? profilePic : defaultPic}/></div>
                        <div className='jobber-profile-modal-top-details-holder'>
                            <div className='jobber-profile-modal-top-name'>{jobberProfile.jobberName}</div>

                            <JobberStarRating/>

                            <div className='jobber-profile-modal-top-contact'>
                                <Tooltip title="Click to copy"><div className='jobber-profile-modal-top-contact-info' onClick={() => copyToClipboard(jobberProfile.jobberEmail)}>{jobberProfile.jobberEmail}</div></Tooltip>
                                <Tooltip title="Click to copy"><div className='jobber-profile-modal-top-contact-info' onClick={() => copyToClipboard(jobberProfile.jobberPhone)}>{jobberProfile.jobberPhone}</div></Tooltip>
                            </div>
                        </div>    
                    </div>
                    
                    <JobberViewRating/>

                    <div className='jobber-profile-modal-bottom'>
                        <Accordion style={{marginBottom:"0px",boxShadow:"none", minHeight:"50px", display: "flex", flexDirection: "column", justifyContent:"center", width: "77.1111111111%", background:"#f5f5f7", borderRadius:"20px"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                            >
                            <span className='accordian-title'>Profile Summary</span>
                            </AccordionSummary> 
                            <AccordionDetails style={{marginBottom:"0px"}}>
                                <div className='profile-summary-text'>{profileText}</div>
                            </AccordionDetails>
                        </Accordion>

                        <Tooltip placement='right' title="Click to Download Resume">
                            <div onClick={() => GetJobberResume({value:jobberProfile.jobberEmail})} className='jobber-profile-modal-resume'>
                                <ArticleIcon style={{fontSize:"25px"}}/>
                            </div>
                        </Tooltip>
                    </div>

                    <JobberSendRating/>
                </div>

            </Modal>):(null)
            }
        </>    
       

    )
}


export {JobberProfile}