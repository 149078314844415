import React, { useCallback, useState, useEffect, useRef } from 'react';
import { CopyToClipboard , JobberStarRating, JobberViewRating} from '../common/CommonUtilsMethods';
// import { useIsMobile } from '../custom-hooks/useIsMobile';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import axios, { AxiosResponse } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { JobberProfile } from './jobberProfileBuilder';
import Rating from '@mui/material/Rating';
import { Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import Modal from '@mui/material/Modal';
import StarIcon from '@mui/icons-material/Star';
import {	
	ServerResponse,
	RemoteExecutionServiceImpl,	
} from '../remoteExecution/RemoteExecutionServiceImpl';
import { TopNav, PushToNewSr, PushtoSrHistory } from './OrgCommonComponents';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import './myworkforce.css'
import {GetJobberProfile, GetJobberRatingV3, GetJobberResume} from '../shared-engineering-services/SharedAPICalls'
import { JobberProfileBuilder, JobberOrgRatingClientResp,
     OrgSpecifiedJobberPerformanceCategory, CreateOrgRatingForJobberReq, JobberPerformanceOrg,
     OrgAllJobberRatingsResp, GetJobberProfileAPIResp } from './orgConstants';

const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();
const defaultPic = './org-default-icon.png';        

const JobberProfileComponent = ({modalOpen, setModalOpen, jobberProfile, handleRefreshCallback}
    :{modalOpen:boolean, setModalOpen: (boolean) => void , jobberProfile: JobberProfileBuilder ,
     handleRefreshCallback}) =>{
            const isMobile = useIsMobile();
            
            
                        


    return <JobberProfileModalComponent modalOpen= {modalOpen} setModalOpen={setModalOpen} jobberProfile={jobberProfile} handleRefreshCallback={handleRefreshCallback}/>    
}



const JobberSendRating = ({handleRefreshCallback, jobberProfile} : {handleRefreshCallback: () => void, jobberProfile: JobberProfileBuilder}) => {
    const [value, setValue] = useState<number | null>(4);
    const [review, setReview] = useState<string>("");
    
    const getCategoryFromRating = (rating: number): string => {
      switch (rating) {
          case 0:
              return "UNSATISFACTORY";
          case 1:
              return "UNSATISFACTORY";
          case 2:
              return "UNSATISFACTORY";
          case 3:
              return "MEET_EXPECTATION";
          case 4:
              return "EXCEEDS_EXPECTATION";
          case 5:
              return "OUTSTANDING";
          
          
      }
    };


  const handleSubmitReview = async () => {
      const category = getCategoryFromRating(value);
      const requestBody:CreateOrgRatingForJobberReq = {
          jobberEmail: { value: jobberProfile.jobberEmail },
          category: category as OrgSpecifiedJobberPerformanceCategory,
          reason: review ? review : null,
          shiftId: null,
          shiftStartDateTimeEpochSec: null
      };
      
      // Perform your API request with the requestBody


      const response: AxiosResponse<ServerResponse<JobberPerformanceOrg, Error>> =
          await remoteCallObject.remotePOSTCallV2<ServerResponse<JobberPerformanceOrg, Error>,CreateOrgRatingForJobberReq>({
              apiUrl: backendApiBasePathLocal + 'jobber/performance/rating/org/create',
              object: requestBody,
              headers: { 'Content-Type': 'application/json' },
          });
      
      if (response && (response.status === 200 || response.status === 201 || response.status === 202)) {                    
      }
      handleRefreshCallback();


  };


    return(
      <div className='jobber-profile-send-ratings'>
          <Rating
              className='jobber-rating-send-stars'
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => { setValue(newValue); }}
          />
          <input
              className='jobber-rating-send-input'
              placeholder='Leave a review'
              maxLength={100}
              value={review}
              onChange={(event) => setReview(event.target.value)}
          />
          <button onClick={handleSubmitReview} className='jobber-rating-send-button'><KeyboardBackspaceRoundedIcon sx={{ fontSize: "20px", rotate: "90deg" }} /></button>
      </div>
    )
  }



const JobberProfileModalComponent = ({modalOpen, setModalOpen, jobberProfile, handleRefreshCallback}
    :{modalOpen:boolean, setModalOpen: (boolean) => void , jobberProfile: JobberProfileBuilder ,
     handleRefreshCallback}) =>{

        const isMobile = useIsMobile();
    
    return( jobberProfile ? <div>
        (<Modal open={modalOpen} onClose={() => setModalOpen(false)} disableAutoFocus={true}>
                <div className={isMobile? 'jobber-profile-modal-mobile':'jobber-profile-modal'}>
                <button onClick={() => setModalOpen(false)} className='modal-close'><CloseIcon/></button>

                    <div className='jobber-profile-modal-top'>
                        <div className='jobber-profile-modal-top-icon'><img src={jobberProfile && jobberProfile.jobberPic ? jobberProfile.jobberPic : defaultPic}/></div>
                        <div className='jobber-profile-modal-top-details-holder'>
                            <div className='jobber-profile-modal-top-name'>{ jobberProfile.jobberName}</div>

                            <JobberStarRating jobberRating={jobberProfile}/>

                            <div className='jobber-profile-modal-top-contact'>
                                <Tooltip title="Click to copy"><div className='jobber-profile-modal-top-contact-info' onClick={() => CopyToClipboard(jobberProfile.jobberEmail)}>{jobberProfile.jobberEmail}</div></Tooltip>
                                <Tooltip title="Click to copy"><div className='jobber-profile-modal-top-contact-info' onClick={() => CopyToClipboard(jobberProfile.jobberPhone)}>{jobberProfile.jobberPhone}</div></Tooltip>
                            </div>
                        </div>    
                    </div>
                    
                    <JobberViewRating jobberRating={jobberProfile}/>

                    <div className='jobber-profile-modal-bottom'>
                        <Accordion style={{marginBottom:"0px",boxShadow:"none", minHeight:"50px", display: "flex", flexDirection: "column", justifyContent:"center", width: "77.1111111111%", background:"#f5f5f7", borderRadius:"20px"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                            >
                            <span className='accordian-title'>Profile Summary</span>
                            </AccordionSummary> 
                            <AccordionDetails style={{marginBottom:"0px"}}>
                                <div className='profile-summary-text'>{ jobberProfile.jobberProfileQuickSummary}</div>
                            </AccordionDetails>
                        </Accordion>

                        <Tooltip placement='right' title="Click to Download Resume">
                            <div onClick={() =>{GetJobberResume({value:jobberProfile.jobberEmail})}} className='jobber-profile-modal-resume'>
                                <ArticleIcon style={{fontSize:"25px"}}/>
                            </div>
                        </Tooltip>
                    </div>

                    <JobberSendRating handleRefreshCallback={handleRefreshCallback} jobberProfile={jobberProfile}/>
                </div>

            </Modal>):(null)
    </div>
    : <div>
        <div>Jobber Profile Not Found</div>
    </div>)
}

export{JobberProfileComponent, JobberProfileModalComponent}