import React, { useState, useEffect } from 'react';
import './jobberhomepage2.css';
import { MobileNav } from '../jobberViews/JobberCommonComponents'



const PrevShiftBlock = (shiftDate,shiftTime,shiftHourlyPay, shiftPayTotal, shiftAddress) => {

    return(
        <div className='prev-jobber-shift-block'>
            <div className='jobber-shift-block-date'>{shiftDate}</div>
            <div className='jobber-shift-block-time'>{shiftTime}</div>
            <div className='jobber-shift-block-total-holder'>
                <div className='jobber-shift-block-total'>{shiftPayTotal}</div>
            </div>
        </div>
    )
}




const ShiftsPrev = () => {
    return(
        <div className='prev-shifts-container'>
            {PrevShiftBlock('Dec 11','9am-5pm','$18.00/hr','$144.59','1234 Factory Lane, Mississauga ON, L5B 1A6')}
            {PrevShiftBlock('Dec 12','9am-5pm','$18.00/hr','$144.59','1234 Factory Lane, Mississauga ON, L5B 1A6')}
            {PrevShiftBlock('Dec 13','9am-5pm','$18.00/hr','$144.59','1234 Factory Lane, Mississauga ON, L5B 1A6')}
            {PrevShiftBlock('Dec 14','9am-5pm','$18.00/hr','$144.59','1234 Factory Lane, Mississauga ON, L5B 1A6')}
            {PrevShiftBlock('Dec 15','9am-5pm','$18.00/hr','$144.59','1234 Factory Lane, Mississauga ON, L5B 1A6')}
            {PrevShiftBlock('Dec 16','9am-5pm','$18.00/hr','$144.59','1234 Factory Lane, Mississauga ON, L5B 1A6')}
            {PrevShiftBlock('Dec 17','9am-5pm','$18.00/hr','$144.59','1234 Factory Lane, Mississauga ON, L5B 1A6')}
            {PrevShiftBlock('Dec 18','9am-5pm','$18.00/hr','$144.59','1234 Factory Lane, Mississauga ON, L5B 1A6')}
        </div>
    )
}



const JobberPreviousShifts = () => {

    return(
        <>
        <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />        

        <div className='mobile-app'>

            <MobileNav/>

            <div style={{marginTop:'100px'}} className='mobile-section-title'>Previous Shifts</div>
            <ShiftsPrev/>


        </div>
        </>
    )

}

export {JobberPreviousShifts}