import React, { useState, useEffect, useMemo , useCallback } from 'react';
import { TopNav } from './OrgCommonComponents'
import './orgaccountsettings.css';
import Modal from '@mui/material/Modal';
import emailjs from 'emailjs-com';
import { OrgMetaData, AgreedRatesPerWorkerType } from './orgConstants';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PercentIcon from '@mui/icons-material/Percent';
import './accountSettings.css'
import Chip from '@mui/material/Chip';
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from '@mui/material';
import { RemoteGETCallInputObject,RemoteExecutionServiceImpl } from '../remoteExecution/RemoteExecutionServiceImpl';
import { AxiosResponse } from 'axios';
import { ServerResponse } from '../remoteExecution/RemoteExecutionServiceImpl';
import { JobberDetailsForOrg } from './OrgUtils';
import { JobberProfileBuilder } from './orgConstants';
import { JobberProfile } from './jobberProfileBuilder';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import { JobberProfileModalComponent } from './JobberProfileComponentOpen';

const firstName = localStorage.getItem('firstName');
const lastName = localStorage.getItem('lastName');
const userEmail = localStorage.getItem('userEmail');
const iconUrl = localStorage.getItem('orgIcon');
const companyname= localStorage.getItem('orgName');
const phoneNumber = localStorage.getItem('userPhoneNumber');
const signOutURI = process.env.REACT_APP_IDP_SIGN_OUT_URL;
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();
const defaultPic = './org-default-icon.png';


const handleGeneralEmailSubmit = async (e) => {
    e.preventDefault();

    const serviceID = 'service_3mlm2ls';
    const templateID = 'template_xl6xpjw';
    const userID = 'zKdgUxA7H3dMaCf-m';

    try {
      await emailjs.sendForm(serviceID, templateID, e.target, userID);
      alert('Email sent successfully!');
    } catch (error) {
      alert('An error occurred while sending the email.');
      console.error(error);
    }
  };


const OrgAccountSettings = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
    const userReason = "ORG: " + companyname + " Account Settings Change Request";
    const userFullName = firstName + " " + lastName;
    const orgMetaData:OrgMetaData = JSON.parse(localStorage.getItem('orgData'))
    const orgWorkyMarkup = orgMetaData && orgMetaData?.orgBillingData && orgMetaData?.orgBillingData?.workyMarkup ? (orgMetaData.orgBillingData.workyMarkup)*100 : null;
    const orgBillingInterval = orgMetaData && orgMetaData?.orgBillingData && orgMetaData?.orgBillingData?.billingTimeInterval ?  orgMetaData.orgBillingData.billingTimeInterval.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()): null;
    const orgCity = orgMetaData && orgMetaData?.addressDto && orgMetaData?.addressDto?.city ? orgMetaData?.addressDto?.city?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : null;
    const orgWarehouseRate = orgMetaData && orgMetaData?.orgBillingData && orgMetaData?.orgBillingData?.agreedRatesPerAWorkerType ? findIndex(JSON.stringify(orgMetaData?.orgBillingData?.agreedRatesPerAWorkerType)) : null;
    const [prevJobbers, setPrevJobbers] = useState<JobberDetailsForOrg[] | null>(null);
    const [hasLoadedPrevJobbers, setHasLoadedPrevJobbers] = useState(false);
    const isMobile = useIsMobile();

    const handleRefreshCallback = () => {
        console.log(" heheheheh ")
        setRefreshKey(refreshKey+1)
        // getAllJobberRating();
    }
    
    useEffect(() => {
        if (!hasLoadedPrevJobbers) {
            const makePrevJobbersApiCall = async () => {
                const getCallInput: RemoteGETCallInputObject = {
                    apiUrl: backendApiBasePathLocal + 'org/previousJobbers',
                    headers: {},
                    queryParams: null,
                };
                
                const prevJobbers: AxiosResponse<ServerResponse<JobberDetailsForOrg[], Error>> =
                await remoteCallObject.remoteGETCallV2<ServerResponse<JobberDetailsForOrg[], Error>>(
                    getCallInput
                    );
                    
                    if (
                        prevJobbers &&
                        prevJobbers.data &&
                        prevJobbers.data.data &&
                        prevJobbers.data.data.length >= 1
                        ) {
                            setPrevJobbers(prevJobbers.data.data);
                        }
                    };

            makePrevJobbersApiCall();
        }
    }, [hasLoadedPrevJobbers]);
    
    function findIndex(inputString: string) {
        const searchString = 'WAREHOUSE_ASSOCIATE';
        const indexstart:number = inputString.indexOf(searchString)+21;
        const indexend:number = inputString.length-1;
        const num = parseInt(inputString.slice(indexstart, indexend));
        return num;
    }
    
    
    const buildJobberProfiles = (data: JobberDetailsForOrg[]) => {
        if (!data || data.length === 0) {
            return []; // Return an empty array if the input data is invalid or empty
        }
    
        const jobberProfiles: JobberProfileBuilder[] = data.map((item) => {
            const userDetails = item.userDetails;
            const userProfilePicUrl = item.userProfilePicUrl;
    
            const jobberProfile: JobberProfileBuilder = {
                jobberName: `${userDetails.firstName} ${userDetails.lastName}`,
                jobberEmail: userDetails.userEmail.value,
                jobberPhone: userDetails.userPhoneNumber.value,
                jobberPic: userProfilePicUrl || '', // Use the userProfilePicUrl if available, otherwise an empty string
            };
    
            return jobberProfile;
        });
    
        return jobberProfiles;
    };
            
    const DesktopAccountSettings = () => {
        return(
            <div className='account-settings-page'>
        
        <div className='account-settings-container'>

            <div className='profile-content-top'>
                <div className='profile-pic'><img src={iconUrl? iconUrl:defaultPic}></img></div>
                <div className='profile-info'>
                    <div className='profile-info-half'>
                        <div className='profile-name'>{firstName +" "+lastName}</div>
                    </div>
                    <div className='profile-info-half'>

                        <div className='profile-card-top'>
                            <div className='profile-card-top-title'>Company</div>
                            <div className='profile-card-top-value'>{companyname}</div>
                        </div>

                        <div className='profile-card-top'>
                            <div className='profile-card-top-title'>Role</div>
                            <div className='profile-card-top-value'>Org Manager</div>
                        </div>

                        <div className='profile-card-top'>
                            <div className='profile-card-top-title'>Phone Number</div>
                            <div className='profile-card-top-value'>{phoneNumber}</div>
                        </div>

                        <div className='profile-card-top'>
                            <div className='profile-card-top-title'>Email Address</div>
                            <div className='profile-card-top-value'>{userEmail}</div>
                        </div>



                    </div>
                </div>
            </div>

            <div className='profile-content-bottom'>
                <div className='profile-card-bottom'>
                    <div className='profile-card-bottom-icon'><LocationOnIcon style={{ color: '#4c4c4c' }}/></div>
                    <div className='profile-card-bottom-holder'>
                        <div className='profile-card-bottom-holder-title'>Address</div>
                        <div className='profile-card-bottom-holder-value'>{orgCity}</div>
                    </div>
                </div>
                <div className='profile-card-bottom'>
                    <div className='profile-card-bottom-icon'><AttachMoneyIcon style={{ color: '#4c4c4c' }}/></div>
                    <div className='profile-card-bottom-holder'>
                        <div className='profile-card-bottom-holder-title'>Worker Rate</div>
                        <div className='profile-card-bottom-holder-value'>${orgWarehouseRate}/hr</div>
                    </div>
                </div>
                <div className='profile-card-bottom'>
                    <div className='profile-card-bottom-icon'><CalendarMonthIcon style={{ color: '#4c4c4c' }}/></div>
                    <div className='profile-card-bottom-holder'>
                        <div className='profile-card-bottom-holder-title'>Billing Interval</div>
                        <div className='profile-card-bottom-holder-value'>{orgBillingInterval}</div>
                    </div>
                </div>
                <div className='profile-card-bottom'>
                    <div className='profile-card-bottom-icon'><PercentIcon style={{ color: '#4c4c4c' }}/></div>
                    <div className='profile-card-bottom-holder'>
                        <div className='profile-card-bottom-holder-title'>Worky Charge</div>
                        <div className='profile-card-bottom-holder-value'>{orgWorkyMarkup}%</div>
                    </div>
                </div>
            </div> 


            <div className='profile-settings-holder'></div>
            

        </div>
        <div id='second' className='account-settings-title2'>My Workers</div>

            <div className='profile-account-jobbers'>
            <div className='profile-account-jobbers-inner'>
                {
                // hasLoadedPrevJobbers && 
                prevJobbers ? (
                    buildJobberProfiles(prevJobbers).map((jobber, index) => (
                        <JobberProfile
                            key={index}
                            hasCheck={false}
                            jobberProfile={jobber}
                        />
                    ))
                ) : (
                    <div>Loading jobber profiles...</div>
                )}
            </div>
            </div>

    </div>
        )
    }        
    const copyToClipboard = async (value: string) => {
        try {
          await navigator.clipboard.writeText(value);
          console.log("Copied to clipboard: " + value);
        } catch (err) {
          console.error("Unable to copy to clipboard:", err);
        }
      };


    const MobileAccountSettings = () => {
        const defaultPic = './org-default-icon.png';

        return (
            <>
            
            <div className='account-settings-container-mobile'>
                <div className='profile-info-holder-mobile'>
                    <div id="org-account-mobile">
                        <div className='jobber-profile-modal-top-icon'><img src={iconUrl ? iconUrl : defaultPic}/></div>
                        <div className='jobber-profile-modal-top-details-holder'>
                            <div className='jobber-profile-modal-top-name'>{userFullName}</div>
                            <div className='jobber-profile-modal-top-contact'>
                                <Tooltip title="Click to copy"><div className='jobber-profile-modal-top-contact-info' onClick={() => copyToClipboard(userEmail)}>{userEmail}</div></Tooltip>
                                <Tooltip title="Click to copy"><div className='jobber-profile-modal-top-contact-info' onClick={() => copyToClipboard(phoneNumber)}>{phoneNumber}</div></Tooltip>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
            
            <div className='profile-settings-title-mobile'>MY SETTINGS</div>
            <div className='general-settings-container-mobile'>
                <div className='general-info-holder-mobile'>

                    {/* company name */}
                    <div className='general-info-row-mobile'>
                        <div className='general-info-row-icon'><BusinessIcon style={{fontSize:"19px"}}/></div>
                        <div className='general-info-row-value'>{companyname}</div>
                    </div>
                    <div className='general-info-line-mobile'></div>
                    {/* role */}
                    <div className='general-info-row-mobile'>
                        <div className='general-info-row-icon'><PersonIcon style={{fontSize:"19px"}}/></div>
                        <div className='general-info-row-value'>Org Manager</div>
                    </div>
                    <div className='general-info-line-mobile'></div>

                    {/* email */}
                    <div className='general-info-row-mobile'>
                        <div className='general-info-row-icon'><AlternateEmailIcon style={{fontSize:"19px"}}/></div>
                        <div className='general-info-row-value'>{userEmail}</div>
                    </div>
                    <div className='general-info-line-mobile'></div>

                    {/* phone number */}
                    <div className='general-info-row-mobile'>
                        <div className='general-info-row-icon'><CallIcon style={{fontSize:"19px"}}/></div>
                        <div className='general-info-row-value'>{phoneNumber}</div>
                    </div>


                </div>
            </div>
            
            


            <div className='profile-settings-title-mobile'>{companyname.toUpperCase()} SETTINGS</div>
            <div className='general-settings-container-mobile'>
                <div className='general-info-holder-mobile'>

                    {/* address */}
                    <div className='general-info-row-mobile'>
                        <div className='general-info-row-icon'><PlaceIcon style={{fontSize:"19px"}}/></div>
                        <div className='general-info-row-value'>{orgCity}</div>
                    </div>
                    <div className='general-info-line-mobile'></div>

                    {/* worker rate */}
                    <div className='general-info-row-mobile'>
                        <div className='general-info-row-icon'><AttachMoneyIcon style={{fontSize:"19px"}}/></div>
                        <div className='general-info-row-value'>${orgWarehouseRate}/hr</div>
                    </div>
                    <div className='general-info-line-mobile'></div>

                    {/* billing interval */}
                    <div className='general-info-row-mobile'>
                        <div className='general-info-row-icon'><CalendarMonthIcon style={{fontSize:"19px"}}/></div>
                        <div className='general-info-row-value'>{orgBillingInterval}</div>
                    </div>
                    <div className='general-info-line-mobile'></div>

                    {/* worky charge */}
                    <div className='general-info-row-mobile'>
                        <div className='general-info-row-icon'><PercentIcon style={{fontSize:"19px"}}/></div>
                        <div className='general-info-row-value'>{orgWorkyMarkup}</div>
                    </div>

                </div>
            </div>

            <div className='profile-settings-title-mobile'>MY WORKERS</div>
            <div className='general-settings-container-mobile2'>
            <div className='general-info-holder-mobile2'>

                {
                prevJobbers ? (
                    buildJobberProfiles(prevJobbers).map((jobber, index) => (
                        <JobberProfile
                            key={index}
                            hasCheck={false}
                            jobberProfile={jobber}
                        />
                    ))
                ) : (
                    <div>Loading jobber profiles...</div>
                )}

            </div>
            </div>
            
            </>
        )
    }



            
    return(
        <div className='org-app'>
            <Modal open={modalOpen} onClose={handleClose}>
                <form id='modal2' className='modal-contact' onSubmit={handleGeneralEmailSubmit}>
                    <div className='srstatus-date'>What would you like to Change?</div>
                    <input style={{display:"none"}} name='user_fullname' defaultValue={userFullName}></input>
                    <input style={{display:"none"}} name='user_reason' defaultValue={userReason}></input>
                    <input style={{display:"none"}} className='modal-email' name="user_email" defaultValue={userEmail === null || userEmail === undefined? 'info@worky.pro':userEmail}/>
                    <div id="message2" className='modal-title'>Message:</div>
                    <textarea name="user_message" className='modal-message'></textarea>
                    <button className='modal-submit'>Send</button>
                </form>
            </Modal>
            
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />
            <TopNav
                firstName={firstName}
                lastname={lastName}
                companyname={companyname}
                iconUrl={iconUrl}
                orgEmail={userEmail}
              />
            {/* <div className='srstatus-container'> */}
            {/* <div className='account-settings-title2'>Account Settings</div> */}
            {isMobile?  <MobileAccountSettings/>:<DesktopAccountSettings/>}
            

            <div className='account-buttons'>
                <button onClick={handleOpen} className='account-settings-change'>See something you'd like to change? Click here.</button>
                <button onClick={() => {window.location.assign(signOutURI);}} className='account-settings-signout'>Sign Out</button>
            </div>

            <div onClick={()=>{window.location.assign('homepage')}} className='worky-footer'>worky</div>

        </div>
    )

}

export {OrgAccountSettings}